import { api } from "../../api";

const driversApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDrivers: builder.query({
      query: ({ page = 1, limit = 20, refreshKey }) =>
        `drivers?page=${page}&limit=${limit}`,
      // The refreshKey is not used in the actual query, but its presence in the arg
      // will cause RTK Query to refetch when it changes
      // transformResponse: (response) => {
      //   return response.drivers;
      // },
    }),
  }),
  overrideExisting: false,
});

export const { useGetDriversQuery } = driversApi;
