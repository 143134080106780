import React from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";

function Table({ columns, data }) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const thClasses = classNames(
    " border-b-2 py-2 text-xs text-left px-1 text-transform: capitalize"
  );
  const tdClasses = classNames("py-2 text-sm text-left px-1");

  return (
    <table id="totals">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} className={thClasses}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id} className={tdClasses}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const TotalsTable = (props) => {
  const tableData = [props.tableData];

  const columns = React.useMemo(() => props.columns, []);
  const data = React.useMemo(() => tableData, [tableData]);

  return props.tableData && <Table columns={columns} data={data} />;
};

export default TotalsTable;
