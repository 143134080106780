import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../shared/Button";

export default function NoMatch() {
  const navigate = useNavigate();

  function handleClick() {
    navigate("/", { replace: true });
  }

  return (
    <main className="grid mt-48 place-items-center">
      <div className="flex flex-col items-center">
        <h2 className="text-6xl font-semibold">404</h2>
        <p className="text-lg text-center font-bold my-2">
          This page does not exist
        </p>
        <Button onClick={handleClick}>Back Home</Button>
      </div>
    </main>
  );
}
