import { useState } from "react";
import Input from "../../../../shared/Input";
import Toggle from "../../../../shared/Toggle";

const Coowner = ({ form, onFormChange, clearForm }) => {
  const [toggle, setToggle] = useState(false);

  function onToggle(e) {
    const { checked } = e.target;

    if (!checked) {
      clearForm("coowner");
    }

    setToggle(checked);
  }

  return (
    <>
      <div className="flex items-center">
        <h3 className="block mr-3 text-lg font-medium text-gray-900">
          Co-owner
        </h3>
        <Toggle
          size="sm"
          checked={form.coowner || toggle}
          onChange={onToggle}
        />
      </div>
      {(form.coowner || toggle) && (
        <Input
          id="coowner"
          value={form.coowner}
          label="Name"
          placeholder="Co-owner Name"
          onChange={onFormChange}
        />
      )}
    </>
  );
};

export default Coowner;
