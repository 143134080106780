import { XMarkIcon } from "@heroicons/react/24/outline";
import Avatar from "../shared/Avatar";

export const AvatarClient = (props) => {
  return (
    <div
      onClick={props.onClick}
      className="flex items-center justify-between hover:bg-gray-100 rounded-lg py-2 px-1 cursor-pointer"
    >
      <div className="flex items-center space-x-2">
        <Avatar size="xs" src={props.avatar} />
        <span className="font-semi-bold text-sm">
          {props.name}{" "}
          <span className="text-gray-500">{`(${props.slug?.toUpperCase()})`}</span>
          {/* <span className="text-gray-500">{`(${props.slug.toUpperCase()})`}</span> */}
        </span>
      </div>
      <div>
        {props.selected && (
          <XMarkIcon onClick={props.onCancel} className="w-5 h-5" />
        )}
      </div>
    </div>
  );
};
