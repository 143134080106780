import classNames from "classnames";
import React from "react";

const RightContent = (props) => {
  return React.Children.map(props.content || props.children, (child, i) => {
    const classes = classNames(
      "flex h-full w-full items-center hover:bg-primary-100 h-full"
    );

    const childClasses = classNames(
      "px-2 cursor-pointer",
      child.props.className
    );

    return (
      <div
        key={i}
        className={classes}
        style={{
          ...child.props.style,
          cursor: child.props.cursor,
        }}
        // {...child.props}
      >
        {React.cloneElement(child, {
          className: childClasses,
        })}
      </div>
    );
  });
};

export default RightContent;
