import { jobs } from "./jobs";
import { dashboard } from "./dashboard";
import { drivers } from "./drivers";
import { invoices } from "./invoices";
import { payouts } from "./payouts";
import { clients } from "./clients";
import { users } from "./users";
import { settings } from "./settings";
import { dispatch } from "./dispatch";
import { equipment } from "./equipment";
export const en = {
  translation: {
    jobs,
    dashboard,
    drivers,
    equipment,
    invoices,
    payouts,
    clients,
    settings,
    users,
    dispatch,
  },
};
