import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const clientsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = getSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `clients?${query}`;
      },
      providesTags: (data) =>
        data?.client
          ? [
              ...data.client.map(({ _id }) => ({ type: "Clients", id: _id })),
              { type: "Clients", id: "LIST" },
            ]
          : [{ type: "Clients", id: "LIST" }],
    }),
    addClient: builder.mutation({
      query(body) {
        return {
          url: "clients",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Clients", id: "LIST" }],
    }),
    getClient: builder.query({
      query: ({ id, ...rest }) => `clients/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
      providesTags: (_result, _error, data) => {
        return [{ type: "Clients", id: data.id }];
      },
    }),
    updateClient: builder.mutation({
      query({ id, data }) {
        return {
          url: `/clients/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => {
        return [{ type: "Clients", id }];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetClientsQuery,
  useAddClientMutation,
  useGetClientQuery,
  useUpdateClientMutation,
} = clientsApi;
