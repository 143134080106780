import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const initialState = {
  payout: {},
  status: "idle",
  error: null,
};

export const fetchPayout = createAsyncThunk(
  "payout/fetchPayout",
  async ({ payoutId, ...rest }) => {
    const queries = renderQueries(rest);
    const res = await request(`/payouts/${payoutId}?${queries}`);
    return res.data;
  }
);

export const addNewPayout = createAsyncThunk(
  "payout/addNewPayout",
  async ({ parentId, data }) => {
    const res = await request(`/payouts?parent=${parentId}`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const updatePayout = createAsyncThunk(
  "payout/updatePayout",
  async ({ parentId, payoutId, data, legacy = true }) => {
    const res = await request(
      `payouts/${payoutId}${legacy ? "/legacy" : ""}?parent=${parentId}`,
      {
        method: "put",
        data,
      }
    );
    return res.data;
  }
);

export const updatePayoutLeg = createAsyncThunk(
  "payout/updatePayoutLeg",
  async ({ data, payoutId, legId, ...obj }) => {
    const queries = renderQueries(obj);
    const res = await request(`/payouts/${payoutId}/leg/${legId}?${queries}`, {
      method: "put",
      data: data,
      obj: obj,
    });
    if (!res.data) {
      rejectWithValue(res.data);
      return;
    }
    return res.data;
  }
);

export const payoutSlice = createSlice({
  name: "payout",
  initialState,
  reducers: {
    resetPayout: () => initialState,
    payoutLegUpdated(state, action) {
      const { _id } = action.payload;
      const index = state.payout.legs?.findIndex((item) => item._id === _id);
      state.payout.legs[index] = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPayout.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPayout.fulfilled, (state, action) => {
        state.payout = {};
        state.status = "succeeded";
        state.payout = action.payload.data;
      })
      .addCase(fetchPayout.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewPayout.fulfilled, (state, action) => {
        state.payout = action.payload.data;
      })
      .addCase(updatePayout.fulfilled, (state, action) => {
        state.payout = action.payload.data;
      })
      .addCase(updatePayoutLeg.fulfilled, (state, action) => {
        state.payout = action.payload.data;
      });
  },
});

export const { resetPayout, payoutLegUpdated } = payoutSlice.actions;

export default payoutSlice.reducer;

export const selectPayout = (state) => state.payout.payout;
