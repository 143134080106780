export const jobs = {
  main: "Jobs",
  orderNumber: "Order Number",
  delete: {
    title: "Delete job",
    default: "Are you sure you want to delete this job?",
    "remove-legs": "Please remove all job(s) legs before deleting",
    "remove-invoice":
      "Please remove job(s) from the connected invoice before deleting",
    "remove-manifest":
      "Please remove job(s) legs from the connected manifest before deleting",
    "remove-payout":
      "Please remove job(s) legs from the connected payout before deleting",
  },
};
