import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../shared/Table/table.utils";

// Config for table and form
const tableConfig = [
  {
    id: "fullName",
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/drivers/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "email",
    validations: { required: true, email: true },
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "firstName",
    visible: false,
    required: true,
  },
  {
    id: "lastName",
    visible: false,
  },
  "phone",
  { id: "type", visible: false },
  {
    id: "displayName",
    visible: false,
  },
  { id: "authority", visible: false },
  {
    id: "active",
    cell: ({ row: { original }, value }) => {
      return value ? (
        <CheckCircleIcon className="w-5 h-5 text-green-500" />
      ) : (
        <XCircleIcon className="w-5 h-5 text-red-500" />
      );
    },
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = { validations: renderValidations(tableConfig) };
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Jobs", value: 10 },
  { label: "20 Jobs", value: 20 },
  { label: "50 Jobs", value: 50 },
  { label: "100 Jobs", value: 100 },
  { label: "150 Jobs", value: 150 },
];

const exportObj = { COLUMN_DEFINITIONS, FORM_OPTIONS, PAGE_SIZE_OPTIONS };
export default exportObj;
