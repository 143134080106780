import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Input from "../../shared/Input";
import useForm from "../../hooks/useForm";
import { Button, Modal, Select } from "../../shared";
import { AddressForm } from "../../components/Address";
import { useAddClientMutation } from "../../redux/services/clients/clientsApi";
import { FORM_OPTIONS } from "./table.config";

export const CreateClientModal = ({ visible, setVisible, onCancel }) => {
  const navigate = useNavigate();
  const { form, updateForm, clearForm, setFormInit, errors, isValid } =
    useForm(FORM_OPTIONS);

  const [addClient, { isLoading }] = useAddClientMutation();

  function closeModal() {
    clearForm();
    onCancel && onCancel(false);
    setVisible(false);
  }

  function updateAddress(e) {
    setFormInit({
      ...form,
      address: {
        ...form.address,
        [e.id]: e.value,
      },
    });
  }

  async function handleSave() {
    const res = await addClient(form).unwrap();
    toast(res.message, {
      toastId: "add-client-" + res.status,
      type: res.status,
    });

    if (res.status === "success") {
      navigate("/clients/" + res.data._id);
      closeModal();
    }
  }

  const footer = (
    <div>
      <Button type="alternative" className="mr-2" onClick={closeModal}>
        Cancel
      </Button>
      <Button
        id="submit-client"
        loading={isLoading}
        disabled={isLoading || !isValid}
        onClick={handleSave}
      >
        Submit
      </Button>
    </div>
  );

  const content = (
    <div className="space-y-4">
      <div>
        <div className="text-lg font-medium text-gray-900 mb-2">Details</div>
        <div className="grid grid-cols-2 gap-3">
          <Input
            id="name"
            value={form.name}
            label="Client Name"
            placeholder="Client Name"
            onChange={updateForm}
            error={errors.name}
          />
          <Input
            id="email"
            value={form.email}
            label="Email"
            placeholder="Email"
            onChange={updateForm}
            error={errors.email}
          />
          <Input
            id="phone"
            value={form.phone}
            label="Phone"
            placeholder="Phone"
            onChange={updateForm}
            error={errors.phone}
          />
        </div>
      </div>
      <div>
        <div className="text-lg font-medium text-gray-900 mb-2">Address</div>
        <AddressForm
          form={form}
          errors={errors}
          updateForm={updateForm}
          inputSize="md"
        />
      </div>
    </div>
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={closeModal}
      title="Create Client"
      footer={footer}
      content={content}
      size="md"
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};
