import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import request, { renderQueries } from "../../../utils/request";

const initialState = {
  payouts: [],
  status: "idle",
  error: null,
};

export const fetchPayouts = createAsyncThunk(
  "payouts/fetchPayouts",
  async (obj, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/payouts?${queries}`);
    if (!res.data.payouts) {
      rejectWithValue(res.data);
      return;
    }
    return res.data;
  }
);

export const deletePayouts = createAsyncThunk(
  "payouts/deletePayouts",
  async ({ data, parentId }) => {
    const res = await request(`/payouts?parent=${parentId}`, {
      method: "delete",
      data,
    });
    return res.data;
  }
);

export const payoutsSlice = createSlice({
  name: "payouts",
  initialState,
  reducers: {
    resetPayouts: () => initialState,
    payoutUpdated(state, action) {
      const { _id } = action.payload;
      const index = state.payouts.findIndex((item) => item._id === _id);
      state.payouts[index] = action.payload;
    },
    setPayouts(state, action) {
      state.payouts = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPayouts.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPayouts.fulfilled, (state, action) => {
        state.payouts = [];
        state.status = "succeeded";
        state.payouts = state.payouts.concat(action.payload.payouts);
      })
      .addCase(fetchPayouts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deletePayouts.fulfilled, (state, action) => {
        const payoutsDeleted = action.payload.data;
        const newPayouts = state.payouts.filter(
          (x) => !payoutsDeleted.includes(x._id)
        );
        state.payouts = newPayouts;
      })
      .addCase(deletePayouts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetPayouts, setPayouts, payoutUpdated } = payoutsSlice.actions;
export default payoutsSlice.reducer;
export const selectAllPayouts = (state) => state.payouts.payouts;
export const selectAllPayoutsOptions = (state) => {
  return state.payouts.payouts.map(({ name }) => {
    return {
      value: name,
      label: name,
    };
  });
};
export const selectPayoutsById = (state, payoutId) =>
  state.payouts.payouts.find((payout) => payout.id === payoutId);
