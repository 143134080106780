import { useGetClientsQuery } from "../redux/services/clients/clientsApi";
import { CheckboxList } from "./CheckboxList";

export const ClientsFilterList = (props) => {
  const { data: clients } = useGetClientsQuery({
    page: 1,
    limit: 300,
  });

  function handleChange(val) {
    const title =
      val.map(({ name }) => name).join(", ") || props.value?.defaultText;
    let value = val.map((client) => client._id);

    if (Array.isArray(value)) {
      if (value.length === 0) {
        value = null;
      } else {
        value = value.join(",");
      }
    }

    props.onChange({ title, value });
  }

  return (
    <CheckboxList
      objectKey="_id"
      value={props.value.value}
      onChange={handleChange}
      data={clients?.clients || []}
    />
  );
};
