import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchClient } from "../../../redux/reducers/clients/clientSlice";
import { useAuth } from "../../../hooks/useAuth";

const ClientEdit = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const clientStatus = useSelector((state) => state.clients.status);

  useEffect(() => {
    if (clientStatus === "idle" && id) {
      async function getData() {
        dispatch(fetchClient({ clientId: id, parentId: user.parentCompany }));
      }
      getData();
    }
  });

  return <div></div>;
};

export default ClientEdit;
