import { CheckboxList } from "./CheckboxList";
import { jobStages } from "../pages/Jobs/job-stages";

const statusList = Object.keys(jobStages).map((key) => {
  const stage = jobStages[key];
  return { id: stage.id, name: stage.title };
});

export const StatusFilterList = (props) => {
  function handleChange(val) {
    const value = val.map(({ name }) => name).join(",");
    const title = value || props.value?.defaultText;

    props.onChange({ title, value });
  }

  return (
    <CheckboxList
      onChange={handleChange}
      objectKey="name"
      value={props.value.value}
      data={statusList}
    />
  );
};
