import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../../utils/request";

const initialState = {
  media: {},
  status: "idle",
  error: null,
};

export const fetchMedia = createAsyncThunk(
  "media/fetchMedia",
  async ({ parentId, jobId }) => {
    const res = await request(`/media/${jobId}?parent=${parentId}`);
    return res.data;
  }
);

export const addNewMedia = createAsyncThunk(
  "media/addNewMedia",
  async ({ parentId, data }) => {
    const res = await request(`/media?parent=${parentId}`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const updateJob = createAsyncThunk(
  "media/updateJob",
  async ({ parentId, jobId, data }) => {
    const res = await request(`media/${jobId}?parent=${parentId}`, {
      method: "put",
      data,
    });
    return res.data;
  }
);

export const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMedia.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchMedia.fulfilled, (state, action) => {
        state.job = {};
        state.status = "succeeded";
        state.job = action.payload.job;
      })
      .addCase(fetchMedia.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewMedia.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addNewMedia.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.job = action.payload;
      });
  },
});

export const {} = mediaSlice.actions;

export default mediaSlice.reducer;
