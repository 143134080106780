import { useEffect, useState } from "react";
import Radio from "../Radio";
import classNames from "classnames";
import Calendar from "../Calendar";
import Button from "../Button";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYesterday,
  format,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYesterday,
  subMonths,
  subWeeks,
} from "date-fns";
import { camelCase } from "lodash";
import Spinner from "../Spinner";

export const RichDatePickerPanel = (props) => {
  const [type, setType] = useState("allTime");
  const [value, setValue] = useState({});
  const [customValue, setCustomValue] = useState();
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    if (props.value && !value.id) {
      const type = props.value.defaultValue?.id || props.value.type;
      setValue(
        props.value.defaultValue || {
          id: props.value.type,
          value: props.value.value,
        }
      );
      if (type === "custom") {
        setSelectedDate(props.value.value.start);
      }
      handleType({ type });
    }
  }, []);

  function handleTypeChange(e) {
    setType(e.id);
  }

  function handleType(val) {
    let id = camelCase(val.displayText || val.type);

    if (id === "last24Hours") {
      id = "last24";
    }

    setType(id);
  }

  function handleChange(e) {
    props.onChange && props.onChange(e);
    setValue(e);
  }

  const wrapper = classNames("flex", {
    // "grid-cols-1": type !== "custom",
    // "grid-cols-2 md:grid-cols-3": type === "custom",
  });

  /**
   * Get set ranges
   * Last month, last week, last 24 hours
   *
   * @param {string} id - Must be lastMonth lastWeek last24, today
   * @returns {object} - { id, value: { start, end } }
   */
  function getQuickRanges(e) {
    const typeId = e.id;

    const newDate = new Date();

    switch (typeId) {
      case "lastMonth":
        const lastMonth = subMonths(newDate, 1);
        const start = startOfMonth(lastMonth);
        const end = endOfMonth(start);
        return {
          id: typeId,
          value: {
            start,
            end,
          },
        };
      case "thisMonth":
        const stM = startOfMonth(newDate);
        const endM = endOfMonth(newDate);
        return {
          id: typeId,
          value: {
            start: stM,
            end: endM,
          },
        };
      case "lastWeek":
        const lastWeek = subWeeks(newDate, 1);
        const startWeek = startOfWeek(lastWeek);
        const endOfLastWeek = endOfWeek(startWeek);

        return {
          id: typeId,
          value: { start: startWeek, end: endOfLastWeek },
        };
      case "thisWeek":
        const stW = startOfWeek(newDate);
        const endW = endOfWeek(newDate);

        return {
          id: typeId,
          value: { start: stW, end: endW },
        };
      case "yesterday":
        return {
          id: typeId,
          value: { start: startOfYesterday(), end: endOfYesterday() },
        };
      case "today":
        return {
          id: typeId,
          value: { start: startOfToday(), end: endOfDay(newDate) },
        };
      default:
        return { id: typeId, value: null };
    }
  }

  function handleQuickRanges(e) {
    const ranges = getQuickRanges(e);
    handleTypeChange(ranges);
    handleChange({ type: e.id, ...e, ...ranges });
  }

  function handleCustomDate(e) {
    const data = {
      type: e.id,
      ...e,
      title: format(e.value, "PP"),
      value: {
        start: startOfDay(e.value),
        end: endOfDay(e.value),
      },
    };

    setSelectedDate(e.value);
    setCustomValue(data);
  }

  function handleSubmit() {
    handleChange(customValue);
    props.close();
  }

  return (
    <div className={wrapper}>
      {!value.id ? (
        <Spinner />
      ) : (
        <>
          <div className="px-4 py-2">
            <Radio
              id="allTime"
              checked={type === "allTime"}
              onChange={(e) =>
                handleQuickRanges({ id: e.id, title: "All Time" })
              }
              label="All Time"
              size="xs"
            />
            <Radio
              id="today"
              checked={type === "today"}
              onChange={(e) => handleQuickRanges({ id: e.id, title: "Today" })}
              label="Today"
              size="xs"
            />
            <Radio
              id="yesterday"
              checked={type === "yesterday"}
              onChange={(e) =>
                handleQuickRanges({ id: e.id, title: "Yesterday" })
              }
              label="Yesterday"
              size="xs"
            />
            <Radio
              id="thisWeek"
              checked={type === "thisWeek"}
              onChange={(e) =>
                handleQuickRanges({ id: e.id, title: "This Week" })
              }
              label="This Week"
              size="xs"
            />
            <Radio
              id="lastWeek"
              checked={type === "lastWeek"}
              onChange={(e) =>
                handleQuickRanges({ id: e.id, title: "Last Week" })
              }
              label="Last Week"
              size="xs"
            />
            <Radio
              id="thisMonth"
              checked={type === "thisMonth"}
              onChange={(e) =>
                handleQuickRanges({ id: e.id, title: "This Month" })
              }
              label="This Month"
              size="xs"
            />
            <Radio
              id="lastMonth"
              checked={type === "lastMonth"}
              onChange={(e) =>
                handleQuickRanges({ id: e.id, title: "Last Month" })
              }
              label="Last Month"
              size="xs"
            />
            <Radio
              id="custom"
              checked={type === "custom"}
              onChange={handleTypeChange}
              label="Custom"
              size="xs"
            />
          </div>
          {type === "custom" && (
            <>
              <div className="px-4 py-2">
                {/* 
              Need to implement
            */}
                {/* <div className="pb-2">
              <Toggle
                label="Range"
                checked={range}
                onChange={() => setRange(!range)}
                size="sm"
              />
            </div> */}

                <div className="w-full">
                  <Calendar
                    value={selectedDate || new Date()}
                    onChange={(value) =>
                      handleCustomDate({ id: "custom", value })
                    }
                    size="sm"
                  />
                </div>
                <div className="flex mt-2 space-x-2 pt-1">
                  <Button
                    size="xs"
                    className="w-full"
                    type="light"
                    onClick={props.close}
                  >
                    Cancel
                  </Button>
                  <Button size="xs" className="w-full" onClick={handleSubmit}>
                    Save
                  </Button>
                </div>
              </div>
              {/* <div>Time</div> */}
            </>
          )}
        </>
      )}
    </div>
  );
};
