import { renderCustomColumnDefs } from "../../../shared/Table/table.utils";
import { renderAmount, renderTotalMileage } from "../../../utils";

// Config for table and form
const tableConfig = [
  {
    Header: "Orders",
    id: "jobs",
    cell: ({ getValue }) => getValue().length, //`${getValue()?.toLocaleString("en-US")} MI`,
    minSize: 120,
  },
  {
    id: "totalMileage",
    header: "Mileage",
    cell: ({ getValue }) => renderTotalMileage(getValue()), //`${getValue()?.toLocaleString("en-US")} MI`,
    minSize: 120,
  },
  {
    id: "totalWeight",
    header: "Weight",
    cell: ({ getValue }) => `${getValue()?.toLocaleString("en-US")} lb`,
    minSize: 160,
  },
  {
    id: "totalBaseCharges",
    header: "Base Charges",
    cell: ({ getValue }) => renderAmount(getValue()),
    minSize: 120,
  },
  {
    id: "totalDropCharges",
    header: "Drop Charges",
    cell: ({ getValue }) => renderAmount(getValue()),
    minSize: 100,
  },
  {
    id: "totalStandByTime",
    header: "Stand By Time",
    cell: ({ getValue }) => `${getValue()?.toLocaleString("en-US")} hrs`,
    size: 90,
  },
  {
    id: "totalOtherCharges",
    header: "Other Charges",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 100,
  },
  {
    Header: "Fuel Surcharge",
    id: "totalFuelCharges",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 100,
  },
  {
    Header: "Total",
    id: "total",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 120,
  },
];

export const TOTAL_COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
