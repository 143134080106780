import Card from "../../../shared/Card";
import EditableText from "../../../shared/EditableText";
import { parseFixed, renderAmount } from "../../../utils";

const PayoutTotals = ({ info, handleUpdate }) => {
  return (
    <>
      <Card className="row-span-2 px-5 py-4">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 lg:grid-cols-4 xl:grid-cols-7">
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">Total</span>
            <span className="font-semibold text-lg">
              <p
                type="text"
                id="total"
                displayText={(e) => (
                  <span className="font-semibold text-lg">{e}</span>
                )}
                value={info.total}
                onSave={handleUpdate}
              >
                {renderAmount(Number(info.total))}
              </p>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Total Miles
            </span>
            <span className="font-semibold text-lg">
              <p>
                {parseFixed(info.totalMileage || 0).toLocaleString("en-US")} mi
              </p>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Mileage Total
            </span>
            <span className="font-semibold text-lg">
              <p
                type="number"
                id="mileageTotal"
                displayText={(e) => (
                  <span className="font-semibold text-lg">{e}</span>
                )}
                value={info.mileageTotal}
                onSave={handleUpdate}
              >
                {renderAmount(info.mileageTotal)}
              </p>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Supplemental Total
            </span>
            <span className="font-semibold text-lg">
              <p
                type="number"
                id="supplementalTotal"
                displayText={(e) => (
                  <span className="font-semibold text-lg">{e}</span>
                )}
                value={info.supplementalTotal}
                onSave={handleUpdate}
              >
                {renderAmount(info.supplementalTotal)}
              </p>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Total Hours
            </span>
            <span className="font-semibold text-lg">
              <p
                type="number"
                id="hours"
                displayText={(e) => (
                  <span className="font-semibold text-lg">{e}</span>
                )}
                value={info.hours}
                onSave={handleUpdate}
              >
                {info.hours}
              </p>
            </span>
          </div>
          {/* <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Hourly Rate
            </span>
            <span className="font-semibold text-lg">
              <p
                type="number"
                id="hourlyRate"
                displayText={(e) => (
                  <span className="font-semibold text-lg">{e}</span>
                )}
                value={info.rate}
                onSave={handleUpdate}
              >
                {info.rate}
              </p>
            </span>
          </div> */}
          {/* <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Hourly Total
            </span>
            <span className="font-semibold text-lg">
              <p
                type="number"
                id="hourlyTotal"
                displayText={(e) => (
                  <span className="font-semibold text-lg">{e}</span>
                )}
                value={info.hourlyTotal}
                onSave={handleUpdate}
              >
                {renderAmount(info.hourlyTotal)}
              </p>
            </span>
          </div> */}
        </div>
      </Card>

      {/* <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title="Table Preferences"
        footer={modalFooter}
        content={modalContent}
        size="md"
        footerPosition="right"
      /> */}
    </>
  );
};

export default PayoutTotals;
