import SideMenu from "../../../../shared/SideMenu";
import GeneralSettings from "./General";
import Rates from "./Rates";

const ClientSettings = (props) => {
  const config = {
    client: [
      {
        title: "General",
        pageTitle: "Client Settings",
        content: <GeneralSettings client={props.client} />,
      },
      {
        title: "Rates",
        content: (
          <Rates rates={props.client?.rates} clientId={props.client?._id} />
        ),
      },
      // {
      //   title: "Members",
      //   content: <ClientMembers clientId={props.client?._id} />,
      //   action: (
      //     <Button
      //       // onClick={() => setAddModalVisible(true)}
      //       size={"xs"}
      //       icon={<PlusCircleIcon className={`w-4 h-4 mr-2`} />}
      //     >
      //       Invite Member
      //     </Button>
      //   ),
      // },
    ],
  };

  return <SideMenu config={config} />;
};

export default ClientSettings;
