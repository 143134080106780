import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "@heroicons/react/24/outline";

const DateRangePicker = ({ startDate, endDate, onChange, ...props }) => {
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [start, end] = dateRange;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChange = (update) => {
    setDateRange(update);
    if (onChange) {
      onChange(update);
    }
  };

  return (
    <div className="relative z-50">
      <DatePicker
        selectsRange={true}
        startDate={start}
        endDate={end}
        onChange={handleChange}
        monthsShown={isMobile ? 1 : 2}
        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
        customInput={<CustomInput />}
        popperClassName="react-datepicker-popper"
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
          {
            name: "preventOverflow",
            options: {
              rootBoundary: "viewport",
              tether: false,
              altAxis: true,
            },
          },
        ]}
        {...props}
      />
    </div>
  );
};

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <div
    className="flex items-center justify-between cursor-pointer bg-white border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#2D9682] focus:border-[#2D9682]"
    onClick={onClick}
    ref={ref}
  >
    <span className="text-gray-700">{value || "Select date range"}</span>
    <CalendarIcon className="w-5 h-5 text-[#2D9682]" />
  </div>
));

export default DateRangePicker;
