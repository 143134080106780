import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Toggle from "../../../shared/Toggle";
import Input from "../../../shared/Input";

const AdvancedSettings = ({ form, updateForm, ...props }) => {
  // const [open, setOpen] = useState();

  function handleChange(e) {
    updateForm(e);
  }

  return (
    <div className="">
      <div className="rounded-2xl bg-white">
        <Disclosure defaultOpen={props.defaultOpen}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-center space-x-1 rounded-lg px-2 py-2 text-left text-sm font-medium text-gray-700 focus:outline-none focus-visible:ring ">
                <span>Advanced Settings</span>
                <ChevronUpIcon
                  className={`${
                    !open ? "rotate-180 transform" : ""
                  } h-4 w-4 text-gray-700`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-700 space-y-3">
                <div className="flex items-center justify-between">
                  <div className="font-semibold">Custom load #</div>
                  <Input
                    wrapperClasses="w-3/5"
                    size="sm"
                    labelClasses="text-gray-700"
                    onChange={handleChange}
                    id="orderNumber"
                    placeholder="EXAMPLE-0001"
                  />
                </div>
                {/* <div className="flex items-center justify-between">
                  <div className="font-semibold">
                    Use global rates
                    <div className="text-slate-400 font-medium text-xs">
                      Overwrite client rates for this job
                    </div>
                  </div>
                  <div className="w-3/5">
                    <Toggle
                      onChange={(e) =>
                        handleChange({
                          id: e.target.id,
                          value: e.target.checked,
                        })
                      }
                      id="useGlobalRates"
                    />
                  </div>
                </div> */}
                {/* <div className="flex items-center justify-between">
                  <div className="font-semibold">
                    Finished Job
                    <div className="text-slate-400 font-medium text-xs">
                      Job is finished just data entry
                    </div>
                  </div>
                  <div className="w-3/5">
                    <Toggle
                      onChange={(e) =>
                        handleChange({
                          id: e.target.id,
                          value: e.target.checked,
                        })
                      }
                      id="useGlobalRates"
                    />
                  </div>
                </div> */}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default AdvancedSettings;
