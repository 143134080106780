import { Badge } from "../../../shared";
import { renderCustomColumnDefs } from "../../../shared/Table/table.utils";
import { formatDate, renderAmount } from "../../../utils";
import { isAuthorized } from "../../../utils/authority";
import LocationTableCell from "./LocationTableCell";

// Config for table and form
const tableConfig = [
  {
    id: "origin",
    accessorKey: "route",
    header: "Origin",
    cell: ({ getValue }) => {
      const value = getValue();
      return value && <LocationTableCell data={value?.origin} />;
    },
    minWidth: 170,
  },
  {
    id: "destination",
    accessorKey: "route",
    header: "Destination",
    cell: ({ getValue }) => {
      const value = getValue();
      return value && <LocationTableCell data={value?.destination} />;
    },
    minWidth: 170,
  },
  {
    id: "appointmentDate",
    header: "Appointment Date",
    minWidth: 170,
    cell: ({ getValue }) => {
      const value = getValue();
      return value && formatDate(value, null, true);
    },
  },
  {
    id: "totalCharges",
    minWidth: 120,
    remove: !isAuthorized("owner,admin"),
    cell: ({ getValue, row }) => {
      return (
        <span>
          {renderAmount(getValue())}{" "}
          {row.original?.useLegFlatRate && (
            <Badge label="Flat" className="ml-2" />
          )}
        </span>
      );
    },
  },
  // {
  //   id: "loadType",
  //   minWidth: 140,
  //   cell: ({ getValue }) => {
  //     return getValue();
  //   },
  // },
  // {
  //   id: "driver",
  //   cell: ({ getValue }) => {
  //     const value = getValue();
  //     return value && value.fullName;
  //   },
  //   minWidth: 220,
  // },
  // {
  //   id: "weight",
  //   cell: ({ getValue }) => {
  //     const value = getValue();
  //     return value && `${value?.toLocaleString("en-US")} lbs`;
  //   },
  // },
  // {
  //   id: "route",
  //   header: "Total Miles",
  //   cell: ({ getValue }) => {
  //     const value = getValue();
  //     return value && `${value.mileage?.toLocaleString("en-US")} mi`;
  //   },
  // },
  // {
  //   id: "waitTime",
  //   minWidth: 100,
  //   header: "Standby Time",
  //   cell: ({ getValue }) => {
  //     const value = getValue();
  //     return value && `${value.total?.toLocaleString("en-US")} hrs`;
  //   },
  // },
  // {
  //   id: "baseCharge",
  //   minWidth: 100,
  //   cell: ({ getValue }) => {
  //     if (!getValue()) {
  //       return null;
  //     }
  //     return renderAmount(getValue());
  //   },
  // },
  // {
  //   id: "dropCharge",
  //   minWidth: 100,
  //   cell: ({ getValue }) => {
  //     if (!getValue()) {
  //       return null;
  //     }
  //     return renderAmount(getValue());
  //   },
  // },
  // {
  //   id: "otherCharges",
  //   minWidth: 100,
  //   cell: ({ getValue }) => {
  //     if (!getValue()) {
  //       return null;
  //     }
  //     return renderAmount(getValue());
  //   },
  // },
  // {
  //   id: "fuelSurcharge",
  //   minWidth: 170,
  //   cell: ({ getValue }) => {
  //     if (!getValue()) {
  //       return null;
  //     }
  //     return renderAmount(getValue());
  //   },
  // },
  // {
  //   id: "totalCharges",
  //   minWidth: 120,
  //   cell: ({ getValue }) => {
  //     if (!getValue()) {
  //       return null;
  //     }
  //     return renderAmount(getValue());
  //   },
  // },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
