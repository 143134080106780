import React, { useEffect, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import Button from "../../shared/Button";
import { applyPlugin } from "jspdf-autotable";

applyPlugin(jsPDF);

export const PdfGenerator = (props) => {
  const printRef = useRef();
  let pdf = new jsPDF();

  function handleDownloadPdf(output) {
    if (!pdf) {
      pdf = new jsPDF();
    }
    props.createHeader && props.createHeader(pdf);

    // TOTALS
    pdf.autoTable({
      setFont: ("Times", "normal"),
      startY: props.startY,
      startX: props.startX,
      theme: "plain",
      styles: { font: "Times" },
      useCss: true,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.05,
        lineColor: [255, 255, 255],
        fontSize: 8,
      },
      html: printRef.current.querySelector("#totals"),
      margin: { top: 4, left: 4, right: 4, bottom: 4 },
    });

    pdf.autoTable({
      startY: pdf.lastAutoTable.finalY + 222,
      startX: props.startX,
      html: printRef.current.querySelector(`#${props.id}`),
      setText: "Times",
      margin: { top: 4, left: 4, right: 4, bottom: 10 },
      styles: { ...(props.headStyles || {}), font: "Times", overflow: "" },
      useCss: true,
      columnStyles: props.columnStyles,
      headStyles: {
        ...(props.headStyles || {}),
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.05,
        lineColor: [255, 255, 255],
        fontSize: 8,
        whiteSpace: "nowrap",
      },
      rowPageBreak: props.rowPageBreak,
      didDrawCell: props.didDrawCell,
      willDrawCell: props.willDrawCell,
      didParseCell: props.didParseCell,
      // didDrawPage: props.didDrawPage,
    });

    pdf.autoTable({
      startY: pdf.lastAutoTable.finalY + 13,
      startX: props.startX,
      theme: "plain",
      styles: { font: "Times" },
      useCss: true,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.05,
        lineColor: [255, 255, 255],
        fontSize: 8,
      },
      html: printRef.current.querySelector("#totals"),
      margin: { top: 4, left: 4, right: 4, bottom: 4 },
    });

    const pages = pdf.internal.getNumberOfPages();
    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height;
    pdf.setFontSize(10);

    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = 5;
      let verticalPos = pageHeight - 5;
      pdf.setPage(j);
      pdf.text(
        `Page ${j} of ${pages}   ${props.pageData}`,
        horizontalPos,
        verticalPos,
        {
          align: "left",
        }
      );
    }

    return output
      ? pdf
      : pdf.save(props.fileName, { returnPromise: true }).then((i) => {
          pdf = null;
        });
  }

  function getPdfOutput() {
    const pdf = handleDownloadPdf(true);
    const pageCount = addFooters();
    const output = pdf.output();
    return output;
  }

  function addFooters() {
    const pageCount = pdf.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      pdf.text(String(i), 196, 285);
    }
  }

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        handleDownloadPdf,
        getPdfOutput,
        addFooters,
      });
    }
    return child;
  });

  return (
    <>
      {childrenWithProps}
      <div className="hidden" ref={printRef}>
        {props.layout}
      </div>
    </>
  );
};

export default PdfGenerator;
