import Card from "../../../shared/Card";
import {
  ArrowDownTrayIcon,
  DocumentChartBarIcon,
  XMarkIcon,
  PencilSquareIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentArrowUpIcon,
} from "@heroicons/react/24/outline";
import Modal from "../../../shared/Modal";
import { useState } from "react";
import Button from "../../../shared/Button";
import Input from "../../../shared/Input";
import EditableText from "../../../shared/EditableText";
import { useNavigate } from "react-router-dom";
import Select from "../../../shared/Select";
import { statusOptions } from "../../../constants/select";
import classNames from "classnames";
import TextArea from "../../../shared/TextArea";
import { renderMenuItems } from "../../../utils/route.utils";
import { useAuth } from "../../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { Badge } from "../../../shared";
import { useAddPayoutMutation } from "../../../redux/services/payouts/payoutsApi";
import { toast } from "react-toastify";
import { isAuthorized } from "../../../utils/authority";

const ManifestInfo = ({
  info,
  form,
  handleUpdate,
  handleManifestUpdate,
  onFormChange,
  clearForm,
  ...props
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [docName, setDocName] = useState("doc");
  const [payoutModalVisible, setPayoutModalVisible] = useState(false);
  const [emails, setEmails] = useState(
    info.organization ? info.organization.email : info.driver?.email
  );

  const [addPayout, { isLoading: addingPayout }] = useAddPayoutMutation();

  function handlePdfEmail(e) {
    const output = props.getPdfOutput();
    // send email
  }

  function handlePdfDownload(e) {
    props.handleDownloadPdf();
  }

  function handleCancelNote() {
    setNoteModalVisible(false);
    clearForm();
  }

  const noteModalFooter = (
    <>
      <Button onClick={handleCancelNote} type="alternative">
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleManifestUpdate({ id: "note", value: form.note });
          handleCancelNote();
        }}
        type="primary"
      >
        Save
      </Button>
    </>
  );

  const noteModalContent = (
    <TextArea id="note" value={form.note} onChange={onFormChange} />
  );
  function handleCancelPayout() {
    setPayoutModalVisible(false);
    clearForm();
  }

  const payoutModalFooter = (
    <>
      <Button onClick={handleCancelPayout} type="alternative">
        Cancel
      </Button>
      <Button
        loading={addingPayout}
        disabled={addingPayout}
        onClick={() => {
          handlePayoutCreate(info);
        }}
        type="primary"
      >
        Create
      </Button>
    </>
  );

  const payoutModalContent = (
    <div>
      <div> Are you sure you want to create a payout from this manifest?</div>
      <div>
        ** This will disable being able to edit this manifest unless the payout
        has been deleted. **
      </div>
    </div>
  );

  async function handlePayoutCreate(info) {
    const data = {
      payDate: info.dateCreated,
      startDate: info.startDate,
      endDate: info.endDate,
      emptyRate: info.driver.meta.emptyRate || 0,
      mileageRate: info.driver.meta.mileageRate || 0,
      hourlyRate: info.driver.meta.hourlyRate || 0,
      note: info.note,
      driver: info.driver,
      manifestId: info._id,
      legs: info.legs.map((i) => ({ _id: i._id })),
      status: "Review",
    };

    const res = await addPayout(data).unwrap();

    toast(res.message, {
      toastId: "add-payout",
      type: res.status,
    });

    if (res.status === "success") {
      handleManifestUpdate({ id: "payoutId", value: res.data._id });
      navigate(`/payouts/${res.data.id}`);
    }
  }

  function handleCancelEmail() {
    setEmailModalVisible(false);
  }

  const emailModalFooter = (
    <>
      <Button onClick={handleCancelEmail} type="alternative">
        Cancel
      </Button>
      <Button onClick={handlePdfEmail} type="primary">
        Send
      </Button>
    </>
  );

  const emailModalContent = (
    <div>
      <Input
        id="emails"
        label={"Emails - seperate by commas"}
        value={emails}
        onChange={({ value }) => setEmails(value)}
      />
      <Card className="p-2 mt-4 flex items-center justify-between">
        <div className="flex items-center">
          <div className="mr-4">
            <DocumentChartBarIcon className="w-10 h-10" />
          </div>
          <div className="text-sm">
            <EditableText
              id="documentName"
              value={docName}
              displayText={(e) => <span className="">{e}.pdf</span>}
              iconClasses="h-3 w-3"
              onSave={(e) => setDocName(e.value)}
            />
          </div>
        </div>
        <div>
          <div
            onClick={handlePdfDownload}
            className="border ease-in-out duration-300 hover:border-gray-300 rounded-full p-2 hover:bg-gray-200 cursor-pointer"
          >
            <ArrowDownTrayIcon className="w-6 h-6" />
          </div>
        </div>
      </Card>
    </div>
  );

  function handleDelete() {
    props.delete([info._id]);
  }

  function handleCancelDeleteModal() {
    setDeleteModalVisible(false);
  }

  const deleteModalFooter = (
    <>
      <Button onClick={handleCancelDeleteModal} type="alternative">
        Cancel
      </Button>
      <Button onClick={handleDelete} type="primary">
        Delete
      </Button>
    </>
  );

  const deleteModalContent = (
    <div>Are you sure you want to delete this manifest?</div>
  );

  const actions = [
    {
      id: "addNote",
      icon: ChatBubbleBottomCenterTextIcon,
      onClick: () => {
        onFormChange({ id: "note", value: info.note });
        setNoteModalVisible(true);
      },
      label: "Add Note",
      type: "success",
    },
    // {
    //   id: "sendCopy",
    //   icon: ArrowUpOnSquareStackIcon,
    //   onClick: () => setEmailModalVisible(true),
    //   label: "Email",
    // },
    {
      id: "pdf",
      icon: ArrowDownTrayIcon,
      onClick: handlePdfDownload,
      label: "To PDF",
    },
    {
      id: "createPayout",
      icon: DocumentArrowUpIcon,
      onClick: () => setPayoutModalVisible(true),
      label: "Create Payout",
    },
    {
      id: "edit",
      icon: PencilSquareIcon,
      onClick: () => navigate(`/manifests/${info._id}/edit`),
      label: "Edit",
      authority: "admin",
    },
    {
      id: "delete",
      icon: XMarkIcon,
      onClick: () => setDeleteModalVisible(true),
      label: "Delete",
      authority: "admin",
    },
  ].filter((i) => {
    if (info.payoutId && (i.id === "createPayout" || i.id === "edit")) {
      return false;
    }
    return true;
  });

  const menuItems = renderMenuItems(actions, user.authority);
  const doesNotHaveAuthority = false; //isAuthorized("owner, admin")
  //   user.authority === "client" || user.authority === "user";

  return (
    <>
      <Card className="row-span-1 px-10 py-4">
        <div className="flex justify-end">
          <div className="flex flex-col items-end">
            {!doesNotHaveAuthority && (
              <>
                <div className="flex justify-right">
                  {menuItems.map((item) => {
                    const Icon = item.icon;
                    const circleClasses = classNames(
                      "border ease-in-out duration-300 rounded-full p-2 cursor-pointer",
                      {
                        "hover:border-gray-300 hover:bg-gray-200":
                          item.type === "default" || !item.type,
                        "hover:border-red-300 hover:bg-red-200":
                          item.type === "danger",
                        "hover:border-primary-300 hover:bg-primary-200":
                          item.type === "primary",
                        "hover:border-green-300 hover:bg-green-200":
                          item.type === "success",
                        "hover:border-orange-300 hover:bg-orange-200":
                          item.type === "warning",
                      }
                    );

                    return (
                      <div className="flex flex-col items-center mx-2">
                        <div onClick={item.onClick} className={circleClasses}>
                          <Icon className="w-6 h-6" />
                        </div>
                        <p className="font-semibold">{item.label}</p>
                      </div>
                    );
                  })}
                </div>
                {user.authority.includes("dmin") && (
                  <div className="mx-2 py-2 w-full">
                    <Select
                      id="status"
                      onChange={handleManifestUpdate}
                      value={info.status}
                      size="sm"
                      placeholder={info.status}
                      options={statusOptions}
                    />
                  </div>
                )}
              </>
            )}
            <div className="mx-2 py-2 w-full">
              <span className="font-bold">Notes:</span>
              <div className="whitespace-pre">{info.note}</div>
            </div>
          </div>
        </div>
      </Card>

      <Modal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        title="Delete Manifest"
        footer={deleteModalFooter}
        content={deleteModalContent}
        size="md"
        footerPosition="right"
      />
      <Modal
        visible={emailModalVisible}
        setVisible={setEmailModalVisible}
        onClose={() => setEmailModalVisible(false)}
        title="Send Invoice"
        footer={emailModalFooter}
        content={emailModalContent}
        size="md"
        footerPosition="right"
      />
      <Modal
        visible={noteModalVisible}
        setVisible={setNoteModalVisible}
        onClose={() => setNoteModalVisible(false)}
        title="Add note"
        footer={noteModalFooter}
        content={noteModalContent}
        size="md"
        footerPosition="right"
      />
      <Modal
        visible={payoutModalVisible}
        setVisible={setPayoutModalVisible}
        onClose={() => setPayoutModalVisible(false)}
        title="Create Payout"
        footer={payoutModalFooter}
        content={payoutModalContent}
        size="md"
        footerPosition="right"
      />
    </>
  );
};

export default ManifestInfo;
