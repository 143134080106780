import React, { useState, useEffect } from "react";
import { Toggle, Input } from "../../../../shared";
import { parseFixed, renderTimeDifference } from "../../../../utils";
import { useConfig } from "../../../../hooks/useConfig";
import { CalculationsLayout } from "./Form";

const WaitTime = ({
  start,
  end,
  onFormChange,
  renderId,
  renderValue,
  setForm,
  form,
  renderFormLeg,
  renderRate,
  renderOtherCharges,
}) => {
  const [show, setShow] = useState(form.totalWaitTime || false);
  const { configs } = useConfig();

  useEffect(() => {
    const waitTime =
      !start && !end
        ? Number(form.totalWaitTime)
        : Math.abs(renderTimeDifference(start, end));
    const waitRate = renderRate("standBy") || 0; //form.client.rates?.["standBy"] || 0;
    const waitCalc = waitTime - configs?.job?.waitTimeOffset;
    const waitCharge = waitCalc < 0 ? 0 : waitCalc * waitRate;

    const data = {
      ...renderFormLeg(),
      [renderId("totalWaitTime")]: parseFixed(waitTime),
      [renderId("otherCharges")]: parseFixed(waitCharge),
    };
    setForm(data, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end, renderValue("totalWaitTime")]);

  function handleWaitTime(e) {
    const data = {
      ...renderFormLeg(),
      [renderId("totalWaitTime")]: 0,
      [renderId("otherCharges")]: renderOtherCharges(),
      [renderId("waitStart")]: null,
      [renderId("waitEnd")]: null,
    };

    setForm(data);
    setShow(!show);
  }

  return (
    <div className="my-2">
      <div className="flex items-center">
        <h3 className="block mr-3 text-lg font-medium text-gray-900">
          Wait Time
        </h3>
        <Toggle
          id={renderId("waitTime")}
          size="sm"
          checked={show}
          onChange={handleWaitTime}
        />
        {/* id={renderId("flatRate")}
            label="Flat Rate"
            size="sm"
            checked={flatRate}
            onChange={handleFlatRate} */}
      </div>
      {show && (
        <div className="grid grid-cols-1 gap-1 md:gap-3 md:grid-cols-3 my-3">
          <Input
            id={renderId("waitStart")}
            value={start}
            type="time"
            onChange={onFormChange}
            label="Start Time"
          />
          <Input
            id={renderId("waitEnd")}
            value={end}
            type="time"
            onChange={onFormChange}
            label="End Time"
          />
          <CalculationsLayout
            label="Total Wait Time"
            value={renderValue("totalWaitTime")}
            editable
            id="totalWaitTime"
            size="md"
            onSave={(saveData) => {
              setForm(
                {
                  ...renderFormLeg(),
                  totalWaitTime: parseFixed(Number(saveData.value)),
                  useCustomWaitTime: true,
                },
                true
              );
            }}
            displayText={(v) => (v || 0) + " hr"}
          />
        </div>
      )}
    </div>
  );
};

export default WaitTime;
