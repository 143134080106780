import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../../utils/request";

const initialState = {
  location: {},
  status: "idle",
  error: null,
};

export const fetchLocation = createAsyncThunk(
  "location/fetchLocation",
  async ({ parentId, locationId }) => {
    const res = await request(`/locations/${locationId}?parent=${parentId}`);
    return res.data;
  }
);

export const addNewLocation = createAsyncThunk(
  "location/addNewLocation",
  async ({ data, parentId }) => {
    const res = await request(`/locations?parent=${parentId}`, {
      method: "post",
      data: data,
    });

    return res.data;
  }
);

export const updateLocation = createAsyncThunk(
  "location/updateLocation",
  async ({ data, parentId, locationId }) => {
    const res = await request(`/locations/${locationId}?parent=${parentId}`, {
      method: "put",
      data,
    });
    return res.data;
  }
);

export const deleteLocation = createAsyncThunk(
  "location/deleteLocation",
  async ({ parentId, locationId, client }) => {
    const res = await request(`/locations/${locationId}?parent=${parentId}`, {
      method: "delete",
      data: { clientId: client },
    });
    return res.data;
  }
);

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLocation.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchLocation.fulfilled, (state, action) => {
        state.location = {};
        state.status = "succeeded";
        state.location = action.payload.location;
      })
      .addCase(fetchLocation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewLocation.fulfilled, (state, action) => {
        state.location = action.payload.data;
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        state.location = action.payload.location;
      });
  },
});

export default locationSlice.reducer;

export const selectLocation = (state) => state.location.location;
