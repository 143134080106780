import Card from "../../../shared/Card";
import { renderAmount, renderTotalMileage } from "../../../utils";
import { isAuthorized } from "../../../utils/authority";

const keys = [
  { title: "Invoice Total", key: "total", type: "amount" },
  { title: "Total Mileage", key: "totalMileage", type: "mi" },
  { title: "Other Charges", key: "totalOtherCharges", type: "amount" },
  { title: "Total Weight", key: "totalWeight", type: "lbs" },
  { title: "Base Charges", key: "totalBaseCharges", type: "amount" },
  { title: "Drop Charges", key: "totalDropCharges", type: "amount" },
  { title: "Fuel Charges", key: "totalFuelCharges", type: "amount" },
  { title: "Total Standby Time", key: "totalStandByTime", type: "hrs" },
];

const InvoiceTotals = ({ info, ...props }) => {
  return (
    <>
      <Card className="row-span-2 px-10 py-4">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 lg:grid-cols-4 xl:grid-cols-9">
          {keys.map((item) => (
            <div className="flex flex-col">
              <span className="text-gray-500 mb-1 font-semibold">
                {item.title}
              </span>
              <span className="font-semibold text-lg">
                <p>
                  {item.type === "amount"
                    ? renderAmount(info[item.key])
                    : item.type === "mi"
                    ? `${renderTotalMileage(info.totalMileage)} mi`
                    : item.type === "hrs"
                    ? `${info.totalStandByTime || 0} hrs`
                    : item.type === "lbs"
                    ? `${info.totalWeight || 0} lbs`
                    : info[item.key] || 0}
                </p>
              </span>
            </div>
          ))}
          {/* <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Invoice Total
            </span>
            <span className="font-semibold text-lg">
              <p>{renderAmount(info.total)}</p>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Base Charges
            </span>
            <span className="font-semibold text-lg">
              <p>{renderAmount(info.totalBaseCharges)}</p>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Drop Charges
            </span>
            <span className="font-semibold text-lg">
              <p>{renderAmount(info.totalDropCharges)}</p>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Fuel Charges
            </span>
            <span className="font-semibold text-lg">
              <p>{renderAmount(info.totalFuelCharges)}</p>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Total Mileage
            </span>
            <span className="font-semibold text-lg">
              <p>{renderTotalMileage(info.totalMileage)} mi</p>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Other Charges
            </span>
            <span className="font-semibold text-lg">
              <p>{renderAmount(info.totalOtherCharges)}</p>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Total Standby Time
            </span>
            <span className="font-semibold text-lg">
              <p>{info.totalStandByTime} hrs</p>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Total Weight
            </span>
            <span className="font-semibold text-lg">
              <p>{info.totalWeight?.toLocaleString("en-US")} lbs</p>
            </span>
          </div> */}
        </div>
      </Card>

      {/* <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title="Table Preferences"
        footer={modalFooter}
        content={modalContent}
        size="md"
        footerPosition="right"
      /> */}
    </>
  );
};

export default InvoiceTotals;
