import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import TableLayout from "../../../layouts/TableLayout/NewTableLayout";
import {
  selectAllCustomers,
  deleteCustomers,
  fetchCustomers,
  resetCustomers,
} from "../../../redux/reducers/customers/customersSlice";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Modal from "../../../shared/Modal";
import { useState } from "react";
import { resetLocations } from "../../../redux/reducers/locations/locationsSlice";
import { toast } from "react-toastify";
import { CreateCustomerModal } from "./CreateModal";
import request, { renderQueries } from "../../../utils/request";

const Customers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customers = useSelector(selectAllCustomers);
  const customersStatus = useSelector((state) => state.customers.status);
  const { user } = useAuth();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState("");

  useEffect(() => {
    if (customersStatus === "idle") {
      dispatch(fetchCustomers({ parent: user.parentCompany }));
    }
  }, [customersStatus]);

  // useEffect(() => {
  //   return () => dispatch(resetUsers());
  // }, []);

  function handleEdit(itemId) {
    navigate(`/customers/${itemId}`);
  }

  async function handleDelete(items) {
    const { parentCompany } = user;
    const params = new URLSearchParams({ parent: parentCompany });
    const res = await request("/customers/" + items[0]._id + "?" + params, {
      method: "delete",
    });

    toast(res.data.message, {
      type: res.data.status,
      toastId: "customerDeleted",
    });

    return res.data;
  }

  return (
    <>
      <TableLayout
        id="customers"
        // title={t("admin.customers.main")}
        loading={customersStatus === "loading"}
        columns={COLUMN_DEFINITIONS}
        data={customers}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        mainAction={{ onClick: () => setModalVisible(true) }}
        handleDelete={handleDelete}
        handleEdit={handleEdit}

        // hideActions={cannotCreateOrEdit}
        // hideMainAction={cannotCreateOrEdit}
        // globalSearchFilters={["transactionDate", "loadNumber"]}
      />
      <CreateCustomerModal
        visible={modalVisible}
        setVisible={setModalVisible}
      />
    </>
  );
};

export default Customers;
