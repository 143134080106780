import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const customersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = getSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `customers?${query}`;
      },
      providesTags: (data) =>
        data?.customer
          ? [
              ...data.customer.map(({ _id }) => ({
                type: "Customers",
                id: _id,
              })),
              { type: "Customers", id: "LIST" },
            ]
          : [{ type: "Customers", id: "LIST" }],
    }),
    addCustomer: builder.mutation({
      query(body) {
        return {
          url: "onboarding/customer",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Customers", id: "LIST" }],
    }),
    getCustomer: builder.query({
      query: ({ id, ...rest }) => `customers/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response,
      providesTags: (_result, _error, data) => {
        return [{ type: "Customers", id: data.id }];
      },
    }),
    getCustomerInvoices: builder.query({
      query: ({ id, ...rest }) =>
        `customers/${id}/invoices?${getSearchParams(rest)}`,
      // transformResponse: (response) => response,
      providesTags: (_result, _error, data) => {
        return [{ type: "Customers_Invoices", id: data.id }];
      },
    }),
    updateCustomer: builder.mutation({
      query({ id, data }) {
        return {
          url: `/customers/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => {
        return [{ type: "Customers", id }];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCustomersQuery,
  useAddCustomerMutation,
  useGetCustomerQuery,
  useGetCustomerInvoicesQuery,
  useUpdateCustomerMutation,
} = customersApi;
