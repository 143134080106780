import React from "react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { renderArray } from "../../utils";
import { isObject } from "lodash";

const TablePagination = ({
  pageCount,
  pagination,
  table: { setPageIndex, previousPage, nextPage, getState, ...table },
  ...rest
}) => {
  function isCustomPagination() {
    return isObject(pagination);
  }

  function gotoPage(id) {
    setPageIndex(id);
  }

  function getPageCount() {
    if (isCustomPagination()) {
      return pagination.totalPages;
    }
    return table.getPageCount();
  }

  function getCanPreviousPage() {
    if (isCustomPagination()) {
      return pagination.hasPrev;
    }
    return table.getCanPreviousPage();
  }

  function getCanNextPage() {
    if (isCustomPagination()) {
      return pagination.hasNext;
    }
    return table.getCanNextPage();
  }

  function renderPages() {
    const pageCount = getPageCount();
    const currentPage = getState().pagination.pageIndex + 1;
    const currentPageNumber = (
      <span className="font-bold text-primary-700">{currentPage}</span>
    );

    const spanClasses = classNames("px-1 cursor-pointer");

    const data = renderArray(pageCount).map((key, i) => {
      if (key + 1 === currentPage) {
        return (
          <span key={i} className={spanClasses}>
            {currentPageNumber}
          </span>
        );
      }
      return (
        <span key={i} onClick={() => gotoPage(key)} className={spanClasses}>
          {key + 1}
        </span>
      );
    });

    const firstNumber =
      currentPage - 2 !== 0 && currentPage !== 1 && currentPage - 2;

    const numBefore = getCanPreviousPage() ? currentPage - 1 : "";
    const numAfter = getCanNextPage() ? currentPage + 1 : "";

    const showTailLeft = currentPage - 2 > 1;
    const showTailRight = currentPage + 2 < pageCount;

    const lastNumber =
      pageCount - 1 !== currentPage && pageCount - 2 && currentPage + 2;

    return (
      <span className="flex items-center text-gray-500">
        {pageCount <= 7 ? (
          data
        ) : (
          <>
            {showTailLeft && (firstNumber || numBefore) && "..."}
            {firstNumber && (
              <span
                onClick={() => gotoPage(firstNumber - 1)}
                className={spanClasses}
              >
                {firstNumber}
              </span>
            )}
            {numBefore && (
              <span
                onClick={() => gotoPage(numBefore - 1)}
                className={spanClasses}
              >
                {numBefore}
              </span>
            )}
            <span
              onClick={() => gotoPage(currentPage - 1)}
              className={spanClasses}
            >
              {currentPageNumber}
            </span>
            {numAfter && (
              <span
                onClick={() => gotoPage(numAfter - 1)}
                className={spanClasses}
              >
                {numAfter}
              </span>
            )}
            {numAfter && lastNumber && (
              <span
                onClick={() => gotoPage(lastNumber - 1)}
                className={spanClasses}
              >
                {lastNumber}
              </span>
            )}
            {showTailRight && numAfter && (lastNumber || numAfter) && "..."}
          </>
        )}
      </span>
    );
  }

  const iconClasses = classNames(
    "stroke-2 h-4 w-4 cursor-pointer stroke-gray-600"
  );

  const canPrevPage = getCanPreviousPage();
  const canNextPage = getCanNextPage();

  return (
    getPageCount() > 1 && (
      <span className="flex items-center justify-center lg:mr-2">
        <ChevronDoubleLeftIcon
          onClick={() => gotoPage(0)}
          disabled={!canPrevPage}
          className={iconClasses}
        />
        <ChevronLeftIcon
          onClick={() => previousPage()}
          disabled={!canPrevPage}
          className={iconClasses}
        />
        {renderPages()}
        <ChevronRightIcon
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className={iconClasses}
        />
        <ChevronDoubleRightIcon
          onClick={() => gotoPage(getPageCount() - 1)}
          disabled={!canNextPage}
          className={iconClasses}
        />
      </span>
    )
  );
};

export default TablePagination;

{
  /* <div className="flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div>{table.getPrePaginationRowModel().rows.length} Rows</div> */
}
