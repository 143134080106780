import { NavLink, useNavigate } from "react-router-dom";
import Drawer from "../../shared/Drawer";
import logo from "../../assets/logos/logo-green-side-truck.png";
import classNames from "classnames";

const borderStyle =
  "bg-primary-50 rounded-lg border border-primary-500 border-[1.3px]";

const MobileNav = ({ navOpen, setNavOpen, breakPoint, ...props }) => {
  const navigate = useNavigate();

  function renderMenuItems() {
    return (
      <ul className="p-6">
        {props.menu.map((menuItem, index) => {
          return (
            <li key={index}>
              <NavLink to={menuItem.path}>
                {({ isActive }) => {
                  const Icon = menuItem.icon && menuItem.icon;
                  const wrapperClasses = classNames(
                    "flex items-center my-1 h-10 text-gray-600 text-sm px-2",
                    { [borderStyle]: isActive }
                  );
                  const iconClasses = classNames("stroke-[1.7px] w-5", {
                    "stroke-primary-700": isActive,
                  });
                  const textClasses = classNames(
                    "origin-left duration-200 mx-2",
                    { "text-primary-700": isActive }
                  );
                  return (
                    <div className={wrapperClasses}>
                      {menuItem.src ? (
                        <img
                          src={menuItem.src}
                          alt={`menu item img ${menuItem.title}`}
                        />
                      ) : (
                        <Icon className={iconClasses} />
                      )}
                      <span className={textClasses}>{menuItem.title}</span>
                    </div>
                  );
                }}
              </NavLink>
            </li>
          );
        })}
      </ul>
    );
  }

  const mobileNavHeader = (
    <div
      className="flex gap-x-4 items-center cursor-pointer px-6 h-10"
      onClick={() => navigate("/")}
    >
      <img
        src={logo}
        alt="logo"
        className={`cursor-pointer duration-500 w-40`}
      />
    </div>
  );

  return (
    <Drawer
      className="bg-white md:hidden"
      noBorderHeader={true}
      position="left"
      title={mobileNavHeader}
      body={renderMenuItems()}
      visible={breakPoint ? navOpen : false}
      setVisible={setNavOpen}
      widthClasses="!w-80"
    />
  );
};

export default MobileNav;
