import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import { deleteClients } from "../../redux/reducers/clients/clientsSlice";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetClientsQuery } from "../../redux/services/clients/clientsApi";
import { usePrefetch } from "../../redux/api";
import { CreateClientModal } from "./CreateModal";

const Clients = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [modalVisible, setModalVisible] = useState(false);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const { data, isLoading, isFetching } = useGetClientsQuery({
    page: pageIndex + 1,
    limit: pageSize,
  });

  const prefetchPage = usePrefetch("getClients");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
    });
  }, [prefetchPage, data?.nextPage, pageSize]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);

  function handleEdit(itemId) {
    navigate(`/clients/${itemId}`);
  }

  async function handleDelete(items) {
    const { parentCompany } = user;
    const res = await dispatch(
      deleteClients({ data: items, parentId: parentCompany })
    ).unwrap();

    if (res.status === "success") {
      toast("Clients Deleted", {
        type: "success",
        toastId: "clientsDeleted",
      });
    }

    return res;
  }

  return (
    <>
      <TableLayout
        id="clients"
        loading={isLoading || isFetching}
        columns={COLUMN_DEFINITIONS}
        data={data?.clients}
        pagination={data}
        pageIndex={pageIndex}
        pageSize={pageSize}
        mainAction={{ onClick: () => setModalVisible(true) }}
        onPaginationChange={setPagination}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        handleDelete={handleDelete}
        noDelete
        handleEdit={handleEdit}
      />
      <CreateClientModal visible={modalVisible} setVisible={setModalVisible} />
    </>
  );
};

export default Clients;
