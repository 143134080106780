import { FolderPlusIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../../../shared";
import { renderAmount } from "../../../../../utils";
import { isAuthorized } from "../../../../../utils/authority";
import { ServicesTable } from "../../../Services/ServicesTable";

export const ServiceInfo = ({
  setModalVisible,
  modalVisible,
  job,
  serviceForm,
  editing,
  isJobActive,
  isPending,
  isNew,
  loading,
}) => {
  // console.log(isAuthorized("owner,admin"), isNew, isPending, editing);

  function handleCreate() {
    serviceForm.setFormInit({
      job,
    });
    setModalVisible(true);
  }

  const empty = (
    <div className="py-4 bg-white">
      <div className="text-center">
        <FolderPlusIcon className="w-12 h-12 mx-auto text-gray-400 stroke-1" />
        <p className="text-sm text-gray-500">Get started by adding a service</p>
        <div className="mt-3">
          <Button
            onClick={handleCreate}
            size={"xs"}
            icon={<PlusCircleIcon className={`w-5 h-5 mr-2`} />}
          >
            Add Service
          </Button>
        </div>
      </div>
    </div>
  );

  // const wrapClasses = classNames(
  //   "flex flex-row justify-evenly items-center whitespace-nowrap"
  // );

  // const isHeaderActive = !isJobActive;

  return (
    <div>
      <div>
        <div className="mb-2 text-sm font-medium text-gray-800">
          <div>
            {!isJobActive ? "Services" : "Active Service"}
            {!isJobActive && ` (${job.services?.length || 0})`}
          </div>
        </div>
        <ServicesTable
          setVisible={setModalVisible}
          visible={modalVisible}
          renderHeader={() => {
            return <Header loading={loading} job={job} />;
          }}
          // hideHeader={!isHeaderActive}
          job={job}
          form={serviceForm}
          loading={loading}
          editing={isAuthorized("owner,admin") ? editing : false}
          isJobActive={isJobActive}
          isPending={isPending}
          isNew={isNew}
          thClasses="rounded-t"
          emptyState={empty}
        />
        {isAuthorized("owner,admin") &&
          job.services?.length > 0 &&
          (editing || isNew || isPending) && (
            <div className="flex mt-2">
              <Button
                className="flex items-center"
                onClick={handleCreate}
                size="sm"
              >
                <PlusCircleIcon className="h-5 w-5 mr-2" />
                Add Service
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

export const Header = ({ job, loading }) => {
  const keys = [
    { id: "totalMileage", title: "Mileage" },
    { id: "totalBaseCharges", title: "Base", amount: true },
    {
      id: "totalFuelCharges",
      title: "Fuel",
      hidden: job.useFlatRate,
      amount: true,
    },
    {
      id: "totalDropCharges",
      title: "Drop",
      hidden: job.useFlatRate,
      amount: true,
    },
    { id: "totalOtherCharges", title: "Other", amount: true },
    { id: "totalStandByTime", title: "Standby" },
    { id: "totalWeight", title: "Weight" },
    { id: "totalCharges", title: "Total", amount: true },
  ].filter((i) => {
    if (!isAuthorized("owner,admin") && i.amount) {
      return false;
    }
    return !i.hidden;
  });

  return (
    <div className="bg-white border-t rounded-t-lg text-xs font-medium px-4 py-4 text-left text-gray-500 uppercase border-b border-gray-200">
      <div className="grid grid-cols-4 gap-4 md:grid-cols-8">
        {keys.map((item, i) =>
          loading ? (
            <div
              key={i + 100 + "loading"}
              role="status"
              className="max-w-sm animate-pulse"
            >
              <div className="h-2 bg-gray-200 rounded-full max-w-[80px] mb-4"></div>
              <div className="h-2.5 bg-gray-200 rounded-full max-w-[50px]"></div>

              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div key={i}>
              <div>{item.title}:</div>
              <span className="text-sm text-gray-800">
                {item.amount ? renderAmount(job[item.id]) : job[item.id]}
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
};
