import React, { useState } from "react";
import Input from "../../shared/Input";
import Button from "../../shared/Button";
import { useNavigate } from "react-router-dom";
import useForm from "../../hooks/useForm";
// import { FORM_OPTIONS } from "./form.config";
import { toast } from "react-toastify";
import { changePassword } from "../../redux/reducers/accountSlice";
import { useDispatch } from "react-redux";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import useQuery from "../../hooks/useQuery";
import Progress from "../../shared/Progress";
import isStrongPassword from "validator/lib/isStrongPassword";
import isAlpha from "validator/lib/isAlpha";

const ChangePassword = (props) => {
  const { form, updateForm, isValid, setFormInit } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queries = useQuery();
  const [loading, setLoading] = useState(false);
  const [passError, setPassError] = useState("");

  const token = queries.get("token");

  function handleUpdateForm(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  async function handleSubmit(id) {
    setLoading(true);
    const email = await dispatch(
      changePassword({ data: { ...form }, token, type: "changePassword" })
    ).unwrap();

    toast(email.message, {
      type: email.status,
      toastId: "changePassword",
    });
    if (email.status === "success") {
      navigate("/login");
    }
    setLoading(false);
  }

  function handlePassword({ id, value }) {
    const strength = isStrongPassword(value, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      returnScore: true,
      pointsPerUnique: 5,
      pointsPerRepeat: 5,
      pointsForContainingLower: 15,
      pointsForContainingUpper: 15,
      pointsForContainingNumber: 15,
      pointsForContainingSymbol: 15,
    });

    setPassError("");
    if (value && !form.confirm) {
      setPassError("Please confirm your password");
    }
    if (isAlpha(value)) {
      setPassError("Password must contain at least 1 number");
    }
    if (!/[A-Z]/.test(value)) {
      setPassError("Password must contain at least 1 uppercase");
    }
    if (value.length < 8) {
      setPassError("Password must be longer than 8 characters");
    }
    if (!value) {
      setPassError("Please provide a password");
    }

    setFormInit(
      {
        ...form,
        [id]: value,
        strength,
      },
      { setErrors: true }
    );
  }

  function handleConfirmPassword({ id, value }) {
    setPassError("");
    if (!value) {
      setPassError("Please confirm password");
    }
    if (form.password !== value) {
      setPassError("Passwords do not match");
    }

    setFormInit(
      {
        ...form,
        [id]: value,
      },
      { setErrors: true }
    );
  }

  return (
    <div className="flex flex-col items-center justify-center md:justify-start my-auto pt-8 md:pt-0 px-1 md:px-5 lg:px-24">
      <p className="text-center text-4xl font-bold pb-1">New Password</p>
      <p className="text-center text-md text-gray-600 pb-5">
        Add your new password below
      </p>
      <div className="w-3/4">
        <div className="my-3">
          <Input
            id="password"
            type="password"
            onChange={handlePassword}
            placeholder="New Password"
            value={form.password}
          />
        </div>
        <div className="my-3">
          <Input
            id="confirm"
            type="password"
            onChange={handleConfirmPassword}
            placeholder="Confirm Password"
            value={form.confirm}
          />
        </div>
        <div className="">
          {(form.password || form.confirm) && (
            <Progress
              color={passError ? "red" : ""}
              percent={form.strength}
              size="sm"
            />
          )}
          <div className="text-gray-700">{passError}</div>
        </div>

        <div className="my-3">
          <Button
            type="primary"
            disabled={!isValid || passError}
            onClick={handleSubmit}
            className="w-full"
            loading={loading}
          >
            Submit
          </Button>
        </div>
        <div className="pt-5">
          <p
            onClick={() => navigate("/login")}
            className="flex items-center hover:underline cursor-pointer"
          >
            <ChevronLeftIcon className="w-4 h-4 stroke-2" /> Go Back
            {/* {" "}
            <Link to="/help" className="underline font-semibold">
              Click here
            </Link> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
