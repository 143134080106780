import React from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { renderDefaultSelected } from "../../shared/Table/table.utils";
import { formatDate } from "../../utils";
import classNames from "classnames";

function Table({ columns, data }) {
  const [expanded, setExpanded] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded: renderDefaultSelected(data, "all"),
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const tableClasses = ""; //classNames("w-full");
  const theadClasses = classNames("bg-gray-100");
  const trClasses = ""; //classNames("w-full");
  const thClasses = classNames("flex bg-gray-100 py-2");
  const tdClasses = classNames("py-2 bg-gray-100");

  return (
    <table id="payout-table" className={tableClasses}>
      <thead className={theadClasses}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className={trClasses}>
            {headerGroup.headers.map((header) => {
              // if (header.id.includes("legs")) return null;
              return (
                <th key={header.id} className={thClasses}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody className="w-full">
        {table.getRowModel().rows.map((row, i) => {
          const route = row.original.route;
          const rowId = route && Number(row.id.split(".")[1]);
          const canExpand = row.getCanExpand();

          return (
            <>
              {canExpand && (
                <tr key={row.id}>
                  <td colSpan={11}>
                    Date: {formatDate(row.original.transactionDate, null, true)}
                    <br />
                    Truck Load: {row.original.loadNumber}
                  </td>
                </tr>
              )}
              {!canExpand && (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    // if (cell.id.includes("legs")) return null;
                    return (
                      <td className={tdClasses} key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              )}
              {/* {!canExpand && route && (
                <Route data={row.original} rowId={rowId} key={row.id} />
              )} */}
            </>
          );
        })}
      </tbody>
    </table>
  );
}

const PayoutTable = (props) => {
  const tableData =
    props.tableData &&
    props.tableData.map((item) => {
      return {
        ...item,
        subRows: item.legs,
      };
    });
  const columns = React.useMemo(() => props.columns, []);
  const data = React.useMemo(() => tableData, [tableData]);

  return props.tableData && <Table columns={columns} data={data} />;
};

export default PayoutTable;
