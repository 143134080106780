import { api } from "../../api";
import { getSearchParams } from "../../../utils";
export const servicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: () => "services",
      providesTags: ["Services"],
    }),
    getServiceById: builder.query({
      query: (id) => `services/${id}`,
      providesTags: (result, error, id) => [{ type: "Services", id }],
    }),
    addService: builder.mutation({
      query: ({ data, ...rest }) => ({
        url: "services?" + getSearchParams(rest),
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Services"],
    }),
    updateService: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `services/${id}`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Services", id }],
    }),
    deleteService: builder.mutation({
      query: (id) => ({
        url: `services/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Services"],
    }),
  }),
});

export const {
  useGetServicesQuery,
  useGetServiceByIdQuery,
  useAddServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
} = servicesApi;
