import { useEffect, useState } from "react";
import RatesSettingsLayout from "../../../../layouts/RatesSettingLayout";
import request, { renderQueries } from "../../../../utils/request";
import { useAuth } from "../../../../hooks/useAuth";
import { toast } from "react-toastify";

const ClientRates = (props) => {
  const { user } = useAuth();
  const [rates, setRates] = useState();

  useEffect(() => {
    if (!rates) {
      setRates(props.rates);
    }
  }, [Object.keys(props.rates || {})]);

  async function handleDelete(data) {
    const deleteRate = await request(
      "/clients/" +
        props.clientId +
        "/rates?" +
        renderQueries({ parent: user.parentCompany }),
      {
        method: "delete",
        data,
      }
    );
    toast(deleteRate.data.message, {
      toastId: "deleteClientRate." + new Date().toISOString(),
      type: deleteRate.data.status,
    });
    setRates(deleteRate.data.rates);
  }

  async function handleAddRate(data) {
    const addRate = await request(
      "/clients/" +
        props.clientId +
        "/rates?" +
        renderQueries({ parent: user.parentCompany }),
      {
        method: "post",
        data,
      }
    );
    toast(addRate.data.message, {
      toastId: "addClientRate." + new Date().toISOString(),
      type: addRate.data.status,
    });
    setRates(addRate.data.rates);
  }

  return (
    <RatesSettingsLayout
      title="Client Rates"
      subtitle="These rates will overwrite the global rates in settings for this client"
      rates={rates}
      deleteRate={handleDelete}
      addRate={handleAddRate}
      updateRate={handleAddRate}
    />
  );
};

export default ClientRates;
