import { flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import React, { Fragment } from "react";
import Cell from "./Cell";

const TBody = ({ table, ...props }) => {
  const BodyWrapper = props.useDivs || !props.useStyles ? "div" : "tbody";
  const TableRow = props.useDivs || !props.useStyles ? "div" : "tr";
  const TableData = props.useDivs || !props.useStyles ? "div" : "td";

  function handleTableRowClick(row) {
    const canExpand = props.rowClickExpand && row.getCanExpand();
    if (canExpand) {
      row.toggleExpanded();
    }
  }

  return (
    <BodyWrapper className="bg-white rounded-b-lg">
      {table.getRowModel().rows.map((row, idx) => {
        const rowClass = classNames(props.rowClasses, "last:rounded-b-lg", {
          "hover:bg-gray-50": props.rowClickExpand && row.getCanExpand(),
        });

        return (
          <Fragment key={row.id}>
            <TableRow
              onClick={() => row.getCanExpand() && handleTableRowClick(row)}
              key={row.id}
              // style={{ lineHeight: "55px", height: "55px" }}
              className={rowClass}
            >
              {row.getVisibleCells().map((cell, idx) => {
                const isCheckbox =
                  !props.hideCheckbox && cell.column.id === "select";
                const classes = props.tableBodyClasses
                  ? props.tableBodyClasses
                  : classNames(
                      "whitespace-no-wrap border-b border-gray-200",
                      props.tbClasses,
                      {
                        "w-0 max-w-0 border-r": isCheckbox,
                      }
                    );

                const content = flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext()
                );

                return (
                  <TableData
                    {...{
                      key: cell.id,
                      style:
                        props.useTableSizes || !props.useStyle
                          ? {
                              width: !isCheckbox && cell.column.getSize(),
                            }
                          : {},
                    }}
                    className={classes}
                    key={cell.id}
                  >
                    {isCheckbox ? (
                      <span className="flex justify-center">{content}</span>
                    ) : (
                      <Cell {...props} {...cell}>
                        {content}
                      </Cell>
                    )}
                  </TableData>
                );
              })}
            </TableRow>
            {row.getIsExpanded() && (
              <tr>{props.expandedLayout({ row, table })}</tr>
            )}
          </Fragment>
        );
      })}
    </BodyWrapper>
  );
};

export default TBody;
