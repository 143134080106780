import { isObject } from "../../../utils";
import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const legsApi = api.injectEndpoints({
  tagTypes: ["Legs"],
  endpoints: (builder) => ({
    getLegs: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = new URLSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `legs?${query}`;
      },
      transformResponse: (response) => response?.data,
      providesTags: (data) =>
        data?.legs
          ? [
              ...data.legs.map(({ _id }) => ({ type: "Legs", id: _id })),
              { type: "Legs", id: "LIST" },
            ]
          : [{ type: "Legs", id: "LIST" }],
    }),
    addLeg: builder.mutation({
      query({ data, ...rest }) {
        const params = getSearchParams(rest);
        return {
          url: "legs?" + params,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { data }) => {
        return [{ type: "Jobs", id: data.jobId }];
      },
    }),
    updateLeg: builder.mutation({
      query({ data, ...rest }) {
        const params = getSearchParams(rest);
        return {
          url: `/legs/${rest.legId}?${params}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, _error, arg) => {
        const res = [{ type: "Jobs", id: arg.jobId }];
        if (result.data.job.invoiceId) {
          res.push({
            type: "Invoices",
            id: result.data.job.invoiceId._id,
          });
        }

        return res;
      },
    }),
    deleteLegs: builder.mutation({
      query({ data, ...rest }) {
        const params = getSearchParams(rest);
        return {
          url: "legs?" + params,
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, data) => {
        return [{ type: "Jobs", id: data.jobId }];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLegsQuery,
  useDeleteLegsMutation,
  useAddLegMutation,
  useUpdateLegMutation,
} = legsApi;
