import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  DatePicker,
  Input,
  Select,
  TimeInput,
  Toggle,
  EditableText,
  Badge,
  Expanded,
  TextArea,
} from "../../../../shared";
import { renderLabelRequired } from "../../../../shared/Table/table.utils";
import { getDistance } from "../../../../redux/reducers/map";
import {
  formatDate,
  padInt,
  padTime,
  parseFixed,
  renderAmount,
} from "../../../../utils";
import WaitTime from "./WaitTime";
import EmptyLocationSearch from "./EmptyLoctionSearch";
import { getMilesFromMeters, renderTotalMileage } from "../../jobs.utils";
import { loadTypeOptions } from "../../select.config";
import { useConfig } from "../../../../hooks/useConfig";
import { Metadata } from "./Metadata";
import { useGetEquipmentQuery } from "../../../../redux/services/equipment";

export const ServiceCreateForm = ({
  form = {},
  handleChange,
  errors = {},
  equipmentOptions = [],
}) => {
  const handleFormChange = (e) => {
    handleChange({ value: e.value, id: e.id });
  };

  const renderEquipmentValue = () => {
    return (
      form.equipment?.name &&
      (form.equipment?.name || "") +
        " • " +
        (form.equipment?.make || "") +
        " " +
        (form.equipment?.model || "") +
        " • " +
        (form.equipment?.serialNumber || "")
    );
  };

  return (
    <div className="grid grid-cols-2 xl:grid-cols-6 gap-2">
      <DatePicker
        id="date"
        value={form.date}
        onChange={handleFormChange}
        placeholder="Date"
        label="Date"
        error={errors.date}
      />
      <Select
        id="equipment"
        search
        searchKeys={["name"]}
        value={renderEquipmentValue}
        onChange={handleFormChange}
        label="Equipment"
        options={equipmentOptions}
        placeholder="Select Equipment"
        error={errors.equipment}
      />
      <Select
        id="serviceType"
        value={form.serviceType}
        onChange={handleChange}
        label="Service Type"
        options={[
          { value: "Routine", label: "Routine" },
          { value: "Repair", label: "Repair" },
          { value: "Inspection", label: "Inspection" },
          { value: "Upgrade", label: "Upgrade" },
        ]}
        placeholder="Select Service Type"
        error={errors.serviceType}
      />
      <div className="col-span-2 xl:col-span-6">
        <TextArea
          id="originalCall"
          value={form.originalCall}
          onChange={handleChange}
          placeholder="Enter the original service call details"
          label="Original Call"
          error={errors.originalCall}
        />
      </div>
    </div>
  );
};

export const CalculationsLayout = ({
  label,
  value,
  editable,
  id,
  displayText,
  onSave,
  size,
}) => {
  return (
    <div className="flex flex-col">
      <span className="block mb-2 text-sm font-medium">{label}</span>
      {!editable ? (
        <span className="font-semibold text-xl">{value}</span>
      ) : (
        <EditableText
          className="ml-1"
          iconClasses="h-3 w-3"
          id={id}
          value={value}
          size={size}
          displayText={(v) => (
            <span className="font-semibold text-xl">{displayText(v)}</span>
          )}
          onSave={onSave}
          type="number"
        />
      )}
    </div>
  );
};
