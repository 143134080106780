export const namePassword = [
  {
    id: "firstName",
    required: true,
  },
  {
    id: "lastName",
    required: true,
  },
  {
    id: "password",
    required: true,
  },
  {
    id: "confirm",
    required: true,
    custom: {
      isValid: (value, { form }) => {
        let isValid = true;
        if (!value) isValid = false;
        if (form.password !== value) isValid = false;
        return { isValid };
      },
    },
  },
];

export const customer = [
  { id: "name", required: true },
  { id: "slug", required: true },
  { id: "phone", required: true },
  { id: "address", required: true },
  { id: "city", require: true },
  { id: "state", require: true },
  { id: "country", require: true },
  { id: "zipcode", require: true },
];

export default { namePassword, customer };
