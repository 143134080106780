import { useRef } from "react";
import { Table } from "../shared";

export const JobItemsTable = ({
  tableData,
  columns,
  job,
  globalSearchFilters,
  ...props
}) => {
  const tableRef = useRef(null);

  return (
    <Table
      ref={tableRef}
      data={tableData}
      columns={columns}
      meta={{
        job,
      }}
      hideCheckbox
      rowClickExpand
      getRowCanExpand={() => true}
      {...props}
    />
  );
};
