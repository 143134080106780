import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../../../shared/Modal";
import { useDeleteEquipmentMutation } from "../../../redux/services/equipment";

export const DeleteEquipmentModal = ({
  visible,
  setVisible,
  equipment,
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deleteEquipment, { isLoading: isDeleting }] =
    useDeleteEquipmentMutation();

  const title = t("equipment.delete.title");

  function cannotDeleteEquipment() {
    if (Array.isArray(equipment)) {
      const equipmentArray = equipment;
      let error = false;
      equipmentArray.forEach((item) => {
        // Add conditions specific to equipment that prevent deletion
        // For example:
        if (item.isInUse) {
          error = "equipment.delete.in-use";
          return;
        }
        // Add more conditions as needed
      });
      return error;
    }
  }

  function renderContent() {
    const cannotDelete = cannotDeleteEquipment();
    if (cannotDelete) {
      return t(cannotDelete);
    }

    return t("equipment.delete.default");
  }

  async function handleDelete() {
    const res = await deleteEquipment({ data: equipment });

    toast(res.data.message, {
      type: res.data.status,
      toastId: "delete-equipment",
    });

    if (res.data.status === "success") {
      navigate("/equipment");
      setVisible(false);
      props.deselectItems();
    }

    return res.data;
  }

  return (
    <Modal
      loading={isDeleting}
      confirm={handleDelete}
      disabled={cannotDeleteEquipment()}
      visible={visible}
      setVisible={setVisible}
      title={title}
      content={renderContent()}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};
