import { useEffect, useLayoutEffect, useState } from "react";
import Modal from "../../shared/Modal";
import Button from "../../shared/Button";
import useForm from "../../hooks/useForm";
import LocationCreateForm from "./LocationCreateForm";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch } from "react-redux";
import {
  addNewLocation,
  updateLocation,
} from "../../redux/reducers/locations/locationSlice";
import {
  locationAdded,
  locationUpdated,
} from "../../redux/reducers/locations/locationsSlice";

const LocationCreateModal = ({
  visible,
  setVisible,
  editData,
  clientId,
  initData,
}) => {
  const { form, updateForm, clearForm, setFormInit } = useForm();
  const { user } = useAuth();
  const dispatch = useDispatch();

  // CREATE MODAL
  function closeCreateModal() {
    setVisible(false);
    clearForm();
  }

  useLayoutEffect(() => {
    if (initData || editData) {
      setFormInit(initData || editData);
    }

    return () => clearForm();
  }, [editData, initData]);

  async function saveLocation(create) {
    let res;
    const receivingHours = { open: form.open, close: form.close };
    const address = {
      address: form.address,
      suite: form.suite,
      city: form.city,
      state: form.state,
      country: form.country,
      zipcode: form.zipcode,
    };

    if (create) {
      const createData = {
        data: {
          name: form.name,
          phone: form.phone,
          note: form.note,
          precall: form.precall,
          address,
          receivingHours,
          client: clientId,
        },
        parentId: user.parentCompany,
      };
      res = await dispatch(addNewLocation(createData)).unwrap();
    } else {
      const update = {
        data: {
          name: form.name,
          phone: form.phone,
          note: form.note,
          precall: form.precall,
          address: { ...address, _id: form.addressId },
          receivingHours,
        },
        locationId: editData._id,
        parentId: user.parentCompany,
      };
      res = await dispatch(updateLocation(update)).unwrap();
    }
    if (res.status === "success") {
      if (create) {
        dispatch(locationAdded({ location: res.data }));
      } else {
        dispatch(locationUpdated({ location: res.data }));
      }
      closeCreateModal();
    }
  }

  const createModalTitle = `${editData ? "Edit" : "Create New"} Location`;
  const createModalFooter = (
    <div>
      <Button className="mr-3" onClick={closeCreateModal} type="alternative">
        Cancel
      </Button>
      <Button onClick={() => saveLocation(!form.edit)} type="primary">
        Submit
      </Button>
    </div>
  );

  const createModalContent = (
    <LocationCreateForm form={form} onChange={updateForm} />
  );

  return (
    <>
      <Modal
        visible={visible}
        setVisible={setVisible}
        title={createModalTitle}
        footer={createModalFooter}
        content={createModalContent}
        size="lg"
        footerPosition="right"
      />
    </>
  );
};

export default LocationCreateModal;
