import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../../utils/request";

const initialState = {
  driver: {},
  status: "idle",
  error: null,
};

export const fetchDriver = createAsyncThunk(
  "user/fetchDriver",
  async ({ parentId, driverId }) => {
    const res = await request(`/users/${driverId}?parent=${parentId}`);
    return res.data;
  }
);

export const updateDriver = createAsyncThunk(
  "driver/updateDriver",
  async ({ parentId, driverId, data }) => {
    const res = await request(`/users/${driverId}?parent=${parentId}`, {
      method: "put",
      data: data,
    });
    return res.data;
  }
);

export const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    resetDriver: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDriver.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchDriver.fulfilled, (state, action) => {
        state.driver = {};
        state.status = "succeeded";
        state.driver = action.payload.user;
      })
      .addCase(fetchDriver.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateDriver.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateDriver.fulfilled, (state, action) => {
        state.driver = {};
        state.status = "succeeded";
        state.driver = action.payload.data;
      })
      .addCase(updateDriver.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetDriver } = driverSlice.actions;
export default driverSlice.reducer;

export const selectDriver = (state) => state.driver.driver;
