import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../../../shared/Table/table.utils";
import { formatDate, renderAmount } from "../../../../utils";
import { loadTypeOptions } from "../../../Jobs/select.config";
import _ from "lodash";

// Config for table and form
const tableConfig = [
  {
    id: "orderNumber",
    visible: true,
    required: true,
    minWidth: 180,
    editable: true,
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${original.jobId}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "loadType",
    // required: true,
    minWidth: 140,
    editable: {
      type: "select",
      options: loadTypeOptions,
    },
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "totalCharges",
    cell: ({ getValue }) => renderAmount(getValue()),
    minWidth: 140,
  },
  {
    id: "transactionDate",
    cell: ({ getValue }) => formatDate(getValue(), null, true),

    visible: false,
  },
  {
    id: "dateUpdated",
    visible: false,
  },
  {
    id: "dateCreated",
    visible: false,
  },
  {
    id: "_id",
    header: "id",
    visible: false,
  },
];

export const FORM_OPTIONS = {
  validations: renderValidations(tableConfig, "jobs"),
};
export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Jobs", value: 10 },
  { label: "20 Jobs", value: 20 },
  { label: "50 Jobs", value: 50 },
  { label: "100 Jobs", value: 100 },
  { label: "150 Jobs", value: 150 },
];
