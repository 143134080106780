import Input from "../../../../shared/Input";

const Users = ({
  remove,
  id,
  formData,
  data,
  renderValue,
  handleChange,
  errors,
  form,
}) => {
  return (
    <div className="my-3">
      <div className="grid grid-cols-1 gap-1 lg:grid-cols-6 lg:gap-3">
        <div>
          <Input
            id={"email"}
            value={form.user?.email}
            label="Email"
            placeholder="Email"
            onChange={handleChange}
            error={errors["email"]}
          />
        </div>
        <div>
          <Input
            id={"firstName"}
            value={form.user?.firstName}
            label="First Name"
            placeholder="First Name"
            onChange={handleChange}
            error={errors["firstName"]}
          />
        </div>
        <div>
          <Input
            id={"lastName"}
            value={form.user?.lastName}
            label="Last Name"
            placeholder="Last Name"
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            id={"phone"}
            value={form.user?.phone}
            label="Phone"
            placeholder="Phone"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
