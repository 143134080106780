import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../shared/Table/table.utils";
import { camelCaseToSpaces } from "../../utils";

// Config for table and form
const tableConfig = [
  {
    id: "fullName",
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/users/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "email",
    minWidth: 260,
    validations: { required: true, email: true },
    cell: ({ getValue }) => <span className="break-all">{getValue()}</span>,
  },
  {
    id: "firstName",
    visible: false,
  },
  {
    id: "lastName",
    visible: false,
  },
  { id: "phone", visible: false },
  {
    id: "type",
    cell: ({ getValue }) => (getValue() ? camelCaseToSpaces(getValue()) : ""),
  },
  {
    id: "displayName",
    visible: false,
  },
  {
    id: "authority",
    visible: false,
    cell: ({ getValue }) => (getValue() ? camelCaseToSpaces(getValue()) : ""),
  },
  {
    id: "active",
    cell: ({ row: { original }, getValue }) => {
      return getValue() ? (
        <CheckCircleIcon className="w-5 h-5 text-green-500" />
      ) : (
        <XCircleIcon className="w-5 h-5 text-red-500" />
      );
    },
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = { validations: renderValidations(tableConfig) };
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Jobs", value: 10 },
  { label: "20 Jobs", value: 20 },
  { label: "50 Jobs", value: 50 },
  { label: "100 Jobs", value: 100 },
  { label: "150 Jobs", value: 150 },
];
