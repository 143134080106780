import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../shared/Button";

const FormLayout = (props) => {
  const navigate = useNavigate();

  function handleBack() {
    navigate(props.back || -1);
  }

  return (
    <div className={props.className}>
      <div className="bg-white border-y py-2 px-4 flex items-center justify-between">
        <div className="flex items-center">
          <div
            onClick={handleBack}
            className="flex items-center  rounded-full cursor-pointer ease-in-out duration-300 hover:bg-primary-50 p-1"
          >
            <ChevronLeftIcon className="h-6 w-6" />
          </div>
          <div className="md:ml-4">{props.title}</div>
        </div>
        {props.actions}
      </div>
      <div className={props.contentClassname || "my-5"}>
        {props.children}
        {!props.hideButtons && (
          <div className="flex justify-end mt-4">
            <Button
              className="w-6/12 lg:w-28 mr-3"
              type="alternative"
              onClick={props.cancel || handleBack}
              disabled={props.cancelDisabled}
            >
              Cancel
            </Button>
            <Button
              loading={props.loading}
              className="w-6/12 lg:w-28"
              disabled={props.loading || props.isValid || props.submitDisabled}
              onClick={props.handleSubmit}
            >
              {props.buttonTitle ? props.buttonTitle : "Save"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormLayout;
