import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import useQuery from "../../hooks/useQuery";
import { capitalizeFirstLetter } from "../../utils";

export const SideMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const config = useMemo(() => props.config, [props.config]);
  const [active, setActive] = useState("");
  const [activeData, setActiveData] = useState();

  const configKeys = Object.keys(config);
  const id = props.id || "menuTitle";
  const queryId = query.get(id);

  function getDefaultTab() {
    const menuQuery = queryId;
    if (!menuQuery) {
      const content = renderContent();
      return content.title;
    }
    return menuQuery;
  }

  function renderContent(activeId, onlyContent, testId) {
    let content;
    if (!activeId) {
      content = config[configKeys[0]];
    } else {
      content = configKeys.map((key) => {
        return config[key].find((o) => {
          return o.id === activeId || o.title === activeId;
        });
      });
    }

    const res = content[0];
    return onlyContent ? res.content : res;
  }

  useEffect(() => {
    const defaultTab = getDefaultTab();
    if (defaultTab) setActive(defaultTab);
  }, [queryId]);

  function handleTabClick(e) {
    const params = new URLSearchParams({ menuTitle: e.id || e.title });
    const path = location.pathname + "?" + params.toString() + location.hash;

    navigate(path);
    setActive(e.id || e.title);
    setActiveData(e);
  }

  const pageContent = renderContent(active);

  return (
    <div className="grid grid-cols-5 divide-x h-[47rem] overflow-hidden">
      <div className="p-4 bg-white overflow-y-auto">
        {configKeys.map((title, idx) => {
          return (
            <div key={idx} className="space-y-2">
              <div>
                <span className="font-medium ">
                  {capitalizeFirstLetter(title)}
                </span>
              </div>
              <div className="space-y-2">
                {config[title].map((item, i) => {
                  const isActive = active === item.title;
                  const classes = classNames(
                    "text-gray-500 cursor-pointer hover:text-gray-600",
                    "flex flex-col",
                    { "text-primary-700 font-semibold": isActive }
                  );
                  return (
                    <div
                      key={i}
                      onClick={() => handleTabClick(item)}
                      className={classes}
                    >
                      {item.title}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="p-4 col-span-4 space-y-4 overflow-y-auto">
        <div className="flex items-center justify-between">
          <div className="text-gray-800 text-3xl font-bold">
            {pageContent.pageTitle || active}
          </div>
          {pageContent.action && <div>{pageContent.action}</div>}
        </div>
        <div className="space-y-2">{pageContent.content}</div>
      </div>
    </div>
  );
};

export default SideMenu;
