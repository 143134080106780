import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const initialState = {
  invoice: {},
  status: "idle",
  error: null,
};

export const fetchInvoice = createAsyncThunk(
  "invoice/fetchInvoice",
  async ({ invoiceId, ...obj }) => {
    const queries = renderQueries(obj);
    const res = await request(`/invoices/${invoiceId}?${queries}`);
    return res.data;
  }
);

export const addNewInvoice = createAsyncThunk(
  "invoice/addNewInvoice",
  async ({ parentId, data }) => {
    const res = await request(`/invoices?parent=${parentId}`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const updateInvoice = createAsyncThunk(
  "invoice/updateInvoice",
  async ({ parentId, invoiceId, data }) => {
    const res = await request(`/invoices/${invoiceId}?parent=${parentId}`, {
      method: "put",
      data,
    });
    return res.data;
  }
);

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    resetInvoice: () => initialState,
    setInvoices(state, action) {
      state.invoice = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInvoice.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchInvoice.fulfilled, (state, action) => {
        state.invoice = {};
        state.status = "succeeded";
        state.invoice = action.payload.data;
      })
      .addCase(fetchInvoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload.data;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload.data;
      });
  },
});

export const { invoiceAdded, resetInvoice } = invoiceSlice.actions;

export default invoiceSlice.reducer;

export const selectInvoice = (state) => state.invoice.invoice;
