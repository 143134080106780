import Modal from "../../shared/Modal";
import Button from "../../shared/Button";

const UserActivateModal = ({ visible, setVisible, submit, loading }) => {
  function handleCancelModal() {
    setVisible(false);
  }

  const modalFooter = (
    <>
      <Button onClick={handleCancelModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={loading}
        disabled={loading}
        onClick={submit}
        type="primary"
      >
        Confirm
      </Button>
    </>
  );

  const modalContent = (
    <div className="space-y-2">
      <p>Are you sure you want to delete this user?</p>
    </div>
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={() => setVisible(false)}
      title="Delete User"
      footer={modalFooter}
      content={modalContent}
      size="md"
      footerPosition="right"
    />
  );
};

export default UserActivateModal;
