import { useEffect, useRef, useState, useMemo } from "react";
import {
  AssignedToSelect,
  ClientCard,
  SidebarContainer,
} from "../../../components";
import Tabs from "../../../shared/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import JobInfo from "./Info";
import Accessories from "./Accessories";
// import Files from "./Files";
import { useAuth } from "../../../hooks/useAuth";
import { jobUpdated } from "../../../redux/reducers/jobs/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import useForm from "../../../hooks/useForm";
import { FORM_OPTIONS } from "../table.config";
import Button from "../../../shared/Button";
import Badge from "../../../shared/Badge";
import SidebarContent from "./SidebarContent";
import Toggle from "../../../shared/Toggle";
import { useConfig } from "../../../hooks/useConfig";
import {
  ChevronDoubleLeftIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import request, { renderQueries } from "../../../utils/request";
import { Avatar, EditableText, Modal } from "../../../shared";
import Dropdown from "../../../shared/Dropdown";
import { getNextStage, getStage } from "../job-stages";
import { camelCase } from "lodash";
import { toast } from "react-toastify";
import { useCrumbs } from "../../../hooks/useCrumbs";
import {
  useDeleteJobsMutation,
  useGetJobQuery,
  useUpdateGlobalJobRatesMutation,
  useUpdateJobMutation,
} from "../../../redux/services/jobs/jobsApi";
import { useAddInvoiceMutation } from "../../../redux/services/invoices/invoicesApi";
import { DeleteJobModal } from "../DeleteModal";
import { capitalizeFirstLetter } from "../../../utils";
import { getAppType, isAuthorized } from "../../../utils/authority";
import JobEquipment from "./Equipment";

const JobsShow = () => {
  // Library Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // Custom Hooks
  const { user } = useAuth();
  const { configs } = useConfig();
  const crumbs = useCrumbs();

  // React Hooks
  const [loading, setLoading] = useState(true);
  const [modalLoad, setModalLoad] = useState(false);
  const [isEditing, setisEditing] = useState(false);
  const [secondaryButtonLoading, setSecondaryButtonLoading] = useState(false);
  const [mainActionModal, setMainActionModal] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [createInvoiceVisible, setCreateInvoiceVisible] = useState(false);

  // Form hook
  const { form, setFormInit, clearForm, updateForm } = useForm(FORM_OPTIONS);

  // RTKQ API calls
  const { data: jobData, isLoading: jobLoading } = useGetJobQuery(
    {
      jobId: params.id,
      drivers: true,
    },
    { skip: !params.id }
  );
  const [addInvoice, { isLoading: addingInvoice }] = useAddInvoiceMutation();
  const [updateJob, { isLoading: jobUpdating }] = useUpdateJobMutation();
  const [updateGlobalJobRates, { isLoading: globalRatesUpdating }] =
    useUpdateGlobalJobRatesMutation();

  const job = jobData || {};

  // Update form helper
  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  // Constant Variables
  const jobId = job.jobId || job.loadNumber || job.orderNumber;
  const editing = isEditing;
  const isNew =
    job.status === getStage(1, configs.parentCompany?.appType).title;
  const isPending =
    job.status === getStage(2, configs.parentCompany?.appType).title;
  // const isBooked = job.status === getStage(3).title;
  const isJobActive = job.status && !isNew && !isPending; //&& !isBooked;
  const stage = getStage(job.status, configs.parentCompany?.appType);

  // console.log(job.status, isNew, stage);

  /* 
    Helper function to get rate based on current job options
  */
  function renderRate(name, useGlobal) {
    if (useGlobal || form.useGlobalRates || job.useGlobalRates) {
      return configs.rates?.[name];
    } else {
      return job.client?.rates?.[name];
    }
  }

  useEffect(() => {
    if (configs) {
      crumbs.setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  useEffect(() => {
    if (job._id) {
      const init = {
        ...job,
        fuelRate: job.fuelRate || renderRate("fuelRate") || renderRate("fuel"),
        // ||
        // renderRate("fuelRate", true),
        baseRate: job.baseRate || renderRate("baseRate") || renderRate("base"),
        // ||
        // renderRate("baseRate", true),
        flatRate: job.flatRate || renderRate("flatRate"),
        // ||
        // renderRate("flatRate", true),
        transactionDate: job.transactionDate, //|| Date.now(),
        // useGlobalRates:
        //   job.useGlobalRates ||
        //   (!renderRate("fuelRate") && !renderRate("baseRate")),
      };

      crumbs.updateCrumbName({
        name: jobId,
        path: "/jobs/" + job._id,
      });
      crumbs.setLoading(false);
      setFormInit(init);
      setTimeout(() => setLoading(false), 500);
    }
  }, [job._id, editing]);

  const tabs = useMemo(() =>
    [
      {
        id: "Info",
        content: (
          <JobInfo
            form={{ form, setFormInit, clearForm, updateForm }}
            job={job}
            editing={editing}
            isJobActive={isJobActive}
            isNew={isNew}
            isPending={isPending}
            loading={jobLoading || loading}
            setLoading={setLoading}
          />
        ),
      },
      // {
      //   id: "Equipment",
      //   content: (
      //     <JobEquipment
      //       form={{ form, setFormInit, clearForm, updateForm }}
      //       job={job}
      //     />
      //   ),
      // },
      // {
      //   id: "Legs",
      //   title: `Legs (${job.legs?.length || 0})`,
      //   content: (
      //     <LegsTable
      //       job={job}
      //       setVisible={setLegModalVisible}
      //       visible={legModalVisible}
      //       renderHeader={() => renderHeader(job)}
      //       form={legForm}
      //       editing={editing}
      //     />
      //   ),
      //   hidden: "New,Pending,Booked",
      // },
      {
        id: "Accessories",
        content: (
          <Accessories
            accessories={job?.accessories}
            user={user}
            jobId={job?._id}
            editing={editing}
          />
        ),
      },
      // {
      //   id: "Files",
      //   content: (
      //     <Files
      //       user={user}
      //       jobId={job?._id}
      //       files={job?.files}
      //       editing={editing}
      //     />
      //   ),
      //   hidden: "New",
      // },
    ].filter((item) => {
      if (item.hidden?.includes(job.status)) {
        return false;
      }
      return true;
    })
  );

  function handleTabClick(_e, _item, path) {
    // location.hash = e.target.innerHTML;
    navigate(path);
  }

  function getDefaultTab() {
    if (location.hash) {
      const tabHash = location.hash.slice(1);
      return tabs.findIndex((tab) => tab.id === tabHash);
    }
    return;
  }

  const title = (
    <div className="flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="jobId"
        value={jobId}
        size="md"
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        disabled={!isAuthorized("owner,admin")}
        loading={modalLoad}
        onSave={(e) => updateJobData({ ...e.data, loadNumber: e.value })}
      />
      {job.status && (
        <Badge
          color={stage?.color}
          label={capitalizeFirstLetter(job.status)}
          size="lg"
          className="ml-2"
        />
      )}
      {isAuthorized("owner,admin") && job.invoiceId?.invoiceId && (
        <Badge
          // color={stage?.color}
          link={`/invoices/${job.invoiceId._id}`}
          label={`Invoice: ${job.invoiceId.invoiceId}`}
          size="lg"
          className="ml-2"
        />
      )}
    </div>
  );

  /* 
    Handle use global rates toggle
  */
  async function handleGlobalRates({ target }) {
    setLoading(true);
    let data = { useGlobalRates: target.checked, id: target.id };

    data.fuelRate =
      renderRate("fuelRate", data.useGlobalRates) ||
      renderRate("fuel", data.useGlobalRates);
    data.baseRate =
      renderRate("baseRate", data.useGlobalRates) ||
      renderRate("base", data.useGlobalRates);

    if (form.useFlatRate) {
      data.flatRate = renderRate("flatRate", data.useGlobalRates);
    }

    const res = await updateGlobalJobRates({ id: form._id, data }).unwrap();

    setFormInit(res.data);
    // dispatch(jobUpdated(res.data.data));
    setLoading(false);
  }

  /* 
    Handle use flat rate toggle
  */
  async function handleFlatRate({ target }) {
    setLoading(true);

    let data = {
      useFlatRate: target.checked,
      id: target.id,
      useGlobalRates: form.useGlobalRates,
    };

    data.flatRate = target.checked && renderRate("flatRate");
    setFormInit(data);
    const res = await updateGlobalJobRates({ id: form._id, data }).unwrap();

    // dispatch(jobUpdated(res.data.data));
    setLoading(false);
  }

  function getNextStatus() {
    return getNextStage(camelCase(job.status)).title;
  }

  /* 
    Update Job 
  */
  async function updateJobData(data) {
    setModalLoad(true);

    const updateData = {
      id: job._id,
      data: {
        ...data,
      },
    };

    const res = await updateJob(updateData).unwrap();

    if (res.status) {
      dispatch(jobUpdated(res.data));
      if (editing) {
        setisEditing(false);
      }
      setMainActionModal(false);
    }

    setModalLoad(false);
  }

  function updateJobStatus(e) {
    const updateData = {};

    if (stage.id === "new" || editing) {
      updateData.transactionDate = form.transactionDate;
    }

    if (!editing) {
      updateData.status = getNextStatus();
    }

    updateJobData(updateData);
  }

  function renderButtonTitle() {
    const res = getStage(camelCase(job.status));
    return res?.button;
  }

  const actions = isAuthorized("owner,admin") && (
    <>
      {(stage?.secondaryButton || (isJobActive && !job.invoiceId)) && (
        <Button
          size="sm"
          onClick={() => setCreateInvoiceVisible(true)}
          type="alternative"
        >
          Create Invoice
        </Button>
      )}
      {(stage?.button || editing) && (
        <Button
          onClick={() => setMainActionModal(true)}
          className="min-w-24"
          size="sm"
        >
          {editing && job.status !== getStage(1).title
            ? "Save"
            : renderButtonTitle()}
        </Button>
      )}
    </>
  );

  const rateActions = isAuthorized("owner,admin") && (editing || isNew) && (
    <>
      <div className="flex items-center space-x-2">
        <span className="text-gray-800">Global Rates:</span>
        <Toggle
          id="useGlobalRates"
          size="sm"
          checked={form.useGlobalRates}
          onChange={(e) => handleGlobalRates({ target: e.target })}
        />
      </div>
      <div className="flex items-center  space-x-2">
        <span className="text-gray-800">Flat Rate:</span>
        <Toggle
          id="useFlatRate"
          size="sm"
          checked={form.useFlatRate}
          onChange={(e) => handleFlatRate({ target: e.target })}
        />
      </div>
    </>
  );

  const assignedTo = <AssignedToSelect />;

  async function createInvoice() {
    const invoiceData = { jobs: [{ _id: job._id }], client: job.client };
    const res = await addInvoice(invoiceData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "inv-job-create",
    });
    setCreateInvoiceVisible(false);
  }

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  let headerActions;
  switch (getAppType()) {
    case "serviceRepair":
      headerActions = assignedTo;
      break;
    default:
      headerActions = rateActions;
  }

  const renderHeader = (headerProps) => {
    return (
      <div ref={headerRef}>
        <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
          <div>{headerProps.title}</div>
          <div className="flex items-center space-x-3">
            <div className="md:flex items-center space-x-3 hidden">
              {headerActions}
            </div>
            {headerProps.actions}
            <div
              onClick={() => headerProps.setOpen(true)}
              className="hover:bg-gray-100 cursor-pointer p-2 rounded-lg block lg:hidden"
            >
              <ChevronDoubleLeftIcon className="w-5 text-gray-800" />
            </div>
          </div>
        </header>
        <div className="flex justify-between md:hidden space-x-5 px-3 py-3 bg-white border-b">
          {headerActions}
        </div>
      </div>
    );
  };

  const sidebarContent = (
    <SidebarContent
      updateForm={updateForm}
      form={form}
      // handleUpdate={handleUpdate}
      onFormChange={onFormChange}
      job={job}
    />
  );

  const modalFooter = (
    <>
      <Button onClick={() => setMainActionModal(false)} type="alternative">
        Cancel
      </Button>
      <Button
        loading={modalLoad}
        disabled={modalLoad}
        onClick={updateJobStatus}
        type="primary"
      >
        Submit
      </Button>
    </>
  );

  const modalCreateFooter = (
    <>
      <Button onClick={() => setCreateInvoiceVisible(false)} type="alternative">
        Cancel
      </Button>
      <Button
        loading={addingInvoice}
        disabled={addingInvoice}
        onClick={createInvoice}
        type="primary"
      >
        Submit
      </Button>
    </>
  );

  // async function handleDelete() {
  //   const res = await deleteJobs({ data: [job._id] });

  //   toast(res.data.message, {
  //     type: res.data.status,
  //     toastId: "delete-jobs",
  //   });

  //   if (res.data.status === "success") {
  //     navigate("/jobs");
  //   }

  //   return res.data;
  // }

  const jobShowActions = [
    { value: "edit", label: "Edit", onClick: () => setisEditing(true) },
    { value: "delete", label: "Delete", onClick: () => setDeleteVisible(true) },
  ].filter((i) => {
    if (isNew && (i.value === "edit" || i.value === "delete")) return false;
    return true;
  });

  function renderModalContent() {
    if (stage && stage.modal) {
      return stage.modal;
    }
    if (editing) return "Are you sure you want to save this job?";
    if (job.status === "New") {
      return "Are you sure you want to publish this job?";
    }
    if (job.status === "Pending") {
      return "Are you sure you want to start this job?";
    }
    if (job.status === "En Route") {
      return "Are you sure you want to mark this job complete?";
    }
    return "";
  }

  function renderTabHeader(headerProps) {
    return (
      <div className="flex flex-row justify-between bg-white items-center">
        <div>{headerProps}</div>

        <div className="flex flex-row items-center mr-6">
          <Dropdown
            align="right"
            closeOnClick
            trigger={
              <div className="flex flex-row items-center">
                <Avatar
                  src={job.client?.avatar}
                  name={job.client?.name}
                  size="xs"
                />
                <div className="ml-2 text-md text-gray-700">
                  {job.client?.name}
                </div>
              </div>
            }
            data={<ClientCard client={job.client} />}
            itemClick={onFormChange}
          />
        </div>
      </div>
    );
  }

  const modalContent = <div className="">{renderModalContent()}</div>;

  return (
    <>
      <div className="flex items-center justify-between px-4">
        <div className="flex items-center space-x-24">
          {/* <div>Assignee: User</div> */}
          {/* <div>Comission: Co-owner</div> */}
        </div>
        {isAuthorized("owner,admin") && !isNew && (
          <div>
            <Dropdown
              align="right"
              closeOnClick
              trigger={
                <EllipsisHorizontalIcon className="cursor-pointer w-5 h-5" />
              }
              data={jobShowActions}
              itemClick={onFormChange}
            />
          </div>
        )}
      </div>
      <SidebarContainer
        title={title}
        header={renderHeader}
        actions={actions}
        // loading={loading}
        hideSidebar={!isAuthorized("owner,admin")}
        sidebarContent={sidebarContent}
        sidebarTitle={<h2 className="text-xl bg-white py-2">Communications</h2>}
      >
        {({ parentHeight, parentWidth }) => {
          const offsetTop = headerContext?.offsetTop;
          const offsetHeight = headerContext?.offsetHeight;
          const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
          const panelStyle = {
            height: `${parentHeight - offsetTop - offsetHeight - 40}px`,
          };
          return (
            <Tabs
              className={`w-full overflow-hidden`}
              style={wrapperStyle}
              panelStyle={panelStyle}
              useSearchParams
              panelClasses={`p-2 border-t overflow-y-scroll`}
              tabListClasses="bg-white px-2 h-[38px]"
              tabclassName="mx-1"
              tabHeader={renderTabHeader}
              id="job-show"
              onClick={handleTabClick}
              data={tabs}
            />
          );
        }}
      </SidebarContainer>
      <Modal
        visible={mainActionModal}
        setVisible={setMainActionModal}
        onClose={() => setMainActionModal(false)}
        title="Are you sure"
        footer={modalFooter}
        content={modalContent}
        size="md"
        footerPosition="right"
      />
      <DeleteJobModal
        visible={deleteVisible}
        setVisible={setDeleteVisible}
        jobs={[job]}
      />
      <Modal
        visible={createInvoiceVisible}
        setVisible={setCreateInvoiceVisible}
        onClose={() => setCreateInvoiceVisible(false)}
        title="Create Invoice"
        footer={modalCreateFooter}
        content="Are you sure you want to create an invoice with this job?"
        size="md"
        footerPosition="right"
      />
    </>
  );
};

export default JobsShow;
