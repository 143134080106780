const isDev = process.env.NODE_ENV === "development";
const isBeta = window.location.href.includes("beta");
const isAlpha = window.location.href.includes("alpha");

let API_STAGE = "dev";

if (isBeta) {
  API_STAGE = "beta";
}
if (isAlpha) {
  API_STAGE = "alpha";
}
if (!isDev && !isBeta && !isAlpha) {
  API_STAGE = "prod";
}

/*
  Stage
*/
export const STAGE = API_STAGE;

export const COOKIE_NAME =
  "Attune-" + (isDev ? "development" : API_STAGE) + "-Context";

/*
  Root API URL
*/
export const ROOT_API_URL = isDev
  ? "http://localhost:8080/api/v1"
  : `https://${
      API_STAGE !== "prod" ? API_STAGE + "." : ""
    }api.attunelogic.com/api/v1`;

/*
  Root UI URL
*/
export const ROOT_URL = isDev
  ? "http://localhost:3000"
  : `https://${
      API_STAGE !== "prod" ? API_STAGE + "." : ""
    }app.attunelogic.com/`;

/*
  Sentry dsn connection string
*/
export const SENTRY_DSN =
  "https://db40a7b27d08585b29086faf9766ef63@o4505003302780928.ingest.sentry.io/4506833066131456";

export const defaultSettings = {
  STAGE: API_STAGE,
  ROOT_API_URL,
  ROOT_URL,
  SENTRY_DSN,
};

export default { ...defaultSettings };
