export const roles = [
  {
    id: 0,
    auth: "client",
    description: "Users with a client role can view their companys data",
  },
  {
    id: 1,
    auth: "user",
    description: "A user role allows users to view their data",
  },
  { id: 2, auth: "admin", description: "An admin is allowed full access data" },
  {
    id: 3,
    auth: "owner",
    description: "An owner is allowed full access and account settings",
  },
  { id: 4, auth: "superAdmin", hide: true, description: "" },
];

export function renderMenuItems(arr, userAuthority) {
  const menuItems = arr;

  return menuItems.filter((i) => {
    if (typeof i.authority === "boolean") {
      return i.authority;
    }
    if (!i.authority) {
      return true;
    }

    const verifyAuthority = verifyAuth(i.authority, userAuthority);
    return verifyAuthority.type === "success";
  });
}

export function verifyAuth(currentAuth, userAuthority) {
  const routeAuth = roles.filter((item) => currentAuth.includes(item.auth))[0];
  const userAuth = roles.filter((item) => userAuthority.includes(item.auth))[0];

  if (!userAuth)
    return {
      type: "error",
      message: "User does not have permission",
    };

  if (userAuth.id < routeAuth.id) {
    return {
      type: "error",
      message: "User does not have permission",
    };
  }

  if (userAuth.id >= routeAuth.id) {
    return {
      type: "success",
      message: "User has permission",
    };
  }
}
