import React, { useCallback, useState } from "react";
import Table from "../../shared/Table";
import Button from "../../shared/Button";
import ButtonDropdown from "../../shared/ButtonDropdown";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils";
import useWindowSize from "../../hooks/useWindowSize";
import Modal from "../../shared/Modal";
import { renderColumnDefs } from "../../shared/Table/table.utils";

const TableLayout = ({
  data,
  columns,
  id,
  loading,
  pageSizeOptions,
  actions,
  createPath,
  handleEdit,
  handleDelete,
  editPath,
  noEdit,
  noDelete,
  onUpdate,
  fullSelectedItem,
  title,
  enableSorting,
  hideActions,
  hideMainAction,
  ...rest
}) => {
  const navigate = useNavigate();
  const { breakpoint } = useWindowSize();
  const isMobile = breakpoint === "sm" || breakpoint === "xs";

  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  // const tableColumns = React.useMemo(() => columns, [columns]);
  // const tableData = React.useMemo(() => data, [data]);

  const handleRowSelection = useCallback(
    (value) => setSelectedItems(value),
    []
  );

  const options = [
    {
      title: "Edit",
      onClick: () =>
        handleEdit
          ? handleEdit(
              fullSelectedItem ? selectedItems[0] : selectedItems[0]._id
            )
          : navigate(`/${editPath || id}/${selectedItems[0]._id}`),
    },
    {
      title: "Delete",
      onClick: () => clickDelete(selectedItems), //
    },
  ]
    .filter((item) => {
      if (noEdit) {
        return false;
      }
      if (noDelete) {
        return false;
      }
      return true;
    })
    .map((item) => {
      if (Object.keys(selectedItems).length > 1 && item.title === "Edit") {
        return { title: item.title, disabled: true };
      }
      return item;
    });

  const buttonSize = isMobile && "sm";
  const tableActions =
    !hideActions &&
    (actions || actions === null ? (
      actions
    ) : (
      <div className="flex items-center">
        {(handleEdit || handleDelete) && (
          <ButtonDropdown
            size={buttonSize}
            className="mr-2"
            type="alternative"
            data={options}
            disabled={Object.keys(selectedItems).length === 0}
          >
            Actions
          </ButtonDropdown>
        )}
        <Button
          className="w-full"
          size={buttonSize}
          onClick={() =>
            rest.mainAction && rest.mainAction.onClick
              ? rest.mainAction.onClick()
              : navigate(createPath ? createPath : `/${id}/create`)
          }
        >
          {rest.mainAction && rest.mainAction.title
            ? rest.mainAction.title
            : "Create New"}
        </Button>
      </div>
    ));

  // UPDATE DATA
  function updateData(obj, setLoading) {
    const data = {
      ...obj,
    };
    onUpdate && onUpdate(data, setLoading);
  }

  // DELETE MODAL
  function clickDelete() {
    setDeleteModalVisible(true);
  }
  async function deleteSelectedItems() {
    const deleted = await handleDelete(selectedItems);
    if (deleted.status === "success") {
      closeDeleteModal();
    }
  }
  function closeDeleteModal() {
    setDeleteModalVisible(false);
  }
  const deleteModalFooter = (
    <div>
      <Button className="mr-3" onClick={closeDeleteModal} type="alternative">
        Cancel
      </Button>
      <Button onClick={deleteSelectedItems} type="danger">
        Delete
      </Button>
    </div>
  );
  const deleteModalContent = (
    <div className="font-base">
      Are you sure you want to delete these items?
    </div>
  );

  const tableColumns = React.useMemo(
    () => renderColumnDefs(data) || [],
    [data]
  );

  return (
    <div>
      <Table
        {...rest}
        search
        loading={loading}
        title={title || capitalizeFirstLetter(id)}
        columns={columns || tableColumns}
        data={data}
        actions={tableActions}
        pageSizeOptions={pageSizeOptions}
        onSelectedRowsChange={handleRowSelection}
        pagination
        updateData={updateData}
        defaultPageSize={20}
        enableSorting={
          typeof enableSorting === "boolean" ? enableSorting : true
        }
      />
      <Modal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        title={`Delete ${Object.keys(selectedItems).length} item(s)`}
        footer={deleteModalFooter}
        content={deleteModalContent}
        size="md"
        footerPosition="right"
      />
    </div>
  );
};

export default TableLayout;
