import React from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { renderDefaultSelected } from "../../shared/Table/table.utils";
import {
  capitalizeAll,
  capitalizeFirstLetter,
  createAlphabet,
  formatDate,
} from "../../utils";
import classNames from "classnames";

function Table({ columns, data }) {
  const [expanded, setExpanded] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded: renderDefaultSelected(data, "all"),
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const tableClasses = ""; //classNames("w-full");
  const theadClasses = classNames("bg-gray-100");
  const trClasses = ""; //classNames("w-full");
  const thClasses = classNames("flex bg-gray-100 ");
  const tdClasses = classNames("py-2 bg-gray-100");

  return (
    <table id="manifest-table" className={tableClasses}>
      <thead className={theadClasses}>
        {table.getHeaderGroups().map((headerGroup, i) => (
          <tr className={trClasses} key={i}>
            {headerGroup.headers.map((header) => {
              // if (header.id.includes("legs")) return null;
              return (
                <th key={header.id + 1000} className={thClasses}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody className="w-full">
        {table.getRowModel().rows.map((row, i) => {
          const route = row.original.route;
          const rowId = route && Number(row.id.split(".")[1]);
          const canExpand = row.getCanExpand();

          return (
            <>
              {canExpand && (
                <tr key={row.id}>
                  <td colSpan={11}>
                    Date: {formatDate(row.original.transactionDate, null, true)}
                    <br />
                    Truck Load: {row.original.loadNumber}
                  </td>
                </tr>
              )}
              {!canExpand && (
                <tr key={row.id + 100}>
                  {row.getVisibleCells().map((cell) => {
                    // if (cell.id.includes("legs")) return null;
                    return (
                      <td className={tdClasses} key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              )}
              {
                !canExpand && ""
                // <Route data={row.original} rowId={rowId} key={row.id + 200} />
              }
            </>
          );
        })}
      </tbody>
    </table>
  );
}

const ManifestTable = (props) => {
  const tableData = props.tableData;
  // props.tableData.map((item) => {
  //   return {
  //     ...item,
  //     // subRows: item.legs,
  //   };
  // });
  const columns = React.useMemo(() => props.columns, []);
  const data = React.useMemo(() => tableData, [tableData]);

  return props.tableData && <Table columns={columns} data={data} />;
};

export default ManifestTable;

function renderAddress(data, id, point) {
  if (!data.route[id]) return;
  const { address, name } = data.route[id];
  const capitalName = capitalizeAll(name);
  const header = `${capitalizeFirstLetter(id)} `;
  const street = capitalizeAll(address.address);
  const addressRest = `${capitalizeAll(address.city)}, ${capitalizeAll(
    address.state
  )} ${address.zipcode}`;

  return (
    <td className="text-left" colSpan={6.5}>
      {header}
      <br />
      {capitalName}
      <br />
      {street}
      <br />
      {addressRest}
    </td>
  );
}

function Route({ data, rowId }) {
  const alphabet = createAlphabet();
  const originPoint = alphabet[rowId];
  const destPoint = alphabet[rowId + 1];

  return !data.route ? (
    <tr>
      {/* <td colSpan={13}>
        {" "}
        *CPM FUEL SURCHARGE DETERMINED BY DISTANCE FROM POINT {originPoint} TO
        POINT {destPoint} ({data.route?.mileage?.toLocaleString("en-US")} MILES)
      </td> */}
    </tr>
  ) : (
    <>
      <tr>
        {renderAddress(data, "origin", originPoint)}
        {renderAddress(data, "destination", destPoint)}
      </tr>
      <tr>
        {/* <td colSpan={13}>
          *CPM FUEL SURCHARGE DETERMINED BY DISTANCE FROM POINT {originPoint} TO
          POINT {destPoint} ({data.route?.mileage?.toLocaleString("en-US")}{" "}
          MILES)
        </td> */}
      </tr>
    </>
  );
}
