import {
  ChevronDownIcon,
  PlusIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../shared/Avatar";
import Button from "../../../shared/Button";
import Input from "../../../shared/Input";
import Modal from "../../../shared/Modal";
import Select from "../../../shared/Select";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import {
  fetchClients,
  selectClientsAvatarOptions,
} from "../../../redux/reducers/clients/clientsSlice";
import {
  driverSelectOptions,
  fetchDrivers,
} from "../../../redux/reducers/drivers/driversSlice";
import request, { renderQueries } from "../../../utils/request";
import InputDropdown from "../../../shared/InputDropdown";
import { useNavigate } from "react-router-dom";
import AdvancedSettings from "./AdvancedSettings";
import { DatePicker } from "../../../shared";
import { formatDate } from "../../../utils";

const CreateModal = ({
  modalOpen,
  setModalOpen,
  renderId = (id) => id,
  ...props
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { form, updateForm, clearForm, setFormInit } = useForm();

  // const [recentDrivers, setRecentDrivers] = useState([]);
  // const [searchTerm, setSearchTerm] = useState("");
  // const [driverOptions, setDriverOptions] = useState([]);

  const driverOptions = useSelector(driverSelectOptions);
  const driversStatus = useSelector((state) => state.drivers.status);

  useEffect(() => {
    if (!props.driver && driversStatus === "idle") {
      dispatch(
        fetchDrivers({
          parent: user.parentCompany,
          type: "driver",
        })
      );
    }
  }, [driversStatus, props.driver]);

  const handleDateUpdate = () => {
    const query = {
      parent: user.parentCompany,
      driverId: form.driver._id,
      manifestId: null,
      route: true,
      startDate: formatDate(form.startDate, null, true),
      endDate: formatDate(form.endDate, null, true),
    };
    getCount(query);
  };

  const getCount = async (query) => {
    query.countOnly = true;
    const res = await request(`/legs?${renderQueries(query)}`, {
      method: "get",
      data: {},
    });
    updateForm({ id: "count", value: res.data.data });
  };

  useEffect(() => {
    if (form.startDate && form.endDate && form.driver) {
      handleDateUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.startDate, form.endDate, form.driver]);

  useEffect(() => {
    if (props.driver && modalOpen) {
      setFormInit({ driver: props.driver });
    }
  }, [setFormInit]);

  // async function getRecentDrivers() {
  //   const res = await request(
  //     "/drivers/recent?" + renderQueries({ parent: user.parentCompany })
  //   );

  //   if (res.data.status === "success") {
  //     setRecentDrivers(res.data.data);
  //   }
  // }

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(async () => {
  //     if (searchTerm) {
  //       const res = await request(
  //         "/drivers/search?" +
  //           renderQueries({ parent: user.parentCompany, search: searchTerm })
  //       );

  //       const { data } = res.data;
  //     }
  //   }, 1000);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchTerm]);

  // useEffect(() => {
  //   if (recentDrivers.length === 0) {
  //     getRecentDrivers();
  //   }
  // }, [recentDrivers.length]);

  function closeModal() {
    setModalOpen(false);
    handleCancel();
  }

  async function savePayout() {
    // setLoading(true);
    const postData = {
      ...form,
    };

    const { data } = await request(
      "/payouts?" + renderQueries({ parent: user.parentCompany }),
      {
        method: "post",
        data: postData,
      }
    );
    {
      if (data.status === "success") {
        // setLoading(false);
        navigate(
          "/payouts/" + data.data._id
          // data.data._id
        );
      }
    }
  }

  const footer = (
    <div>
      <Button className="mr-3" onClick={closeModal} type="alternative">
        Cancel
      </Button>
      <Button onClick={() => savePayout()} type="primary">
        Submit
      </Button>
    </div>
  );

  function handleSelectDriver(recentDriver) {
    updateForm({ id: "driver", value: recentDriver });
  }

  function handleCancel() {
    clearForm("driver");
  }

  const createModalContent = (
    <div className="">
      {!form.driver ? (
        <div className="space-y-3">
          <div className="col-span-2 md:col-span-1 xl:col-span-2">
            <Select
              search
              searchKeys={["fullName"]}
              id={renderId("driver")}
              value={form.driver}
              onChange={updateForm}
              label="Driver"
              options={driverOptions}
              placeholder="Select Driver"
            />
          </div>
        </div>
      ) : (
        <>
          <div className="mb-5 ">
            <AvatarClient selected onCancel={handleCancel} {...form.driver} />
          </div>
          <div className="mb-5 flex items-center pl-10 ">
            <DatePicker
              id="startDate"
              value={formatDate(form.startDate, null, true)}
              placeholder="Start Date"
              label="Start Date"
              onChange={updateForm}
            />
            <DatePicker
              id="endDate"
              value={formatDate(form.endDate, null, true)}
              placeholder="End Date"
              label="End Date"
              onChange={updateForm}
            />
            <div className="pl-5 mb-5">Leg Count:{form.count}</div>
          </div>

          {/* <AdvancedSettings form={form} updateForm={updateForm} /> */}
        </>
      )}
    </div>
  );

  const title = <span className="mx-4">Select a driver</span>;

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={createModalContent}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};

const AvatarClient = (props) => {
  return (
    <div
      onClick={props.onClick}
      className="flex items-center justify-between hover:bg-gray-100 rounded-lg py-2 px-1 cursor-pointer"
    >
      <div className="flex items-center space-x-2">
        <Avatar size="xs" src={props.avatar} />
        <span className="font-semi-bold text-sm">
          {props.name}{" "}
          {/* <span className="text-gray-500">{`(${props.slug.toUpperCase()})`}</span> */}
        </span>
      </div>
      <div>
        {props.selected && (
          <XMarkIcon onClick={props.onCancel} className="w-5 h-5" />
        )}
      </div>
    </div>
  );
};

export default CreateModal;
