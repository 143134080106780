import Modal from "../../shared/Modal";
import Button from "../../shared/Button";
import { capitalizeFirstLetter } from "../../utils";

const UserActivateModal = ({ visible, setVisible, submit, loading, type }) => {
  function handleCancelModal() {
    setVisible(false);
  }

  const modalFooter = (
    <>
      <Button onClick={handleCancelModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={loading}
        disabled={loading}
        onClick={submit}
        type="primary"
      >
        Confirm
      </Button>
    </>
  );

  const modalContent = (
    <div className="space-y-2">
      <p>Are you sure you want to {type} this user?</p>
      {type === "activate" && (
        <p>
          This will send an email for them to activate or reactivate their
          account
        </p>
      )}
    </div>
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={() => setVisible(false)}
      title={`${capitalizeFirstLetter(type)} User`}
      footer={modalFooter}
      content={modalContent}
      size="md"
      footerPosition="right"
    />
  );
};

export default UserActivateModal;
