import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../shared/Input";
import MultiSelect from "../../shared/MultiSelect";
import Card from "../../shared/Card";
import {
  fetchDrivers,
  driverSelectOptions,
  resetDrivers,
} from "../../redux/reducers/drivers/driversSlice";
import { XMarkIcon } from "@heroicons/react/24/outline";

const OrgForm = (props) => {
  const dispatch = useDispatch();
  const { form, updateForm, errors } = props.form;
  const drivers = useSelector((state) => state.drivers);
  const driverOptions = useSelector(driverSelectOptions);

  useEffect(() => {
    if (drivers.status === "idle") {
      dispatch(
        fetchDrivers({ parent: props.user?.parentCompany, notInOrg: true })
      );
    }
  }, [drivers.status]);

  useEffect(() => {
    return () => resetDrivers();
  }, []);

  function handleEmployeeUpdate(e) {
    updateForm({ id: "employees", value: e.map((i) => i.value) });
  }

  return (
    <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-3">
      <div>
        <Input
          id="name"
          label="Name"
          placeholder="Name"
          onChange={updateForm}
          value={form.name}
          error={errors.name}
        />
      </div>
      <div>
        <Input
          id="email"
          label="Email"
          placeholder="Email"
          value={form.email}
          onChange={updateForm}
        />
      </div>
      <div>
        <Input
          id="phone"
          label="Phone"
          placeholder="Phone"
          value={form.phone}
          onChange={updateForm}
          type="tel"
        />
      </div>
      <div>
        <Input
          id="hourlyRate"
          label="Hourly Rate"
          placeholder="Hourly Rate"
          value={form.hourlyRate}
          onChange={updateForm}
          type="number"
        />
      </div>
      <div>
        <Input
          id="mileageRate"
          value={form.mileageRate}
          label="Mileage Rate"
          placeholder="Mileage Rate"
          onChange={updateForm}
          type="number"
        />
      </div>
      {props.hideEmployees ? (
        <div className="col-span-1 lg:col-span-2">
          <MultiSelect
            id="employees"
            value={form.employees}
            label="Employees"
            placeholder="Employees"
            onChange={handleEmployeeUpdate}
            options={driverOptions}
          >
            <SelectedOptions />
          </MultiSelect>
        </div>
      ) : null}
    </div>
  );
};

const SelectedOptions = ({ selected, onChange }) => {
  return (
    <div className="grid grid-cols-4 gap-3 my-3">
      {selected.map((item) => {
        return (
          <Card className="flex items-center justify-between font-semibold">
            {item.value.fullName}
            <XMarkIcon onClick={() => onChange(item)} className="w-4 h-4" />
          </Card>
        );
      })}
    </div>
  );
};

export default OrgForm;
