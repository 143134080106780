import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../../shared/Table/table.utils";
import { isNum, parseFixed } from "../../../utils";

// Config for table and form
const tableConfig = [
  // { id: "fullName", width: 120 },
  {
    id: "fullName",
    visible: true,
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/drivers/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  //   custom: {
  //     isValid: (str) => {
  //       const containerId = str;
  //       const firstFour = containerId.substring(0, 4);
  //       const rest = containerId.substr(4);

  //       if (!/^[a-zA-Z]+$/.test(firstFour)) {
  //         return false;
  //       }

  //       if (rest.length > 0 && !isNum(rest)) {
  //         return false;
  //       }

  //       if (containerId < 1) return false;

  //       return true;
  //     },
  //     message: "Container must start with four letters and end with 7 numbers",
  //   },
  // },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = { validations: renderValidations(tableConfig) };
export const PAGE_SIZE_OPTIONS = [{ label: "12 Jobs", value: 12 }];
