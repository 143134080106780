import React, { useState } from "react";
import Input from "../../shared/Input";
import Button from "../../shared/Button";
import { useNavigate } from "react-router-dom";
import useForm from "../../hooks/useForm";
import { FORM_OPTIONS } from "./form.config";
import { toast } from "react-toastify";
import { forgotPassword } from "../../redux/reducers/accountSlice";
import { useDispatch } from "react-redux";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

const ForgotPassword = (props) => {
  const { form, updateForm, isValid } = useForm(FORM_OPTIONS);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();

  function handleUpdateForm(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  async function handleSubmit(id) {
    setLoading(true);
    const email = await dispatch(
      forgotPassword({ email: form.email, type: "changePassword" })
    ).unwrap();

    toast(email.message, {
      type: email.status,
      toastId: "forgotPassword",
    });
    if (email.status === "success") {
      navigate("/login");
    }
    setLoading(false);
  }

  // if (emailSent) {
  //   toast("Email sent", {
  //     type: "success",
  //     toastId: "sessionExpired",
  //     progressClassName: "bg-primary-500",
  //     onClose: () => setEmailSent(false),
  //   });
  // }

  return (
    <div className="flex flex-col items-center justify-center md:justify-start my-auto pt-8 md:pt-0 px-1 md:px-5 lg:px-24">
      <p className="text-center text-4xl font-bold pb-1">Welcome</p>
      <p className="text-center text-md text-gray-600 pb-5">
        Please input your account email below
      </p>
      <div className="w-3/4">
        <div className="my-3">
          <Input id="email" onChange={handleUpdateForm} placeholder="Email" />
        </div>

        <div className="my-3">
          <Button
            type="primary"
            disabled={!isValid}
            onClick={handleSubmit}
            className="w-full"
            loading={loading}
          >
            Submit
          </Button>
        </div>
        <div className="pt-5">
          <p
            onClick={() => navigate("/login")}
            className="flex items-center hover:underline cursor-pointer"
          >
            <ChevronLeftIcon className="w-4 h-4 stroke-2" /> Go Back
            {/* {" "}
            <Link to="/help" className="underline font-semibold">
              Click here
            </Link> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
