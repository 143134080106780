import Modal from "../../../../shared/Modal";
import Select from "../../../../shared/Select";
import Input from "../../../../shared/Input";
import { accessoryOptions } from "../../../../constants/select";
import { parseFixed } from "../../../../utils";
import Button from "../../../../shared/Button";

const AddAccModal = ({
  form,
  visible,
  setVisible,
  submit,
  loading,
  cancel,
}) => {
  function handleCancelModal() {
    cancel && cancel();
    setVisible(false);
    form.clearForm();
  }

  const modalFooter = (
    <>
      <Button onClick={handleCancelModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={loading}
        disabled={loading}
        onClick={submit}
        type="primary"
      >
        Submit
      </Button>
    </>
  );

  const modalContent = (
    <div className="grid gap-3 sm:grid-cols-2">
      <div className="w-full sm:col-span-2">
        <Select
          options={accessoryOptions}
          onChange={form.updateForm}
          id="type"
          value={form.form.type}
          size="md"
          placeholder="Accessory Type"
          label="Accessory"
          // wrapperClasses="w-full"
        />
      </div>
      <div className="w-full">
        <Input
          onChange={form.updateForm}
          value={form.form.quantity}
          id="quantity"
          size="md"
          type="number"
          min={0}
          placeholder="Quantity"
          label="Quantity"
          // wrapperClasses="w-full"
        />
      </div>
      <div className="w-full">
        <Input
          value={form.form.unitPrice}
          id="unitPrice"
          size="md"
          type="number"
          min={0}
          placeholder="Unit Price"
          label="Unit Price"
          // wrapperClasses="w-full"
          step="0.05"
          onChange={(e) => {
            form.updateForm({
              id: "unitPrice",
              value: parseFixed(e.value),
            });
          }}
          icon={{
            left: <div className="text-gray-500 text-sm">$</div>,
          }}
        />
      </div>
    </div>
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={() => setVisible(false)}
      title="Add Accessory"
      footer={modalFooter}
      content={modalContent}
      size="md"
      footerPosition="right"
    />
  );
};

export default AddAccModal;
