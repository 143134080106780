import { useEffect, useState } from "react";
import Modal from "../../shared/Modal";
import Button from "../../shared/Button";
import useForm from "../../hooks/useForm";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { FORM_OPTIONS } from "../Drivers/org.table.config";
import {
  fetchDrivers,
  resetDrivers,
} from "../../redux/reducers/drivers/driversSlice";
import { toast } from "react-toastify";
import { addNewOrganization } from "../../redux/reducers/organizations/organizationSlice";
import OrgForm from "./CreateForm";
import { organizationAdded } from "../../redux/reducers/organizations/organizationsSlice";

const OrgCreateModal = ({ visible, setVisible }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const form = useForm(FORM_OPTIONS);

  function handleClose() {
    dispatch(resetDrivers());
    setVisible(false);
    setLoading(false);
    form.clearForm();
  }

  async function handleSubmit() {
    setLoading(true);
    const res = await dispatch(
      addNewOrganization({
        data: {
          ...form.form,
          type: "drivers",
          meta: {
            hourlyRate: form.form.hourlyRate,
            mileageRate: form.form.mileageRate,
          },
        },
        parent: user.parentCompany,
      })
    ).unwrap();

    if (res.status === "success") {
      setVisible(false);
      form.clearForm();
      setLoading(false);
      dispatch(resetDrivers());
      dispatch(organizationAdded(res));
      toast("Successfully created a new organization", {
        toastId: "newOrgAdded",
        type: "success",
      });
    }
  }

  const modalFooter = (
    <div>
      <Button className="mr-3" onClick={handleClose} type="alternative">
        Cancel
      </Button>
      <Button
        // disabled={loading || !isValid}
        // loading={loading}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </div>
  );

  const modalContent = <OrgForm user={user} form={form} />;

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={handleClose}
      title="Create Organization"
      footer={modalFooter}
      content={modalContent}
      size="lg"
      footerPosition="right"
    />
  );
};

export default OrgCreateModal;
