import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const initialState = {
  manifest: {},
  status: "idle",
  error: null,
};

export const fetchManifest = createAsyncThunk(
  "manifest/fetchManifest",
  async ({ manifestId, ...rest }) => {
    const queries = renderQueries(rest);
    const res = await request(`/manifests/${manifestId}?${queries}`);
    return res.data;
  }
);

export const addNewManifest = createAsyncThunk(
  "manifest/addNewManifest",
  async ({ parentId, data }) => {
    const res = await request(`/manifests?parent=${parentId}`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const updateManifest = createAsyncThunk(
  "manifest/updateManifest",
  async ({ parentId, manifestId, data }) => {
    const res = await request(`manifests/${manifestId}?parent=${parentId}`, {
      method: "put",
      data,
    });
    return res.data;
  }
);

export const manifestSlice = createSlice({
  name: "manifest",
  initialState,
  reducers: { resetManifest: () => initialState },
  extraReducers(builder) {
    builder
      .addCase(fetchManifest.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchManifest.fulfilled, (state, action) => {
        state.manifest = {};
        state.status = "succeeded";
        state.manifest = action.payload.data;
      })
      .addCase(fetchManifest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewManifest.fulfilled, (state, action) => {
        state.manifest = action.payload.data;
      })
      .addCase(updateManifest.fulfilled, (state, action) => {
        state.manifest = action.payload.data;
      });
  },
});

export const { resetManifest } = manifestSlice.actions;

export default manifestSlice.reducer;

export const selectManifest = (state) => state.manifest.manifest;
