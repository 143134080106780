import React, { forwardRef, useCallback } from "react";
import Table from "../../../shared/Table";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";

const SelectedJobsTable = forwardRef(
  ({ selectedJobs, setSelectedInvoiceItems }, ref) => {
    const handleRowSelection = useCallback(
      (value) => setSelectedInvoiceItems(value),
      [setSelectedInvoiceItems]
    );

    return (
      <>
        <Table
          search
          searchKeys={["loadNumber", "transactionDate"]}
          title="Invoice"
          columns={COLUMN_DEFINITIONS}
          data={selectedJobs}
          onSelectedRowsChange={handleRowSelection}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          pagination
          ref={ref}
        />
      </>
    );
  }
);

export default SelectedJobsTable;
