export const CardHeader = (props) => {
  return (
    <div className="flex justify-between items-center border border-b-0 rounded-t-lg p-2">
      <div className="text-lg font-semibold text-gray-700">
        {props.title || props.children}
      </div>
      {props.actions && <div>{props.actions}</div>}
    </div>
  );
};
