import React from "react";
import { bgHoverPrimary } from "../../../styles/constants";

const ClientPayout = ({ payout }) => {
  return (
    <div className="flex flex-col">
      <div className="text-gray-800 font-bold text-base sm:text-xl lg:text-2xl p-4">
        Total Revenue
      </div>
      <div className="text-center font-bold text-4xl sm:text-5xl flex justify-center">
        <div
          className={"py-2 px-4 rounded-lg cursor-default " + bgHoverPrimary}
        >
          <span className="text-4xl"></span>
          {payout}
        </div>
      </div>
    </div>
  );
};

export default ClientPayout;
