import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../hooks/useAuth";
import TableLayout from "../../../layouts/TableLayout";
import { updateJob } from "../../../redux/reducers/jobs/jobSlice";
import { jobUpdated } from "../../../redux/reducers/jobs/jobsSlice";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "../../Jobs/table.config";
import { useNavigate, useParams } from "react-router-dom";
import CreateModal from "../../Jobs/CreateModal";

const ClientJobsTab = ({ jobs, client }) => {
  const jobStatus = useSelector((state) => state.jobs.status);
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user } = useAuth();

  const [createModalOpen, setCreateModalOpen] = useState(false);

  async function handleUpdate(e, setLoading) {
    const updateData = {
      parentId: user.parentCompany,
      jobId: e.row.original._id,
      data: e.data,
    };

    const res = await dispatch(updateJob(updateData)).unwrap();

    if (res.status === "success") {
      dispatch(jobUpdated(res.data));

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  // async function createJob() {
  //   // setLoading(true);
  //   const postData = {
  //     client,
  //     useLegacyCreate: false,
  //   };

  //   const { data } = await request(
  //     "/jobs?" + renderQueries({ parent: user.parentCompany }),
  //     {
  //       method: "post",
  //       data: postData,
  //     }
  //   );
  //   if (data.status === "success") {
  //     navigate("/jobs/" + data.data._id);
  //   }
  // }
  return (
    <div>
      <TableLayout
        search
        title="Jobs"
        loading={jobStatus === "loading"}
        columns={COLUMN_DEFINITIONS}
        data={jobs}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        updateData={handleUpdate}
        pagination
        // createPath={{onClick: createJob()}}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
      />
      <CreateModal
        client={client}
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
      />
    </div>
  );
};

export default ClientJobsTab;
