import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../shared/Table/table.utils";
import { capitalizeFirstLetter, formatDate, renderAmount } from "../../utils";
import _ from "lodash";
import Badge from "../../shared/Badge";
import Popover from "../../shared/Popover";
import { getStage } from "./job-stages";
import { format } from "date-fns";
import { isAuthorized } from "../../utils/authority";

// Config for table and form
const tableConfig = [
  {
    accessorKey: "loadNumber",
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id));
    // },
    accessorFn: (e) => e.loadNumber || e.orderNumber,
    id: "loadNumber",
    visible: true,
    required: true,
    minWidth: 250,
    editable: isAuthorized("owner,admin"),
    cell: ({ getValue, row: { original } }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${original._id}`}
        >
          {getValue() || original.orderNumber}
        </Link>
      );
    },
  },
  {
    id: "status",
    // editable: {
    //   type: "select",
    //   options: jobStatusOptions,
    // },
    cell: (info) => {
      const value = info.getValue();
      const color = getStage(value)?.color;
      return <Badge color={color} label={capitalizeFirstLetter(value)}></Badge>;
    },
  },

  {
    id: "transactionDate",
    enableGlobalFilter: true,
    // accessorFn: (row) => formatDate(row.transactionDate),
    cell: (info) => {
      const value = info.getValue();
      return value && formatDate(new Date(value), null, true);
    },
    width: 180,
    visible: true,
  },
  {
    id: "client",
    enableGlobalFilter: true,
    visible: true,
    editable: false,
    cell: ({ getValue }) => {
      return getValue()?.name;
    },
    minWidth: 140,
  },
  {
    id: "legs",
    header: "Drops",
    cell: (info) => info.getValue()?.length,
    width: 100,
    required: true,
    custom: {
      isValid: (value, obj) => {
        if (!value) return;
        const type = "leg";
        let errors = {};
        let isValid = true;
        const map = Object.keys(value).map((key) => value[key]);
        map.forEach((item, i) => {
          const id = type + "_" + (i + 1);
          const orderNumber = id + "_orderNumber";

          Object.keys(item).forEach((key) => {
            let renderOrderNumberError = obj.hasBeenInit[orderNumber];

            if (obj.valueIsEmpty(renderOrderNumberError)) {
              isValid = false;
              return;
            }
            if (!item[orderNumber]) {
              errors[orderNumber] = "This field is required";
              isValid = false;
            }
          });
        });

        return { isValid, errors };
      },
    },
  },
  {
    id: "totalCharges",
    cell: ({ getValue }) => renderAmount(getValue()),
    minWidth: 140,
    remove: !isAuthorized("owner,admin"),
  },
  {
    header: "Invoice",
    id: "invoiceId",
    minWidth: 200,
    cell: ({ getValue }) => {
      if (getValue()) {
        return (
          <Badge
            link={`/invoices/${getValue()._id}`}
            label={`Invoice ${getValue().invoiceId}`}
          ></Badge>
        );
      }
      return null;
    },
    visible: true,
    remove: !isAuthorized("owner,admin"),
  },
  {
    id: "note",
    minWidth: 140,
    cell: ({ getValue }) => {
      if (getValue()) {
        return (
          <Popover
            className="w-43"
            position="top"
            trigger={<Badge label={"Note"}></Badge>}
            offset={[-12, 0]}
          >
            {getValue()}
          </Popover>
        );
      }
    },
    remove: !isAuthorized("owner,admin"),
  },
  {
    id: "totalMileage",
    visible: false,
  },
  {
    id: "active",
    visible: false,
  },
  {
    id: "completed",
    visible: false,
  },
  {
    id: "author",
    visible: false,
    cell: ({ getValue }) => {
      return getValue()?.fullName;
    },
  },
  {
    id: "dateUpdated",
    visible: false,
    cell: ({ getValue }) => formatDate(getValue(), null, true),
    disableGlobalFilter: true,
  },
  {
    id: "dateCreated",
    visible: false,
    cell: ({ getValue }) => formatDate(getValue(), null, true),
    disableGlobalFilter: true,
  },
  {
    id: "_id",
    header: "id",
    visible: false,
    disableGlobalFilter: true,
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = {
  validations: renderValidations(tableConfig, "jobs"),
};
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Jobs", value: 10 },
  { label: "20 Jobs", value: 20 },
  { label: "50 Jobs", value: 50 },
  { label: "100 Jobs", value: 100 },
  { label: "150 Jobs", value: 150 },
];
