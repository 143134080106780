import Input from "../../shared/Input";
import Modal from "../../shared/Modal";
import Button from "../../shared/Button";
import Select from "../../shared/Select";
import { multiplierRates } from "../../constants/select";
import { parseFixed } from "../../utils";
import { loadTypeOptions } from "../../pages/Jobs/select.config";

const RateAddModal = ({ form, visible, setVisible, submit, loading }) => {
  function handleCancelModal() {
    setVisible(false);
    form.clearForm();
  }

  function onFormTypeChange(e) {
    form.setFormInit({ [e.id]: e.label, data: e.data });
  }

  const modalFooter = (
    <>
      <Button onClick={handleCancelModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={loading}
        disabled={loading}
        onClick={submit}
        type="primary"
      >
        Submit
      </Button>
    </>
  );

  const modalContent = (
    <div className="grid gap-3 sm:grid-cols-7">
      <div className="w-full sm:col-span-5">
        <Select
          options={[
            ...loadTypeOptions,
            ...multiplierRates,
            // ...accessoryOptions,
          ]}
          onChange={onFormTypeChange}
          id="type"
          value={form.form.type}
          size="md"
          placeholder="Rate Type"
          label="Rate Label"
          search
          // wrapperClasses="w-full"
        />
      </div>
      <div className="w-full sm:col-span-2">
        <Input
          value={form.form.total}
          id="total"
          size="md"
          type="number"
          min={0}
          placeholder="Total"
          label="Total"
          // wrapperClasses="w-full"
          step="0.05"
          onChange={(e) => {
            form.updateForm({
              id: "total",
              value: parseFixed(e.value),
            });
          }}
          icon={{
            left: <div className="text-gray-500 text-sm">$</div>,
          }}
        />
      </div>
    </div>
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={() => setVisible(false)}
      title="Add Rate"
      footer={modalFooter}
      content={modalContent}
      size="md"
      footerPosition="right"
    />
  );
};

export default RateAddModal;
