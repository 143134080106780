import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../shared/Table/table.utils";
import { addressValidations } from "../../constants/validations";

// Config for table and form
const tableConfig = [
  {
    id: "name",
    required: true,
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/clients/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
    height: 20,
  },

  {
    id: "phone",
    required: true,
    cell: ({ getValue }) => {
      return getValue();
    },
  },
  {
    id: "email",
    minWidth: 290,
    validations: { required: true, email: true },
    cell: ({ getValue }) => {
      return getValue();
    },
  },
  { id: "coowner", header: "Co Owner", visible: false },
  {
    id: "active",
    cell: ({ row: { original }, value }) => {
      return value ? (
        <CheckCircleIcon className="w-5 h-5 text-green-500" />
      ) : (
        <XCircleIcon className="w-5 h-5 text-red-500" />
      );
    },
  },
  { id: "dateUpdated", visible: false },
  { id: "dateCreated", visible: false },
];

export const FORM_OPTIONS = {
  validations: renderValidations([...tableConfig, ...addressValidations]),
};
export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Jobs", value: 10 },
  { label: "20 Jobs", value: 20 },
  { label: "50 Jobs", value: 50 },
  { label: "100 Jobs", value: 100 },
  { label: "150 Jobs", value: 150 },
];
