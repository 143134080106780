import { format } from "date-fns";
import _ from "lodash";

export * from "./authority";
export * from "./request";
export * from "./date.utils";

export function valueIsEmpty(value) {
  if (!value) return true;

  if (value instanceof Object && Object.keys(value) === 0) {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === "string" && value === "") {
    return true;
  }

  return false;
}

export function truncate(str, n, useWordBoundary) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.slice(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
}

export function capitalizeFirstLetter(string) {
  if (!string) return null;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeAddress(string) {
  if (!string) return null;
  return string.toUpperCase();
}

export function capitalizeAll(string) {
  if (!string) return null;
  return string.toUpperCase();
}

export function isObject(obj) {
  return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
}

export function isNum(num) {
  return !isNaN(num);
}

export function createAlphabet() {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  return alphabet;
}

export function camelCaseToSpaces(str) {
  if (!str) return;
  return (
    str
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

export function renderArray(num, options) {
  const arr = Array.from(Array(num).keys()).map((key, i) => {
    if (options) {
      if (options.numbers) return key;
      if (options.letters) return String.fromCharCode(key + 64);
      if (options.item) return options.item(key, i);
    }
    return key;
  });
  return arr;
}

export function padInt(num, count = 2) {
  return num.toLocaleString("en-US", {
    minimumIntegerDigits: count,
    useGrouping: false,
  });
}

export function padTime(timeString, legacy = true) {
  const times = timeString.split(":");

  const formatted = times.map((time) => {
    return time.padStart(2, "0");
  });

  return formatted.join(":");
}

export function getFormattedTime(time) {
  if (!time) return "";
  const milTime = time.split(":").join("");
  var hours24 = parseInt(milTime.substring(0, 2));
  var hours = ((hours24 + 11) % 12) + 1;
  var amPm = hours24 > 11 ? "pm" : "am";
  var minutes = milTime.substring(2);

  return hours + ":" + minutes + amPm;
}

export function renderTimeDifference(start, end) {
  if (!start) return;
  if (!end) return;

  const startTime = start.split(":");
  const endTime = end.split(":");

  const s = new Date().setHours(...startTime);
  const e = new Date().setHours(...endTime);

  const diffMs = e - s; // milliseconds between now & end
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) / 60; // minutes
  const minsRounded = Math.round(diffMins * 100) / 100;

  const res = diffHrs + minsRounded;
  return res;
}

export function renderDayDifference(start, end, options) {
  if (!start) return;
  if (!end) return;

  const startDay = start.split("-");
  const endDay = end.split("-");

  const date1 = new Date(...startDay);
  const date2 = new Date(...endDay);

  const timeDiff = date2.getTime() - date1.getTime();
  const dayDiff = timeDiff / (1000 * 3600 * 24);

  return dayDiff;
}

export function parseFixed(num, fixed) {
  return Number(parseFloat(num).toFixed(fixed || 2));
}

export function checkNaN(num) {
  return num === "NaN" ? 0 : num;
}

export function renderAmount(amount) {
  const total = amount || 0;
  let parsed = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(total);

  return `${parsed}`;
}

export function formatDate(value, dbFormat, dateFns, time) {
  // console.log({ value });
  if (!value || value === "Invalid Date") return;
  if (typeof value === "string" && !dbFormat) {
    if (dateFns) {
      return format(new Date(value), "MM/dd/yyyy").split("/").join("-");
    }

    if (time) {
      return format(new Date(value), "hh:mm a MM/dd/yyyy").split("/").join("-");
    }
    const newDate = value.substring(0, 10).split("-");

    const year = newDate[0].length !== 4 ? newDate[2] : newDate[0];
    const month = newDate[0].length !== 4 ? newDate[0] : newDate[1];
    const day = newDate[0].length !== 4 ? newDate[1] : newDate[2];

    return [month, day, year].join("-");
  }

  if (dateFns) {
    return format(value || new Date(), "MM/dd/yyyy")
      .split("/")
      .join("-");
  }

  const newDate = value ? new Date(value) : new Date();
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  const date = newDate.getDate();

  if (dbFormat) {
    return `${year}-${month}-${date}`;
  }

  return `${month}-${date}-${year}`;
}

export function convertMonthToAbreviation(value) {
  let date = new Date(value);
  return `${
    ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()]
  } ${date.getDate()} ${
    [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ][date.getMonth()]
  }`;
}

export function getDPI() {
  var div = document.createElement("div");
  div.style.height = "1in";
  div.style.width = "1in";
  div.style.top = "-100%";
  div.style.left = "-100%";
  div.style.position = "absolute";

  document.body.appendChild(div);

  var result = div.offsetHeight;

  document.body.removeChild(div);

  return result;
}

export function removeEmptyFromObj(obj) {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key]))
      newObj[key] = removeEmptyFromObj(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
}

export function renderTotalMileage(mileage) {
  let res = checkNaN(Math.ceil(parseFixed(mileage)).toLocaleString("en-US"));

  if (!res) {
    res = 0;
  }
  return res;
}

export function zeroPad(num, spaces = 4, toAppend = "0") {
  return num.toString().padStart(spaces, toAppend);
}

export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a?.[i];
    if (k in o) {
      o = o?.[k];
    } else {
      return;
    }
  }
  return o;
};

export function renderAddress(dataObj, id, point) {
  if (!dataObj) return;
  const data = id ? dataObj[id] : dataObj;
  const { address, name } = data;
  const capitalName = capitalizeAll(name);
  // const header = `${capitalizeFirstLetter(id)} `;
  const street = capitalizeAll(address.address);
  const addressRest = `${capitalizeAll(address.city)}, ${capitalizeAll(
    address.state
  )} ${address.zipcode}`;
  // return capitalName;

  return (
    <tr className="text-left" colSpan={6.5}>
      {/* {header} */}
      {capitalName}
      <br />
      {street}
      <br />
      {addressRest}
    </tr>
  );
}

export function formatAddress(address) {
  if (!address) return "Address not available";
  const parts = address.split(",");
  if (parts.length === 1) return address;
  return (
    <>
      {parts[0].trim()}
      <br />
      {parts.slice(1).join(",").trim()}
    </>
  );
}

export function renderAddressHeader(dataObj, id, point) {
  if (!dataObj) return;
  const data = id ? dataObj[id] : dataObj;
  const { name } = data;
  const capitalName = capitalizeAll(name);

  return (
    <tr className="text-left" colSpan={6.5}>
      {/* {header} */}
      {capitalName}
    </tr>
  );
}

/* 
  Format Phone number 
  formatPhoneNumber('2345678900')   => "(234) 567-8900"
  formatPhoneNumber('+12345678900') => "+1 (234) 567-8900"
*/
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export function removeEmptyValues(obj) {
  return _.pickBy(obj, _.identity);
}

export function renderInitials(name) {
  const initials = name
    .split(" ")
    .map((n) => n[0])
    .join("");
  return initials.length === 3 ? initials : initials.slice(0, 2);
}
