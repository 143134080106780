import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const initialState = {
  message: {},
  status: "idle",
  error: null,
};

export const fetchMessage = createAsyncThunk(
  "messages/fetchMessage",
  async ({ messageId }) => {
    const res = await request(`/messages/${messageId}`);
    return res.data;
  }
);

export const updateMessage = createAsyncThunk(
  "messages/updateMessage",
  async ({ parentId, messageId, data }) => {
    const res = await request(`/messages/${messageId}?parent=${parentId}`, {
      method: "put",
      data,
    });
    return res.data;
  }
);

export const addNewMessage = createAsyncThunk(
  "messages/addNewMessage",
  async ({ data, ...obj }) => {
    const queries = renderQueries(obj);
    const res = await request(`/messages?${queries}`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const sendEmail = createAsyncThunk(
  "messages/sendEmail",
  async ({ data, ...obj }) => {
    const queries = renderQueries(obj);
    const res = await request(`/messages/email?${queries}`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    resetMessage: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMessage.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchMessage.fulfilled, (state, action) => {
        state.message = {};
        state.status = "succeeded";
        state.message = action.payload.data;
      })
      .addCase(fetchMessage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewMessage.fulfilled, (state, action) => {
        state.message = action.payload;
      })
      .addCase(updateMessage.fulfilled, (state, action) => {
        state.message = {};
        state.status = "succeeded";
        state.message = action.payload.data;
      });
  },
});

export const { resetMessage } = messageSlice.actions;
export default messageSlice.reducer;

export const selectAllMessages = (state) => state.message.message;
