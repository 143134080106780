import { createContext, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import {
  fetchCurrentUser,
  selectCurrentUser,
  userLogin,
  userLogout,
} from "../redux/reducers/accountSlice";
import { deleteTokens } from "../utils/authority";
import PageLoading from "../shared/PageLoading";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { COOKIE_NAME } from "../config";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", { active: false });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const accountStatus = useSelector((state) => state.account.status);
  const currentUser = useSelector(selectCurrentUser);

  const loading = accountStatus === "idle" || accountStatus === "loading";

  useEffect(() => {
    (async () => {
      if (accountStatus === "idle") {
        try {
          const { user, status, message } = await dispatch(
            fetchCurrentUser()
          ).unwrap();

          if (status === "error") {
          }
          setUser(user);
          if (user.active) {
            navigate(location);
          }
          // else if (user.invalidToken) {
          //   location.pathname = "/login";
          //   navigate(location);
          // }
          else if (
            location.pathname === "/activate" ||
            location.pathname === "/change/password"
          ) {
            navigate(location);
          } else {
            location.pathname = "/login";
            toast(message, {
              toastId: "somethingWentWrong",
              type: "error",
            });
            navigate(location);
          }
        } catch (err) {
          console.error(err);
          if (
            location.pathname === "/activate" ||
            location.pathname === "/change/password"
          ) {
            navigate(location);
            return;
          }
          location.pathname = "/login";
          toast("Something went wrong", {
            toastId: "somethingWentWrong",
            type: "error",
          });
          navigate(location);
          return;
        }
      }
    })();
  }, [accountStatus, currentUser, setUser, location]);

  const login = async (data) => {
    const res = await dispatch(userLogin(data)).unwrap();

    res.status &&
      toast(res.message, {
        type: res.status,
        toastId: `login-${res.status}`,
      });

    if (res.user.active) {
      setUser(res.user);
      localStorage.setItem("parentCompany", res.user.parentCompany);
      localStorage.setItem("appType", res.user.parentCompany?.appType);

      navigate("/", { replace: true });
    }
  };

  const logout = async () => {
    dispatch(userLogout());
    Cookies.remove(COOKIE_NAME, { path: "/", domain: "localhost" });
    localStorage.removeItem("parentCompany");
    deleteTokens();
    setUser({ active: false });
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      loading,
      setUser,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return (
    <AuthContext.Provider value={value}>
      {loading ? <PageLoading /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
