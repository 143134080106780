import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../shared/Avatar";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import {
  fetchClients,
  selectAllClients,
} from "../../../redux/reducers/clients/clientsSlice";
import request, { renderQueries } from "../../../utils/request";
import InputDropdown from "../../../shared/InputDropdown";
import { useNavigate } from "react-router-dom";
import AdvancedSettings from "./AdvancedSettings";
import classNames from "classnames";
import { useAddJobMutation } from "../../../redux/services/jobs/jobsApi";

const CreateModal = ({ modalOpen, setModalOpen, ...props }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { form, updateForm, clearForm, setFormInit } = useForm();
  const [loading, setLoading] = useState(false);
  const [recentClients, setRecentClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [addJob, { isLoading: addingJob }] = useAddJobMutation();

  async function getRecentClients() {
    const res = await request(
      "/clients/recent?" + renderQueries({ parent: user.parentCompany })
    );

    if (res.data.status === "success") {
      setRecentClients(res.data.data);
    }
  }

  const clients = useSelector(selectAllClients);
  const clientsStatus = useSelector((state) => state.clients.status);

  useEffect(() => {
    if (!props.client && clientsStatus === "idle") {
      dispatch(fetchClients(user.parentCompany));
    }
  }, [clientsStatus, user, props.client]);

  useEffect(() => {
    if (props.client && modalOpen) {
      setFormInit({ client: props.client });
    }
  }, [props.client?._id, modalOpen]);

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(async () => {
  //     if (searchTerm) {
  //       const res = await request(
  //         "/clients/search?" +
  //           renderQueries({ parent: user.parentCompany, search: searchTerm })
  //       );

  //       const { data } = res.data;

  //       setClientOptions(data);
  //     }
  //   }, 1000);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchTerm]);

  useEffect(() => {
    if (!props.client && recentClients.length === 0) {
      getRecentClients();
    }
  }, [recentClients.length, props.client]);

  function closeModal() {
    setRecentClients([]);
    setModalOpen(false);
    handleCancel();
  }

  const footer = (
    <div>
      <Button className="mr-3" onClick={closeModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={addingJob}
        disabled={addingJob}
        onClick={() => saveJob()}
        type="primary"
      >
        Submit
      </Button>
    </div>
  );

  async function saveJob() {
    const postData = {
      ...form,
      // transactionDate: ,
      useLegacyCreate: false,
    };

    const res = await addJob(postData).unwrap();

    if (res.status === "success") {
      closeModal();
      navigate("/jobs/" + res.data._id);
    }
  }

  function handleSelectClient(recentClient) {
    updateForm({ id: "client", value: recentClient });
  }

  function handleCancel() {
    clearForm("client");
  }

  const createModalContent = (
    <div className="">
      {!form.client ? (
        <div className="space-y-3">
          <InputDropdown
            onChange={(e) => setSearchTerm(e.value)}
            value={searchTerm}
            placeholder="Search clients by name"
            data={clients}
            renderItem={(e) => (
              <AvatarClient
                onClick={() => handleSelectClient(e.item)}
                {...e}
                {...e.item}
                disableCancel
              />
            )}
          />
          <div className="flex items-center justify-between">
            <div>Recent clients</div>
            {/* <div className="flex items-center">
              <PlusIcon className="w-5 h-5 mr-1" />
              Add new customer
            </div> */}
          </div>
          <div>
            {recentClients.map((recent, i) => {
              return (
                <AvatarClient
                  key={i}
                  onClick={() => handleSelectClient(recent)}
                  {...recent}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <>
          <div className="mb-5">
            <AvatarClient selected onCancel={handleCancel} {...form.client} />
          </div>
          <AdvancedSettings defaultOpen form={form} updateForm={updateForm} />
        </>
      )}
    </div>
  );

  const title = <span className="mx-4">Select a client</span>;

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={createModalContent}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};

const AvatarClient = (props) => {
  const wrapperClasses = classNames(
    "flex items-center justify-between rounded-lg py-2 px-1 cursor-pointer",
    {
      "hover:bg-gray-100": !props.disableCancel,
    }
  );
  return (
    <div onClick={props.onClick} className={wrapperClasses}>
      <div className="flex items-center space-x-2">
        <Avatar
          // className={`${!props.disableCancel ? "-z-10" : "hover:z-10"}`}
          size="xs"
          src={props.avatar}
        />
        <span className="font-semi-bold text-sm">
          {props.name}{" "}
          {/* <span className="text-gray-500">{`(${props.slug.toUpperCase()})`}</span> */}
        </span>
      </div>
      {!props.disableCancel && (
        <div>
          {props.selected && (
            <XMarkIcon onClick={props.onCancel} className="w-5 h-5" />
          )}
        </div>
      )}
    </div>
  );
};

export default CreateModal;
