import classNames from "classnames";
import React from "react";
import Spinner from "../Spinner";
import { renderInitials } from "../../utils";

export const Avatar = (props) => {
  const size = props.size;
  const xs = size === "xs";
  const sm = size === "sm";
  const md = size === "md" || !size;
  const lg = size === "lg";

  const sizes = {
    "w-7 h-7": xs,
    "w-10 h-10": sm,
    "w-24 h-24": md,
    "w-28 h-28 md:w-36 md:h-36": lg,
  };

  const classes = classNames(
    props.className,
    sizes,
    "m-0 relative flex justify-center items-center rounded-full bg-gray-500 text-xl text-white overflow-hidden"
  );

  const imgClasses = classNames(sizes, "rounded-full w-full");

  return (
    <div className={classes}>
      {props.loading || props.src === null ? (
        <Spinner />
      ) : props.src ? (
        <img
          alt="avatar"
          src={props.src}
          className={imgClasses}
          onError={(e) => (e.target.style.display = "none")}
        />
      ) : (
        <div
          className={`${imgClasses} bg-gray-300 flex items-center justify-center`}
        >
          <span
            className={`text-primary-600 font-bold ${
              xs
                ? "text-xs"
                : sm
                ? "text-sm"
                : md
                ? "text-xl"
                : lg
                ? "text-2xl"
                : "text-xl"
            }`}
          >
            {props.name ? renderInitials(props.name) : "?"}
          </span>
        </div>
      )}
    </div>
  );
};

export default Avatar;
