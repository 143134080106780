import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  deleteUsers,
  selectAllUsers,
  resetUsers,
} from "../../../redux/reducers/users/usersSlice";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import TableLayout from "../../../layouts/TableLayout/NewTableLayout";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetUsersQuery } from "../../../redux/services/users/usersApi";
import { removeEmptyValues } from "../../../utils";
import { usePrefetch } from "../../../redux/api";

const UsersAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const users = useSelector(selectAllUsers);
  // const usersStatus = useSelector((state) => state.users.status);
  // const usersError = useSelector((state) => state.users.status);
  const { user } = useAuth();
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const { data, isLoading } = useGetUsersQuery({
    admin: true,
    fullParent: true,
    page: pageIndex + 1,
    limit: pageSize,
  });

  const prefetchPage = usePrefetch("getUsers");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
    });
  }, [prefetchPage, data?.nextPage, pageSize]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);

  // useEffect(() => {
  //   if (usersStatus === "idle") {
  //     dispatch(
  //       fetchUsers({
  //         parent: user.parentCompany,
  //         admin: true,
  //         fullParent: true,
  //       })
  //     );
  //   }
  // }, [usersStatus, dispatch, user.parentCompany]);

  // useEffect(() => {
  //   return () => dispatch(resetUsers());
  // }, []);

  function handleEdit(itemId) {
    navigate(`/users/${itemId}`);
  }

  async function handleDelete(items) {
    const { parentCompany } = user;
    const res = await dispatch(
      deleteUsers({ data: items, parentId: parentCompany })
    ).unwrap();

    if (res.status === "success") {
      toast("Users Deleted", {
        type: "success",
        toastId: "usersDeleted",
      });
    }

    return res;
  }

  return (
    <TableLayout
      id="users"
      title="Users"
      buttonSize="xs"
      loading={isLoading}
      columns={COLUMN_DEFINITIONS}
      data={data?.users}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      pagination={data}
      pageIndex={pageIndex}
      pageSize={pageSize}
      onPaginationChange={setPagination}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  );
};

export default UsersAdmin;
