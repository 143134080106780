import classNames from "classnames";
import { useConfig } from "../../../hooks";
import { jobStages } from "../job-stages";

const Timeline = ({ job = "New", timeline }) => {
  const { configs } = useConfig();

  return (
    <ol className="items-center justify-around flex w-full">
      {timeline.map((item, index) => {
        return (
          <li key={index} className="relative mb-6 sm:mb-0 w-full">
            <div className="flex items-center justify-center ">
              <div
                className={`z-10 flex items-center justify-center w-6 h-6 sm:w-10 sm:h-10 rounded-full ring-0 ring-gray-50 dark:ring-gray-900 shrink-0 ${
                  item.active ? "bg-primary-200 sm:ring-8" : "bg-gray-200"
                }`}
              >
                <svg
                  className={`w-2.5 h-2.5 ${
                    item.active ? "text-primary-700" : "text-gray-500"
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  {/* You can add different path data for each icon based on item.icon */}
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </div>
              <div
                className={classNames(
                  "flex w-full bg-gray-200 h-0.5 absolute",
                  {
                    "right-0 !w-1/2": index === 0,
                    "left-0 !w-1/2": index === timeline.length - 1,
                  }
                )}
              ></div>
            </div>
            <div className="mt-3 smpe-8 flex justify-center">
              <h3 className="text-xs md:text-lg md:font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                {item.label}
              </h3>
              {/* <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
              {job.date || "Date not available"}
            </time>
            <p className="text-base font-normal text-gray-500 dark:text-gray-400">
              {item.description || "Description not available"}
            </p> */}
            </div>
          </li>
        );
      })}
    </ol>
  );
};

export default Timeline;
