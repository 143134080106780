import React from "react";
import UserInfo from "./UserInfo";
import { useAuth } from "../../../hooks/useAuth";
import Tabs from "../../../shared/Tabs";
import classNames from "classnames";
import AccountSettings from "./Settings";
import SettingsLayout from "../../../layouts/SettingsLayout";

const UserEdit = () => {
  // const { user } = useAuth();

  // const tabs = [
  //   {
  //     id: "Information",
  //     content: user._id && (
  //       <UserInfo user={user} parentId={user.parentCompany} />
  //     ),
  //   },
  //   {
  //     id: "Settings",
  //     content: <AccountSettings />,
  //   },
  // ];

  // const tabListWrapper = classNames();
  // const tabContentClassName = (isActive) =>
  //   classNames("focus-visible:outline-0 mx-2 px-4 py-1 rounded-md", {
  //     "bg-primary-100": isActive,
  //   });

  return (
    <SettingsLayout
      basePath="/account"
      menu={{
        User: ["Information", "Settings"],
      }}
    />
    // <Tabs
    //   id="account"
    //   data={tabs}
    //   className="sm:flex bg-white divide-x border-y"
    //   tabListClasses="flex sm:flex-col"
    //   tabContentClassName={tabContentClassName}
    //   panelClasses="p-5 sm:p-8 w-full"
    //   useSearchParams
    // />
  );
};

export default UserEdit;
