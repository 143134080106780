import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const invoicesApi = api.injectEndpoints({
  tagTypes: ["Invoices"],
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const params = new URLSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `invoices?${params}`;
      },
      providesTags: (data) =>
        data?.invoices
          ? [
              ...data.invoices.map(({ _id }) => ({
                type: "Invoices",
                id: _id,
              })),
              { type: "Invoices", id: "LIST" },
            ]
          : [{ type: "Invoices", id: "LIST" }],
    }),
    addInvoice: builder.mutation({
      query(body) {
        return {
          url: "invoices",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Invoices", id: "LIST" }, "Jobs"],
    }),
    getInvoice: builder.query({
      query: ({ id, ...rest }) => `invoices/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
      providesTags: (_result, _error, data) => {
        return [{ type: "Invoices", id: data.id }];
      },
    }),
    updateInvoice: builder.mutation({
      query({ id, data }) {
        return {
          url: `/invoices/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Invoices", id },
        { type: "Jobs", id: "LIST" },
      ],
    }),
    deleteInvoices: builder.mutation({
      query({ data }) {
        return {
          url: `/invoices`,
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { data, jobs }) => {
        return [
          ...data.map((id) => ({
            type: "Invoices",
            id,
          })),
          ...jobs.map((id) => ({
            type: "Jobs",
            id,
          })),
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetInvoicesQuery,
  useDeleteInvoicesMutation,
  useAddInvoiceMutation,
  useGetInvoiceQuery,
  useUpdateInvoiceMutation,
} = invoicesApi;
