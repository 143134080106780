import { useState, useEffect } from "react";
import _ from "lodash";
import { SettingsHeader } from ".";
import { Input, SettingsPanel } from "../../shared";
import { useConfig, useForm, useAuth } from "../../hooks";

export const Features = () => {
  const { addConfig, configs } = useConfig();
  const { form, setFormInit } = useForm();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormInit({
      "legs.metadata": { data: configs?.products?.legs?.metadata },
      "jobs.metadata": { data: configs?.products?.jobs?.metadata },
    });
  }, [configs?.products?.legs?.metadata, configs?.products?.jobs?.metadata]);

  function handleBlur(key, type) {
    const edited = _.pickBy(form, function (obj) {
      return obj.updated;
    });
    const res = _.mapValues(edited, (value) => value.data);

    if (Object.keys(res).length === 0) return;

    handleConfigSave(res, key, type);

    return;
  }

  function handleConfigSave(data, key, type) {
    setLoading(key + ".metadata");
    addConfig({
      data: { [key]: data },
      configKey: type,
      type: "object",
    });
    setLoading("");
  }

  function onFormChange(e) {
    setFormInit({ [e.id]: { data: e.value, updated: true } });
  }

  const data = [
    {
      title: "Legs",
      data: [
        {
          title: "Metadata",
          subtitle:
            "Used to keep track of metadata you want to see in the UI, seperate by commas",
          content: (
            <Input
              loading={loading === "legs.metadata"}
              onBlur={() => handleBlur("legs", "products")}
              id="legs.metadata"
              onChange={onFormChange}
              value={form["legs.metadata"]?.data}
              size="md"
              placeholder="Seperate by comma"
            />
          ),
        },
      ],
    },
    {
      title: "Jobs",
      data: [
        {
          title: "Metadata",
          subtitle:
            "Used to keep track of metadata you want to see in the UI, seperate by commas",
          content: (
            <Input
              loading={loading === "jobs.metadata"}
              onBlur={() => handleBlur("jobs", "products")}
              id="jobs.metadata"
              onChange={onFormChange}
              value={form["jobs.metadata"]?.data}
              size="md"
              placeholder="Seperate by comma"
            />
          ),
        },
      ],
    },
  ];

  return (
    <>
      <SettingsHeader title="Features" />
      {data.map((configData) => (
        <SettingsPanel title={configData.title} data={configData.data} />
      ))}
    </>
  );
};
