import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const initialState = {
  manifests: [],
  status: "idle",
  error: null,
};

export const fetchManifests = createAsyncThunk(
  "manifests/fetchManifests",
  async (obj, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/manifests?${queries}`);
    if (!res.data.manifests) {
      rejectWithValue(res.data);
      return;
    }
    return res.data;
  }
);

export const deleteManifests = createAsyncThunk(
  "manifests/deleteManifests",
  async ({ data, parentId }) => {
    const res = await request(`/manifests?parent=${parentId}`, {
      method: "delete",
      data,
    });
    return res.data;
  }
);

export const manifestsSlice = createSlice({
  name: "manifests",
  initialState,
  reducers: {
    resetManifests: () => initialState,
    manifestUpdated(state, action) {
      const { _id } = action.payload;
      const index = state.manifests.findIndex((item) => item._id === _id);
      state.manifests[index] = action.payload;
    },
    setManifests(state, action) {
      state.manifests = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManifests.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchManifests.fulfilled, (state, action) => {
        state.manifests = [];
        state.status = "succeeded";
        state.manifests = state.manifests.concat(action.payload.manifests);
      })
      .addCase(fetchManifests.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteManifests.fulfilled, (state, action) => {
        const manifestsDeleted = action.payload.data;
        const newManifests = state.manifests.filter(
          (x) => !manifestsDeleted.includes(x._id)
        );
        state.manifests = newManifests;
      })
      .addCase(deleteManifests.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetManifests, setManifests, manifestUpdated } =
  manifestsSlice.actions;
export default manifestsSlice.reducer;
export const selectAllManifests = (state) => state.manifests.manifests;
export const selectAllManifestsOptions = (state) => {
  return state.manifests.manifests.map(({ name }) => {
    return {
      value: name,
      label: name,
    };
  });
};
export const selectManifestsById = (state, manifestId) =>
  state.manifests.manifests.find((manifest) => manifest.id === manifestId);
