import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";
import _ from "lodash";

const initialState = {
  job: {},
  status: "idle",
  error: null,
  loading: false,
};

export const fetchJob = createAsyncThunk(
  "jobs/fetchJob",
  async ({ drivers, parent, jobId }) => {
    let queryObject = { drivers, parent };
    const queries = renderQueries(queryObject);
    const res = await request(`/jobs/${jobId}?${queries}`);
    return res.data;
  }
);

export const addNewJob = createAsyncThunk(
  "jobs/addNewJob",
  async ({ parentId, data }) => {
    const res = await request(`/jobs?parent=${parentId}`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const updateJob = createAsyncThunk(
  "jobs/updateJob",
  async ({ parentId, parent, jobId, data }) => {
    const res = await request(`jobs/${jobId}?parent=${parentId || parent}`, {
      method: "put",
      data,
    });
    return res.data;
  }
);

export const fetchJobFiles = createAsyncThunk(
  "jobs/fetchJobFiles",
  async ({ parent, jobId }) => {
    const res = await request(`jobs/files/${jobId}?parent=${parent}`);
    return res.data;
  }
);

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    resetJob: () => initialState,
    jobLegUpdated(state, action) {
      const { _id } = action.payload;
      const index = state.job.legs?.findIndex((item) => item._id === _id);
      state.job.legs[index] = action.payload;
    },
    removedJobLegs(state, action) {
      const newLegs = _.differenceBy(state.job.legs, action.payload, "_id");
      state.job = {};
    },
    addedLegToJob(state, action) {
      const newLegs = [...state.job.legs, action.payload.leg];
      state.job.legs = newLegs;
      state.job = { ...state.job, ...action.payload.job };
    },
    jobUpdated(state, action) {
      state.loading = true;
      state.job = { ...state.job, ...action.payload };
      state.loading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchJob.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchJob.fulfilled, (state, action) => {
        state.job = {};
        state.status = "succeeded";
        state.job = action.payload.data;
      })
      .addCase(fetchJob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchJobFiles.fulfilled, (state, action) => {
        state.job.files = action.payload.data;
      })
      .addCase(addNewJob.fulfilled, (state, action) => {
        state.job = action.payload;
      })
      .addCase(updateJob.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateJob.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.job = action.payload.data;
      });
  },
});

export const {
  resetJob,
  jobLegUpdated,
  removedJobLegs,
  jobUpdated,
  addedLegToJob,
} = jobSlice.actions;

export default jobSlice.reducer;
