import { AddressForm } from "../../../components/Address";
import { Input } from "../../../shared";

export const CustomerOnboarding = ({ form, handleForm, ...props }) => {
  return (
    <>
      <div className="pt-10 w-full">
        <div className="text-left pb-2 text-gray-600">
          Please review your company details
        </div>
        <div className="grid grid-cols-2 gap-3">
          <Input
            label="Name"
            onChange={handleForm}
            value={form.name}
            placeholder="Company Name"
            id="name"
          />
          <Input
            onChange={handleForm}
            value={form.slug}
            placeholder="Slug"
            id="slug"
            label="Slug"
          />
          <Input
            onChange={handleForm}
            value={form.phone}
            id="phone"
            label="Phone"
            placeholder="Phone"
          />
        </div>
      </div>
      <div className="pt-10 w-full">
        <div className="text-left pb-2 text-gray-600">
          What is your companys address?
        </div>
        <AddressForm
          form={form}
          updateForm={handleForm}
          errors={props.errors}
          inputSize="md"
        />
      </div>
    </>
  );
};
