import React from "react";
import { Link } from "react-router-dom";

const LoginFooter = (props) => {
  return (
    <div className="my-10 w-72">
      <div className="flex justify-between my-3 font-base">
        <Link to="/help">Help</Link>
        <Link to="/report/bug">Report Bug</Link>
      </div>
      <div className="text-center">
        <span>@2022 All Right Reserved</span>
      </div>
    </div>
  );
};

export default LoginFooter;
