import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../shared/Table/table.utils";
import { statusOptions } from "../../constants/select";
import { formatDate, renderAmount } from "../../utils";
import { isAuthorized } from "../../utils/authority";

// Config for table and form
const tableConfig = [
  {
    id: "invoiceId",
    visible: true,
    editable: isAuthorized("owner, admin"),
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/invoices/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "status",
    cell: ({ getValue }) => {
      const value = getValue();
      return value;
    },
    editable: {
      type: "select",
      options: statusOptions,
      disabled: !isAuthorized("owner, admin"),
    },
  },
  {
    id: "jobs",
    cell: ({ getValue }) => {
      return getValue().length;
    },
  },
  {
    id: "client",
    visible: true,
    cell: ({ getValue }) => getValue()?.name,
  },
  {
    id: "total",
    visible: true,
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    id: "dateUpdated",
    visible: false,
    cell: ({ getValue }) => {
      return formatDate(getValue(), null, true);
    },
  },
  {
    id: "dateCreated",
    visible: false,
    cell: ({ getValue }) => {
      return formatDate(getValue(), null, true);
    },
  },
  {
    id: "author",
    visible: false,
    cell: ({ getValue }) => getValue().fullName,
  },
  {
    id: "_id",
    header: "id",
    visible: false,
    width: 230,
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = { validations: renderValidations(tableConfig) };
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Jobs", value: 10 },
  { label: "20 Jobs", value: 20 },
  { label: "50 Jobs", value: 50 },
  { label: "100 Jobs", value: 100 },
  { label: "150 Jobs", value: 150 },
];
