import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const manifestsApi = api.injectEndpoints({
  tagTypes: ["Manifests"],
  endpoints: (builder) => ({
    getManifests: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const params = new URLSearchParams({
          page,
          limit,
          legacy: false,
          ...rest,
        });
        return `manifests?${params}`;
      },
      providesTags: (data) =>
        data?.manifests
          ? [
              ...data.manifests.map(({ _id }) => ({
                type: "Manifests",
                id: _id,
              })),
              { type: "Manifests", id: "LIST" },
            ]
          : [{ type: "Manifests", id: "LIST" }],
    }),
    addManifest: builder.mutation({
      query(body) {
        return {
          url: "manifests",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Manifests", id: "LIST" }],
    }),
    getManifest: builder.query({
      query: ({ id, ...rest }) => `manifests/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
      providesTags: (_result, _error, data) => {
        return [{ type: "Manifests", id: data.id }];
      },
    }),
    updateManifest: builder.mutation({
      query({ id, data }) {
        return {
          url: `/manifests/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, _error, { id }) => {
        const jobs = result.data.legs
          .filter((i) => i.jobId)
          .map((item) => ({
            type: "Jobs",
            id: item.jobId?._id,
          }));

        return [
          ...jobs,
          { type: "Legs", id: "LIST" },
          { type: "Manifests", id },
        ];
      },
    }),
    updateManifestLeg: builder.mutation({
      query({ data, id, legId, ...obj }) {
        const params = getSearchParams(obj);
        return {
          url: `/manifests/${id}/leg/${legId}?${params}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, _error, data) => {
        const jobs = result.data.legs
          .filter((i) => i.jobId)
          .map((item) => ({
            type: "Jobs",
            id: item.jobId?._id,
          }));

        return [
          ...jobs,
          { type: "Legs", id: "LIST" },
          { type: "Manifests", id: data.id },
        ];
      },
    }),

    deleteManifests: builder.mutation({
      query({ data }) {
        return {
          url: "/manifests",
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { data }) => [
        ...data.map((id) => ({ type: "Manifests", id })),
        { type: "Payouts", id: "LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetManifestsQuery,
  useDeleteManifestsMutation,
  useAddManifestMutation,
  useGetManifestQuery,
  useUpdateManifestLegMutation,
  useUpdateManifestMutation,
} = manifestsApi;
