import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import {
  fetchOrganization,
  resetOrg,
  updateOrganization,
} from "../../../redux/reducers/organizations/organizationSlice";
import DriverCard from "../../Drivers/Show/DriverCard";
import DriversTable from "./Drivers";
import { useCrumbs } from "../../../hooks/useCrumbs";
import { SidebarContainer } from "../../../components";
import { EditableText } from "../../../shared";
import OrgJobs from "./Jobs/OrgJobs";
import OrgPayouts from "./Payouts/OrgPayouts";
import OrgManifests from "./Manifests/OrgManifests";
import Tabs from "../../../shared/Tabs";

const OrgShow = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const crumbs = useCrumbs();

  const org = useSelector((state) => state.organization);
  const organization = useSelector((state) => state.organization.organization);

  useEffect(() => {
    if (org.status === "idle") {
      dispatch(
        fetchOrganization({ parentId: user.parentCompany, id: params.id })
      );
    }
  }, [org.status, dispatch, user, params.id]);

  useEffect(() => {
    return () => dispatch(resetOrg());
  }, []);

  useEffect(() => {
    if (org.data) {
      crumbs.updateCrumbName({
        name: org.data.name,
        path: "/organizations/" + org.data._id,
      });
      crumbs.setLoading(false);
    }
  }, [org]);

  async function handleUpdate(e) {
    const update = {
      ...e,
    };

    if (e.fullName) {
      update.name = e.fullName;
      update.organization = e._id;
    }

    const resUpdate = await dispatch(
      updateOrganization({
        data: update,
        parent: user.parentCompany,
        id: params.id,
      })
    ).unwrap();

    // if (resUpdate.status === "success") {
    //   dispatch(driverUpdated(resUpdate.data));
    // }
  }

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = (headerProps) => {
    return (
      <div ref={headerRef} className="p-2 border-b bg-white">
        {headerProps.title}
      </div>
    );
  };

  const tabs = [
    {
      id: "Info",
      content: (
        <div className="h-auto">
          <div className="grid grid-cols-8 gap-3">
            <DriverCard
              handleUpdate={handleUpdate}
              info={{
                ...org.data,
                fullName: org.data.name,
                org: true,
                avatar: org.data.avatar
                  ? org.data.avatar
                  : "https://ui-avatars.com/api/?name=" + org.data.name,
              }}
            />
            <DriversTable
              update={handleUpdate}
              org={org.data}
              data={org.data.employees || []}
            />
            {/* <Card className="col-span-3">
              <JobChart data={reportData} />
            </Card> */}
            {/* <DriverPayouts
              driver={driver}
              driverStatus={driverStatus}
              payouts={payouts}
              payoutsStatus={payoutsStatus}
            /> */}
            {/* <DriverJobs driver={driver} driverStatus={driverStatus} /> */}
          </div>
        </div>
      ),
    },
    // {
    //   id: "Jobs",
    //   content: (
    //     <div className="h-auto">
    //       <OrgJobs driver={org.data} driverStatus={org.status} />
    //     </div>
    //   ),
    // },
    // {
    //   id: "Payouts",
    //   content: (
    //     <div className="h-auto">
    //       <OrgPayouts
    //         driver={org.data}
    //         driverStatus={org.status}
    //         // payouts={payouts}
    //         // payoutsStatus={payoutsStatus}
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   id: "Manifests",
    //   content: (
    //     <div className="h-auto">
    //       <OrgManifests driver={org.data} driverStatus={org.status} />
    //     </div>
    //   ),
    // },
  ];

  const title = (
    <div className="text-gray-800 font-semibold text-lg flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="name"
        value={org.data.name}
        size="sm"
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        // loading={modalLoad}
        onSave={(e) => handleUpdate({ ...e.data, name: e.value })}
      />
    </div>
  );

  return (
    <SidebarContainer title={title} header={renderHeader} hideSidebar>
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 70}px`,
        };
        return (
          <Tabs
            className={`w-full overflow-hidden`}
            style={wrapperStyle}
            panelStyle={panelStyle}
            useSearchParams
            panelClasses={`p-2 border-t overflow-y-scroll`}
            tabListClasses="bg-white px-2 h-[38px]"
            tabclassName="mx-1"
            id="organization-show"
            // onClick={handleTabClick}
            data={tabs}
          />
          // <div className="px-3 pb-3 h-auto">
          //   <div className="grid grid-cols-8 gap-3">
          //     <DriverCard
          //       handleUpdate={handleUpdate}
          //       info={{
          //         ...org.data,
          //         fullName: org.data.name,
          //         org: true,
          //         avatar: org.data.avatar
          //           ? org.data.avatar
          //           : "https://ui-avatars.com/api/?name=" + org.data.name,
          //       }}
          //     />
          //     <DriversTable
          //       update={handleUpdate}
          //       org={org.data}
          //       data={org.data.employees || []}
          //     />
          //   </div>
          // </div>
        );
      }}
    </SidebarContainer>
  );
};

export default OrgShow;
