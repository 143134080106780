import React from "react";
import Input from "../Input";

const GlobalFilter = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Input
      id="table-search"
      onChange={(e) => {
        setValue(e.value);
        onChange(e.value);
      }}
      size="sm"
      placeholder="Search..."
      value={value}
      className="bg-white"
      {...props}
    />
  );
};

export default GlobalFilter;
