import { useEffect, useState } from "react";
import Toggle from "../shared/Toggle";
import Select from "../shared/Select";

const primary = [
  "bg-primary-50",
  "bg-primary-100",
  "bg-primary-200",
  "bg-primary-300",
  "bg-primary-400",
  "bg-primary-500",
  "bg-primary-600",
  "bg-primary-700",
  "bg-primary-800",
  "bg-primary-900",
];
const logos = ["green-border", "white-bg-green", "green-lg", "green"];
const neutral = [
  "bg-neutral-50",
  "bg-neutral-100",
  "bg-neutral-200",
  "bg-neutral-300",
  "bg-neutral-400",
  "bg-neutral-500",
  "bg-neutral-600",
  "bg-neutral-700",
  "bg-neutral-800",
  "bg-neutral-900",
];
const secondary = [
  "bg-secondary-50",
  "bg-secondary-100",
  "bg-secondary-200",
  "bg-secondary-300",
  "bg-secondary-400",
  "bg-secondary-500",
  "bg-secondary-600",
  "bg-secondary-700",
  "bg-secondary-800",
  "bg-secondary-900",
];

const themeOptions = [
  { label: "Green", value: "green" },
  { label: "Green 2", value: "green2" },
];

const Colors = (props) => {
  const [logoSlant, setLogoSlant] = useState("-slant");
  const [color, setColor] = useState("green");

  const logoColors = logos.map((logo) => {
    return logo.replace("green", color);
  });

  function handleThemeChange(e) {
    setColor(e.value);
    const $body = document.querySelector("body");
    $body.classList = "";
    $body.classList.add("theme-" + e.value);
  }

  return (
    <div className="px-3 pb-3 h-auto">
      <div className="flex items-center space-x-2 mb-4">
        <div>
          <Toggle
            label="Slant"
            checked={logoSlant}
            onChange={() => setLogoSlant(logoSlant === "" ? "-slant" : "")}
          />
        </div>
        <Select
          id="color"
          value={color}
          onChange={handleThemeChange}
          options={themeOptions}
        />
      </div>
      <div className="grid gap-3">
        <div className="flex space-x-2">
          {/* {logoColors.map((item) => {
            return (
              <img
                className="h-24"
                src={require(`../assets/logos/logo-${item}${logoSlant}.png`)}
                alt={item}
              />
            );
          })} */}
        </div>
        <div className="font-bold">Primary</div>
        <div className="grid grid-cols-5 gap-5">
          {primary.map((item) => {
            const number = item.split("-")[2];
            return (
              <div className={`w-full h-20 rounded-lg ${item}`}>
                <span className={number > 400 ? "text-white" : "text-black"}>
                  {number}
                </span>
              </div>
            );
          })}
        </div>
        <div className="font-bold">Neutral</div>
        <div className="grid grid-cols-5 gap-5">
          {neutral.map((item) => {
            const number = item.split("-")[2];
            return (
              <div className={`w-full h-20 rounded-lg ${item}`}>
                <span className={number > 400 ? "text-white" : "text-black"}>
                  {number}
                </span>
              </div>
            );
          })}
        </div>
        {/* <div className="font-bold">Secondary</div>
      <div className="grid grid-cols-10 gap-5">
        {secondary.map((item) => {
          const number = item.split("-")[2];
          return (
            <div className={`w-full h-10 rounded-lg ${item}`}>
              <span className={number > 500 ? "text-white" : "text-black"}>
                {number}
              </span>
            </div>
          );
        })}
      </div> */}
      </div>
    </div>
  );
};

export default Colors;
