import {
  CheckCircleIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState, useRef } from "react";
import Input from "../Input";
import classNames from "classnames";
import Spinner from "../Spinner";
import Select from "../Select";
import DatePicker from "../DatePicker";
import { isObject } from "../../utils";

// { getValue, row: { index }, column: { id }, table }

const Cell = ({ getValue, column, ...props }) => {
  const table = props.getContext().table;
  const initialValue = getValue();
  const [hovered, setHovered] = useState(false);
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const isEditable = !!column.columnDef.meta?.editable;
  const editData = column.columnDef.meta?.editable;

  const wrapperClasses = classNames("flex items-center", {
    "p-2": editing,
    "p-4": !editing,
  });

  const onChange = (e) => {
    setValue(e.value);
  };

  const canEdit = () => {
    if (typeof editData?.disabled === "function") {
      return editData.disabled(props.row);
    }

    return !editData?.disabled;
  };

  // We'll only update the external data when the input is blurred
  const save = () => {
    const saveData = {
      row: props.row,
      column,
      value,
      data: { [column.id]: value },
    };
    props.updateData && props.updateData(saveData, setLoading);
    setEditing(false);
    setHovered(false);
    setLoading(true);
  };

  const cancel = () => {
    table.resetColumnSizing();
    setEditing(false);
  };

  function setEdit() {
    table.setColumnSizing({ [column.id]: 450 });
    setEditing(true);
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(value);
  }, [value]);

  const actions = editData?.actions ? (
    editData.actions
  ) : (
    <span className="flex items-center ml-1">
      <CheckCircleIcon
        onClick={(e) => save(e)}
        className="h-5 w-5 cursor-pointer stroke-primary-600"
      />
      <XMarkIcon
        onClick={(e) => cancel(e)}
        className="h-5 w-5 cursor-pointer stroke-primary-600"
      />
    </span>
  );

  const editText = (typeof editData === "boolean" ||
    editData?.type === "text") && (
    <>
      <span className="w-full">
        <Input size="sm" value={value} ref={inputRef} onChange={onChange} />
      </span>
      {actions}
    </>
  );

  const editSelect = editData?.type === "select" && (
    <>
      <span className="w-full">
        <Select
          size="sm"
          value={value}
          onChange={onChange}
          options={editData?.options}
        />
      </span>
      {actions}
    </>
  );

  const editNumber = editData?.type === "number" && (
    <>
      <span className="w-full">
        <Input type="number" size="sm" value={value} onChange={onChange} />
      </span>
      {actions}
    </>
  );

  const editDate = editData?.type === "date" && (
    <>
      <span className="w-full">
        <DatePicker size="sm" value={value} onChange={onChange} />
      </span>
      {actions}
    </>
  );

  const editCustom =
    editData?.type === "custom" &&
    (() => {
      const Layout = editData.layout;
      return (
        <>
          <span className="w-full">
            <Layout {...props} {...column} value={value} onChange={onChange} />
          </span>
          {actions}
        </>
      );
    })();

  function renderEditType(type) {
    switch (type) {
      case "custom":
        return editCustom;
      case "date":
        return editDate;
      case "number":
        return editNumber;
      case "select":
        return editSelect;
      default:
        return editText;
    }
  }

  if (inputRef.current && editing) {
    inputRef.current.focus();
  }
  return loading ? (
    <span className={wrapperClasses}>
      <Spinner size="sm" />
    </span>
  ) : (
    <span
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={wrapperClasses}
    >
      <span className="text-sm font-medium text-gray-900 w-full">
        {editing ? (
          <span className="flex items-center w-full">
            {!column.editLayout && renderEditType(editData?.type)}
          </span>
        ) : (
          <span className="flex items-center w-full">
            <span className="mr-1 whitespace-nowrap">{props.children}</span>
            <span className="h-3 w-3">
              {canEdit() && isEditable && hovered && (
                <PencilIcon
                  onClick={setEdit}
                  className="h-3 w-3 text-primary-500 hover:text-primary-700 cursor-pointer"
                />
              )}
            </span>
          </span>
        )}
      </span>
    </span>
  );
};

export default Cell;
