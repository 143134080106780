import React from "react";
import { Button, Card } from "../shared";

export const ClientCard = ({ client }) => {
  return (
    <Card className="!p-0 w-full bg-white !border-gray-200 shadow-sm">
      <div className="flex flex-col items-start space-x-2">
        <div className="mb-4 w-full ">
          {/* Placeholder for customer image */}
          <div className="w-full h-32 bg-gray-300 rounded-t-lg"></div>
        </div>
        <div className="">
          <h3 className="text-xl font-semibold mb-2">{client?.name}</h3>
          <p className="text-gray-600">{client?.address?.fullAddress}</p>
          <div className="mt-2">
            <p className="text-gray-600">{client?.phone}</p>
            <p className="text-gray-600">{client?.email}</p>
          </div>
        </div>
      </div>
      <div className="mt-4 p-2 border-t border-gray-200 hover:bg-gray-50 cursor-pointer">
        <Button type="link" className="text-blue-600 hover:underline">
          Client History
        </Button>
      </div>
    </Card>
  );
};
