import { Button, Modal, Input, Select, TextArea } from "../../shared";
import request, { renderQueries } from "../../utils/request";
import useForm from "../../hooks/useForm";
import { useState } from "react";
import { toast } from "react-toastify";

const ContactModal = ({ modalOpen, setModalOpen, ...props }) => {
  const { form, updateForm, clearForm } = useForm();
  const [loading, setLoading] = useState(false);

  function onFormChange(e) {
    updateForm(e);
  }

  const contactOptions = [
    { value: "Phone", label: "Phone" },
    { value: "Email", label: "Email" },
  ];

  const moreInfoContent = (
    <div className="space-y-3">
      <Input
        value={form.companyName}
        id="companyName"
        label="Company Name"
        placeholder="Company Name"
        onChange={onFormChange}
      />
      <Input
        value={form.clientContact}
        id="contactName"
        placeholder="Contact Name"
        label="Contact Name"
        onChange={onFormChange}
      />
      <Input
        value={form.email}
        id="email"
        label="Contact Email"
        placeholder="Contact Email"
        onChange={onFormChange}
      />
      <Input
        value={form.phone}
        id="phone"
        placeholder="Contact Phone"
        label="Contact Phone"
        onChange={onFormChange}
      />
      <TextArea
        value={form.questions}
        id="questions"
        label="Initial Questions"
        placeholder="Initial Questions"
        onChange={updateForm}
      />
      {/* <Select
        value={form.preferredContact}
        placeholder="Preferred Contact Method"
        options={contactOptions}
      /> */}
    </div>
  );

  const footer = (
    <div>
      <Button className="mr-3" onClick={closeModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={loading}
        onClick={() => saveContact()}
        // type="primary"
        disabled={form.count === 0}
      >
        Submit
      </Button>
    </div>
  );

  const title = (
    <span className="mx-4">Tell us how to get in contact with you</span>
  );

  function handleCancel() {
    setModalOpen(false);
    clearForm();
  }

  function closeModal() {
    setModalOpen(false);
    handleCancel();
  }

  async function saveContact() {
    setLoading(true);

    const postData = {
      type: "Contact Form",
      ...form,
    };

    const { data } = await request("/leads", {
      method: "post",
      data: postData,
    });

    if (data.status === "success") {
      setLoading(false);
      setModalOpen(false);
      toast("Contact info saved, we'll be in touch soon!", {
        type: "success",
        toastId: "leadCreated",
      });
    }
  }

  return (
    <Modal
      size="sm"
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={moreInfoContent}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};
export default ContactModal;
