import React, { useEffect, useState } from "react";
import UserInfo from "./UserInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUser,
  resetUser,
  selectUser,
  sendEmail,
} from "../../../redux/reducers/users/userSlice";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import FormLayout from "../../../layouts/FormLayout";
import Toggle from "../../../shared/Toggle";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Dropdown from "../../../shared/Dropdown";
import { toast } from "react-toastify";

const UserEdit = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const params = useParams();
  const selectedUser = useSelector(selectUser);
  const userStatus = useSelector((state) => state.user.status);
  const [dropLoading, setDropLoading] = useState(false);

  useEffect(() => {
    if (userStatus === "idle") {
      dispatch(fetchUser({ parentId: user.parentCompany, userId: params.id }));
    }
  }, [userStatus, dispatch, user, params.id]);

  useEffect(() => {
    return () => dispatch(resetUser());
  }, [dispatch]);

  async function email(id) {
    setDropLoading(true);
    const email = await dispatch(
      sendEmail({ userId: params.id, type: id, parent: user.parentCompany })
    ).unwrap();

    if (email.status === "success") {
      toast("Email Sent", {
        type: "success",
        toastId: "emailSent",
      });
      setDropLoading(false);
    }
  }

  const formTypeOptions = [
    {
      value: "activate",
      onClick: () => email("activate"),
      label: "Resend Activate Email",
    },
    {
      value: "changePassword",
      onClick: () => email("changePassword"),
      label: "Send Change Password",
    },
  ];

  const actionsTrigger = (
    <div className="flex items-center cursor-pointer font-semibold">
      Actions <ChevronDownIcon className="h-3 w-3 ml-1" />
    </div>
  );

  const actions =
    user.authority === "admin" ||
    (user.authority === "superAdmin" && (
      <div>
        <Dropdown
          disabled={dropLoading}
          closeOnClick
          trigger={actionsTrigger}
          data={formTypeOptions}
        />
      </div>
    ));

  return (
    <div className="px-3 pb-3 h-auto">
      <FormLayout hideButtons back="/users" actions={actions}>
        <div className="w-full p-4 bg-white rounded-lg border border-gray-200 shadow-md">
          {selectedUser._id && (
            <UserInfo
              user={selectedUser}
              currentUser={user}
              parentId={user.parentCompany}
            />
          )}
        </div>
      </FormLayout>
    </div>
  );
};

export default UserEdit;
