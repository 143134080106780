import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useForm from "../../../hooks/useForm";
import { FORM_OPTIONS } from "../table.config";
import { fetchClients } from "../../../redux/reducers/clients/clientsSlice";
import { useAuth } from "../../../hooks/useAuth";
import JobsTable from "../Create/JobsTable";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import SelectedJobsTable from "../Create/SelectedJobsTable";
import { renderAmount } from "../../../utils";
import { toast } from "react-toastify";
import Tabs from "../../../shared/Tabs";
import Spinner from "../../../shared/Spinner";
import { SidebarContainer } from "../../../components";
import { Button, EditableText } from "../../../shared";
import {
  useGetInvoiceQuery,
  useUpdateInvoiceMutation,
} from "../../../redux/services/invoices/invoicesApi";
import { useGetJobsQuery } from "../../../redux/services/jobs/jobsApi";

const InvoiceEdit = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const table1Ref = useRef();
  const table2Ref = useRef();

  const [jobsData, setJobsData] = useState({ jobs: [] });

  const { form, setFormInit } = useForm(FORM_OPTIONS);

  const clientsStatus = useSelector((state) => state.clients.status);

  // Update invoice function
  const [updateInvoice, { isLoading: invoiceUpdating }] =
    useUpdateInvoiceMutation();

  // Invoice Query
  const { data: invoiceData, isLoading } = useGetInvoiceQuery({
    id: params.id,
    drivers: true,
  });
  const invoice = invoiceData || {};

  // Jobs Query
  const { data } = useGetJobsQuery(
    {
      client: invoice?.client?._id,
      invoiceId: false,
    },
    { skip: !invoice?.client?._id }
  );

  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedInvoiceItems, setSelectedInvoiceItems] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [shouldFetchClients, setShouldFetchClients] = useState(false);

  useEffect(() => {
    if (data?.jobs.length > 0 && jobsData.jobs.length === 0) {
      setJobsData(data);
    }
  }, [data?.jobs.length]);

  useEffect(() => {
    if (invoice._id) {
      setFormInit(invoice);
      setInvoiceItems(invoice.jobs);
    }
  }, [invoice._id]);

  useEffect(() => {
    if (shouldFetchClients && clientsStatus === "idle") {
      dispatch(fetchClients(user.parentCompany));
      setShouldFetchClients(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchClients, clientsStatus]);

  function addToInvoice() {
    const newJobs = jobsData.jobs.filter((x) => !selectedJobs.includes(x));
    setJobsData({ jobs: newJobs });
    setInvoiceItems([...invoiceItems, ...selectedJobs]);
    table1Ref.current.resetSelectedRows(false);
  }

  function removeFromInvoice() {
    const newJobs = [...jobsData.jobs, ...selectedInvoiceItems];
    const newInvoiceItems = invoiceItems.filter(
      (x) => !selectedInvoiceItems.includes(x)
    );
    setJobsData({ jobs: newJobs });
    setInvoiceItems(newInvoiceItems);
    table2Ref.current.resetSelectedRows(false);
  }

  async function handleSubmit() {
    const data = {
      jobs: invoiceItems.map(({ _id }) => ({ _id })),
      invoiceId: form._id,
    };

    const res = await updateInvoice({
      data,
      id: params.id,
    }).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "updated-invoice",
    });

    if (res.status === "success") {
      navigate(`/invoices/${res.data._id}`);
    }
  }

  const renderInvoiceTotal = () => {
    let totalInvoiceCharges = invoiceItems
      .map((item) => {
        return item.totalCharges;
      })
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    return totalInvoiceCharges;
  };

  const totalCharges = (
    <div className="flex items-center">
      Total Charges:{" "}
      <span className="font-bold pl-2 text-xl">
        {renderAmount(renderInvoiceTotal())}
      </span>
    </div>
  );

  const tabs = [
    {
      id: "Edit",
      content: (
        <div className="h-auto">
          {form.client && (
            <div className="flex flex-col xl:flex-row justify-evenly justify-items-stretch space-x-0 xl:space-x-2">
              <div className="flex-grow w-full">
                <JobsTable
                  data={jobsData.jobs}
                  setSelectedJobs={setSelectedJobs}
                  client={form.client}
                  ref={table1Ref}
                />
              </div>
              <div className="flex-grow-0 self-center z-50">
                <div className="hidden xl:block">
                  <IconButton icon={ChevronRightIcon} onClick={addToInvoice} />
                  <IconButton
                    icon={ChevronLeftIcon}
                    onClick={removeFromInvoice}
                  />
                </div>
                <div className="flex xl:hidden">
                  <IconButton
                    icon={ChevronUpIcon}
                    onClick={removeFromInvoice}
                  />
                  <IconButton icon={ChevronDownIcon} onClick={addToInvoice} />
                </div>
              </div>
              <div className="flex-grow w-full">
                <SelectedJobsTable
                  setSelectedInvoiceItems={setSelectedInvoiceItems}
                  selectedJobs={invoiceItems}
                  client={form.client}
                  ref={table2Ref}
                />
              </div>
            </div>
          )}
          <div className="my-4">{totalCharges}</div>
          <div className="flex justify-end mt-4">
            <Button
              className="w-6/12 lg:w-28 mr-3"
              type="alternative"
              onClick={() => navigate(`/invoices/${params.id}`)}
            >
              Cancel
            </Button>
            <Button
              loading={invoiceUpdating}
              className="w-6/12 lg:w-28"
              disabled={invoiceUpdating}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderTabHeader = (headerProps) => {
    return (
      <div ref={headerRef}>
        <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
          <div>{headerProps.title}</div>
          <div className="flex items-center space-x-3">
            <div className="md:flex items-center space-x-3 text-gray-800 hidden">
              {headerProps.actions}
            </div>
          </div>
        </header>
        <div className="flex justify-between md:hidden space-x-5 px-3 py-3 bg-white border-b">
          {headerProps.actions}
        </div>
      </div>
    );
  };

  const title = (
    <EditableText
      className="ml-1"
      iconClasses="h-3 w-3"
      id="invoiceId"
      value={form.invoiceId}
      disabled
      size="sm"
      displayText={(v) => (
        <span className="text-gray-800 font-semibold text-lg">{v}</span>
      )}
    />
  );

  return (
    <SidebarContainer
      title={title}
      hideSidebar
      header={renderTabHeader}
      actions={totalCharges}
    >
      {({ parentHeight }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 38}px`,
        };
        return (
          <div style={wrapperStyle} className="h-auto w-full">
            {isLoading === "loading" ? (
              <div>
                <Spinner />{" "}
              </div>
            ) : (
              <Tabs
                className={`w-full overflow-hidden`}
                style={wrapperStyle}
                panelStyle={panelStyle}
                useSearchParams
                panelClasses={`p-2 border-t overflow-y-scroll`}
                tabListClasses="bg-white px-2 h-[38px]"
                tabclassName="mx-1"
                id="invoice-edit"
                hideTabs
                data={tabs}
              />
            )}
          </div>
        );
      }}
    </SidebarContainer>
  );
};

const IconButton = (props) => {
  const Icon = props.icon;
  return (
    <div className="flex flex-col items-center my-4 mx-1">
      <div
        onClick={props.onClick}
        className="border border-gray-300 ease-in-out duration-300 hover:border-gray-300 rounded-full p-2 hover:bg-gray-200 cursor-pointer"
      >
        <Icon className="w-6 h-6" />
      </div>
      {props.label && <p className="font-semibold">{props.label}</p>}
    </div>
  );
};

export default InvoiceEdit;
