import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import { COLUMN_DEFINITIONS } from "../Payouts/table.config";
import {
  useDeletePayoutsMutation,
  useGetPayoutsQuery,
  useUpdatePayoutMutation,
} from "../../redux/services/payouts/payoutsApi";
import { usePrefetch } from "../../redux/api";

const Payouts = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const { data, isLoading, isFetching } = useGetPayoutsQuery({
    page: pageIndex + 1,
    limit: pageSize,
  });

  const [updatePayout, { isLoading: isUpdating }] = useUpdatePayoutMutation();
  const [deletePayouts, { isLoading: isDeleting }] = useDeletePayoutsMutation();

  const prefetchPage = usePrefetch("getPayouts");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
    });
  }, [prefetchPage, data?.nextPage, pageSize]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);

  function handleEdit(itemId) {
    navigate(`/payouts/${itemId}`);
  }

  async function handleDelete(items) {
    const res = await deletePayouts({ data: items.map((item) => item._id) });

    toast(res.data.message, {
      type: res.data.status,
      toastId: "delete-payouts",
    });

    return res.data;
  }

  async function handleUpdate(e, setLoading) {
    const updateData = {
      id: e.row.original._id,
      data: e.data,
    };

    const res = await updatePayout(updateData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "index-delete-payouts",
    });

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const cannotCreateOrEdit =
    user.authority === "client" || user.authority === "user";

  return (
    <TableLayout
      id="payouts"
      loading={isLoading || isFetching}
      columns={COLUMN_DEFINITIONS}
      data={data?.payouts}
      pagination={data}
      pageIndex={pageIndex}
      pageSize={pageSize}
      onPaginationChange={setPagination}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      onUpdate={handleUpdate}
      hideActions={cannotCreateOrEdit}
      hideMainAction={cannotCreateOrEdit}
    />
  );
};

export default Payouts;
