import { useEffect, useRef } from "react";

function IndeterminateCheckbox({
  indeterminate,
  checked,
  className = "",
  ...rest
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = indeterminate;
    }

    if (typeof checked === "boolean" && !indeterminate) {
      ref.current.checked = checked;
    }
  }, [ref, indeterminate, checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer accent-primary-600"}
      {...rest}
      header={rest.header && rest.header.toString()}
    />
  );
}

export default IndeterminateCheckbox;
