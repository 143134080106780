import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../../../shared/Table/table.utils";
import { capitalizeFirstLetter, formatDate } from "../../../../utils";
import Badge from "../../../../shared/Badge";
import Popover from "../../../../shared/Popover";
import { isAuthorized } from "../../../../utils/authority";

// Config for table and form
const tableConfig = [
  {
    accessorKey: "equipment.name",
    id: "equipmentName",
    header: "Equipment",
    visible: true,
    required: true,
    minWidth: 200,
    editable: isAuthorized("owner,admin"),
    cell: ({ getValue, row: { original } }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/equipment/${original.equipment._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "maintenanceType",
    header: "Type",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => {
      const value = info.getValue();
      return <Badge label={capitalizeFirstLetter(value)}></Badge>;
    },
  },
  {
    id: "status",
    header: "Status",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => {
      const value = info.getValue();
      return <Badge label={capitalizeFirstLetter(value)}></Badge>;
    },
  },
  {
    id: "description",
    header: "Description",
    enableGlobalFilter: true,
    visible: true,
    minWidth: 200,
  },
  {
    id: "performedBy.name",
    header: "Performed By",
    enableGlobalFilter: true,
    visible: true,
    minWidth: 140,
  },
  {
    id: "date",
    header: "Date",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => {
      const value = info.getValue();
      return value && formatDate(new Date(value), null, true);
    },
    width: 180,
  },
  {
    id: "cost",
    header: "Cost",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => {
      const value = info.getValue();
      return value ? `$${value.toFixed(2)}` : "-";
    },
    width: 100,
  },
  {
    id: "nextMaintenanceDate",
    header: "Next Maintenance",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => {
      const value = info.getValue();
      return value && formatDate(new Date(value), null, true);
    },
    width: 180,
  },
  {
    id: "client.name",
    header: "Client",
    enableGlobalFilter: true,
    visible: true,
    minWidth: 140,
  },
  {
    id: "parentCompany.name",
    header: "Parent Company",
    enableGlobalFilter: true,
    visible: true,
    minWidth: 140,
  },
  {
    id: "notes",
    header: "Notes",
    minWidth: 140,
    cell: ({ getValue }) => {
      if (getValue()) {
        return (
          <Popover
            className="w-43"
            position="top"
            trigger={<Badge label={"Note"}></Badge>}
            offset={[-12, 0]}
          >
            {getValue()}
          </Popover>
        );
      }
    },
  },
  {
    id: "parts",
    header: "Parts",
    enableGlobalFilter: false,
    visible: true,
    cell: ({ getValue }) => {
      const parts = getValue();
      if (parts && parts.length > 0) {
        return (
          <Popover
            className="w-43"
            position="top"
            trigger={<Badge label={`Parts (${parts.length})`}></Badge>}
            offset={[-12, 0]}
          >
            <ul>
              {parts.map((part, index) => (
                <li key={index}>{`${part.name} (${
                  part.quantity
                }) - $${part.cost.toFixed(2)}`}</li>
              ))}
            </ul>
          </Popover>
        );
      }
      return null;
    },
    minWidth: 100,
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
