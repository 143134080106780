import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../../utils/request";

const initialState = {
  client: {},
  status: "idle",
  error: null,
};

export const fetchClient = createAsyncThunk(
  "client/fetchClient",
  async ({ parentId, clientId }) => {
    const res = await request(`/clients/${clientId}?parent=${parentId}`);
    return res.data;
  }
);

export const addNewClient = createAsyncThunk(
  "client/addNewClient",
  async (data, { rejectWithValue }) => {
    const res = await request(`/clients`, {
      method: "post",
      data: data,
    });
    if (!res.data.client) {
      rejectWithValue();
    }
    return res.data;
  }
);

export const updateClient = createAsyncThunk(
  "client/updateClient",
  async ({ data, parentId, clientId, type }, { rejectWithValue }) => {
    const res = await request(
      `/clients/${clientId}${
        type === "address" ? "/address" : ""
      }?parent=${parentId}`,
      {
        method: "put",
        data: data,
      }
    );
    if (!res.data.client) {
      rejectWithValue();
    }
    return res.data;
  }
);

export const updateClientLocation = createAsyncThunk(
  "client/updateClientLocation",
  async ({ data, parentId, locationId }) => {
    const res = await request(`/locations/${locationId}?parent=${parentId}`, {
      method: "put",
      data,
    });
    return res.data;
  }
);

export const updateClientRates = createAsyncThunk(
  "client/updateClientRates",
  async ({ data, parentId, locationId }) => {
    const res = await request(`/clients/${locationId}?parent=${parentId}`, {
      method: "put",
      data,
    });
    return res.data;
  }
);

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    resetClient() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClient.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchClient.fulfilled, (state, action) => {
        state.client = {};
        state.status = "succeeded";
        state.client = action.payload.client;
      })
      .addCase(fetchClient.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewClient.fulfilled, (state, action) => {
        state.client = action.payload;
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.client = action.payload.data;
      })
      .addCase(updateClientLocation.fulfilled, (state, action) => {
        const index = state.client.locations.findIndex(
          (obj) => obj._id === action.payload.data._id
        );
        state.client.locations[index] = action.payload.data;
      });
  },
});

export const { clientAdded, resetClient } = clientSlice.actions;

export default clientSlice.reducer;

export const selectClient = (state) => state.client.client;
export const selectClientLocations = (state) => {
  return state.clients.clients.map((obj) => {
    return {
      value: obj.address,
      label: obj.address,
    };
  });
};
