import { useEffect, useState } from "react";
import { Button, Input, SettingsPanel } from "../../../shared";
// import SettingsPanel from "./SettingsPanel";
import _ from "lodash";
import { useConfig, useForm, useAuth } from "../../../hooks";
import request, { renderQueries } from "../../../utils/request";
import { SettingsHeader } from "../../Settings";
import { toast } from "react-toastify";

const AccountSettingsPage = () => {
  const { addConfig, configs } = useConfig();
  const { form, setFormInit } = useForm();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormInit({
      slug: { data: configs?.parentCompany?.slug },
      name: { data: configs?.parentCompany?.name },
      email: { data: configs?.parentCompany?.email },
      phone: { data: configs?.parentCompany?.phone },
      address: { data: configs?.parentCompany?.address?.fullAddress },
    });
  }, [configs?.parentCompany?.name]);

  function handleBlur(key, type) {
    const edited = _.pickBy(form, function (obj, key) {
      return obj.updated;
    });
    const res = _.mapValues(edited, (value) => value.data);

    if (Object.keys(res).length === 0) return;

    if (type === "parentCompany") {
      handleParentCompanySave(res);
    } else {
      handleConfigSave(res, key, type);
    }

    return;
  }

  async function handleParentCompanySave(data) {
    setLoading(true);

    delete data.configKey;

    const updatedParent = await request(
      "/customers/" +
        configs.parentCompany._id +
        "?" +
        renderQueries({ parent: user.parentCompany }),
      { method: "put", data }
    );

    setLoading(false);
  }

  function handleConfigSave(data, key, type) {
    setLoading(true);
    if (type) {
      data.type = type;
    }
    data.configKey = key;
    addConfig(data);
    setLoading(false);
  }

  function onFormChange(e) {
    setFormInit({ [e.id]: { data: e.value, updated: true } });
  }

  async function handleChangePassEmail() {
    const queries = renderQueries({ type: "changePassword" });
    const res = await request(`/users/email/${user._id}?${queries}`, {
      method: "post",
    });

    toast(res.data.message, {
      type: res.data.status,
      id: "send-reset-pass-email",
    });
  }

  const data = [
    {
      title: "Change Password",
      subtitle: "You will receive an email with instructions",
      content: (
        <div className="flex justify-end">
          <Button
            id="change-pass-email"
            onClick={handleChangePassEmail}
            size="sm"
          >
            Send Email
          </Button>
        </div>
      ),
    },
    // {
    //   title: "Close account",
    //   subtitle: "Your organizations name",
    //   content: (
    //     <Input
    //       onBlur={() => handleBlur("name", "parentCompany")}
    //       id="name"
    //       onChange={onFormChange}
    //       value={form.name?.data}
    //       size="md"
    //       placeholder="Display Name"
    //     />
    //   ),
    // },
  ];

  return (
    <>
      <SettingsHeader title="Account Settings" />
      <SettingsPanel title="General" data={data} />
    </>
  );
};

export default AccountSettingsPage;
