import classNames from "classnames";
import React from "react";

export const Radio = (props) => {
  function handleChange(e) {
    props.onChange && props.onChange(e.target);
  }

  const sizeLg = props.size === "lg";
  const sizeMd = props.size === "md" || !props.size;
  const sizeSm = props.size === "sm";

  const radioClasses = classNames(
    "accent-primary-600 bg-gray-100 border-gray-300",
    { "w-6 h-6": sizeMd }
    // { "w-4 h-4": sizeMd },
    // { "w-4 h-4": sizeSm }
  );

  return (
    <div className="flex items-center my-2">
      <input
        id={props.id}
        type="radio"
        value={props.value}
        name={props.name}
        checked={props.checked}
        className={radioClasses}
        onChange={handleChange}
      />
      {props.label && (
        <label
          htmlFor={props.id}
          className="ml-2 text-sm font-medium text-gray-900"
        >
          {props.label}
        </label>
      )}
    </div>
  );
};

export default Radio;
