import classNames from "classnames";
import React from "react";
import { CardHeader } from "./CardHeader";

export const Card = (props) => {
  const classes = classNames(
    "p-3 w-full bg-white border border-gray-200 shadow-sm",
    props.className,
    {
      "rounded-lg": !props.header && !props.title,
      "rounded-b-lg": props.header || props.title,
    }
  );

  const mainCard = (
    <div className={classes}>{props.children || props.content}</div>
  );

  const header = props.header ? (
    props.header
  ) : props.title ? (
    <CardHeader title={props.title} />
  ) : null;

  return header ? (
    <div className={props.className}>
      {header}
      {mainCard}
    </div>
  ) : (
    mainCard
  );
};

export default Card;
