import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";
import _ from "lodash";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchOrganizations = createAsyncThunk(
  "organizations/fetchOrganizations",
  async ({ parent }) => {
    const res = await request(`/organizations?parent=${parent}`);
    return res.data;
  }
);

export const deleteOrganizations = createAsyncThunk(
  "organizations/deleteOrganizations",
  async ({ data, ...rest }) => {
    const queries = renderQueries(rest);
    const res = await request(`/organizations?${queries}`, {
      method: "delete",
      data,
    });
    return res.data;
  }
);

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    organizationAdded(state, action) {
      const { data } = action.payload;
      state.data = state.data.concat(data);
    },
    organizationDeleted(state, action) {
      const { data } = action.payload;
      const index = state.data.findIndex((item) => item._id === data._id);
      state.data.splice(index, 1);
      const newData = state.data;
      state.data = newData;
    },
    organizationUpdated(state, action) {
      const { data } = action.payload;
      const index = state.data.findIndex((item) => item._id === data._id);
      state.data[index] = data;
    },
    resetOrganizations() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrganizations.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.data = [];
        state.status = "succeeded";
        state.data = state.data.concat(action.payload.data);
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteOrganizations.fulfilled, (state, action) => {
        const deleted = action.payload.data;
        const newData = _.differenceBy(state.data, deleted, "_id");
        state.data = newData;
      });
  },
});

export const {
  organizationAdded,
  organizationDeleted,
  organizationUpdated,
  resetOrganizations,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;

export const selectOrganizationsOptions = (state) => {
  return state.organizations.data.map((obj) => {
    return {
      value: obj,
      label: obj?.name,
    };
  });
};
