import React, { useEffect, useRef, useState } from "react";
import useForm from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addNewInvoice } from "../../../redux/reducers/invoices/invoiceSlice";
import { FORM_OPTIONS } from "../table.config";
import Select from "../../../shared/Select";
import { fetchClients } from "../../../redux/reducers/clients/clientsSlice";
import { useAuth } from "../../../hooks/useAuth";
import FormLayout from "../../../layouts/FormLayout";
import { selectClientsOptions } from "../../../redux/reducers/clients/clientsSlice";
import JobsTable from "./JobsTable";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  resetJobs,
  fetchJobs,
  selectAllJobs,
  setJobs,
} from "../../../redux/reducers/jobs/jobsSlice";
import SelectedJobsTable from "./SelectedJobsTable";
import { renderAmount } from "../../../utils";
import { SidebarContainer } from "../../../components";
import Tabs from "../../../shared/Tabs";

const InvoiceCreate = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const table1Ref = useRef();
  const table2Ref = useRef();

  const [loading, setLoading] = useState(false);

  const { form, updateForm, clearForm } = useForm(FORM_OPTIONS);

  const clientStatus = useSelector((state) => state.client.status);
  const clientOptions = useSelector(selectClientsOptions);

  const jobs = useSelector(selectAllJobs);
  const jobStatus = useSelector((state) => state.jobs.status);

  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedInvoiceItems, setSelectedInvoiceItems] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (clientStatus === "idle") {
      dispatch(fetchClients(user.parentCompany));
    }
  }, [clientStatus, dispatch, user]);

  useEffect(() => {
    if (form.client && jobStatus === "idle") {
      dispatch(
        fetchJobs({
          parent: user.parentCompany,
          clientId: form.client._id,
          invoiceId: false,
        })
      );
    }
  }, [jobStatus, dispatch, user, form.client]);

  useEffect(() => {
    return () => dispatch(resetJobs());
  }, [dispatch, form.client]);

  function addToInvoice() {
    const newJobs = jobs.filter((x) => !selectedJobs.includes(x));

    dispatch(setJobs(newJobs));
    setInvoiceItems([...invoiceItems, ...selectedJobs]);
    table1Ref.current.toggleAllRowsSelected(false);
  }

  function removeFromInvoice() {
    const newJobs = [...jobs, ...selectedInvoiceItems];
    const newInvoiceItems = invoiceItems.filter(
      (x) => !selectedInvoiceItems.includes(x)
    );
    dispatch(setJobs(newJobs));
    setInvoiceItems(newInvoiceItems);
    table2Ref.current.toggleAllRowsSelected(false);
  }

  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  async function handleSubmit() {
    setLoading(true);
    const data = {
      jobs: invoiceItems.map((i) => ({ _id: i._id })),
      // total: renderInvoiceTotal(),
      status: "Review",
      ...form,
    };

    const res = await dispatch(
      addNewInvoice({ data, parentId: user.parentCompany })
    ).unwrap();

    if (res.status === "success") {
      setLoading(false);
      navigate(`/invoices/${res.data._id}`);
    } else {
      setLoading(false);
      navigate(`/invoices`);
    }
  }

  const client = form.client && (
    <div className="flex items-center">
      Client:{" "}
      <span className="font-bold text-xl ml-2 flex items-center">
        {form.client.name}{" "}
        <XMarkIcon
          className="h-5 w-5 cursor-pointer"
          onClick={() => {
            clearForm();
            setInvoiceItems([]);
          }}
        />
      </span>
    </div>
  );

  const renderInvoiceTotal = () => {
    let totalInvoiceCharges = invoiceItems
      .map((item) => {
        return item.totalCharges;
      })
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    return totalInvoiceCharges;
  };

  const totalCharges = (
    <div className="flex items-center">
      Total Charges:{" "}
      <span className="font-bold pl-2 text-xl">
        {renderAmount(renderInvoiceTotal())}
      </span>
    </div>
  );

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderTabHeader = (headerProps) => {
    return <div ref={headerRef}></div>;
  };

  const tabs = [
    {
      id: "Create",
      content: (
        <div className="px-3 pb-3 h-auto">
          <FormLayout
            title={client}
            handleSubmit={handleSubmit}
            actions={totalCharges}
            loading={loading}
          >
            <div className="my-5">
              {!form.client ? (
                <Select
                  id="client"
                  onChange={onFormChange}
                  label="Client"
                  options={clientOptions}
                  placeholder="Select a client"
                />
              ) : (
                <div className="flex flex-col xl:flex-row justify-evenly justify-items-stretch space-x-0 xl:space-x-2">
                  <div className="flex-grow w-full">
                    <JobsTable
                      data={jobs}
                      setSelectedJobs={setSelectedJobs}
                      client={form.client}
                      ref={table1Ref}
                    />
                  </div>
                  <div className="flex-grow-0 self-center z-50">
                    <div className="hidden xl:block">
                      <IconButton
                        icon={ChevronRightIcon}
                        onClick={addToInvoice}
                      />
                      <IconButton
                        icon={ChevronLeftIcon}
                        onClick={removeFromInvoice}
                      />
                    </div>
                    <div className="flex xl:hidden">
                      <IconButton
                        icon={ChevronUpIcon}
                        onClick={removeFromInvoice}
                      />
                      <IconButton
                        icon={ChevronDownIcon}
                        onClick={addToInvoice}
                      />
                    </div>
                  </div>
                  <div className="flex-grow w-full">
                    <SelectedJobsTable
                      setSelectedInvoiceItems={setSelectedInvoiceItems}
                      selectedJobs={invoiceItems}
                      client={form.client}
                      ref={table2Ref}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>{totalCharges}</div>
          </FormLayout>
        </div>
      ),
    },
  ];

  return (
    <SidebarContainer
      // title={title}
      hideSidebar
      header={renderTabHeader}
      // actions={actions}
      // sidebarContent={sidebarContent}
      // sidebarTitle={<h2 className="text-xl bg-white py-2">Communications</h2>}
    >
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 38}px`,
        };
        return (
          <div style={wrapperStyle} className="px-3 pb-3 h-auto w-full">
            <Tabs
              className={`w-full overflow-hidden`}
              style={wrapperStyle}
              panelStyle={panelStyle}
              useSearchParams
              panelClasses={`p-2 border-t overflow-y-scroll`}
              tabListClasses="bg-white px-2 h-[38px]"
              tabclassName="mx-1"
              id="job-show"
              // onClick={handleTabClick}
              data={tabs}
            />
          </div>
        );
      }}
    </SidebarContainer>
  );
};

const IconButton = (props) => {
  const Icon = props.icon;
  return (
    <div className="flex flex-col items-center my-4 mx-1">
      <div
        onClick={props.onClick}
        className="border ease-in-out duration-300 hover:border-gray-300 rounded-full p-2 hover:bg-gray-200 cursor-pointer"
      >
        <Icon className="w-6 h-6" />
      </div>
      {props.label && <p className="font-semibold">{props.label}</p>}
    </div>
  );
};

export default InvoiceCreate;
