import { useCallback, useEffect, useState } from "react";
import Button from "../../../../shared/Button";
import Modal from "../../../../shared/Modal";
import ServiceCreate from ".";
import { useAuth } from "../../../../hooks/useAuth";
import { parse, stringify } from "flatted";
import {
  useAddServiceMutation,
  useUpdateServiceMutation,
} from "../../../../redux/services/services/servicesApi";
import { toast } from "react-toastify";
import useEmblaCarousel from "embla-carousel-react";
import "./style.css";
import { formatDate } from "../../../../utils";
import useForm from "../../../../hooks/useForm";

export const ServiceCreateModal = ({
  visible,
  setVisible,
  form,
  job,
  onCancel,
}) => {
  // Get current user data
  const { user } = useAuth();

  // Main modal title
  const [modalTitle, setModalTitle] = useState("Add");
  // Submit button title
  const [submitButton, setSubmitButton] = useState("Submit");
  // Cancel button title
  const [cancelButton, setCancelButton] = useState("Cancel");

  // Handoff form hook
  const handoffForm = useForm();

  // Add service hook
  const [addService, { isLoading: addingService }] = useAddServiceMutation();
  // Update service hook
  const [updateService, { isLoading: updatingService }] =
    useUpdateServiceMutation();

  // Close job service modal
  function closeModal() {
    form.clearForm();
    onCancel(false);
  }

  function getApptDate(date, time) {
    // const apptDate = new Date(date);
    // if (time) {
    //   const timeArr = time.split(":");
    //   apptDate.setHours(timeArr[0]);
    //   apptDate.setMinutes(timeArr[1]);
    // }
    // return apptDate;
  }

  // Handle service update
  async function handleUpdate(e) {
    // let updateData = {
    //   parent: user.parentCompany,
    //   data: e,
    // };
    // if (e.column) {
    //   updateData = e.column.editable.update
    //     ? e.column.editable.update({ user, ...e })
    //     : {
    //         ...updateData,
    //         serviceId: e.row.original._id,
    //         data: e.data,
    //       };
    // } else {
    //   updateData = {
    //     parent: user.parentCompany,
    //     serviceId: e._id,
    //     jobId: job._id,
    //     data: {
    //       ...e,
    //       driver: e.driver?._id,
    //       client: job.client?._id,
    //       route: {
    //         origin: e.origin?._id,
    //         destination: e.destination?._id,
    //         mileage: Number(e.totalMiles),
    //         customMileage: Number(e.customMileage),
    //         _id: form.form.route?._id,
    //       },
    //       appointmentDate: getApptDate(e.appointmentDate, e.appointmentTime),
    //       // appointment: { date: e.appointmentDate, time: e.appointmentTime },
    //       waitTime: {
    //         total: e.totalWaitTime,
    //         start: e.waitStart,
    //         end: e.waitEnd,
    //       },
    //     },
    //   };
    //   delete updateData.origin;
    //   delete updateData.destination;
    //   // delete updateData.appointmentDate;
    //   // delete updateData.appointment;
    //   delete updateData.author;
    //   delete updateData.dateCreated;
    //   delete updateData.dateUpdated;
    //   delete updateData.parentCompany;
    //   delete updateData.payoutId;
    //   delete updateData.status;
    // }
    // const dataString = stringify(updateData);
    // const dataToSend = parse(dataString);
    // let res = await updateService(dataToSend).unwrap();
    // if (res.status === "success") {
    //   closeModal();
    // }
  }

  // Reset service data
  function resetService() {
    setSubmitButton("Submit");
    handoffForm.clearForm();
  }

  function postSaveCleanUp() {
    closeModal();
    setModalTitle("Add");
    resetHandoff();
  }

  // Save form with no handoff
  async function save() {
    const formData = form.form;
    // Main service data
    const data = {
      ...formData,
      date: formatDate(formData.date, null, true),
      equipment: formData.equipment._id,
      jobId: formData.job._id,
    };

    // Remove job before sending service to create
    delete data.job;

    // Create main service
    const res = await addService({
      data,
      client: job.client._id,
      jobId: data.jobId,
      addToJob: true,
    }).unwrap();

    // Main service toast
    toast("Successfully created service", {
      type: res.status,
      toastId: "service-" + res.data.service._id + "-added",
    });

    postSaveCleanUp();
  }

  // Main modal button handler
  function handleSubmit() {
    if (!form.form.edit) {
      return save();
    }

    // Handle update
    return handleUpdate(form.form);
  }

  // Handle cancel button
  function handleCancel() {
    closeModal();
    resetHandoff();
  }

  // Main form title
  const title = form.form.orderNumber || "New Service";

  // Form footer
  const footer = (
    <div>
      {/* Cancel button */}
      <Button type="alternative" className="mr-2" onClick={handleCancel}>
        {cancelButton}
      </Button>
      {/* Back button */}
      {submitButton === "Submit" && handoffForm.form.isHandoff && (
        <Button type="alternative" className="mr-2" onClick={scrollPrev}>
          Back
        </Button>
      )}
      {/* Main modal button */}
      <Button
        id="submit-service"
        loading={addingService || updatingService}
        disabled={addingService || updatingService || !form.isValid}
        onClick={handleSubmit}
      >
        {submitButton}
      </Button>
    </div>
  );

  const content = (
    <ServiceCreate {...form} type={modalTitle} job={job} client={job?.client} />
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={content}
      size="xl"
      footerPosition="right"
      contentWrapperClass="p-3 lg:px-6 space-y-6"
    />
  );
};
