import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  DatePicker,
  Input,
  Select,
  TimeInput,
  Toggle,
  EditableText,
  Badge,
  Expanded,
} from "../../../../shared";
import { renderLabelRequired } from "../../../../shared/Table/table.utils";
import { getDistance } from "../../../../redux/reducers/map";
import {
  formatDate,
  padInt,
  padTime,
  parseFixed,
  renderAmount,
} from "../../../../utils";
import WaitTime from "./WaitTime";
import EmptyLocationSearch from "./EmptyLoctionSearch";
import { getMilesFromMeters, renderTotalMileage } from "../../jobs.utils";
import { loadTypeOptions } from "../../select.config";
import { useConfig } from "../../../../hooks/useConfig";
import { Metadata } from "./Metadata";

export const LegCreateForm = ({
  remove,
  id,
  formData = {},
  data = {},
  renderId = (id) => id,
  handleChange,
  errors = {},
  renderValue,
  form = {},
  driverOptions = [],
  locationOptions = [],
  setForm,
  addForm,
  formId,
  title,
  jobCreate = false,
  toggleHandoff,
}) => {
  const legId = formId;
  const legForm = formData;
  const dispatch = useDispatch();
  const { configs } = useConfig();

  // Get value
  function getValue(valueId) {
    if (jobCreate) {
      return renderValue(valueId);
    } else {
      return Object.byString(form, valueId);
    }
  }

  function renderAppointmentTime(date, time) {
    if (time) {
      return time;
    }
    const d = new Date(date);
    const hours = padInt(d.getHours());
    const mins = padInt(d.getMinutes());

    if (isNaN(hours)) return null;

    const res = `${hours}:${mins}`;
    return res;
  }

  // Set values as variables
  const isEditing = getValue("edit");
  const orderNumber = getValue("orderNumber");
  const loadType = getValue("loadType");
  const origin = getValue("origin");
  const destination = getValue("destination");
  const driver = getValue("driver.fullName"); //?.fullName || form.driver?.fullName;
  const start = getValue("waitStart");
  const end = getValue("waitEnd");
  const legFuelSurcharge = getValue("fuelSurcharge") || 0;
  const useCustomFuelSurcharge = getValue("useCustomFuelSurcharge");
  const baseCharge = getValue("baseCharge") || 0;
  const useCustomBaseCharge = getValue("useCustomBaseCharge");
  const dropCharge = getValue("dropCharge") || 0;
  const useCustomDropCharge = getValue("useCustomDropCharge");
  const otherCharges = getValue("otherCharges") || 0;
  const useCustomOtherCharges = getValue("useCustomOtherCharges");
  const weight = getValue("weight") || 0;
  const appointmentDate = getValue("appointmentDate");
  const appointmentTime = renderAppointmentTime(
    appointmentDate,
    getValue("appointmentTime")
  );
  const totalMiles = getValue("totalMiles");
  const totalCharges = getValue("totalCharges");
  const hasHandoff = getValue("hasHandoff");
  const isHandoff = getValue("isHandoff");
  const useLegFlatRate = getValue("useLegFlatRate");
  const useGlobalRates = getValue("job.useGlobalRates");
  const useFlatRate = getValue("job.useFlatRate");
  const totalWaitTime = getValue("totalWaitTime");

  // Function to get form leg by id or form
  function renderFormLeg() {
    if (jobCreate) {
      return form.legs[legId];
    }
    return form;
  }

  // Function to set form data
  async function setFormData(newData) {
    let initialData = {
      ...form,
    };

    if (jobCreate) {
      initialData.legs = {
        ...form.legs,
        [legId]: newData,
      };
    } else {
      initialData = { ...newData };
    }

    await setForm(initialData, true);
  }

  function renderRate(rateId, id) {
    if (form.job?.[rateId]) {
      return form.job[rateId];
    }

    let rateBases = { client: form.client?.rates, configs: configs?.rates };
    let rateBase = "client";

    let res = rateBases[rateBase]?.[rateId];

    if (res === undefined || useGlobalRates) {
      rateBase = "configs";
      res = rateBases[rateBase]?.[rateId];
    }

    return res;
  }

  /*
    Render leg baseCharge, dropCharge, fuelSurcharge, dropCharge
    when setting loadType
  */
  useEffect(() => {
    if (loadType) {
      const loadTypeId = loadType?.toLowerCase();
      const loadTypeCharge = renderRate(loadTypeId, "loadType");
      const update = {
        ...renderFormLeg(),
      };

      const dropCharge = useFlatRate ? null : loadTypeCharge || 0;

      if (useLegFlatRate) {
        update[renderId("baseCharge")] = renderDropCharge(dropCharge);
        update[renderId("dropCharge")] = null;
        update[renderId("fuelSurcharge")] = null;
      } else {
        update[renderId("dropCharge")] = renderDropCharge(dropCharge);
      }

      setFormData(update);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadType]);

  /*
    Render leg totalMiles, defaultMileage, customMileage, useCustomMileage, fuelSurcharge, baseCharge
    when setting custom mileage
  */
  function setCustomDistance(miles, doNotSet) {
    const newData = {
      ...renderFormLeg(),
      [renderId("totalMiles")]: miles,
      [renderId("defaultMileage")]: totalMiles,
      [renderId("customMileage")]: miles,
      [renderId("useCustomMileage")]: true,
    };

    if (!useFlatRate && !useLegFlatRate) {
      newData[renderId("fuelSurcharge")] = renderFuelSurcharge(miles);
      newData[renderId("baseCharge")] = renderBaseCharge(miles);
    }

    !doNotSet && setFormData(newData);
    return newData;
  }

  /*
    Render leg total miles, fuelSurcharge, baseCharge
  */
  useEffect(() => {
    if (!form.useCustomMileage && origin && destination) {
      (async () => {
        const res = await dispatch(
          getDistance({
            origin: {
              _id: origin._id,
              address: origin.address.fullAddress,
            },
            destination: {
              _id: destination._id,
              address: destination.address.fullAddress,
            },
          })
        ).unwrap();

        if (res.status === "success") {
          const miles = renderTotalMiles(
            res.data.distance.adjusted ||
              getMilesFromMeters(res.data.distance.value),
            !form.useCustomMileage
          );

          const data = {
            ...renderFormLeg(),
            [renderId("totalMiles")]: miles,
            [renderId("defaultMileage")]: miles,
            [renderId("fullRouteData")]: res.data.setFullRouteData && {
              distance: res.data.distance.value,
              distanceText: res.data.distance.text,
              duration: res.data.duration.value,
              durationText: res.data.duration.text,
            },
          };

          if (!useLegFlatRate || !useFlatRate) {
            data[renderId("fuelSurcharge")] = renderFuelSurcharge(miles);
          }
          if (!useFlatRate) {
            data[renderId("baseCharge")] = renderBaseCharge(miles);
          }

          setFormData(data);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin, destination, form.useCustomMileage, form.useLegFlatRate]);

  /*
    Render leg base charge after leg flat rate change
  */
  useEffect(() => {
    if (form.useLegFlatRate) {
      handleChange({ baseCharge: renderBaseCharge(baseCharge) });
    }
  }, [form.useLegFlatRate]);

  /*
    Render leg total fuelSurcharge
  */
  useEffect(() => {
    const newData = {
      ...renderFormLeg(),
      [renderId("fuelSurcharge")]: renderFuelSurcharge(
        totalMiles,
        renderRate("fuelRate") //form.fuelRate
      ),
    };
    setFormData(newData);
  }, [form.fuelRate]);

  /*
    Render leg total charges
  */
  useEffect(() => {
    const total = renderTotalCharges();
    handleChange({ id: "totalCharges", value: parseFixed(total) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legFuelSurcharge, baseCharge, dropCharge, otherCharges, totalMiles]);

  /*
    Render leg total charges
  */
  function renderTotalCharges() {
    if (useFlatRate || useLegFlatRate) {
      return Number(otherCharges) + Number(baseCharge);
    }

    const total =
      Number(legFuelSurcharge) +
      Number(baseCharge) +
      Number(dropCharge) +
      Number(otherCharges);

    return total;
  }

  /*
    Render leg total miles
  */
  function renderTotalMiles(totalMiles, reset) {
    if (reset) {
      return totalMiles;
    }
    if (form.useCustomMileage) return form.customMileage;
    return totalMiles;
  }

  // console.log(
  //   form.job,
  //   getValue("job.baseRate"),
  //   renderRate("baseRate"),
  //   renderRate("base")
  // );

  /*
    Render leg base charge
  */
  function renderBaseCharge(totalMiles) {
    if (useFlatRate) return 0;
    if (useCustomBaseCharge || useLegFlatRate) return baseCharge;
    const baseRate =
      getValue("job.baseRate") || renderRate("baseRate") || renderRate("base"); // || 0;

    const mileage = totalMiles || 0;
    const res = parseFixed(Number(mileage) * Number(baseRate));
    return res;
  }

  /*
    Render leg fuel charge
  */
  function renderFuelSurcharge(totalMiles, charge) {
    if (useFlatRate || useLegFlatRate) return 0;
    if (useCustomFuelSurcharge) return legFuelSurcharge;
    const fuelRate =
      charge ||
      getValue("job.fuelRate") ||
      renderRate("fuelRate") ||
      renderRate("fuel");
    const mileage = totalMiles || 0;
    return parseFixed(Number(mileage) * Number(fuelRate));
  }

  /*
    Render leg drop charge
  */
  function renderDropCharge(charge) {
    if (useFlatRate) return 0;
    if (useCustomDropCharge) return dropCharge;
    return parseFixed(charge);
  }

  /*
    Render leg other charges
  */
  function renderOtherCharges(waitTime = 0) {
    const waitCalc = waitTime - (configs?.job?.waitTimeOffset || 0);
    const waitCharge = waitCalc <= 0 ? 0 : waitCalc * renderRate("standBy");

    return waitCharge;

    // if (useFlatRate || useLegFlatRate) {
    //   if (useCustomOtherCharges) return otherCharges;
    //   return Number(totalWaitTime) + Number(charge || 0) || 0;
    // }
    // return parseFixed(otherCharges);
  }

  /*
    Handle handoff
  */
  function handleHandoff({ target }) {
    toggleHandoff({ id: target.id, value: target.checked });
    // if (!jobCreate) {
    //   return;
    // }
    // const newKey = "leg_" + (id + 1);
    // if (target.checked) {
    //   const initData = {
    //     [`${newKey}_orderNumber`]: orderNumber,
    //     [`${newKey}_loadType`]: loadType,
    //     [`${newKey}_weight`]: weight,
    //     [`${newKey}_appointmentDate`]: appointmentDate,
    //     [`${newKey}_origin`]: destination,
    //     [`${newKey}_isHandoff`]: true,
    //   };
    //   addForm(initData, { init: true });
    // } else {
    //   remove(newKey);
    // }
  }

  function handleUseLegFlatRate(e) {
    let { id, checked } = e.target;
    let finalData;
    // const oldLeg = renderFormLeg();

    // const newLeg = Object.keys(oldLeg)
    //   .filter((i) => {
    //     if (i.includes("loadType") || i.includes("baseCharge")) {
    //       return false;
    //     }
    //     if (checked) {
    //       if (i.includes("fuelSurcharge") || i.includes("dropCharge")) {
    //         return false;
    //       }
    //     }
    //     return true;
    //   })
    //   .map((i) => ({ [i]: oldLeg[i] }));

    // const finalLeg = Object.assign({}, ...newLeg);
    finalData = {
      ...renderFormLeg(),
      [id]: checked,
      dropCharge: 0,
      loadType: null,
    };

    // Check if value is false and rerender baseCharge
    if (!checked) {
      finalData.baseCharge = renderBaseCharge(totalMiles);
      finalData.fuelSurcharge = renderFuelSurcharge(totalMiles);
    } else {
      if (useCustomBaseCharge) {
        finalData.baseCharge = baseCharge;
      } else {
        finalData.baseCharge = 0;
      }
    }

    setFormData(finalData, true);
  }

  function renderLoadTypeOptions() {
    return loadTypeOptions.filter((item) => {
      if (useLegFlatRate && item.base) {
        return true;
      }
      if (!useLegFlatRate && !item.base) {
        return true;
      }
      return false;
    });
  }

  function routeChange(e) {
    const res = {
      ...form,
      [e.id]: e.value,
      useCustomMileage: false,
      useCustomDropCharge: false,
      useCustomOtherCharges: false,
      useCustomFuelSurcharge: false,
      useCustomBaseCharge: false,
    };
    setFormData(res);
  }

  function handleCustomCharge(e) {
    const res = { ...renderFormLeg(), [e.id]: e.value, [e.type]: true };
    setFormData(res);
  }

  function handleApptDate(e) {
    if (appointmentTime) {
      const time = appointmentTime.split(":");
      e.value.setHours(time[0]);
      e.value.setMinutes(time[1]);
    }

    handleChange(e);
  }

  function handleApptTime(e) {
    const apptDate = new Date(appointmentDate);
    if (apptDate) {
      const time = e.value.split(":");
      apptDate.setHours(padInt(time[0]));
      apptDate.setMinutes(padInt(time[1]));
    }

    handleChange({ id: "appointmentDate", value: apptDate });
  }

  return (
    <div className="mx-1 col-span-2 md:col-span-1 xl:col-span-2">
      <div className="flex items-center justify-between pb-4">
        <div className="flex items-center">
          {(isHandoff || hasHandoff) && (
            <Badge label={isHandoff ? "Handoff" : "Has Handoff"} />
          )}
        </div>
        <div className="flex space-x-2">
          <Toggle
            // disabled={useFlatRate}
            id={renderId("useLegFlatRate")}
            label="Flat Rate"
            size="sm"
            checked={useLegFlatRate}
            onChange={handleUseLegFlatRate}
          />
          {!isHandoff && toggleHandoff && (
            <Toggle
              id={renderId("hasHandoff")}
              label="Handoff"
              size="sm"
              checked={hasHandoff}
              onChange={handleHandoff}
              disabled={isEditing && hasHandoff}
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 xl:grid-cols-6 gap-2">
        <div className="col-span-2 md:col-span-1 xl:col-span-2">
          <Input
            id={renderId("orderNumber")}
            value={orderNumber}
            onChange={handleChange}
            placeholder="Order #"
            label={renderLabelRequired("Order #")}
            error={errors[renderId("orderNumber")]}
          />
        </div>
        <Select
          id={renderId("loadType")}
          value={loadType}
          onChange={handleChange}
          label="Load Type"
          options={renderLoadTypeOptions()}
          placeholder="Select Load Type"
        />
        <Input
          type="number"
          min="0"
          id={renderId("weight")}
          value={weight}
          onChange={handleChange}
          placeholder="Weight"
          label="Weight"
          icon={<div className="text-gray-500 text-sm">lbs</div>}
        />
        <div className="col-span-2 md:col-span-1 xl:col-span-2">
          <Select
            search
            searchKeys={["fullName"]}
            id={renderId("driver")}
            value={driver}
            onChange={handleChange}
            label="Driver"
            options={driverOptions}
            placeholder="Select Driver"
          />
        </div>
        <div className="col-span-2 sm:col-span-1 xl:col-span-2">
          <Select
            search
            searchKeys={["name"]}
            id={renderId("origin")}
            value={origin?.address?.fullAddress}
            onChange={routeChange}
            label="Origin"
            options={locationOptions}
            placeholder="Select Origin"
            emptyLabel={(e) => (
              <EmptyLocationSearch clientId={form.client?._id} text={e} />
            )}
          />
        </div>
        <div className="col-span-2 sm:col-span-1 xl:col-span-2">
          <Select
            search
            searchKeys={["name"]}
            id={renderId("destination")}
            value={destination?.address?.fullAddress}
            onChange={routeChange}
            label="Destination"
            options={locationOptions}
            placeholder="Select Destination"
            emptyLabel={(e) => (
              <EmptyLocationSearch clientId={form.client?._id} text={e} />
            )}
          />
        </div>
        <DatePicker
          id={renderId("appointmentDate")}
          value={formatDate(appointmentDate, null, true)}
          placeholder="Date"
          label="Appointment Date"
          onChange={handleApptDate}
        />
        <TimeInput
          id={renderId("appointmentTime")}
          value={appointmentTime}
          placeholder="Time"
          label="Appointment Time"
          onChange={handleApptTime}
        />
        <div className="col-span-2 xl:col-span-6">
          <WaitTime
            start={start}
            end={end}
            onFormChange={handleChange}
            legId={legId}
            legForm={legForm}
            form={form}
            renderId={renderId}
            setForm={setFormData}
            renderValue={getValue}
            renderRate={renderRate}
            renderFormLeg={renderFormLeg}
            handleChange={handleChange}
            renderOtherCharges={renderOtherCharges}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-3">
        <CalculationsLayout
          label="Total Mileage"
          value={renderTotalMiles(totalMiles, true)}
          editable={true}
          id="totalMiles"
          onSave={(saveData) => setCustomDistance(Number(saveData.value))}
          displayText={(v) => renderTotalMileage(v)}
        />
        {!useFlatRate && (
          <CalculationsLayout
            label="Base Charge"
            value={baseCharge}
            editable={true}
            id="baseCharge"
            onSave={(saveData) =>
              handleCustomCharge({
                id: renderId("baseCharge"),
                value: Number(saveData.value),
                type: "useCustomBaseCharge",
              })
            }
            displayText={(v) => renderAmount(v)}
          />
        )}
        {!useLegFlatRate && (
          <>
            <CalculationsLayout
              label="Fuel Surcharge"
              value={legFuelSurcharge}
              editable={true}
              id="fuelSurcharge"
              onSave={(saveData) =>
                handleCustomCharge({
                  id: renderId("fuelSurcharge"),
                  value: Number(saveData.value),
                  type: "useCustomFuelSurcharge",
                })
              }
              displayText={(v) => renderAmount(v)}
            />
            <CalculationsLayout
              label="Drop Charges"
              value={dropCharge}
              editable={true}
              id="dropCharge"
              onSave={(saveData) =>
                handleCustomCharge({
                  id: renderId("dropCharge"),
                  value: Number(saveData.value),
                  type: "useCustomDropCharge",
                })
              }
              displayText={(v) => renderAmount(v)}
            />
          </>
        )}
        <CalculationsLayout
          label="Other Charges"
          value={otherCharges}
          editable={true}
          id="otherCharges"
          onSave={(saveData) =>
            handleChange({
              id: renderId("otherCharges"),
              value: Number(saveData.value),
              type: "useCustomOtherCharges",
            })
          }
          displayText={(v) => renderAmount(v)}
        />
        <CalculationsLayout
          label="Total Charges"
          value={renderAmount(totalCharges)}
        />
      </div>
      {/* <Metadata metadata={{}} /> */}
    </div>
  );
};

export const CalculationsLayout = ({
  label,
  value,
  editable,
  id,
  displayText,
  onSave,
  size,
}) => {
  return (
    <div className="flex flex-col">
      <span className="block mb-2 text-sm font-medium">{label}</span>
      {!editable ? (
        <span className="font-semibold text-xl">{value}</span>
      ) : (
        <EditableText
          className="ml-1"
          iconClasses="h-3 w-3"
          id={id}
          value={value}
          size={size}
          displayText={(v) => (
            <span className="font-semibold text-xl">{displayText(v)}</span>
          )}
          onSave={onSave}
          type="number"
        />
      )}
    </div>
  );
};
