import { useState, useMemo, useEffect, useCallback } from "react";
import { FolderPlusIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { SettingsPanel, Button, Avatar, Spinner, Select } from "../../shared";
// import UserAddModal from "./UserAddModal";
import { capitalizeFirstLetter, formatAddress, formatDate } from "../../utils";
// import UserExistsModal from "./UserExistsModal";
// import UserActivateModal from "./UserActivateModal";
// import UserDe/leteModal from "./UserDeleteModal";
import useForm from "../../hooks/useForm";
import { Link } from "react-router-dom";
import {
  useUpdateUserMutation,
  useAddUserMutation,
  useDeleteUserMutation,
} from "../../redux/services/users/usersApi";
import { toast } from "react-toastify";
import { sendEmail } from "../../redux/reducers/users/userSlice";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { TrashIcon } from "@heroicons/react/24/outline";
import Dropdown from "../../shared/Dropdown";
import ButtonDropdown from "../../shared/ButtonDropdown";
import { isAuthorized } from "../../utils/authority";
// import NewDispatchModal from "./newDispatchModal";
import NewDispatchModal from "../../pages/Dispatch/newDispatchModal";

import { useUpdateLegMutation } from "../../redux/services/legs/legsApi";

const DispatchLayout = ({
  data = [],
  drivers = [],
  title = "Unassigned Drops",
  subtitle,
  icon = (
    <FolderPlusIcon className="w-12 h-12 mx-auto text-gray-400 stroke-1" />
  ),
  selectedDate,
  onDataUpdate,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [assignedDrivers, setAssignedDrivers] = useState({});

  const { user } = useAuth();
  const formHook = useForm();
  const dispatch = useDispatch();

  const [updateLeg, { isLoading: updatingLeg }] = useUpdateLegMutation();

  const [addModalVisible, setAddModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const allDrivers = useMemo(() => {
    if (Array.isArray(drivers)) {
      return drivers;
    } else if (
      drivers &&
      typeof drivers === "object" &&
      Array.isArray(drivers.drivers)
    ) {
      return drivers.drivers;
    } else {
      console.error("Unexpected drivers prop format:", drivers);
      return [];
    }
  }, [drivers]);

  // useEffect(() => {}, [allDrivers]);

  const availableDrivers = useMemo(() => {
    const assignedDriverIds = new Set(
      data
        .filter(
          (item) =>
            item.driver &&
            new Date(item.appointmentDate).toDateString() ===
              selectedDate.toDateString()
        )
        .map((item) => item.driver._id)
    );

    return allDrivers.filter((driver) => !assignedDriverIds.has(driver._id));
  }, [allDrivers, data, selectedDate]);

  // console.log("allDrivers", allDrivers);
  // console.log("availableDrivers", availableDrivers);

  const transformedData = useMemo(() => {
    return data
      .filter((item) => !item.driver)
      .map((item) => ({
        id: item._id,
        orderNumber: item.orderNumber,
        jobId: item.jobId,
        driver: item.driver?.id || "",
        origin: item.route?.origin?.name || "N/A",
        destination: item.route?.destination?.name || "N/A",
        startTime: item.appointmentDate,
        endTime: item.endDate,
        mileage: item.route?.mileage,
        duration: item.route?.fullData?.duration
          ? item.route.fullData.duration / 60
          : null,
        originAddress: item.route?.origin?.address?.fullAddress,
        destinationAddress: item.route?.destination?.address?.fullAddress,
      }));
  }, [data]);

  const onDriverAssign = useCallback(
    async (leg, driverId) => {
      setAssignedDrivers((prev) => ({
        ...prev,
        [leg.id]: driverId
          ? allDrivers.find((d) => d._id === driverId.value)?.fullName ||
            "Unassigned"
          : "Unassigned",
      }));
      try {
        // Call the updateLeg function with the original leg data
        const result = await updateLeg({
          legId: leg.id,
          data: { driver: driverId?.value || null },
        }).unwrap();

        toast.success("Driver assignment updated successfully");

        if (onDataUpdate) {
          onDataUpdate(result);
        }
      } catch (error) {
        console.error("Failed to update driver assignment:", error);
        toast.error("Failed to update driver assignment");
      }
    },
    [allDrivers, updateLeg, onDataUpdate]
  );

  const dataKeys = Object.keys(data);
  const dataEmpty = dataKeys.length > 0;
  const isLoading = loading || props.loading;
  const emptyData = {
    content: isLoading ? (
      <div className="p-5">
        <Spinner />
      </div>
    ) : (
      <div className="py-5">
        <div className="text-center">
          {icon}
          <h3 className="mt-2 font-semibold text-sm">No Drops found</h3>
          <p className="text-sm text-gray-500"></p>
          {/* <div className="mt-3">{renderButton("xs", "Invite User")}</div> */}
        </div>
      </div>
    ),
  };

  const dataMap = data.users || [];

  async function email(userId, type, options) {
    const email = await dispatch(
      sendEmail({ userId, type, ...options })
    ).unwrap();

    toast(email.message, {
      type: email.status,
      toastId: "user-email-sent-" + userId,
    });
  }

  function itemLayout(item, i) {
    const isOwner = item.accountHolder;
    const isSelf = item.email === user.email;

    const actions = [
      {
        id: "activate",
        title: item.active ? "Deactivate" : "Send Activate Link",
        remove: isSelf || !isAuthorized("owner, admin"),
        onClick: () =>
          item.active
            ? handleActivateModal(item._id, "deactivate")
            : handleActivateModal(item._id, "activate"),
      },
      {
        id: "delete",
        remove: !isAuthorized("owner, admin"),
        title: "Delete",
        onClick: () => handleDeleteUserModal(item._id),
      },
    ].filter((i) => {
      if (i.remove) {
        return false;
      }
      return true;
    });
    const isLoading = loading || props.loading;

    const driverOptions = [
      { value: "", label: "Unassigned" },
      ...(availableDrivers.length > 0
        ? availableDrivers.map((driver) => ({
            value: driver._id,
            label: driver.fullName || `${driver.firstName} ${driver.lastName}`,
          }))
        : [{ value: "no-drivers", label: "No drivers available" }]),
    ];

    return (
      <div
        key={i}
        className="grid grid-cols-8 gap-4 items-center p-3 bg-white rounded-b-xl"
      >
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${item.jobId}`}
        >
          {item.orderNumber || "N/A"}
        </Link>
        <div className="space-y-1">
          <div>{item.origin || "N/A"}</div>
          <div className="text-sm text-gray-500">
            {formatAddress(item.originAddress)}
          </div>
        </div>
        <div className="space-y-1">
          <div>{item.destination || "N/A"}</div>
          <div className="text-sm text-gray-500">
            {formatAddress(item.destinationAddress)}
          </div>
        </div>
        <div className="col-span-2">
          <Select
            value={
              item.driver
                ? { value: item.driver._id, label: item.driver.fullName }
                : ""
            }
            onChange={(value) => onDriverAssign(item, value)}
            options={driverOptions}
            placeholder="Select a driver"
          />
        </div>
        <div className="text-center">
          {item.duration !== null && item.duration !== undefined
            ? item.duration >= 60
              ? `${Math.floor(item.duration / 60)} hr${
                  Math.floor(item.duration / 60) > 1 ? "s" : ""
                } ${Math.round(item.duration % 60)} min`
              : `${Math.round(item.duration)} min`
            : "N/A"}
        </div>
        <div className="text-center">
          {item.mileage ? `${item.mileage} mi` : "N/A"}
        </div>
        <div className="text-center">
          {formatDate(item.startTime, false, false, true)}
        </div>
      </div>
    );
  }

  const actions = (
    <Button
      onClick={() => setAddModalVisible(true)}
      size="xs"
      icon={<EnvelopeIcon className="w-4 h-4 mr-2" />}
    >
      Create Dispatch
    </Button>
  );

  const isEmpty = dataMap.length === 0;
  const settingData = isEmpty ? [] : dataMap;

  return (
    <>
      <div className="space-y-4">
        <SettingsPanel
          itemLayout={itemLayout}
          title={title}
          empty={emptyData}
          subtitle={subtitle}
          data={transformedData}
          actions={actions}
        />
      </div>
      <NewDispatchModal
        form={formHook}
        visible={addModalVisible}
        setVisible={setAddModalVisible}
        submit={() => {
          // Add your submit logic here
          setAddModalVisible(false);
        }}
        loading={loading}
      />
    </>
  );
};

export default DispatchLayout;
