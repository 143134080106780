import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import React from "react";
import "./index.css";

const THead = ({ table, ...props }) => {
  const HeadWrapper = props.useDivs || !props.useStyles ? "div" : "thead";
  const TableRow = props.useDivs || !props.useStyles ? "div" : "tr";
  const TableHeader = props.useDivs || !props.useStyles ? "div" : "th";

  return (
    <HeadWrapper>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const isCheckbox =
              !props.hideCheckbox && header.column.id === "select";

            const thClasses = props.tableHeadClasses
              ? props.tableHeadClasses
              : classNames(
                  props.thClasses,
                  "text-xs font-medium",
                  "leading-[0] text-left text-gray-500",
                  "uppercase border-b border-gray-200 bg-gray-50",
                  {
                    "border-r p-2": isCheckbox,
                    "relative px-2 py-2 h-3": !isCheckbox,
                  }
                );

            const resizerClasses = classNames(
              "resizer bg-gray-300 opacity-0 hover:opacity-100 hover:bg-gray-300 w-[2px]"
            );

            const wrapClasses = classNames(
              "flex flex-row items-center whitespace-nowrap",
              {
                "justify-center": isCheckbox,
                "cursor-pointer select-none": header.column.getCanSort(),
              }
            );

            const headerSize = header.getSize();

            return (
              <TableHeader
                {...{
                  key: header.id,
                  colSpan: header.colSpan,
                  className: thClasses,
                  style:
                    props.useTableSizes || !props.useStyles
                      ? {
                          width: !isCheckbox && headerSize,
                        }
                      : {},
                }}
              >
                {header.isPlaceholder ? null : (
                  <span
                    className={wrapClasses}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {!isCheckbox && (
                      <span className="h-3 w-3">
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === "asc" ? (
                            <ChevronDownIcon className="h-3 w-3 stroke-[4]" />
                          ) : (
                            <ChevronUpIcon className="h-3 w-3 stroke-[4]" />
                          )
                        ) : null}
                      </span>
                    )}
                    {/* <div
                      {...{
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: resizerClasses,
                      }}
                    /> */}
                  </span>
                )}
              </TableHeader>
            );
          })}
        </TableRow>
      ))}
    </HeadWrapper>
  );
};

export default THead;
