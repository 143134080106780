import {
  CheckCircleIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState, useRef } from "react";
import Input from "../Input";
import classNames from "classnames";
import Spinner from "../Spinner";
import Select from "../Select";
import DatePicker from "../DatePicker";
import { formatDate } from "../../utils";

export const EditableText = (props) => {
  const [hovered, setHovered] = useState(false);
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(props.value);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const size = props.size || "sm";

  const onChange = (e) => {
    const newValue = e.value;
    if (props.type === "date") {
      return setValue(formatDate(newValue, null, true));
    }
    props.onChange && props.onChange({ ...e, id: props.id });
    setValue(newValue);
  };

  const save = () => {
    const saveData = {
      // row: props.row,
      // column,
      id: props.id,
      value,
      data: { [props.id]: value },
    };
    setHovered(false);
    // setLoading(true);
    props.onSave(saveData, setLoading);
    setEditing(false);
    // setTimeout(() => setLoading(false), 400);
  };

  const cancel = () => {
    setEditing(false);
    setHovered(false);
  };

  function setEdit() {
    setValue(props.value);
    setEditing(true);
    setHovered(false);
  }

  useEffect(() => {
    setValue(value);
  }, [value]);

  const actions =
    !props.hideActions &&
    (props.actions ? (
      props.actions
    ) : (
      <span className="flex items-center ml-1 text-primary-600">
        <CheckCircleIcon
          onClick={(e) => save(e)}
          className="h-5 w-5 cursor-pointer stroke-primary-600"
        />
        <XMarkIcon
          onClick={(e) => cancel(e)}
          className="h-5 w-5 cursor-pointer stroke-primary-600"
        />
      </span>
    ));

  const editText = (!props.type || props.type === "text") && (
    <>
      <span className="w-full">
        <Input
          size={size}
          icon={props.icon}
          value={value}
          ref={inputRef}
          onChange={onChange}
        />
      </span>
      {actions}
    </>
  );

  const editSelect = props.type === "select" && (
    <>
      <span className="w-full">
        <Select
          size={size}
          value={value}
          onChange={onChange}
          options={props.options}
        />
      </span>
      {actions}
    </>
  );

  const editNumber = props.type === "number" && (
    <>
      <span className="w-full">
        <Input
          type="number"
          icon={props.icon}
          size={size}
          value={value}
          onChange={onChange}
        />
      </span>
      {actions}
    </>
  );

  const editDate = props.type === "date" && (
    <>
      <span className="w-full">
        <DatePicker size={size} value={value} onChange={onChange} />
      </span>
      {actions}
    </>
  );

  const editCustom =
    props.type === "custom" &&
    (() => {
      const Layout = props.layout;
      return (
        <>
          <span className="w-full">
            <Layout {...props} value={value} onChange={onChange} />
          </span>
          {actions}
        </>
      );
    })();

  function renderEditType(type) {
    switch (type) {
      case "custom":
        return editCustom;
      case "date":
        return editDate;
      case "number":
        return editNumber;
      case "select":
        return editSelect;
      default:
        return editText;
    }
  }

  const wrapperClasses = classNames("flex items-center", props.className);
  const iconClasses = classNames(props.iconClasses);
  const textClasses = classNames(props.textClasses);

  if (inputRef.current && (props.editing || editing)) {
    inputRef.current.focus();
  }
  return props.loading ? (
    <div className={wrapperClasses}>
      <Spinner size="sm" />
    </div>
  ) : (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={wrapperClasses}
    >
      <div className="text-sm font-medium text-gray-900 w-full">
        {props.editing || editing ? (
          <div className="flex items-center w-full">
            {renderEditType(props.type)}
          </div>
        ) : (
          <div className="flex items-center w-full space-x-1">
            <div className={textClasses}>
              {(props.displayText && props.displayText(props.value)) ||
                props.children ||
                props.value}
            </div>
            {!props.hidePencilIcon && (
              <div className={iconClasses}>
                {!props.disabled && (!props.value || hovered) && (
                  <PencilIcon
                    onClick={setEdit}
                    className="h-3 w-3 text-primary-600 hover:text-primary-700 cursor-pointer"
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditableText;
