import { isObject } from "../../../utils";
import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

export const dispatchApi = api.injectEndpoints({
  tagTypes: ["Dispatch"],
  endpoints: (builder) => ({
    getDispatchList: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = new URLSearchParams({
          page,
          limit,
          ...rest,
        });
        return `dispatch?${query}`;
      },
      transformResponse: (response) => response.items,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Dispatch", id: _id })),
              { type: "Dispatch", id: "LIST" },
            ]
          : [{ type: "Dispatch", id: "LIST" }],
    }),

    updateDispatch: builder.mutation({
      query: ({ dispatchId, data, queryParams }) => {
        const params = getSearchParams(queryParams);
        return {
          url: `/dispatch/${dispatchId}?${params}`,
          method: "PUT",
          body: data,
        };
      },
      async onQueryStarted(
        { dispatchId, data, queryParams, refetch },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: updatedDispatch } = await queryFulfilled;

          // Invalidate the specific dispatch item
          dispatch(
            dispatchApi.util.invalidateTags([
              { type: "Dispatch", id: dispatchId },
            ])
          );

          // If refetch is true, invalidate the entire list
          if (refetch) {
            dispatch(
              dispatchApi.util.invalidateTags([
                { type: "Dispatch", id: "LIST" },
              ])
            );
          }
        } catch {
          // Error handling if needed
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetDispatchListQuery, useUpdateDispatchMutation } =
  dispatchApi;
