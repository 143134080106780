import React, { useState, useEffect } from "react";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import "./style.css";

export const Calendar = (props) => {
  const [value, setValue] = useState(props.value || new Date());

  useEffect(() => {
    props.value && setValue(props.value);
  }, [props.value]);

  function handleChange(e) {
    props.onChange && props.onChange(e);
    setValue(e);
  }

  return (
    <div className="w-auto">
      <ReactCalendar
        onChange={handleChange}
        calendarType="gregory"
        value={value}
        maxDetail="month"
        minDetail="year"
      />
    </div>
  );
};

export default Calendar;
