export const dateStage = (selector, start, end, type, parentCompany) => {
  const sortingDate = {
    year: { $year: `$${selector}` },
    month: { $month: `$${selector}` },
  };
  let sortKey = "_id.day";
  let format = "%m-%d-%Y";
  if (type === "yearly") {
    format = "%m-%Y";
    sortKey = "_id.year";
  }

  if (type === "monthly") {
    sortingDate.day = { $dayOfMonth: `$${selector}` };
    sortKey = "_id.month";
  }

  return [
    {
      $match: {
        parentCompany,
        [selector]: {
          $gte: start,
          $lte: end,
        },
      },
    },
    {
      $group: {
        _id: {
          ...sortingDate,
          date: {
            $dateToString: { format, date: `$${selector}` },
          },
          status: "$status",
        },
        count: { $sum: 1 },
      },
    },
    {
      $group: {
        _id: "$_id.date",
        name: { $first: "$_id.date" },
        data: { $push: { k: "$_id.status", v: "$count" } },
      },
    },
    { $sort: { name: 1 } },
    {
      $project: {
        name: "$_id",
        countTotal: {
          $sum: "$data.v",
        },
        data: {
          $arrayToObject: {
            $map: {
              input: "$data",
              as: "el",
              in: { k: "$$el.k", v: "$$el.v" },
            },
          },
        },
      },
    },
    {
      $replaceRoot: {
        newRoot: {
          $mergeObjects: [
            {
              New: 0,
              Pending: 0,
              "En Route": 0,
              Delivered: 0,
              Invoiced: 0,
              Name: "$name",
              Total: "$countTotal",
            },
            "$data",
          ],
        },
      },
    },
  ];
};

export const invoiceStage = (selector, start, end, type, parentCompany) => {
  const sortingDate = {
    year: { $year: `$${selector}` },
    month: { $month: `$${selector}` },
  };
  let sortKey = "_id.day";
  let format = "%m-%d-%Y";
  if (type === "yearly") {
    format = "%Y-%m";
    sortKey = "_id.year";
  }

  if (type === "monthly") {
    sortingDate.day = { $dayOfMonth: `$${selector}` };
    sortKey = "_id.month";
  }

  return [
    {
      $match: {
        parentCompany,
        [selector]: {
          $gte: start,
          $lte: end,
        },
      },
    },
    {
      $group: {
        _id: {
          date: {
            $dateToString: { format, date: `$${selector}` },
          },
          ...sortingDate,
          status: "$status",
        },
        count: { $sum: 1 },
      },
    },
    {
      $group: {
        _id: "$_id.status",
        name: { $first: "$_id.status" },
        data: { $push: { k: "$_id.status", v: "$count" } },
      },
    },
    { $sort: { name: 1 } },
    {
      $project: {
        name: "$_id",
        countTotal: {
          $sum: "$data.v",
        },
        data: {
          $arrayToObject: {
            $map: {
              input: "$data",
              as: "el",
              in: { k: "$$el.k", v: "$$el.v" },
            },
          },
        },
      },
    },
    {
      $replaceRoot: {
        newRoot: {
          $mergeObjects: [
            {
              Review: 0,
              Sent: 0,
              Paid: 0,
              Name: "$name",
              Total: "$countTotal",
            },
            "$data",
          ],
        },
      },
    },
  ];
};

export const clientJobsStage = (selector, parentCompany, client) => {
  const sortingDate = {
    year: { $year: `$${selector}` },
    month: { $month: `$${selector}` },
  };
  // let sortKey = "_id.day";
  let format = "%Y-%m";
  // if (type === "yearly") {
  //   format = "%Y-%m";
  //   sortKey = "_id.month";
  // }

  // if (type === "monthly") {
  //   sortingDate.day = { $dayOfMonth: `$${selector}` };
  // }

  return [
    {
      $match: {
        // parentCompany,
        client,

        // [selector]: {
        //   $gte: start,
        //   $lte: end,
        // },
      },
    },
    {
      $group: {
        _id: {
          date: {
            $dateToString: { format, date: `$${selector}` },
          },
          ...sortingDate,
          status: "$status",
        },
        count: { $sum: 1 },
      },
    },
    {
      $group: {
        _id: "$_id.date",
        name: { $first: "$_id.date" },
        data: { $push: { k: "$_id.status", v: "$count" } },
      },
    },
    { $sort: { name: 1 } },
    {
      $project: {
        name: "$_id",
        countTotal: {
          $sum: "$data.v",
        },
        data: {
          $arrayToObject: {
            $map: {
              input: "$data",
              as: "el",
              in: { k: "$$el.k", v: "$$el.v" },
            },
          },
        },
      },
    },
    {
      $replaceRoot: {
        newRoot: {
          $mergeObjects: [
            {
              New: 0,
              Pending: 0,
              "En Route": 0,
              Delivered: 0,
              Invoiced: 0,
              Name: "$name",
              Total: "$countTotal",
            },
            "$data",
          ],
        },
      },
    },
  ];
};

export const driverJobsStage = (selector, parentCompany, driver) => {
  const sortingDate = {
    year: { $year: `$${selector}` },
    month: { $month: `$${selector}` },
  };
  // let sortKey = "_id.day";
  let format = "%Y-%m";
  // if (type === "yearly") {
  //   format = "%Y-%m";
  //   sortKey = "_id.month";
  // }

  // if (type === "monthly") {
  //   sortingDate.day = { $dayOfMonth: `$${selector}` };
  // }

  return [
    {
      $match: {
        // parentCompany,
        driver,

        // [selector]: {
        //   $gte: start,
        //   $lte: end,
        // },
      },
    },
    {
      $group: {
        _id: {
          date: {
            $dateToString: { format, date: `$${selector}` },
          },
          ...sortingDate,
          status: "$status",
        },
        count: { $sum: 1 },
      },
    },
    {
      $group: {
        _id: "$_id.date",
        name: { $first: "$_id.date" },
        data: { $push: { k: "$_id.status", v: "$count" } },
      },
    },
    { $sort: { name: 1 } },
    {
      $project: {
        name: "$_id",
        countTotal: {
          $sum: "$data.v",
        },
        data: {
          $arrayToObject: {
            $map: {
              input: "$data",
              as: "el",
              in: { k: "$$el.k", v: "$$el.v" },
            },
          },
        },
      },
    },
    {
      $replaceRoot: {
        newRoot: {
          $mergeObjects: [
            {
              New: 0,
              Pending: 0,
              "En Route": 0,
              Delivered: 0,
              Invoiced: 0,
              Name: "$name",
              Total: "$countTotal",
            },
            "$data",
          ],
        },
      },
    },
  ];
};

export const totalStage = (selector, start, end, type, parentCompany) => {};
//   const sortingDate = {
//     year: { $year: `$${selector}` },
//     month: { $month: `$${selector}` },
//   };
//   let sortKey = "_id.day";
//   let format = "%Y-%m-%d";
//   if (type === "yearly") {
//     format = "%Y-%m";
//     sortKey = "_id.month";
//   }

//   if (type === "monthly") {
//     sortingDate.day = { $dayOfMonth: `$${selector}` };
//   }

//   return [
//     {
//       $match: {
//         // parentCompany,
//         [selector]: {
//           $gte: start,
//           $lte: end,
//         },
//       },
//     },
//     {
//       $group: {
//         _id: {
//           date: {
//             $dateToString: { format, date: `$${selector}` },
//           },
//           ...sortingDate,
//           status: "$status",
//         },
//         count: { $sum: 1 },
//       },
//     },
//     {
//       $group: {
//         _id: "$_id.date",
//         // name: { $first: "$_id.date" },
//         total: {
//           $sum: { $sum: "$count" },
//           // data: { $push: { $sum: "$count" } },
//         },
//       },
//     },
//     { $sort: { name: 1 } },
//     {
//       $project: {
//         name: "$_id",
//         total: "$total",
//       },
//     },
//     data: {
//       $arrayToObject: {
//         $map: {
//           input: "$data",
//           as: "el",
//           in: { k: "$$el.k", v: "$$el.v" },
//         },
//       },
//     },
//   },
// },
// {
//   $replaceRoot: {
//     newRoot: { $mergeObjects: [{ name: "$name" }, "$data"] },
//   },
// },
//   ];
// };
