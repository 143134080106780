import React from "react";
import Form from "../../../shared/Form";
import Input from "../../../shared/Input";

const CompanyRates = ({ onFormChange, form }) => {
  const icon = {
    left: "$",
  };
  return (
    <Form className="grid grid-cols-1 gap-1 lg:grid-cols-6 lg:gap-3">
      <div>
        <Input
          type="number"
          min={0}
          id="regular"
          value={form.regular}
          label="Regular"
          placeholder="Regular"
          onChange={onFormChange}
          icon={icon}
        />
      </div>
      <div>
        <Input
          type="number"
          min={0}
          id="hot"
          value={form.hot}
          label="Hot"
          placeholder="Hot"
          onChange={onFormChange}
          icon={icon}
        />
      </div>
      <div>
        <Input
          type="number"
          min={0}
          id="layover"
          value={form.layover}
          label="Layover"
          placeholder="Layover"
          onChange={onFormChange}
          icon={icon}
        />
      </div>
      <div>
        <Input
          type="number"
          min={0}
          id="deadhead"
          value={form.deadhead}
          label="Deadhead"
          placeholder="Deadhead"
          onChange={onFormChange}
          icon={icon}
        />
      </div>
      <div>
        <Input
          type="number"
          min={0}
          id="reroute"
          value={form.reroute}
          label="Reroute"
          placeholder="Reroute"
          onChange={onFormChange}
          icon={icon}
        />
      </div>
      <div>
        <Input
          type="number"
          min={0}
          id="return"
          value={form.return}
          label="Return"
          placeholder="Return"
          onChange={onFormChange}
          icon={icon}
        />
      </div>
      <div>
        <Input
          type="number"
          min={0}
          id="internet"
          value={form.internet}
          label="Internet"
          placeholder="Internet"
          onChange={onFormChange}
          icon={icon}
        />
      </div>
      <div>
        <Input
          type="number"
          min={0}
          id="backhaul"
          value={form.backhaul}
          label="Backhaul"
          placeholder="Backhaul"
          onChange={onFormChange}
          icon={icon}
        />
      </div>
      <div>
        <Input
          type="number"
          min={0}
          id="standBy"
          value={form.standBy}
          label="Stand By"
          placeholder="Stand By"
          onChange={onFormChange}
          icon={icon}
        />
      </div>
      <div>
        <Input
          type="number"
          min={0}
          id="baseRate"
          value={form.baseRate}
          label="Base Rate"
          placeholder="Base Rate"
          onChange={onFormChange}
          icon={icon}
        />
      </div>
      <div>
        <Input
          type="number"
          min={0}
          id="fuel"
          value={form.fuel}
          label="Fuel Surcharge"
          placeholder="Fuel Surcharge"
          onChange={onFormChange}
          icon={icon}
        />
      </div>
    </Form>
  );
};

export default CompanyRates;
