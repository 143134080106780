import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import classNames from "classnames";

export function Toggle(props) {
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    setToggled(props.checked || false);
  }, [props.checked]);

  const sizeLg = props.size === "lg";
  const sizeMd = props.size === "md" || !props.size;
  const sizeSm = props.size === "sm";
  const sizeXs = props.size === "xs";

  const buttonWrapperClasses = classNames(
    "relative inline-flex cursor-pointer ring-1 ring-gray-200",
    "shrink-0 bg-gray-100",
    "rounded-full border-2 border-transparent",
    "transition-colors duration-200 ease-in-out",
    "focus:outline-none focus-visible:ring-2",
    "focus-visible:ring-white focus-visible:ring-opacity-75",
    { "!bg-primary-600": toggled, "opacity-60 cursor-auto": props.disabled },
    {
      "w-7 h-4": sizeXs,
      "w-9 h-5": sizeSm,
      "w-11 h-6": sizeMd,
      "w-14 h-7": sizeLg,
    }
  );
  const buttonClasses = classNames(
    { "translate-x-full": toggled },
    {
      "w-3 h-3": sizeXs,
      "w-4 h-4": sizeSm,
      "w-5 h-5 ": sizeMd,
      "w-6 h-6 ": sizeLg,
    },
    "pointer-events-none inline-block shadow",
    "transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
  );
  const labelClasses = classNames("mx-2", {
    "text-sm": sizeXs,
    "text-md": sizeSm,
    "text-lg": sizeMd,
    "text-xl ": sizeLg,
    "text-gray-500": props.disabled,
  });

  function handleChange(e) {
    if (props.disabled) return null;
    setToggled(e);
    props.onChange &&
      props.onChange({ target: { id: props.id, checked: !toggled } });
    props.setToggled &&
      props.setToggled({ target: { id: props.id, checked: !toggled } });
  }

  return (
    <div className="flex items-center">
      {/* <div className="flex items-center pointer-events-none transform rounded-full ring-0 border border-gray-200"> */}
      <Switch
        checked={toggled}
        onChange={handleChange}
        className={buttonWrapperClasses}
      >
        <span className="sr-only">Use setting</span>
        <span aria-hidden="true" className={buttonClasses} />
      </Switch>
      {/* </div> */}
      {props.label && <span className={labelClasses}>{props.label}</span>}
    </div>
  );
}
export default Toggle;
