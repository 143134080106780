import { XMarkIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../shared/Button";
import ButtonDropdown from "../../../shared/ButtonDropdown";
import Card from "../../../shared/Card";
import MultiSelect from "../../../shared/MultiSelect";
import Select from "../../../shared/Select";
import Table from "../../../shared/Table";
import { useAuth } from "../../../hooks/useAuth";
import {
  driverSelectOptions,
  fetchDrivers,
} from "../../../redux/reducers/drivers/driversSlice";
import { updateOrganization } from "../../../redux/reducers/organizations/organizationSlice";
import { COLUMN_DEFINITIONS } from "../../Drivers/driver.table.config";
import DriverCreateModal from "./DriverCreateModal";

const Drivers = (props) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [drivers, setDrivers] = useState(props.data);

  const [selectedItems, setSelectedItems] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    setDrivers(props.data);
  }, [props.data.length]);

  const driversStatus = useSelector((state) => state.drivers.status);
  const driverOptions = useSelector(driverSelectOptions);

  useEffect(() => {
    if (driversStatus === "idle") {
      dispatch(fetchDrivers({ parent: user.parentCompany, notInOrg: true }));
    }
  }, [driversStatus]);

  function handleClick() {
    setVisible(true);
  }

  const handleRowSelection = useCallback(
    (value) => setSelectedItems(value),
    []
  );

  function handleRemoveUser(items) {
    const updateData = _.difference(drivers, items);
    props.update({ employees: updateData });
  }

  const actionOptions = [
    {
      title: "Remove",
      onClick: () => handleRemoveUser(selectedItems), //
    },
  ];

  const actions = (
    <div className="flex items-center">
      <ButtonDropdown
        className="mr-2"
        type="alternative"
        data={actionOptions}
        disabled={selectedItems.length === 0}
      >
        Actions
      </ButtonDropdown>
      <Button className="w-full" title="Add Driver" onClick={handleClick} />
    </div>
  );

  const selectOptions = _.differenceBy(
    driverOptions.filter((i) => !i.organization),
    props.data.map((i) => ({
      label: i.fullName,
    })),
    "label"
  );

  const modalContent = (
    <MultiSelect
      id="employees"
      value={employees}
      label="Employees"
      placeholder="Employees"
      onChange={(e) => setEmployees(e)}
      options={selectOptions}
    >
      <SelectedOptions />
    </MultiSelect>
  );

  function handleDriverCreateSubmit(e, skipped) {
    const newUser = e.data || e.user;
    let newEmployees = [];
    if (!skipped) {
      newEmployees.push(newUser);
      setDrivers([...drivers, newUser]);
    }

    props.update({
      employees: [
        ...new Set([...employees.map((i) => i.value), ...props.data]),
      ],
    });
  }

  return (
    <div className="col-span col-span-8 xl:col-span-6">
      <Table
        scrollable
        actions={actions}
        id="jobs"
        title="Drivers"
        loading={props.loading}
        columns={COLUMN_DEFINITIONS}
        data={drivers}
        onSelectedRowsChange={handleRowSelection}
      />
      <DriverCreateModal
        onSubmit={handleDriverCreateSubmit}
        visible={visible}
        setVisible={setVisible}
        hideOrg
        content={modalContent}
        toggle={true}
      >
        <DriverModalSet
          init={{
            hourlyRate: Number(props.org.meta?.hourlyRate),
            mileageRate: Number(props.org.meta?.mileageRate),
            organization: props.org?._id,
          }}
        />
      </DriverCreateModal>
    </div>
  );
};

const SelectedOptions = ({ selected, onChange }) => {
  return (
    <div className="grid grid-cols-4 gap-3 my-3">
      {selected.map((item) => {
        return (
          <Card className="flex items-center justify-between font-semibold">
            {item.value.fullName}
            <XMarkIcon onClick={() => onChange(item)} className="w-4 h-4" />
          </Card>
        );
      })}
    </div>
  );
};

const DriverModalSet = ({ form, setFormInit, init }) => {
  useEffect(() => {
    setFormInit(init);
  }, [init]);

  return null;
};
export default Drivers;
