import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const Drawer = ({
  description = "",
  children,
  visible,
  setVisible,
  widthClasses = "max-w-sm",
  headerClassNames = "p-3",
  position = "left",
  body,
  ...props
}) => {
  const title =
    typeof props.title === "string" ? (
      <h5 className="duration-300 inline-flex items-center text-base font-semibold text-gray-800">
        {props.title}
      </h5>
    ) : (
      props.title
    );

  const headerClasses = classNames(
    "flex items-center justify-between",
    headerClassNames,
    { "border-b": !props.noBorderHeader }
  );

  const header = props.header ? (
    props.header
  ) : (
    <div className={headerClasses}>
      {title}
      <button
        onClick={() => setVisible(false)}
        type="button"
        className="text-gray-100 bg-transparent hover:bg-gray-100 hover:text-gray-900 text-sm rounded-full p-1"
      >
        <XMarkIcon className="w-5 h-5 stroke-gray-600" />
        <span className="sr-only">Close menu</span>
      </button>
    </div>
  );

  const wrapperClasses = classNames(
    // "z-50 h-screen overflow-y-auto duration-300",
    // {
    //   "transition-transform right-0 top-0 transform-none":
    //     props.position === "right",
    // },
    "fixed top-0 right-0 z-[1000] h-screen overflow-y-auto",
    "bg-white w-80 dark:bg-gray-800 ",
    // "transition-transform transform-none"
    props.className
  );

  const transitions = {
    enter: "transform transition ease-in-out duration-500",
    enterFrom: "translate-x-full",
    enterTo: "translate-x-0",
    leave: "transform transition ease-in-out duration-500 delay-100",
    leaveFrom: "translate-x-0",
    leaveTo: "translate-x-full",
  };

  return (
    <Transition.Root show={visible}>
      {/* <BackgroundLayer /> */}
      <Dialog onClose={() => setVisible(false)}>
        <SlideOverLayer position={position} widthClasses={widthClasses}>
          <Dialog.Panel>
            <Dialog.Title>{header}</Dialog.Title>
            {body || children}
          </Dialog.Panel>
        </SlideOverLayer>
      </Dialog>
    </Transition.Root>
  );
};

const BackgroundLayer = () => (
  <Transition.Child
    enter="transition-opacity ease-in-out duration-500"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity ease-in-out duration-500"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="fixed inset-0 bg-gray-500 opacity-75" />
  </Transition.Child>
);

const base =
  "fixed z-40 p-4 transition-transform bg-white dark:bg-gray-800 transform-none";
const left = "top-0 left-0 h-screen overflow-y-auto w-80";
const right = "top-0 right-0 h-screen overflow-y-auto w-80";
const top = "top-0 left-0 right-0 w-full";
const bottom = "bottom-0 left-0 right-0 w-full overflow-y-auto";

// pointer-events-none fixed inset-y-0 right-0 flex max-w-full

const SlideOverLayer = ({ children, widthClasses, position }) => {
  const isRight = position === "right";
  const isLeft = position === "left" || !position;
  const isTop = position === "top";
  const isBottom = position === "bottom";

  const positionClasses = classNames(
    "pointer-events-none fixed inset-y-0 flex max-w-full",
    {
      "right-0": isRight,
      [top]: isTop,
    }
  );

  const widthWrapperClass = classNames("pointer-events-auto w-screen", {
    [widthClasses]: !isLeft || !isRight,
  });

  function renderPlane() {
    return isRight ? "x" : isTop ? "y" : isBottom ? "y" : "x";
  }

  const transitions = {
    enter: "transform transition ease-in-out duration-500",
    enterFrom: `${isLeft ? "-" : ""}translate-${renderPlane()}-full`, //"translate-x-full",
    enterTo: `${isLeft ? "-" : ""}translate-${renderPlane()}-0`,
    leave: "transform transition ease-in-out duration-500 delay-100",
    leaveFrom: `${isLeft ? "-" : ""}translate-${renderPlane()}-0`,
    leaveTo: `${isLeft ? "-" : ""}translate-${renderPlane()}-full`,
  };

  return (
    <Transition.Child as={Fragment} {...transitions}>
      <div className="fixed inset-0 overflow-hidden z-[1000]">
        <div className="absolute inset-0 overflow-hidden">
          <div className={positionClasses}>
            <div className={widthWrapperClass}>
              <div className="flex h-full flex-col bg-white shadow-xl">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition.Child>
  );
};

const FadeIn = ({ delay, children }) => (
  <Transition.Child
    enter={`transition-all ease-in-out duration-700 ${delay}`}
    enterFrom="opacity-0 translate-y-6"
    enterTo="opacity-100 translate-y-0"
    leave="transition-all ease-in-out duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition.Child>
);

export default Drawer;
