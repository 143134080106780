import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import { COLUMN_DEFINITIONS } from "./table.config";
import {
  fetchManifests,
  resetManifests,
  selectAllManifests,
} from "../../../../redux/reducers/manifests/manifestsSlice";
import TableLayout from "../../../../layouts/TableLayout";
import CreateModal from "../../../Manifests/CreateModal";

const DriverManifests = ({ driver }) => {
  const { user } = useAuth();
  const params = useParams();
  const dispatch = useDispatch();

  const manifests = useSelector(selectAllManifests);
  const manifestsStatus = useSelector((state) => state.manifests.status);

  const navigate = useNavigate();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    if (user._id && params.id && manifestsStatus === "idle") {
      dispatch(
        fetchManifests({
          parent: user.parentCompany,
          userId: user._id,
          driverId: params.id,
        })
      );
    }
  }, [manifestsStatus, dispatch, user, driver]);

  useEffect(() => {
    return () => dispatch(resetManifests());
  }, [dispatch]);

  async function handleUpdate(e, setLoading) {
    const update = {
      parentId: user.parentCompany,
      manifestId: e.row.original._id,
      data: e.data,
    };

    const res = await dispatch(updateManifest(update)).unwrap();

    if (res.status === "success") {
      dispatch(manifestUpdated(res.data));

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  function handleClick() {
    navigate(`/manifests/create`);
  }

  return (
    <div className="xl:col-span-6 col-span-8 max-h-[450px]">
      <TableLayout
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
        scrollable
        // actions={<Button title="Create Manifest" onClick={handleClick} />}
        id="manifests"
        title="Manifests"
        loading={manifestsStatus === "loading"}
        columns={COLUMN_DEFINITIONS}
        data={manifests}
        hideCheckbox
        updateData={handleUpdate}
      />
      <CreateModal
        modalOpen={createModalOpen}
        driver={driver}
        setModalOpen={setCreateModalOpen}
      />
    </div>
  );
};

export default DriverManifests;
