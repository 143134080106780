import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import { COLUMN_DEFINITIONS } from "./table.config";
import CreateModal from "./CreateModal";
import { manifestUpdated } from "../../redux/reducers/manifests/manifestsSlice";
import {
  useDeleteManifestsMutation,
  useGetManifestsQuery,
  useUpdateManifestMutation,
} from "../../redux/services/manifests/manifestsApi";
import { usePrefetch } from "../../redux/api";
import { isAuthorized } from "../../utils/authority";

const Manifests = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const { data, isLoading, isFetching } = useGetManifestsQuery({
    page: pageIndex + 1,
    limit: pageSize,
  });

  const [updateManifest, { isLoading: manifestUpdating }] =
    useUpdateManifestMutation();
  const [deleteManifests, { isLoading: isDeleting }] =
    useDeleteManifestsMutation();

  const prefetchPage = usePrefetch("getManifests");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
    });
  }, [prefetchPage, data?.nextPage, pageSize]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);

  const [createModalOpen, setCreateModalOpen] = useState(false);

  function handleEdit(itemId) {
    navigate(`/manifests/${itemId}`);
  }

  async function handleDelete(items) {
    const res = await deleteManifests({ data: items.map((item) => item._id) });

    toast(res.data.message, {
      type: res.data.status,
      toastId: "delete-payouts",
    });

    return res.data;
  }

  async function handleUpdate(e, setLoading) {
    const updateData = {
      id: e.row.original._id,
      data: e.data,
    };

    const res = await updateManifest(updateData).unwrap();

    toast(res.message, {
      type: res.status,
      toast: "manifest-index-updated",
    });

    // if (res.status === "success") {
    //   dispatch(manifestUpdated(res.data));
    setTimeout(() => {
      setLoading(false);
    }, 500);
    // }
  }

  const cannotCreateOrEdit = !isAuthorized("owner, admin");

  return (
    <div>
      <TableLayout
        id="manifests"
        loading={isLoading || isFetching}
        columns={COLUMN_DEFINITIONS}
        data={data?.manifests}
        pagination={data}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPaginationChange={setPagination}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        onUpdate={handleUpdate}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
        hideCheckbox={!isAuthorized("owner,admin")}
        hideActions={cannotCreateOrEdit}
        hideMainAction={cannotCreateOrEdit}
      />
      <CreateModal
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
      />
    </div>
  );
};

export default Manifests;
