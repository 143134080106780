import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../../shared/Table/table.utils";
import _ from "lodash";

// Config for table and form
const tableConfig = [
  {
    accessorKey: "id",
    header: "Invoice ID",
  },
  {
    header: "Billing date",
    accessorKey: "due_date",
  },
  {
    accessorKey: "Plan",
  },
  {
    accessorKey: "amount_paid",
    header: "Amount",
  },
  {
    accessorKey: "status",
    header: "Status",
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = {
  validations: renderValidations(tableConfig, "jobs"),
};
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Jobs", value: 10 },
  { label: "20 Jobs", value: 20 },
  { label: "50 Jobs", value: 50 },
  { label: "100 Jobs", value: 100 },
  { label: "150 Jobs", value: 150 },
];
