import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import * as XLSX from "xlsx";
import { createWorker } from "tesseract.js";
import { Document, Page, pdfjs } from "react-pdf";
import { LinkIcon } from "@heroicons/react/24/outline";
import { DocumentEditorComponent } from "@syncfusion/ej2-react-documenteditor";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  RangesDirective,
  RangeDirective,
} from "@syncfusion/ej2-react-spreadsheet";
import {
  PdfViewerComponent,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-pdfviewer";
import "./dispatchModal.css";

import { Modal, Button, Input } from "../../shared"; // Adjust import paths as needed

// Set up the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const NewDispatchModal = ({ visible, setVisible, onSubmit }) => {
  const [formData, setFormData] = useState({});
  const [excelData, setExcelData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [extractedText, setExtractedText] = useState("");
  const [parsedData, setParsedData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [excelSheets, setExcelSheets] = useState([]);

  const [isPdfLoaded, setIsPdfLoaded] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const fileInputRef = useRef(null);

  const [pdfData, setPdfData] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [cellInputLinks, setCellInputLinks] = useState({});

  const spreadsheetRef = useRef(null);

  const handleCellSelected = useCallback((args) => {
    setSelectedCell({
      sheetName: args.sheet.name,
      rowIndex: args.rowIndex,
      colIndex: args.colIndex,
    });
    console.log("Selected Cell:", args);
    console.log("Selected Range:", args.range);
    console.log("Selected Row:", args.rowIndex);
    console.log("Selected Column:", args.colIndex);
  }, []);

  const spreadsheetCreated = useCallback(
    (args) => {
      if (args && args.spreadsheet) {
        spreadsheetRef.current = args.spreadsheet;
        args.spreadsheet.cellSelected = handleCellSelected;
      }
    },
    [handleCellSelected]
  );

  const linkCellToInput = useCallback(
    (inputName) => {
      if (selectedCell) {
        setCellInputLinks((prev) => ({
          ...prev,
          [inputName]: selectedCell,
        }));
      }
    },
    [selectedCell]
  );

  const updateLinkedInputs = useCallback(() => {
    if (spreadsheetRef.current) {
      const spreadsheet = spreadsheetRef.current;
      Object.entries(cellInputLinks).forEach(([inputName, cellRef]) => {
        const cellValue =
          spreadsheet.sheets[cellRef.sheetName].rows[cellRef.rowIndex].cells[
            cellRef.colIndex
          ].value;
        setFormData((prev) => ({
          ...prev,
          [inputName]: cellValue,
        }));
      });
    }
  }, [cellInputLinks]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLabelChange = useCallback((oldName, newLabel) => {
    setFormData((prev) => {
      const { [oldName]: value, ...rest } = prev;
      return { ...rest, [newLabel]: value };
    });
  }, []);

  const handleDelete = useCallback((name) => {
    setFormData((prev) => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);

  useEffect(() => {
    return () => {
      if (fileType === "image" && fileData) {
        URL.revokeObjectURL(fileData);
      }
    };
  }, [fileData, fileType]);

  useEffect(() => {
    return () => {
      if (pdfData) {
        URL.revokeObjectURL(
          URL.createObjectURL(new Blob([pdfData], { type: "application/pdf" }))
        );
      }
    };
  }, [pdfData]);

  useEffect(() => {
    if (pdfData && pdfViewerRef.current) {
      // Give some time for the viewer to initialize
      const timer = setTimeout(() => {
        extractTextFromPdf();
      }, 1000); // Adjust the delay as needed

      return () => clearTimeout(timer);
    }
  }, [pdfData]);

  const handlePdfLoad = useCallback(() => {
    setIsPdfLoaded(true);
    if (pdfViewerRef.current && pdfViewerRef.current.viewer) {
      setIsPdfViewerReady(true);
    }
  }, []);

  useEffect(() => {
    if (isPdfLoaded && pdfViewerRef.current) {
      const timer = setTimeout(() => {
        extractTextFromPdf();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isPdfLoaded]);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setIsProcessing(true);

    try {
      if (file.type === "application/pdf") {
        await handlePdfUpload(file);
      } else if (
        file.type.includes("excel") ||
        file.type.includes("spreadsheetml")
      ) {
        await handleExcelUpload(file);
        setFileType("excel");
      } else if (file.type.includes("image")) {
        await handleImageUpload(file);
        setFileType("image");
      } else {
        throw new Error("Unsupported file type");
      }
    } catch (error) {
      console.error("Error processing file:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsProcessing(false);
    }
  };

  const parseExtractedText = (text) => {
    // Remove any extra whitespace and split by colon
    const items = text.replace(/\s+/g, " ").trim().split(":");
    const extractedData = {};

    for (let i = 0; i < items.length - 1; i++) {
      const key = items[i].split(" ").slice(-1)[0].trim().replace(/"/g, "");
      const value = items[i + 1]
        .split(" ")
        .slice(0, -1)
        .join(" ")
        .trim()
        .replace(/"/g, "");
      if (key && value) {
        extractedData[key] = value;
      }
    }

    console.log("Parsed data:", extractedData);
    setParsedData(extractedData);
    setFormData(extractedData);
  };
  const extractTextFromPdf = useCallback(
    async (arrayBuffer) => {
      try {
        const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
        let fullText = "";

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const content = await page.getTextContent();
          const pageText = content.items.map((item) => item.str).join(" ");
          fullText += pageText + "\n";
        }

        console.log("Extracted text from PDF:", fullText);
        setExtractedText(fullText);
        parseExtractedText(fullText);
      } catch (error) {
        console.error("Error extracting text from PDF:", error);
      }
    },
    [parseExtractedText]
  );

  const handleExcelUpload = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const bstr = event.target.result;
        const workbook = XLSX.read(bstr, { type: "binary" });
        const sheets = workbook.SheetNames.map((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          return { name: sheetName, rows: data };
        });
        // setFileData(data);
        // setExcelData(processedData);
        setExcelSheets(sheets);

        const extractedText = sheets
          .map((sheet) => {
            const sheetText = sheet.rows.map((row) => row.join(" ")).join("\n");
            return `Sheet: ${sheet.name}\n${sheetText}\n\n`;
          })
          .join("");

        const processedData = processExcelData(sheets);
        setFormData(processedData);
        setExtractedText(extractedText);

        // console.log("Processed Excel Data:", processedData);

        resolve();
      };
      reader.readAsBinaryString(file);
    });
  };

  const worker = createWorker();
  const handleImageUpload = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const imageUrl = event.target.result;
        setFileData(imageUrl);
        setFileType("image");

        // Perform OCR on the image
        const worker = await createWorker();
        await worker.load();
        await worker.loadLanguage("eng");
        await worker.initialize("eng");
        const {
          data: { text },
        } = await worker.recognize(imageUrl);
        await worker.terminate();

        console.log("Extracted text from image:", text);
        setExtractedText(text);
        parseExtractedText(text);

        const processedData = processImageData(text);
        setFormData(processedData);

        resolve();
      };
      reader.readAsDataURL(file);
    });
  };

  const processImageData = (text) => {
    // Implement your logic to process the extracted text
    // This is a placeholder function, you should adapt it to your needs
    const lines = text.split("\n");
    const processedData = {};

    // Example: Look for key-value pairs in the text
    lines.forEach((line) => {
      const [key, value] = line.split(":").map((item) => item.trim());
      if (key && value) {
        processedData[key] = value;
      }
    });

    return processedData;
  };

  //   const handleImageUpload = async (file) => {
  //     const worker = createWorker();
  //     await worker.load();
  //     await worker.loadLanguage("eng");
  //     await worker.initialize("eng");
  //     const {
  //       data: { text },
  //     } = await worker.recognize(file);
  //     await worker.terminate();
  //     setExtractedText(text);
  //     parseExtractedText(text);
  //     setFileData(URL.createObjectURL(file));
  //   };

  const pdfViewerRef = useRef(null);

  const handlePdfUpload = useCallback(
    (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const arrayBuffer = e.target.result;
          setPdfData(arrayBuffer);
          setFileType("pdf");

          // Create a Blob URL for the viewer
          const blob = new Blob([arrayBuffer], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);

          extractTextFromPdf(arrayBuffer);
          resolve();
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [extractTextFromPdf]
  );
  //   const extractTextFromPdf = async () => {
  //     if (pdfViewerRef.current && pdfViewerRef.current.viewer) {
  //       const viewer = pdfViewerRef.current.viewer;
  //       let fullText = "";

  //       if (viewer.pageCount) {
  //         for (let i = 1; i <= viewer.pageCount; i++) {
  //           try {
  //             const text = await viewer.extractText(i);
  //             fullText += text + "\n";
  //           } catch (error) {
  //             console.error(`Error extracting text from page ${i}:`, error);
  //           }
  //         }

  //         console.log("Extracted text from PDF:", fullText);
  //         setExtractedText(fullText);
  //         parseExtractedText(fullText);
  //       } else {
  //         console.warn("PDF viewer is not ready or has no pages.");
  //       }
  //     } else {
  //       console.warn("PDF viewer reference is not available.");
  //     }
  //   };

  const pdfOptions = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    // Add any other PDF.js options you need
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const processExcelData = (sheets) => {
    let processedData = {};

    sheets.forEach((sheet, sheetIndex) => {
      if (sheet.rows.length < 2) return; // Skip sheets with no data rows

      const headers = sheet.rows[0];
      const sheetData = sheet.rows.slice(1);

      sheetData.forEach((row, rowIndex) => {
        let rowData = {};
        headers.forEach((header, colIndex) => {
          const value = row[colIndex];
          if (value !== undefined && value !== "") {
            rowData[header] = value;
          }
        });

        if (Object.keys(rowData).length > 0) {
          processedData[`${sheet.name}_Row${rowIndex + 1}`] = rowData;
        }
      });
    });

    return processedData;
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = () => {
    onSubmit({ ...formData, excelData, extractedText });
    clearCache();
    setVisible(false);
  };

  const renderFileViewer = () => {
    const viewerStyle = {
      height: "70vh", // This sets the height to 70% of the viewport height, adjust as needed
      maxWidth: "1200px", // Adjust this value to set a maximum width if desired
      margin: "0 auto", // You can adjust this maximum height as needed
      width: "100%",
    };
    switch (fileType) {
      case "excel":
        return (
          <div style={viewerStyle}>
            {excelSheets.length > 0 ? (
              <SpreadsheetComponent
                ref={spreadsheetRef}
                openUrl="https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open"
                saveUrl="https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/save"
                sheets={excelSheets.map((sheet) => ({
                  name: sheet.name,
                  ranges: [{ dataSource: sheet.rows }],
                  columns:
                    sheet.rows && sheet.rows.length > 0
                      ? sheet.rows[0].map((_, index) => ({ width: 100 }))
                      : [],
                }))}
                cellSelected={handleCellSelected}
                dataBound={updateLinkedInputs}
                created={spreadsheetCreated}
                // allowevents={["cellselected"]}
              />
            ) : (
              <div>No Excel Sheets Found</div>
            )}
          </div>
        );
      case "pdf":
        return (
          <PdfViewerComponent
            id="pdfViewer"
            documentPath={pdfUrl}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
            style={viewerStyle}
          >
            <Inject services={[Toolbar]} />
          </PdfViewerComponent>
        );
      case "image":
        return (
          <img
            src={fileData}
            alt="Uploaded file"
            style={{ maxWidth: "100%", maxHeight: "500px" }}
          />
        );
      default:
        return null;
    }
  };

  const clearCache = useCallback(() => {
    setFormData({});
    setExcelData(null);
    setFileData(null);
    setFileType(null);
    setExtractedText("");
    setParsedData({});
    setIsProcessing(false);
    setPdfFile(null);
    setPdfUrl(null);
    setExcelSheets([]);
    setIsPdfLoaded(false);
    setNumPages(null);
    setPdfData(null);
    setSelectedCell(null);
    setCellInputLinks({});
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, []);

  const handleClose = useCallback(() => {
    clearCache();
    setVisible(false);
  }, [clearCache, setVisible]);

  return (
    <Modal
      visible={visible}
      onClose={handleClose}
      title="Create New Dispatch"
      size="75%"
      className="w-[95vw] max-w-[2500px] h-[90vh]" // Increase the width of the modal
    >
      <div className="flex h-full">
        <div className="w-2/5 pr-4 flex flex-col h-full">
          <div className="flex-grow overflow-y-auto">
            <div className="dispatch-grid">
              {Object.entries(formData).map(([key, value]) => (
                <div key={key} className="dispatch-row">
                  <Input
                    name={key}
                    label={key}
                    value={value}
                    icon={{ iconRight: true, icon: <LinkIcon /> }}
                    onClick={() => linkCellToInput(key)}
                    //   onLabelChange={handleLabelChange}
                    onChange={handleInputChange}
                    // onDelete={handleDelete}
                    className="mb-2 flex-grow"
                  />
                  {/* <button
                    onClick={() => linkCellToInput(key)}
                    className="link-button"
                  >
                    Link
                  </button> */}
                </div>
              ))}

              <div className="mt-4 space-y-2">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  accept=".xlsx,.xls,image/*,.pdf"
                />
                <Button
                  type="button"
                  onClick={triggerFileInput}
                  disabled={isProcessing}
                  className="bg-primary-600 text-white rounded px-4 py-2"
                >
                  {isProcessing ? "Processing File..." : "Upload File"}
                </Button>

                {/* <Button type="submit" onClick={handleSubmit}>
                  Create Dispatch
                </Button> */}
              </div>
            </div>
          </div>

          {/* <div className="w-1/2 pl-4">
          {pdfData && (
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              options={pdfOptions}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={1.5}
                />
              ))}
            </Document>
          )}
        </div> */}
        </div>

        {/* {excelData && (
          <div className="mt-4">
            <h4 className="font-semibold">Uploaded Excel Data:</h4>
            <div className="mt-2 border border-gray-300 p-2 max-h-40 overflow-auto">
              <table className="w-full">
                <thead>
                  <tr>
                    {Object.keys(excelData[0]).map((header, index) => (
                      <th key={index} className="border px-2 py-1">
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.values(row).map((cell, cellIndex) => (
                        <td key={cellIndex} className="border px-2 py-1">
                          {cell === "" ? "-" : cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )} */}

        <div className="w-3/5 pl-4 flex flex-col h-full">
          {renderFileViewer()}
        </div>
      </div>

      {/* {extractedText && (
        <div className="mt-4">
          <h4 className="font-semibold">Extracted Text:</h4>
          <div className="mt-2 border border-gray-300 p-2 max-h-40 overflow-auto">
            <pre>{extractedText}</pre>
          </div>
        </div>
      )} */}
    </Modal>
  );
};

export default NewDispatchModal;
