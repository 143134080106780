import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import TableLayout from "../../layouts/TableLayout";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Modal from "../../shared/Modal";
import { useState } from "react";
import { resetLocations } from "../../redux/reducers/locations/locationsSlice";
import { toast } from "react-toastify";
import {
  deleteFeedbacks,
  fetchFeedbacks,
  resetFeedbacks,
  selectAllFeedbacks,
} from "../../redux/reducers/feedbacks/feedbacksSlice";

const Feedback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const feedbacks = useSelector(selectAllFeedbacks);
  const feedbackStatus = useSelector((state) => state.feedbacks.status);
  const { user } = useAuth();
  // const error = useSelector((state) => state.drivers.error);
  useEffect(() => {
    if (feedbackStatus === "idle") {
      dispatch(fetchFeedbacks({ parent: user.parentCompany }));
    }
  }, [feedbackStatus]);

  useEffect(() => {
    return () => dispatch(resetFeedbacks());
  }, []);

  function handleEdit(itemId) {
    navigate(`/feedback/${itemId}`);
  }

  async function handleDelete(items) {
    const { parentCompany } = user;
    const res = await dispatch(
      deleteFeedbacks({ data: items, parentId: parentCompany })
    ).unwrap();

    if (res.status === "success") {
      toast("feedbacks Deleted", {
        type: "success",
        toastId: "feedbacksDeleted",
      });
    }

    return res;
  }

  return (
    <>
      <TableLayout
        id="feedback"
        loading={feedbackStatus === "loading"}
        columns={COLUMN_DEFINITIONS}
        data={feedbacks}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
    </>
  );
};

export default Feedback;
