import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

export const Badge = (props) => {
  const color = props.color || "primary";
  const classes = classNames(
    props.className,
    "px-2.5 py-0.5 rounded border",
    {
      "bg-primary-100 text-primary-800 border-primary-300": color === "primary",
      "bg-red-100 text-red-800 border-red-300": color === "red",
      "bg-green-100 text-green-800 border-green-300": color === "green",
      "bg-blue-100 text-blue-800 border-blue-300": color === "blue",
      "bg-orange-100 text-orange-800 border-orange-300": color === "orange",
      "bg-yellow-100 text-yellow-800 border-yellow-300": color === "yellow",
      "bg-purple-100 text-purple-800 border-purple-300": color === "purple",
      "bg-sky-100 text-sky-800 border-sky-300": color === "sky",
      "bg-teal-100 text-teal-800 border-teal-300": color === "teal",
      "bg-indigo-100 text-indigo-800 border-indigo-300": color === "indigo",
      "bg-fuchsia-100 text-fuchsia-800 border-fuchsia-300": color === "fuchsia",
      "bg-emerald-100 text-emerald-800 border-emerald-300": color === "emerald",
      "bg-amber-100 text-amber-800 border-amber-300": color === "amber",
      "bg-slate-100 text-slate-800 border-slate-300": color === "slate",
      "bg-gray-100 text-gray-800 border-gray-300": color === "gray",
      "bg-zinc-100 text-zinc-800 border-zinc-300": color === "zinc",
      "bg-neutral-100 text-neutral-800 border-neutral-300": color === "neutral",
      "bg-stone-100 text-stone-800 border-stone-300": color === "stone",
      "bg-lime-100 text-lime-800 border-lime-300": color === "lime",
      "bg-cyan-100 text-cyan-800 border-cyan-300": color === "cyan",
      "bg-pink-100 text-pink-800 border-pink-300": color === "pink",
      "bg-rose-100 text-rose-800 border-rose-300": color === "rose",
    },
    { "font-semibold text-xs": props.size === "sm" || !props.size },
    { "font-medium text-sm": props.size === "lg" },
    { "font-lg text-xl": props.size === "xl" },
    { "inline-flex": props.dismissable },
    { [`hover:bg-${color}-200`]: props.link }
  );

  const dismissClasses = classNames(
    "inline-flex items-center p-0.5 ml-2 text-sm bg-transparent rounded-sm",
    // `text-${color}-400 hover:bg-${color}-200 hover:text-${color}-900`
    {
      "text-primary-600": color === "primary",
      "text-red-600": color === "red",
      "text-green-600": color === "green",
      "text-blue-600": color === "blue",
      "text-orange-600": color === "orange",
      "text-yellow-600": color === "yellow",
      "text-purple-600": color === "purple",
      "text-sky-600": color === "sky",
      "text-teal-600": color === "teal",
      "text-indigo-600": color === "indigo",
      "text-fuchsia-600": color === "fuchsia",
      "text-emerald-600": color === "emerald",
      "text-amber-600": color === "amber",
      "text-slate-600": color === "slate",
      "text-gray-600": color === "gray",
      "text-zinc-600": color === "zinc",
      "text-neutral-600": color === "neutral",
      "text-stone-600": color === "stone",
      "text-lime-600": color === "lime",
      "text-cyan-600": color === "cyan",
      "text-pink-600": color === "pink",
      "text-rose-600": color === "rose",
    }
  );

  const dismiss = props.dismissable && (
    <button type="button" className={dismissClasses} aria-label="Remove">
      <XMarkIcon className="w-3 h-3" />
      <span className="sr-only">Remove badge</span>
    </button>
  );

  return props.link ? (
    <Link className={classes} to={props.link}>
      {props.icon && props.icon}
      {props.label || props.children}
      {dismiss}
    </Link>
  ) : (
    <span className={classes}>
      {props.icon && props.icon}
      {props.label || props.children}
      {dismiss}
    </span>
  );
};

export default Badge;
