import { LegsInfo } from "./LegsInfo";
import { General } from "./General";

const TruckingJobInfo = ({ job, form, ...props }) => {
  return (
    <>
      <General job={job} {...form} {...props} />
      <LegsInfo
        {...form}
        {...props}
        legForm={form}
        job={job}
        status={job.status}
        setModalVisible={showCreateModal}
        modalVisible={createModalVisible}
      />
    </>
  );
};

export default TruckingJobInfo;
