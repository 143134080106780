import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useAddQrMutation,
  useGetQrByIdQuery,
} from "../../../redux/services/qr/qrApi";
import { useGetEquipmentByIdQuery } from "../../../redux/services/equipment";
import Modal from "../../../shared/Modal";
import { Button } from "../../../shared";
import { DashboardPublishModal } from "../../../components";

const EquipmentQr = () => {
  const [qrCode, setQrCode] = useState("");
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState(false);
  const [qrConfig, setQrConfig] = useState({});
  const { id } = useParams();
  const { data: equipment, isLoading } = useGetEquipmentByIdQuery({ id });
  const [addQr] = useAddQrMutation();
  const { data: qr, isLoading: isQrLoading } = useGetQrByIdQuery({ id });

  const generateQRCode = (config = {}) => {
    if (equipment) {
      const qrData = { equipmentId: id };
      addQr(qrData);
    }
  };

  const handleGenerate = () => {
    setIsGenerateModalOpen(true);
  };

  const handleReplace = () => {
    setIsReplaceModalOpen(true);
  };

  const handleGenerateConfirm = () => {
    generateQRCode(qrConfig);
    setIsGenerateModalOpen(false);
    setQrConfig({});
  };

  const handleReplaceConfirm = () => {
    generateQRCode();
    setIsReplaceModalOpen(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <DashboardPublishModal />
    </>
  );
};

export default EquipmentQr;
