import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const initialState = {
  messages: [],
  status: "idle",
  error: null,
};

export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async (obj, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/messages?${queries}`);
    return res.data;
  }
);

export const deleteMessages = createAsyncThunk(
  "messages/deleteMessages",
  async ({ data, parentId }, { rejectWithValue }) => {
    const res = await request(`/messages?parent=${parentId}`, {
      method: "delete",
      data,
    });
    if (!res.data.messages) {
      rejectWithValue();
    }
    return res.data;
  }
);

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    resetMessages: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMessages.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.messages = [];
        state.status = "succeeded";
        state.messages = action.payload.data;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteMessages.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteMessages.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { data } = action.payload;
        const newMessages = state.customers.filter(
          (x) => !data.includes(x._id)
        );
        state.messages = newMessages;
      })
      .addCase(deleteMessages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default messagesSlice.reducer;

export const { resetMessages } = messagesSlice.actions;

export const selectAllMessages = (state) => state.messages.messages;
export const selectAllMessagesOptions = (state) => {
  return state.messages.messages.map(({ name }) => {
    return {
      value: name,
      label: name,
    };
  });
};
export const selectMessagesById = (state, messageId) =>
  state.messages.messages.find((message) => message.id === messageId);
