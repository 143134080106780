import React, { useRef } from "react";
import Input from "../Input";

export const TimeInput = (props) => {
  const inputRef = useRef(null);

  const timeInputStyles = {
    // color: "#2d9682",
    // borderColor: "#2d9682",
    cursor: "pointer",
    "&:focus": {
      borderColor: "#2d9682",
      boxShadow: "0 0 0 1px #2d9682",
    },
    "&::-webkit-calendar-picker-indicator": {
      filter:
        "invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)",
    },
    "&::-webkit-datetime-edit-fields-wrapper": {
      color: "#2d9682",
    },
    "&::-webkit-datetime-edit-text": {
      color: "#2d9682",
    },
    "&::-webkit-datetime-edit-hour-field": {
      color: "#2d9682",
    },
    "&::-webkit-datetime-edit-minute-field": {
      color: "#2d9682",
    },
    "&::-webkit-datetime-edit-ampm-field": {
      color: "#2d9682",
    },
    "&::-webkit-time-picker-popup": {
      backgroundColor: "#2d9682",
    },
  };

  const wrapperStyles = {
    position: "relative",
    width: "100%",
  };

  const overlayStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: "pointer",
  };

  const handleClick = () => {
    inputRef.current.showPicker();
  };

  return (
    <div style={wrapperStyles} onClick={handleClick}>
      <Input
        {...props}
        type="time"
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        style={timeInputStyles}
        ref={inputRef}
      />
      <div style={overlayStyles}></div>
      <style jsx global>{`
        input[type="time"]::-webkit-calendar-picker-indicator {
          background: none;
          color: #2d9682;
        }
        input[type="time"]::-webkit-time-picker-popup {
          background-color: white;
        }
        input[type="time"]::-webkit-time-picker-popup .clock-face {
          background-color: #2d9682;
        }
        input[type="time"]::-webkit-time-picker-popup .clock-hand {
          background-color: #2d9682;
        }
        input[type="time"]::-webkit-time-picker-popup .clock-number {
          color: #2d9682;
        }
      `}</style>
    </div>
  );
};

export default TimeInput;
