import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import request, { renderQueries } from "../../../utils/request";
import _ from "lodash";

const initialState = {
  jobs: [],
  status: "idle",
  error: null,
};

export const fetchJobs = createAsyncThunk(
  "jobs/fetchJobs",
  async (obj, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/jobs?${queries}`);
    if (!res.data.jobs) {
      rejectWithValue(res.data);
      return;
    }
    return res.data;
  }
);

export const deleteJobs = createAsyncThunk(
  "jobs/deleteJobs",
  async ({ data, ...rest }) => {
    const queries = renderQueries(rest);
    const res = await request(`/jobs?${queries}`, {
      method: "delete",
      data,
    });
    return res.data;
  }
);

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    resetJobs: () => initialState,
    setJobs(state, action) {
      state.jobs = action.payload;
    },
    jobUpdated(state, action) {
      const { _id } = action.payload;
      const index = state.jobs.findIndex((item) => item._id === _id);
      state.jobs[index] = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchJobs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.jobs = [];
        state.status = "succeeded";
        state.jobs = state.jobs.concat(action.payload.jobs);
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteJobs.fulfilled, (state, action) => {
        const jobsDeleted = action.payload.data;
        const newJobs = _.differenceBy(state.jobs, jobsDeleted, "_id");
        state.jobs = newJobs;
      })
      .addCase(deleteJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetJobs, setJobs, jobUpdated } = jobsSlice.actions;
export default jobsSlice.reducer;

export const selectAllJobs = (state) => state.jobs.jobs;
export const selectAllJobsOptions = (state) => {
  return state.jobs.jobs.map(({ name }) => {
    return {
      value: name,
      label: name,
    };
  });
};
export const selectJobsById = (state, jobId) =>
  state.jobs.jobs.find((job) => job.id === jobId);
