import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../shared/Avatar";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import {
  fetchClients,
  selectAllClients,
} from "../../../redux/reducers/clients/clientsSlice";
import request, { renderQueries } from "../../../utils/request";
import InputDropdown from "../../../shared/InputDropdown";
import { useNavigate } from "react-router-dom";
import AdvancedSettings from "./AdvancedSettings";
import classNames from "classnames";
import { useAddEquipmentMutation } from "../../../redux/services/equipment";
import Input from "../../../shared/Input";
import Select from "../../../shared/Select";
import TextArea from "../../../shared/TextArea";

const CreateModal = ({ modalOpen, setModalOpen, ...props }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { form, updateForm, clearForm, setFormInit } = useForm();
  const [loading, setLoading] = useState(false);
  const [recentClients, setRecentClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [addEquipment, { isLoading: addingEquipment }] =
    useAddEquipmentMutation();

  async function getRecentClients() {
    const res = await request(
      "/clients/recent?" + renderQueries({ parent: user.parentCompany })
    );

    if (res.data.status === "success") {
      setRecentClients(res.data.data);
    }
  }

  const clients = useSelector(selectAllClients);
  const clientsStatus = useSelector((state) => state.clients.status);

  useEffect(() => {
    if (!props.client && clientsStatus === "idle") {
      dispatch(fetchClients(user.parentCompany));
    }
  }, [clientsStatus, user, props.client]);

  useEffect(() => {
    if (props.client && modalOpen) {
      setFormInit({ client: props.client });
    }
  }, [props.client?._id, modalOpen]);

  useEffect(() => {
    if (!props.client && recentClients.length === 0) {
      getRecentClients();
    }
  }, [recentClients.length, props.client]);

  function closeModal() {
    setRecentClients([]);
    setModalOpen(false);
    handleCancel();
  }

  const footer = (
    <div>
      <Button className="mr-3" onClick={closeModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={addingEquipment}
        disabled={addingEquipment}
        onClick={() => saveEquipment()}
        type="primary"
      >
        Submit
      </Button>
    </div>
  );

  async function saveEquipment() {
    const postData = {
      ...form,
      type: form.type.value,
      status: form.status.value,
    };

    const res = await addEquipment(postData).unwrap();

    if (res.status === "success") {
      closeModal();
      navigate("/equipment/" + res.data._id);
    }
  }

  function handleSelectClient(recentClient) {
    updateForm({ id: "client", value: recentClient });
  }

  function handleCancel() {
    clearForm("client");
  }

  console.log(form);

  const createModalContent = (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        saveEquipment();
      }}
    >
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4 w-full">
          <Input
            label="Equipment Name"
            value={form.name || ""}
            onChange={(e) => updateForm({ id: "name", value: e.value })}
            required
            className="w-full"
          />
          <Select
            label="Equipment Type"
            value={form.type?.label || ""}
            onChange={(value) => updateForm({ id: "type", value })}
            options={[
              { value: "heavy", label: "Heavy Equipment" },
              { value: "light", label: "Light Equipment" },
              { value: "tool", label: "Tool" },
            ]}
            required
            className="w-full"
          />
        </div>
        <Input
          label="Serial Number"
          value={form.serialNumber || ""}
          onChange={(e) => updateForm({ id: "serialNumber", value: e.value })}
        />
        <div className="grid grid-cols-2 gap-4 w-full">
          <Input
            label="Model"
            value={form.model || ""}
            onChange={(e) => updateForm({ id: "model", value: e.value })}
          />
          <Input
            label="Manufacturer"
            value={form.manufacturer || ""}
            onChange={(e) => updateForm({ id: "manufacturer", value: e.value })}
          />
        </div>

        <Input
          type="date"
          label="Last Maintenance Date"
          value={form.lastMaintenanceDate || ""}
          onChange={(e) =>
            updateForm({ id: "lastMaintenanceDate", value: e.value })
          }
        />
        <Select
          label="Status"
          value={form.status || ""}
          onChange={(value) => updateForm({ id: "status", value })}
          options={[
            { value: "Available", label: "Available" },
            { value: "In Use", label: "In Use" },
            { value: "Under Maintenance", label: "Under Maintenance" },
            { value: "Out of Service", label: "Out of Service" },
          ]}
          required
        />
        <TextArea
          label="Notes"
          value={form.notes || ""}
          onChange={(e) => updateForm({ id: "notes", value: e.value })}
        />
      </div>
    </form>
  );

  const title = <span className="mx-4">Select a client for equipment</span>;

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={createModalContent}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};

const AvatarClient = (props) => {
  const wrapperClasses = classNames(
    "flex items-center justify-between rounded-lg py-2 px-1 cursor-pointer",
    {
      "hover:bg-gray-100": !props.disableCancel,
    }
  );
  return (
    <div onClick={props.onClick} className={wrapperClasses}>
      <div className="flex items-center space-x-2">
        <Avatar size="xs" src={props.avatar} />
        <span className="font-semi-bold text-sm">{props.name}</span>
      </div>
      {!props.disableCancel && (
        <div>
          {props.selected && (
            <XMarkIcon onClick={props.onCancel} className="w-5 h-5" />
          )}
        </div>
      )}
    </div>
  );
};

export default CreateModal;
