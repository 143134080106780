import React from "react";
import {
  ArrowLeftOnRectangleIcon,
  BellIcon,
  MagnifyingGlassIcon,
  Cog8ToothIcon,
  ClipboardDocumentListIcon,
  ChatBubbleLeftEllipsisIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import Avatar from "../../shared/Avatar";
import Dropdown from "../../shared/Dropdown";
import Header from "../../shared/Header";
import Input from "../../shared/Input";
import { useAuth } from "../../hooks/useAuth";
import { useContext } from "react";
import { LayoutContext } from "../Root";
import defaultSettings from "../../config/defaultSettings";
import { Badge } from "../../shared";
import { useConfig } from "../../hooks";

// const tabs = [
//   {
//     id: "Notifications",
//     content: <NotificationContent />,
//   },
//   {
//     id: "Tasks",
//     content: <TaskShow />,
//   },
// ];

const LayoutHeader = (props) => {
  const { logout, user } = useAuth();
  const { configs } = useConfig();
  const { breakpoint } = useContext(LayoutContext);

  const options = [
    // {
    //   icon: <UserIcon className="w-4 h-4" />,
    //   title: "Account",
    //   path: "/account",
    // },
    {
      icon: <Cog8ToothIcon className="w-4 h-4" />,
      title: "Account Settings",
      path: "/account/information",
    },
    // {
    //   icon: <WrenchScrewdriverIcon className="w-4 h-4" />,
    //   title: "Site Settings",
    //   onClick: props.openDrawer,
    // },
    {
      icon: <ClipboardDocumentListIcon className="w-4 h-4" />,
      title: "Admin Menu",
      path: "/admin",
    },
    {
      icon: <ChatBubbleLeftEllipsisIcon className="w-4 h-4" />,
      title: "Feedback",
      path: "/feedback/create",
    },
    {
      title: "Logout",
      icon: <ArrowLeftOnRectangleIcon className="w-4 h-4" />,
      section: true,
      onClick: () => logout(),
    },
  ].filter(({ path }) => {
    if (path === "/admin" && props.user?.authority !== "superAdmin") {
      return false;
    }
    return true;
  });

  const searchIcon = (
    <div className="h-full flex items-center cursor-pointer">
      <MagnifyingGlassIcon className="stroke-2 h-5 w-5" />
    </div>
  );

  const avatar = (
    // <div className="flex flex-row items-center h-full cursor-pointer">
    //   <Avatar src={user.avatar} size="sm" />
    //   <span className="ml-1 hidden md:block">
    //     <ChevronDownIcon className="w-4 h-4" />
    //   </span>
    // </div>
    <div className="flex flex-row items-center h-full cursor-pointer">
      <Avatar src={user.avatar} size="xs" />
      <span className="ml-1 hidden md:block">
        <ChevronDownIcon className="w-4 h-4" />
      </span>
    </div>
  );

  const bell = (
    <div className="flex items-center h-full cursor-pointer">
      <BellIcon className="stroke-2 h-5 w-5" />
    </div>
  );

  // const notifications = <Tabs data={tabs} />;

  const searchBar = (
    <div className="overflow-x-auto w-80 p-2 h-full cursor">
      <Input clearable size="sm" placeholder="Search..." />
    </div>
  );

  const isSmall = breakpoint === "sm" || breakpoint === "xs";

  const rightContent = [
    // <Dropdown
    //   isMobile={isSmall}
    //   key={1}
    //   data={searchBar}
    //   trigger={searchIcon}
    // />,
    // <div
    //   key={2}
    //   onClick={() => navigate("/help")}
    //   className="h-full flex items-center cursor-pointer"
    // >
    //   <QuestionMarkCircleIcon className="stroke-2 h-5 w-5" />
    // </div>,
    // <Dropdown
    //   widthClasses="w-96"
    //   isMobile={isSmall}
    //   key={3}
    //   data={notifications}
    //   trigger={bell}
    // />,
    <Dropdown
      closeOnClick
      key={4}
      data={options}
      trigger={avatar}
      className="flex"
      width={14}
    />,
  ];

  const envBadge = defaultSettings.STAGE !== "prod" && (
    <div className="flex flex-row items-center">
      <Badge className="uppercase" label={defaultSettings.STAGE} />
      <Badge
        color="blue"
        className="uppercase"
        label={configs?.parentCompany?.appType}
      />
      <Badge
        color="orange"
        className="uppercase"
        label={configs?.parentCompany?.industry}
      />
    </div>
  );

  return <Header leftContent={envBadge} rightContent={rightContent} />;
};

export default LayoutHeader;
