import { Link } from "react-router-dom";
import { renderCustomColumnDefs } from "../../../shared/Table/table.utils";
import { formatDate, renderAddress } from "../../../utils";
import { manifestMileageOptions } from "../../../constants/select";

// Config for table and form
const tableConfig = [
  {
    Header: "#",
    accessor: "",
    Cell: (row) => {
      return row.row.id * 1 + 1;
    },
    disableSortBy: true,
    disableFilters: true,
  },
  {
    header: "Job Load #",
    id: "job",
    minWidth: 120,
    accessorFn: (row) => row.jobId?._id,
    cell: ({ row: { original }, getValue }) => {
      const value = getValue();
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${original.jobId?._id}`}
        >
          {original.jobId?.loadNumber}
        </Link>
      );
    },
  },
  {
    id: "appointment",
    header: "Pickup Date",
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) {
        return null;
      }
      return formatDate(value.date, null, true);
    },
    width: 120,
  },
  {
    id: "orderNumber",
    visible: true,
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${original.jobId?._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    Header: "Pickup Location",
    accessorFn: (row) => row.route?.origin,
    cell: ({ getValue }) => renderAddress(getValue()),
    width: 100,
  },
  {
    Header: "Dropoff Location",
    accessorFn: (row) => row.route?.destination,
    cell: ({ getValue }) => renderAddress(getValue()),
    width: 100,
  },
  // {
  //   id: "loadType",
  //   accessorKey: "loadType",
  //   width: 120,
  //   cell: ({ getValue }) => getValue(),
  // },

  {
    header: "Standby Hours",
    id: "waitTime",
    visible: true,
    required: true,
    cell: ({ getValue }) => getValue()?.total,
  },
  // { id: "driver", minWidth: 100, cell: ({ getValue }) => getValue()?.fullName },
  {
    id: "route",
    header: "Total Miles",
    editable: {
      type: "number",
      disabled: ({ original }) => !original.payoutId,
    },
    // editable: {
    //   type: "select",
    //   options: manifestMileageOptions,
    // },
    cell: ({ getValue, row }) => {
      const value = getValue();
      if (row.original.useCustomManifestMileage) {
        return value && <div>{row.original.customManifestMileage} mi</div>;
      }
      return value && <div>{value.defaultMileage || value.mileage} mi</div>;
    },
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
