import React, { useEffect, useRef, useState } from "react";
import useForm from "../../../hooks/useForm";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FORM_OPTIONS } from "../table.config";
import { useAuth } from "../../../hooks/useAuth";
import LegsTable from "../Create/LegsTable";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import SelectedLegsTable from "../Create/SelectedLegsTable";
import Spinner from "../../../shared/Spinner";
import Tabs from "../../../shared/Tabs";
import { SidebarContainer } from "../../../components";
import {
  useGetManifestQuery,
  useUpdateManifestMutation,
} from "../../../redux/services/manifests/manifestsApi";
import { useGetLegsQuery } from "../../../redux/services/legs/legsApi";
import { Button } from "../../../shared";
import { toast } from "react-toastify";

const ManifestsEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const table1Ref = useRef();
  const table2Ref = useRef();

  const { user } = useAuth();
  const { form, updateForm, setFormInit } = useForm(FORM_OPTIONS);

  const [legsData, setLegsData] = useState([]);
  const [manifestItems, setManifestItems] = useState([]);
  const [selectedOrganizationDrivers, setSelectedOrganizationDrivers] =
    useState([]);
  const [type, setType] = useState("Individual");
  const [selectedLegs, setSelectedLegs] = useState([]);
  const [selectedManifestItems, setSelectedManifestItems] = useState([]);

  // Update Manifest function
  const [updateManifest, { isLoading: manifestUpdating }] =
    useUpdateManifestMutation();

  // Get manifest query
  const { data: manifestData, isLoading } = useGetManifestQuery(
    {
      id: params.id,
    },
    { skip: !params.id }
  );
  const manifest = manifestData || {};

  // Legs Query
  const { data } = useGetLegsQuery(
    {
      driverId: manifest?.driver?._id,
      manifestId: false,
      route: true,
      customQuery:
        type === "Organization" &&
        form.organization &&
        selectedOrganizationDrivers.length > 0
          ? JSON.stringify({
              driver: {
                $in: selectedOrganizationDrivers.map((i) => i._id),
              },
            })
          : undefined,
    },
    { skip: !manifest?.driver?._id }
  );
  const legs = data || [];

  useEffect(() => {
    if (legs.length > 0 && legsData.length === 0) {
      setLegsData(legs);
    }
  }, [legs.length]);

  useEffect(() => {
    if (manifest._id) {
      setFormInit({
        ...manifest,
        totalmanifest: form.total,
        hours: manifest.hours || 0,
      });
      setManifestItems(manifest.legs);
      manifest.organization && setSelectedOrganizationDrivers(manifest.drivers);
      setType(manifest.organization ? "Organization" : "Individual");
    }
  }, [manifest._id]);

  useEffect(() => {
    let manifestTotal = renderManifestTotal();
    const manifestMileage = renderManifestMileage();
    const manifestWaitTime = renderManifestWaitTime();

    setFormInit({
      hours: manifestWaitTime,
      manifestMileage: manifestMileage,
      totalManifest: manifestTotal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    manifestItems.length,
    form.totalManifest,
    form.manifestMileage,
    form.totalMileage,
    form.hours,
    form.rate,
    form.mileageRate,
  ]);

  function addToManifest() {
    const newData = legsData.filter((x) => !selectedLegs.includes(x));
    onFormChange({ id: "legs", value: [...manifestItems, ...selectedLegs] });
    setLegsData(newData);
    setManifestItems([...manifestItems, ...selectedLegs]);
    table1Ref.current.resetSelectedRows(false);
  }

  function removeFromManifest() {
    const newData = [...legsData, ...selectedManifestItems];
    const newmanifestItems = manifestItems.filter(
      (x) => !selectedManifestItems.includes(x)
    );
    setLegsData(newData);
    setManifestItems(newmanifestItems);
    table2Ref.current.resetSelectedRows(false);
  }

  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  async function handleSubmit() {
    const data = {
      ...form,
      type: form.type,
      legs: manifestItems.map((i) => ({ _id: i._id })),
    };

    const res = await updateManifest({
      data,
      id: params.id,
    }).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "invoice-updated",
    });

    if (res.status === "success") {
      navigate(`/manifests/${res.data._id}`);
    }
  }

  const renderManifestMileage = () => {
    let totalManifestMileage = manifestItems
      .map((item) => {
        return item.route?.mileage || 0;
      })
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    return Math.ceil(totalManifestMileage);
  };

  const renderManifestWaitTime = () => {
    let total = manifestItems
      .map((item) => {
        return item.waitTime?.total || 0;
      })
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    return form.hours || total;
  };

  const renderHourlyTotal = () => {
    let totalHourly = Number(form.rate) * Number(form.hours);
    return totalHourly;
  };

  const renderMileageTotal = () => {
    let mileageRate = form.mileageRate;
    const res = Number(form.manifestMileage) * Number(mileageRate);
    return res;
  };

  const renderManifestTotal = () => {
    const hourlyTotal = renderHourlyTotal() || 0;
    const mileageTotal = renderMileageTotal() || 0;
    let totalManifestCharges = Number(hourlyTotal) + Number(mileageTotal);
    return totalManifestCharges;
  };

  // const totalCharges = (
  //   <DisplayTotal
  //     title="Manifest Total"
  //     value={renderAmount(form.totalManifest)}
  //   />
  // );

  // const title = (
  //   <div className="flex items-center">
  //     {type === "Organization" ? "Organization" : "Driver"}:{" "}
  //     <span className="font-bold text-xl ml-2 flex items-center">
  //       {type === "Organization"
  //         ? form.organization.name
  //         : form.drivers[0].fullName}{" "}
  //     </span>
  //   </div>
  // );

  const legsSelector = (
    <div>
      <div className="flex flex-col xl:flex-row justify-evenly justify-items-stretch space-x-0 xl:space-x-2">
        <div className="flex-grow w-full">
          <LegsTable
            data={legsData}
            setData={setSelectedLegs}
            client={form.client}
            ref={table1Ref}
          />
        </div>
        <div className="flex-grow-0 self-center z-10">
          <div className="hidden xl:block">
            <IconButton icon={ChevronRightIcon} onClick={addToManifest} />
            <IconButton icon={ChevronLeftIcon} onClick={removeFromManifest} />
          </div>
          <div className="flex xl:hidden">
            <IconButton icon={ChevronUpIcon} onClick={removeFromManifest} />
            <IconButton icon={ChevronDownIcon} onClick={addToManifest} />
          </div>
        </div>
        <div className="flex-grow w-full">
          <SelectedLegsTable
            setSelectedItems={setSelectedManifestItems}
            selectedData={manifestItems}
            client={form.client}
            ref={table2Ref}
          />
        </div>
      </div>
      <DisplayTotal title="# of Drops" value={manifestItems.length} />
      <DisplayTotal title="Total Miles" value={form.manifestMileage + " mi"} />

      <div className="flex justify-end mt-4">
        <Button
          className="w-6/12 lg:w-28 mr-3"
          type="alternative"
          onClick={() => navigate(`/manifests/${params.id}`)}
        >
          Cancel
        </Button>
        <Button
          loading={manifestUpdating}
          className="w-6/12 lg:w-28"
          disabled={manifestUpdating}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );

  const tabs = [
    {
      id: "Edit",
      content: <div className="my-5">{legsSelector}</div>,
    },
  ];

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderTabHeader = () => {
    return (
      <div>
        <div ref={headerRef}></div>
      </div>
    );
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <SidebarContainer hideSidebar header={renderTabHeader}>
      {({ parentHeight }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 38}px`,
        };
        return (
          <div style={wrapperStyle} className="h-auto w-full">
            {isLoading ? (
              <div>
                <Spinner />{" "}
              </div>
            ) : (
              <Tabs
                className={`w-full overflow-hidden`}
                style={wrapperStyle}
                panelStyle={panelStyle}
                useSearchParams
                panelClasses={`p-2 border-t overflow-y-scroll`}
                tabListClasses="bg-white px-2 h-[38px]"
                tabclassName="mx-1"
                id="manifest-edit"
                hideTabs
                data={tabs}
              />
            )}
          </div>
        );
      }}
    </SidebarContainer>
  );
};

const IconButton = (props) => {
  const Icon = props.icon;
  return (
    <div className="flex flex-col items-center my-4 mx-1">
      <div
        onClick={props.onClick}
        className="border ease-in-out duration-300 hover:border-gray-300 rounded-full p-2 hover:bg-gray-200 cursor-pointer"
      >
        <Icon className="w-6 h-6" />
      </div>
      {props.label && <p className="font-semibold">{props.label}</p>}
    </div>
  );
};

export const DisplayTotal = (props) => {
  return (
    <div className="flex items-center">
      {props.title}:{" "}
      <span className="font-bold pl-2 text-xl">{props.value}</span>
    </div>
  );
};

export default ManifestsEdit;
