import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { renderAmount } from "../../../../utils";
import _ from "lodash";
import { PencilSquareIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { EditableText, Input } from "../../../../shared";
import { accessoryOptions } from "../../../../constants/select";
import { useForm } from "../../../../hooks/useForm";
import { useMemo } from "react";
import { renderCustomColumnDefs } from "../../../../shared/Table/table.utils";
import { isAuthorized } from "../../../../utils/authority";

// const columnHelper = createColumnHelper();

const AccessoryTable = ({ data, editAcc, removeAcc, ...props }) => {
  const formHook = useForm();

  const tableCols = [
    {
      id: "type",
      header: "Type",
      accessor: "type",
      accessorFn: (originalRow) => originalRow.type,
      minSize: 300,
      cell: ({ getValue, row }) => {
        const isEditing = row.original.editing;
        return (
          <TableItem
            id="type"
            type="select"
            placeholder="Type"
            value={formHook.form.type}
            getValue={getValue}
            editing={isEditing}
            options={accessoryOptions}
            {...formHook}
          />
        );
      },
    },
    {
      id: "quantity",
      header: "Quantity",
      accessor: "quantity",
      accessorFn: (originalRow) => originalRow.quantity,
      minSize: 110,
      cell: ({ getValue, row }) => {
        const isEditing = row.original.editing;
        return (
          <TableItem
            id="quantity"
            type="number"
            placeholder="Quantity"
            value={formHook.form.quantity}
            getValue={getValue}
            editing={isEditing}
            displayText={(v) => (
              <span className="font-semibold text-xl">{getValue()}</span>
            )}
            {...formHook}
          />
        );
      },
    },
    {
      id: "unitPrice",
      header: "Unit Price",
      accessor: "unitPrice",
      remove: !isAuthorized("owner,admin"),
      accessorFn: (originalRow) => originalRow.unitPrice,
      minSize: 150,
      cell: ({ getValue, row }) => {
        const isEditing = row.original.editing;
        const amount = renderAmount(getValue());
        return (
          <TableItem
            id="unitPrice"
            type="number"
            placeholder="Unit Price"
            value={formHook.form.unitPrice}
            getValue={getValue}
            editing={isEditing}
            icon={{
              left: <div className="text-gray-500 text-sm">$</div>,
            }}
            {...formHook}
          />
        );
      },
    },
    {
      id: "total",
      header: "Total",
      accessor: "total",
      remove: !isAuthorized("owner,admin"),
      accessorFn: (originalRow) => originalRow.total,
      cell: ({ row, getValue }) => {
        const total =
          Number(formHook.form.unitPrice) * Number(formHook.form.quantity);
        return renderAmount(total || getValue());
      },
      minSize: 150,
    },
    {
      id: "actions",
      // header: "Actions",
      remove: !isAuthorized("owner,admin"),
      accessor: "actions",
      cell: ({ row }) => {
        const isEditing = row.original.editing;
        return (
          <div className="flex space-x-2">
            {isEditing ? (
              "Save"
            ) : (
              <>
                <div
                  className="cursor-pointer w-5 h-5"
                  onClick={() => editAcc(row.original)}
                >
                  <PencilSquareIcon className="w-5 h-5 text-blue-500" />
                </div>
                <div
                  className="cursor-pointer w-5 h-5"
                  onClick={() => removeAcc(row.original)}
                >
                  <XCircleIcon className="w-5 h-5 text-red-500" />
                </div>
              </>
            )}
          </div>
        );
      },
      minSize: 150,
    },
  ];

  const columns = useMemo(() => renderCustomColumnDefs(tableCols), []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const headers = table.getHeaderGroups();
  const grandTotal =
    isAuthorized("owner,admin") && renderAmount(_.sumBy(data, "total"));

  return (
    <div className="">
      <div
        className=""
        style={
          {
            // width: table.getCenterTotalSize(),
          }
        }
      >
        <div className=" border-b border-gray-700">
          {headers.map((headerGroup, idx) => (
            <div key={idx} className="flex">
              {headerGroup.headers.map((header, i) => (
                <div
                  {...{
                    key: i,
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),
                    },
                    className: "py-2",
                  }}
                >
                  <span
                    {...{
                      className: "flex flex-row items-center",
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div>
          {table.getRowModel().rows.map((row, i) => (
            <div key={i} className="flex border-b border-gray-300">
              {row.getVisibleCells().map((cell, idx) => (
                <div
                  {...{
                    key: idx,
                    style: {
                      width: cell.column.getSize(),
                    },
                    className: "py-2",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {!isAuthorized("owner, admin") ? null : props.footer ? (
        props.footer({ total: grandTotal })
      ) : (
        <div
          className="flex justify-end"
          style={{
            width: 680,
          }}
        >
          <div>
            <div className="flex space-x-9 my-3 items-end">
              <div className="text-xl font-bold">Total</div>
              <div className="text-xl">{grandTotal}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccessoryTable;

const TableItem = ({
  type,
  placeholder,
  editing,
  value,
  options,
  id,
  icon,
  updateForm,
  getValue,
}) => {
  function handleChange(e) {
    updateForm({ id: id, value: e.value });
  }

  return (
    <EditableText
      id={id}
      disabled
      className="mr-2"
      onChange={handleChange}
      type={type}
      placeholder={placeholder}
      editing={editing}
      value={value || getValue()}
      hideActions
      options={options}
      icon={icon}
    />
  );
};
