import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../shared/Sidebar";
import { LayoutContext } from "../Root";
import LayoutHeader from "./LayoutHeader";
import {
  BanknotesIcon,
  BriefcaseIcon,
  BuildingStorefrontIcon,
  HomeIcon,
  TruckIcon,
  DocumentArrowUpIcon,
  Cog8ToothIcon,
  ClipboardDocumentListIcon,
  RadioIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import useWindowSize from "../../hooks/useWindowSize";
import { useAuth } from "../../hooks/useAuth";
import Breadcrumbs from "../../shared/Breadcrumbs";
import PageLoading from "../../shared/PageLoading";
import MobileNav from "./MobileNav";
import { renderMenuItems } from "../../utils/route.utils";
import { useTranslation } from "react-i18next";
import { useCrumbs } from "../../hooks/useCrumbs";
import { isAuthorized } from "../../utils/authority";
import { useConfig } from "../../hooks";

export default function DashboardLayout({ hideMenu }) {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const { t } = useTranslation();
  const { navOpen, setNavOpen } = useContext(LayoutContext);
  const windowSize = useWindowSize();
  const { loading, user } = useAuth();
  const crumbs = useCrumbs();
  const { configs } = useConfig();

  const menuMap = [
    { title: t("dashboard.main"), path: "/", icon: HomeIcon },
    { title: t("jobs.main"), path: "/jobs", icon: BriefcaseIcon },
    // { title: t("dispatch.main"), path: "/dispatch", icon: RadioIcon },
    {
      title: t("invoices.main"),
      path: "/invoices",
      icon: BanknotesIcon,
      authority: isAuthorized("owner, admin, client"),
    },
    {
      title: "Manifests",
      // title: t("manifests.main"),
      authority: isAuthorized("owner, admin, user, driver"),
      appTypes: ["trucking"],
      path: "/manifests",
      icon: ClipboardDocumentListIcon,
    },
    {
      title: t("payouts.main"),
      path: "/payouts",
      icon: DocumentArrowUpIcon,
      authority: isAuthorized("owner, admin"),
    },
    {
      title: t("clients.main"),
      path: "/clients",
      icon: BuildingStorefrontIcon,
      authority: isAuthorized("owner, admin"),
    },
    // {
    //   title: t("equipment.main"),
    //   path: "/equipment",
    //   icon: WrenchScrewdriverIcon,
    // },
    {
      title: t("drivers.main"),
      authority: isAuthorized("owner, admin"),
      path: "/drivers",
      icon: TruckIcon,
    },
    // {
    //   title: t("users.main"),
    //   path: "/users",
    //   icon: UsersIcon,
    //   authority: "admin",
    // },
    {
      title: t("settings.main"),
      path: "/settings",
      icon: Cog8ToothIcon,
      authority: isAuthorized("owner, admin"),
    },
  ].filter((item) => {
    return item.appTypes && configs.parentCompany?.appType
      ? item.appTypes.includes(configs.parentCompany?.appType)
      : true;
  });

  const menuItems = renderMenuItems(menuMap, user.authority);

  const breakPoint =
    windowSize.breakpoint === "sm" || windowSize.breakpoint === "xs";

  const bodyWrapperClasses = classNames(
    "flex flex-col w-full duration-200 mb-5 bg-gray-50",
    { "md:pl-20": !hideMenu && !navOpen },
    { "md:pl-[288px]": !hideMenu && navOpen }
  );

  const headFootTransition = {
    "md:pl-20": !hideMenu && !navOpen,
    "md:pl-60": !hideMenu && navOpen,
  };

  const rightWrapper = classNames(
    "flex flex-col h-screen duration-300 transition-all",
    "overflow-hidden",
    headFootTransition
  );

  const footerClasses = classNames(
    "duration-200 h-10 bg-gray-100 p-4 flex items-center justify-end",
    { "md:pl-20": !hideMenu && !navOpen, "md:pl-[288px]": !hideMenu && navOpen }
  );
  const headerOffset = 42;
  const parentHeight = windowSize?.size.height - headerOffset;

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = (headerProps) => {
    return (
      <div ref={headerRef} className="">
        {/* {actions} */}
      </div>
    );
  };

  return loading ? (
    <PageLoading />
  ) : (
    // <SidebarContainer header={renderHeader} hideSidebar className="w-full">
    //   {({ parentHeight, parentWidth }) => {
    //     const offsetTop = headerContext?.offsetTop;
    //     const offsetHeight = headerContext?.offsetHeight;
    //     const wrapperStyle = {
    //       height: `${parentHeight - offsetTop - offsetHeight - 38}px`,

    //       // minHeight: `${parentHeight - offsetTop - offsetHeight - 5}px`,
    //     };
    //     // const panelStyle = {
    //     //   height: `${parentHeight - offsetTop - offsetHeight}px`,
    //     // };
    //     return (
    <>
      <div
        // style={wrapperStyle}
        className="w-full"
      >
        {!hideMenu && (
          <>
            <Sidebar className="hidden md:block" menu={menuItems} />
            <MobileNav
              breakPoint={breakPoint}
              noBorderHeader={true}
              position="left"
              menu={menuItems}
              setNavOpen={setNavOpen}
              navOpen={navOpen}
            />
          </>
        )}
        <div className={rightWrapper}>
          <div>
            <LayoutHeader
              user={user}
              openDrawer={() => setDrawerVisible(true)}
            />
          </div>
          <main
            style={{ height: parentHeight }}
            // className="flex-grow overflow-y-auto m-0 flex"
            // style={{ flexFlow: "column" }}
          >
            <Breadcrumbs data={crumbs.crumbs} loading={crumbs.loading} />
            <div style={{ height: parentHeight - 30 }}>
              <Outlet />
            </div>
          </main>
          {/* <footer className="py-2 bg-gray-100 border-t text-center text-white">
            Footer
          </footer> */}
        </div>
      </div>
      {/* <Drawer
              position="right"
              title="Site Settings"
              body={<p>Site Settings</p>}
              visible={drawerVisible}
              setVisible={setDrawerVisible}
              onClose={() => setDrawerVisible(false)}
            /> */}
    </>
  );
  //   }}
  // </SidebarContainer>
  // );
}

// const data = (
//   <>
//     <div className="flex">
//       {!hideMenu && (
//         <>
//           <Sidebar className="hidden md:block" menu={menuItems} />
//           <MobileNav
//             breakPoint={breakPoint}
//             noBorderHeader={true}
//             position="left"
//             menu={menuItems}
//             setNavOpen={setNavOpen}
//             navOpen={navOpen}
//           />
//         </>
//       )}
//       <div className={bodyWrapperClasses}>
//         <LayoutHeader user={user} openDrawer={() => setDrawerVisible(true)} />
//         <Breadcrumbs data={breadcrumbs} />
//         <div className="px-3 pb-3 h-auto">
//           <Outlet />
//         </div>
//       </div>
//     </div>
//     <Drawer
//       position="right"
//       title="Site Settings"
//       body={<p>Site Settings</p>}
//       visible={drawerVisible}
//       setVisible={setDrawerVisible}
//       onClose={() => setDrawerVisible(false)}
//     />
//   </>
// );
