import { useCallback, useEffect, useState } from "react";
import UsersSettingsLayout from "../../../layouts/UsersSettingsLayout";
import { Input } from "../../../shared";
import { useGetUsersQuery } from "../../../redux/services/users/usersApi";
import { usePrefetch } from "../../../redux/api";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { capitalizeFirstLetter } from "../../../utils";
import { SettingsHeader } from "..";

export * from "./Show";

export const Users = () => {
  const [search, setSearch] = useState({
    model: "",
    term: "",
    useSearch: false,
  });
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isLoading, isFetching } = useGetUsersQuery({
    page: pageIndex + 1,
    limit: pageSize,
    search: search.useSearch,
    model: search.model,
    searchTerm: search.term,
  });

  const prefetchPage = usePrefetch("getUsers");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
    });
  }, [prefetchPage, data?.nextPage, pageSize]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);

  function handleSearch(data) {
    const delayDebounceFn = setTimeout(async () => {
      if (data.value.length > 1 && data.value) {
        setSearch({ term: data.value, model: "User", useSearch: true });
      } else {
        setSearch({
          term: "",
          model: "",
          useSearch: false,
        });
      }
    }, 800);
    return () => clearTimeout(delayDebounceFn);
  }

  function getCanNavigate(direction) {
    if (!data) return false;
    return data["has" + capitalizeFirstLetter(direction)];
  }

  function handlePage(direction) {
    if (getCanNavigate(direction)) {
      if (data?.["has" + capitalizeFirstLetter(direction)]) {
        const data = { pageSize, pageIndex };
        if (direction === "next") {
          data.pageIndex = pageIndex + 1;
        } else {
          data.pageIndex = pageIndex - 1;
        }
        setPagination(data);
      }
    }
  }

  const iconClasses = "w-5 h-5";

  const canPrev = getCanNavigate("prev");
  const prevClasses = classNames("rounded-l-lg p-3", {
    "bg-gray-100 cursor-not-allowed": !canPrev,
    "hover:bg-gray-50 cursor-pointer": canPrev,
  });
  const canNext = getCanNavigate("next");
  const nextClasses = classNames("rounded-r-lg p-3", {
    "bg-gray-100 cursor-not-allowed": !canNext,
    "hover:bg-gray-50 cursor-pointer": canNext,
  });

  return (
    <>
      <SettingsHeader title="Users" />
      <div>
        <Input onChange={handleSearch} placeholder="Search users" />
      </div>
      <UsersSettingsLayout data={data} loading={isLoading || isFetching} />
      <div className="bg-white flex border border-gray-300 rounded-lg divide-x divide-gray-300 w-fit">
        <div className={prevClasses} onClick={() => handlePage("prev")}>
          <ChevronLeftIcon className={iconClasses} />
        </div>
        <div className={nextClasses} onClick={() => handlePage("next")}>
          <ChevronRightIcon className={iconClasses} />
        </div>
      </div>
    </>
  );
};
