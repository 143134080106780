import Input from "../../../../shared/Input";
import Select from "../../../../shared/Select";
import TimeInput from "../../../../shared/TimeInput";
import Toggle from "../../../../shared/Toggle";
import { stateOptions } from "../../../../constants/select";

const Locations = ({
  remove,
  id,
  formData,
  data,
  renderId,
  renderValue,
  handleChange,
  errors,
  updateForm,
  ...props
}) => {
  return (
    <>
      <div className="my-3">
        <div className="grid grid-cols-1 gap-1 lg:grid-cols-12 lg:gap-3">
          <div className="col-span-6">
            <Input
              id={renderId("name")}
              error={errors?.[renderId("name")]}
              value={renderValue("name")}
              label="Name"
              placeholder="Name"
              onChange={handleChange}
            />
          </div>
          <div className="col-span-3">
            <TimeInput
              id={renderId("open")}
              value={renderValue("open")}
              label="Open"
              placeholder="Open"
              onChange={handleChange}
            />
          </div>
          <div className="col-span-3">
            <TimeInput
              id={renderId("close")}
              value={renderValue("close")}
              label="Close"
              placeholder="Close"
              onChange={handleChange}
            />
          </div>
          <div className="col-span-3 row-start-2">
            <Input
              id={renderId("address")}
              error={errors?.[renderId("address")]}
              value={renderValue("address")}
              label="Address"
              placeholder="Address"
              onChange={handleChange}
            />
          </div>
          <div className="row-start-2">
            <Input
              id={renderId("suite")}
              error={errors?.[renderId("suite")]}
              value={renderValue("suite")}
              label="Suite"
              placeholder="Suite"
              onChange={handleChange}
            />
          </div>
          <div className="col-span-3 row-start-2">
            <Input
              id={renderId("city")}
              error={errors?.[renderId("city")]}
              value={renderValue("city")}
              label="City"
              placeholder="City"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="col-span-2 row-start-2">
          <Select
            id={renderId("state")}
            value={renderValue("state")}
            error={errors?.[renderId("state")]}
            label="State/Province"
            placeholder="State/Province"
            onChange={handleChange}
            search
            options={stateOptions}
            autoComplete="state"
          />
        </div>
        <div className="col-span-2 row-start-2">
          <Input
            id={renderId("country")}
            error={errors?.[renderId("country")]}
            value={renderValue("country")}
            label="Country"
            placeholder="Country"
            onChange={handleChange}
          />
        </div>
        <div className="col-span-1 row-start-2">
          <Input
            id={renderId("zipcode")}
            error={errors?.[renderId("zipcode")]}
            value={renderValue("zipcode")}
            label="Zipcode"
            placeholder="Zipcode"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-span-2">
        <Input
          id={renderId("phone")}
          error={errors?.[renderId("phone")]}
          size="md"
          value={renderValue("phone")}
          label="Phone"
          placeholder="Phone"
          onChange={handleChange}
        />
      </div>
      <div className="col-span-2">
        <Input
          id={renderId("note")}
          error={errors?.[renderId("note")]}
          size="md"
          value={renderValue("note")}
          label="Note"
          placeholder="Note"
          onChange={handleChange}
        />
      </div>
      <div className="col-span-2 py-1">
        <Toggle
          id={renderId("precall")}
          size="md"
          checked={renderValue("precall")}
          label="Precall"
          placeholder="Precall"
          onChange={(e) =>
            handleChange({ id: e.target.id, value: e.target.checked })
          }
        />
      </div>
    </>
  );
};

export default Locations;
