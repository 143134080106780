import classNames from "classnames";
import { Link } from "react-router-dom";
import Card from "../../shared/Card";
import Spinner from "../../shared/Spinner";
import { formatDate } from "../../utils";

const FeedbackCard = ({ title, loading, color, money, i }) => {
  const bodyClasses = classNames(
    "text-center text-3xl md:text-4xl lg:text-3xl xl:text-5xl my-2 whitespace-nowrap overflow-x-auto scrollbar-hide",
    `text-${color}-500`,
    {
      "!text-red-500": money && body < 0,
      "!text-yellow-500": money && body === 0,
    }
  );

  return (
    <Card className="min-h-full rounded-lg row-span-1 col-span-1 md:col-span-2 lg:col-span-3 flex flex-col font-bold">
      <div className="flex justify-between">
        <span className="text-xs md:text-sm uppercase text-gray-500">
          {i.feedbackId}
        </span>
        <span className="text-xs md:text-sm uppercase text-gray-500">
          {formatDate(i.dateUpdated, null, true)}
        </span>
      </div>
      <span className={bodyClasses}>
        {loading ? (
          <Spinner size="md" />
        ) : (
          <>
            <div className="whitespace-nowrap overflow-x-auto scrollbar-hide">
              <Link to={`admin/feedback/${i._id}`}>{i.messages}</Link>
            </div>
          </>
        )}
      </span>
    </Card>
  );
};

export default FeedbackCard;
