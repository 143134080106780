import React, { useMemo } from "react";
import { useState } from "react";
import {
  AreaChart,
  Area,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { colors } from "./colors";

export const AreaChartComponent = ({ data, title, ...props }) => {
  return (
    <ResponsiveContainer width="100%" height={props.height}>
      <AreaChart
        // width={1200}
        // height={300}
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid opacity={0.5} vertical={false} />
        <XAxis dataKey="Name" tick={{ fontSize: 13 }} />
        <YAxis allowDecimals={false} tick={{ fontSize: 13 }} />

        <defs>
          <linearGradient id="one" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.green} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.green} stopOpacity={0.2} />
          </linearGradient>
          <linearGradient id="two" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.purple} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.purple} stopOpacity={0.2} />
          </linearGradient>
          <linearGradient id="three" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.cyan} stopOpacity={0} />
            <stop offset="95%" stopColor={colors.cyan} stopOpacity={0.8} />
          </linearGradient>
          <linearGradient id="four" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.blue} stopOpacity={0} />
            <stop offset="95%" stopColor={colors.blue} stopOpacity={0.8} />
          </linearGradient>
          <linearGradient id="five" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.orange} stopOpacity={0} />
            <stop offset="95%" stopColor={colors.orange} stopOpacity={0.8} />
          </linearGradient>
          <linearGradient id="six" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.primary} stopOpacity={0} />
            <stop offset="95%" stopColor={colors.primary} stopOpacity={0.8} />
          </linearGradient>
        </defs>
        <Tooltip />
        <Legend wrapperStyle={{ fontSize: "13px" }} />

        <Area
          type="monotone"
          strokeWidth={3}
          dataKey="Total"
          fillOpacity={1}
          // stackId="1"
          stroke={colors.green}
          fill="url(#one)"
        />
        <Area
          type="monotone"
          strokeWidth={3}
          dataKey="Invoiced"
          fillOpacity={2}
          // stackId="8"
          stroke={colors.purple}
          fill="url(#two)"
        />
        <Area
          type="monotone"
          dataKey="Delivered"
          fillOpacity={2}
          strokeWidth={3}
          // stackId="7"
          stroke={colors.cyan}
          fill="url(#three)"
        />
        <Area
          type="monotone"
          dataKey="En Route"
          fillOpacity={2}
          strokeWidth={3}
          // stackId="1"
          stroke={colors.blue}
          fill="url(#four)"
        />
        <Area
          type="monotone"
          dataKey="Pending"
          fillOpacity={2}
          strokeWidth={3}
          // stackId="1"
          stroke={colors.orange}
          fill="url(#six)"
        />
        <Area
          type="monotone"
          dataKey="New"
          fillOpacity={2}
          strokeWidth={3}
          // stackId="1"
          stroke={colors.primary}
          fill="url(#five)"
        />

        {/* <Area
              type="monotone"
              dataKey="Pending"
              stackId="2"
              stroke="#8884d8"
              // fill="#8884d8"
            />
            <Area
              type="monotone"
              dataKey="En Route"
              stackId="3"
              stroke="#878486"
              // fill="#878486"
            />
            <Area
              type="monotone"
              dataKey="Booked"
              stackId="4"
              stroke="#878486"
              // fill="#878486"
            />
            <Area
              type="monotone"
              dataKey="New"
              stackId="5"
              stroke="#82ca9d"
              // fill="#82ca9d"
            />
            <Area
              type="monotone"
              dataKey="Complete"
              stackId="6"
              stroke="#3b2532"
              // fill="#3b2532"
            />
      
            <Area
              type="monotone"
              dataKey="Invoiced"
              stackId="8"
              stroke="#3b2532"
              // fill="#3b2532"
            /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartComponent;
