import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../../utils/request";

const initialState = {
  data: {},
  status: "idle",
  error: null,
};

export const fetchOrganization = createAsyncThunk(
  "organization/fetchOrganization",
  async ({ parentId, id }) => {
    const res = await request(`/organizations/${id}?parent=${parentId}`);
    return res.data;
  }
);

export const addNewOrganization = createAsyncThunk(
  "organization/addNewOrganization",
  async ({ data, parent }) => {
    const res = await request(`/organizations?parent=${parent}`, {
      method: "post",
      data: data,
    });

    return res.data;
  }
);

export const updateOrganization = createAsyncThunk(
  "organization/updateOrganization",
  async ({ data, parent, id }) => {
    const res = await request(`/organizations/${id}?parent=${parent}`, {
      method: "put",
      data,
    });
    return res.data;
  }
);

export const deleteOrganization = createAsyncThunk(
  "organization/deleteOrganization",
  async ({ parentId, id, client }) => {
    const res = await request(`/organizations/${id}?parent=${parentId}`, {
      method: "delete",
      data: { clientId: client },
    });
    return res.data;
  }
);

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    resetOrg() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrganization.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.data = {};
        state.status = "succeeded";
        state.data = action.payload.data;
      })
      .addCase(fetchOrganization.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewOrganization.fulfilled, (state, action) => {
        state.data = action.payload.data;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.data = action.payload.data;
      });
  },
});

export const { resetOrg } = organizationSlice.actions;

export default organizationSlice.reducer;
