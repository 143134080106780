import { useEffect, useState } from "react";
import Input from "../../../../shared/Input";
import SettingsPanel from "../../../../shared/SettingsPanel";
import useForm from "../../../../hooks/useForm";
import _ from "lodash";
import { mapValues } from "radash";
import { useDispatch } from "react-redux";
import { updateClient } from "../../../../redux/reducers/clients/clientSlice";
import { useAuth } from "../../../../hooks/useAuth";
import { AddressModal } from "../../../../components";
import { toast } from "react-toastify";

const GeneralSettings = (props) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { form, setFormInit } = useForm();
  const [addressModalVisible, setAddressModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    setFormInit({
      slug: { data: props.client?.slug },
      name: { data: props.client?.name },
      email: { data: props.client?.email },
      phone: { data: props.client?.phone },
      address: { data: props.client?.address?.fullAddress },
      coowner: { data: props.client?.coowner },
    });
  }, [props.client?._id]);

  function handleSave() {
    const edited = _.pickBy(form, function (obj, key) {
      return obj.updated;
    });
    const res = mapValues(edited, (value) => value.data);

    if (Object.keys(res).length === 0) return;

    const updateData = {
      data: res,
      parentId: user.parentCompany,
      clientId: props.client._id,
    };

    if (res.address) {
      updateData.type = "address";
    }
    dispatch(updateClient(updateData));
  }

  async function submitAddress(data) {
    setModalLoading(true);
    const updateData = {
      data,
      type: "address",
      parentId: user.parentCompany,
      clientId: props.client._id,
    };
    const res = await dispatch(updateClient(updateData)).unwrap();

    if (res.status === "success") {
      setAddressModalVisible(false);
      setFormInit({
        slug: { data: res.data.slug },
        name: { data: res.data.name },
        email: { data: res.data.email },
        phone: { data: res.data.phone },
        address: { data: res.data.address?.fullAddress },
        coowner: { data: res.data.coowner },
      });
    }
    toast(res.message, { toastId: "client-address-updated", type: res.status });
    setModalLoading(false);
  }

  function onFormChange(e) {
    setFormInit({ [e.id]: { data: e.value, updated: true } });
  }

  const data = [
    {
      title: "Client Slug",
      subtitle: "Unique identifier for this client",
      content: (
        <Input
          id="slug"
          onChange={onFormChange}
          value={form.slug?.data}
          size="md"
          placeholder="Client slug"
          onBlur={handleSave}
        />
      ),
    },
    {
      title: "Client Name",
      subtitle: "Client companies name",
      content: (
        <Input
          id="name"
          onChange={onFormChange}
          value={form.name?.data}
          size="md"
          placeholder="Client Name"
          onBlur={handleSave}
        />
      ),
    },
    {
      title: "Client Email",
      subtitle: "The main email for the client",
      content: (
        <Input
          id="email"
          onChange={onFormChange}
          value={form.email?.data}
          size="md"
          type="email"
          placeholder="example@123.com"
          onBlur={handleSave}
        />
      ),
    },
    {
      title: "Client Address",
      subtitle: "The clients address",
      content: (
        <Input
          id="address"
          onChange={onFormChange}
          value={form.address?.data}
          size="md"
          placeholder="Address"
          // onBlur={handleSave}
          onClick={() => setAddressModalVisible(true)}
        />
      ),
    },
    {
      title: "Phone Number",
      subtitle: "The main contact for the client",
      content: (
        <Input
          id="phone"
          onChange={onFormChange}
          value={form.phone?.data}
          size="md"
          type="tel"
          placeholder="(555)555-5555"
          onBlur={handleSave}
        />
      ),
    },
    {
      title: "Co-Owner",
      subtitle: "The clients co-owner",
      content: (
        <Input
          id="coowner"
          onChange={onFormChange}
          value={form.coowner?.data}
          size="md"
          placeholder="Client Co-Owner"
          onBlur={handleSave}
        />
      ),
    },
  ];

  return (
    <>
      <div className="space-y-4">
        <SettingsPanel title="Basic Info" data={data} />
      </div>
      <AddressModal
        visible={addressModalVisible}
        setVisible={setAddressModalVisible}
        data={props.client.address}
        submit={submitAddress}
        loading={modalLoading}
        setLoading={setModalLoading}
      />
    </>
  );
};

export default GeneralSettings;
