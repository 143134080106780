import Select from "../../shared/Select";
import TextArea from "../../shared/TextArea";
import FormLayout from "../../layouts/FormLayout";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addNewFeedback } from "../../redux/reducers/feedbacks/feedbackSlice";
import useForm from "../../hooks/useForm";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const FeedbackCreate = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { form, updateForm } = useForm();
  const [loading, setLoading] = useState(false);

  const typeOptions = [
    { label: "Bug", value: "Bug" },
    { label: "General Feedback", value: "General Feedback" },
    { label: "Feature Request", value: "Feature" },
  ];

  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  async function handleSubmit() {
    setLoading(true);
    const data = {
      ...form,
    };
    const res = await dispatch(
      addNewFeedback({ data, parentId: user.parentCompany, user })
    ).unwrap();

    if (res.status === "success") {
      toast("Feedback Sent", {
        type: "success",
        toastId: "feedbackSent",
      });
      navigate("/");
    }
    setLoading(false);
  }

  return (
    <FormLayout
      contentClassname="mx-32 mt-10"
      title="Feedback"
      handleSubmit={handleSubmit}
      back="/"
      loading={loading}
    >
      <div>
        <p>
          Please report any bugs, ideas or general feedback which would allow us
          to further help you to reach your true potential.
        </p>
      </div>
      <div className="py-6 w-1/4">
        <Select
          id="type"
          onChange={onFormChange}
          value={form.type}
          label="Issue Type"
          options={typeOptions}
        />
      </div>
      <div>
        <TextArea
          id="message"
          onChange={onFormChange}
          value={form.message}
        ></TextArea>
      </div>
    </FormLayout>
  );
};

export default FeedbackCreate;
