import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const jobsApi = api.injectEndpoints({
  tagTypes: ["Jobs"],
  endpoints: (builder) => ({
    getJobs: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = getSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `jobs?${query}`;
      },
      providesTags: (data) =>
        data?.jobs
          ? [
              ...data.jobs.map(({ _id }) => ({ type: "Jobs", id: _id })),
              { type: "Jobs", id: "LIST" },
            ]
          : [{ type: "Jobs", id: "LIST" }],
    }),
    addJob: builder.mutation({
      query(body) {
        return {
          url: "jobs",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Jobs", id: "LIST" }],
    }),
    getJob: builder.query({
      query: ({ jobId, ...rest }) => `jobs/${jobId}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
      providesTags: (_result, _error, data) => {
        return [{ type: "Jobs", id: data.jobId }];
      },
    }),
    updateJob: builder.mutation({
      query({ id, data }) {
        return {
          url: `/jobs/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Jobs", id }],
    }),
    deleteJobs: builder.mutation({
      query({ data }) {
        return {
          url: `/jobs`,
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Jobs", id: "LIST" }],
    }),
    updateGlobalJobRates: builder.mutation({
      query({ id, data }) {
        return {
          url: `/jobs/${id}/rates/global`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Jobs", id }],
    }),
    updateJobRates: builder.mutation({
      query({ id, data }) {
        return {
          url: `/jobs/${id}/rates`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Jobs", id }],
    }),
    updateJobAccessories: builder.mutation({
      query({ method, data }) {
        return {
          url: "/jobs/accessory",
          method,
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { data }) => [
        { type: "Jobs", id: data.jobId },
      ],
    }),
    deleteJobAccessory: builder.mutation({
      query({ data }) {
        return {
          url: "/jobs/accessory",
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { data }) => [
        { type: "Jobs", id: data.jobId },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetJobsQuery,
  useGetJobQuery,
  useDeleteJobsMutation,
  useAddJobMutation,
  useUpdateJobMutation,
  useUpdateGlobalJobRatesMutation,
  useUpdateJobRatesMutation,
  useUpdateJobAccessoriesMutation,
  useDeleteJobAccessoryMutation,
} = jobsApi;
