import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const initialState = {
  feedbacks: [],
  status: "idle",
  error: null,
};

export const fetchFeedbacks = createAsyncThunk(
  "feedbacks/fetchFeedbacks",
  async (obj, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/feedback?${queries}`);
    return res.data;
  }
);

export const deleteFeedbacks = createAsyncThunk(
  "feedbacks/deleteFeedbacks",
  async ({ data, parentId }, { rejectWithValue }) => {
    const res = await request(`/feedback?parent=${parentId}`, {
      method: "delete",
      data,
    });
    if (!res.data.feedbacks) {
      rejectWithValue();
    }
    return res.data;
  }
);

export const feedbacksSlice = createSlice({
  name: "feedbacks",
  initialState,
  reducers: {
    resetFeedbacks: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFeedbacks.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchFeedbacks.fulfilled, (state, action) => {
        state.feedbacks = [];
        state.status = "succeeded";
        state.feedbacks = action.payload.data;
      })
      .addCase(fetchFeedbacks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteFeedbacks.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteFeedbacks.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { data } = action.payload;
        const newFeedbacks = state.customers.filter(
          (x) => !data.includes(x._id)
        );
        state.feedbacks = newFeedbacks;
      })
      .addCase(deleteFeedbacks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default feedbacksSlice.reducer;

export const { resetFeedbacks } = feedbacksSlice.actions;

export const selectAllFeedbacks = (state) => state.feedbacks.feedbacks;
export const selectAllFeedbacksOptions = (state) => {
  return state.feedbacks.feedbacks.map(({ name }) => {
    return {
      value: name,
      label: name,
    };
  });
};
export const selectFeedbacksById = (state, feedbackId) =>
  state.feedbacks.feedbacks.find((feedback) => feedback.id === feedbackId);
