import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const initialState = {
  invoices: [],
  status: "idle",
  error: null,
};

export const fetchInvoices = createAsyncThunk(
  "invoices/fetchInvoices",
  async (obj, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/invoices?${queries}`);
    if (!res.data.invoices) {
      rejectWithValue();
    }
    return res.data;
  }
);

export const deleteInvoices = createAsyncThunk(
  "jobs/deleteInvoices",
  async ({ data, parentId }) => {
    const res = await request(`/invoices?parent=${parentId}`, {
      method: "delete",
      data,
    });
    return res.data;
  }
);

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    resetInvoices: () => initialState,
    invoiceUpdated(state, action) {
      const { _id } = action.payload;
      const index = state.invoices.findIndex((item) => item._id === _id);
      state.invoices[index] = action.payload;
    },
    setInvoices(state, action) {
      state.invoices = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInvoices.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.invoices = [];
        state.status = "succeeded";
        state.invoices = state.invoices.concat(action.payload.invoices);
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteInvoices.fulfilled, (state, action) => {
        const invoicesDeleted = action.payload.data;
        const newInvoices = state.invoices.filter(
          (x) => !invoicesDeleted.includes(x._id)
        );
        state.invoices = newInvoices;
      })
      .addCase(deleteInvoices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetInvoices, setInvoices, invoiceUpdated } =
  invoicesSlice.actions;
export default invoicesSlice.reducer;

export const selectAllInvoices = (state) => state.invoices.invoices;
export const selectAllInvoicesOptions = (state) => {
  return state.invoices.invoices.map((obj) => {
    return {
      value: obj,
      label: obj.name,
    };
  });
};

export const selectInvoicesJobs = (state, invoiceId) => {
  if (!invoiceId) return;
  return state.invoices.invoices
    .filter((invoice) => invoice._id === invoiceId)[0]
    .jobs.map((obj) => {
      return {
        value: obj,
        label: obj.name,
      };
    });
};
