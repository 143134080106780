import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../../../shared/Table/table.utils";
import { statusOptions } from "../../../../constants/select";
import { formatDate, renderAmount } from "../../../../utils";
import { Badge } from "../../../../shared";

// Config for table and form
const tableConfig = [
  {
    id: "payoutId",
    visible: true,
    header: "Payout id",
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/payouts/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "dateCreated",
    cell: ({ row: { original }, getValue }) => {
      return formatDate(getValue(), null, true);
    },
  },
  {
    id: "driver",
    visible: false,
    cell: ({ getValue }) => {
      if (!getValue()) return;
      return getValue().fullName;
    },
  },
  {
    id: "total",
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    id: "status",
    editable: {
      type: "select",
      options: statusOptions,
    },
    cell: ({ getValue }) => getValue(),
  },
  {
    header: "Manifest",
    id: "manifestId",
    minWidth: 200,
    cell: ({ getValue }) => {
      if (getValue()) {
        return (
          <Badge
            link={`/manifests/${getValue()._id}`}
            label={`${getValue().manifestId}`}
          ></Badge>
        );
      }
      return null;
    },
    visible: true,
  },
  // {
  //   id: "active",
  //   cell: ({ row: { original }, value }) => {
  //     return value ? (
  //       <CheckCircleIcon className="w-5 h-5 text-green-500" />
  //     ) : (
  //       <XCircleIcon className="w-5 h-5 text-red-500" />
  //     );
  //   },
  // },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = { validations: renderValidations(tableConfig) };
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Jobs", value: 10 },
  { label: "20 Jobs", value: 20 },
  { label: "50 Jobs", value: 50 },
  { label: "100 Jobs", value: 100 },
  { label: "150 Jobs", value: 150 },
];
