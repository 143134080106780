import {
  ChevronDownIcon,
  PlusIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../shared/Avatar";
import Button from "../../../shared/Button";
import Input from "../../../shared/Input";
import Modal from "../../../shared/Modal";
import Select from "../../../shared/Select";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import request, { renderQueries } from "../../../utils/request";
import InputDropdown from "../../../shared/InputDropdown";
import { useNavigate } from "react-router-dom";
import AdvancedSettings from "./AdvancedSettings";
import {
  driverSelectOptions,
  fetchDrivers,
} from "../../../redux/reducers/drivers/driversSlice";
import { DatePicker } from "../../../shared";
import { formatDate } from "../../../utils";
import { fetchLegs } from "../../../redux/reducers/legs/legsSlice";
import { update } from "lodash";
import { toast } from "react-toastify";
import { useAddManifestMutation } from "../../../redux/services/manifests/manifestsApi";
import { Popover, PopoverGroup } from "@headlessui/react";
import { endOfDay, startOfDay } from "date-fns";
import "./CreateModal.css"; // We'll create this file for component-specific styles
import DateRangePicker from "../../../shared/DatePicker/DateRange";
// import DateRangePicker from "../../../shared/DatePicker/DateRange";

const CreateModal = ({
  modalOpen,
  setModalOpen,
  renderId = (id) => id,
  ...props
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { form, updateForm, clearForm, setFormInit } = useForm();
  const [loading, setLoading] = useState(false);

  const [recentDrivers, setRecentDrivers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // const [driverOptions, setDriverOptions] = useState([]);

  const [addManifest, { isLoading: addingManifest }] = useAddManifestMutation();

  const driverOptions = useSelector(driverSelectOptions);
  const driversStatus = useSelector((state) => state.drivers.status);

  const legs = useSelector((state) => state.legs.legs);
  const legsStatus = useSelector((state) => state.legs.status);

  useEffect(() => {
    if (!props.driver && driversStatus === "idle") {
      dispatch(
        fetchDrivers({
          parent: user.parentCompany,
          type: "driver",
        })
      );
    }
  }, [driversStatus, user, props.driver]);

  // const handleDateRangeChange = (args) => {
  //   if (args.startDate && args.endDate) {
  //     updateForm((prevForm) => ({
  //       ...prevForm,
  //       startDate: args.startDate.toISOString(),
  //       endDate: args.endDate.toISOString(),
  //     }));

  //     console.log("Date range updated:", {
  //       startDate: args.startDate.toISOString(),
  //       endDate: args.endDate.toISOString(),
  //     });
  //   }
  // };

  const handleDateUpdate = () => {
    const query = {
      parent: user.parentCompany,
      driverId: form.driver._id,
      manifestId: null,
      route: true,
      // pickupDate: form.pickupDate,
      startDate: startOfDay(form.startDate).toISOString(),
      endDate: endOfDay(form.endDate).toISOString(),
    };
    getCount(query);
  };

  const getCount = async (query) => {
    query.countOnly = true;
    const res = await request(`/legs?${renderQueries(query)}`);
    updateForm({ id: "count", value: res.data.data || "0" });
  };

  useEffect(() => {
    if (form.startDate && form.endDate && form.driver) {
      handleDateUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.startDate, form.endDate, form.driver]);

  useEffect(() => {
    if (props.driver && modalOpen) {
      setFormInit({ driver: props.driver });
    }
  }, [setFormInit]);

  // async function getRecentDrivers() {
  //   const res = await request(
  //     "/drivers/recent?" + renderQueries({ parent: user.parentCompany })
  //   );

  //   if (res.data.status === "success") {
  //     setRecentDrivers(res.data.data);
  //   }
  // }

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(async () => {
  //     if (searchTerm) {
  //       const res = await request(
  //         "/drivers/search?" +
  //           renderQueries({ parent: user.parentCompany, search: searchTerm })
  //       );

  //       const { data } = res.data;
  //     }
  //   }, 1000);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchTerm]);

  // useEffect(() => {
  //   if (recentDrivers.length === 0) {
  //     getRecentDrivers();
  //   }
  // }, [recentDrivers.length]);

  function closeModal() {
    setModalOpen(false);
    handleCancel();
  }

  const footer = (
    <div>
      <Button className="mr-3" onClick={closeModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={addingManifest}
        onClick={() => saveManifest()}
        type="primary"
        disabled={form.count === 0 || addingManifest}
      >
        Submit
      </Button>
    </div>
  );

  async function saveManifest() {
    const postData = {
      ...form,
      startDate: startOfDay(form.startDate).toISOString(),
      endDate: endOfDay(form.endDate).toISOString(),
    };

    const res = await addManifest(postData).unwrap();

    toast(res.message, {
      toastId: "add-manifest",
      type: res.status,
    });

    if (res.status === "success") {
      navigate(`/manifests/${res.data._id}`);
    }

    // const { data } = await request(
    //   "/manifests?" + renderQueries({ parent: user.parentCompany }),
    //   {
    //     method: "post",
    //     data: postData,
    //   }
    // );

    // if (data.status === "success") {
    //   navigate(
    //     "/manifests/" + data.data._id
    //     // data.data._id
    //   );
    // }
  }

  function handleSelectDriver(recentDriver) {
    updateForm({ id: "driver", value: recentDriver });
  }

  function handleCancel() {
    clearForm("driver");
  }

  const commonInputStyles = {
    width: "100%",
    height: "40px",
    border: "1px solid #d1d5db",
    borderRadius: "0.375rem",
    fontSize: "14px",
    color: "#374151",
    padding: "0 12px",
    boxSizing: "border-box",
  };

  const dropCountStyles = {
    ...commonInputStyles,
    backgroundColor: "#f3f4f6",
  };

  const createModalContent = (
    <div className="space-y-3">
      {!form.driver ? (
        <div className="space-y-3">
          <div className="col-span-2 md:col-span-1 md:w-1/2  xl:col-span-2 ">
            <Select
              search
              searchKeys={["fullName"]}
              id={renderId("driver")}
              value={form.driver}
              onChange={updateForm}
              label="Driver"
              options={driverOptions}
              placeholder="Select Driver"
            />
          </div>
          {/* <InputDropdown
            onChange={(e) => setSearchTerm(e.value)}
            value={searchTerm}
            placeholder="Search drivers by name"
            data={driverOptions}
          /> */}
          {/* <div className="flex items-center justify-between">
            <div>Recent Drivers</div>
            {/* <div className="flex items-center">
              <PlusIcon className="w-5 h-5 mr-1" />
              Add new customer
            </div> */}
          {/* </div> */}
          {/* <div>
            {recentDrivers.map((recent, i) => {
              return (
                <AvatarClient
                  key={i}
                  onClick={() => handleSelectDriver(recent)}
                  {...recent}
                />
              );
            })}
          </div> */}
        </div>
      ) : (
        <>
          <div className="mb-5 ">
            <AvatarClient selected onCancel={handleCancel} {...form.driver} />
          </div>
          <div className="mb-5 items-center gap-4 mx-5 grid grid-cols-1 w-1/2 md:w-full md:grid-cols-3">
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date Range
              </label>
              {/* <DateRangePicker
                id="dateRange"
                label="Date Range"
                placeholder="Choose date range"
                startDate={form.startDate ? new Date(form.startDate) : null}
                endDate={form.endDate ? new Date(form.endDate) : null}
                format="MM/dd/yyyy"
                change={handleDateRangeChange}
                cssClass="custom-date-range"
                style={dateRangeStyles}
                showClearButton={false}
                monthsCount={1}
                enableRtl={false}
              /> */}
              <DateRangePicker
                startDate={form.startDate ? new Date(form.startDate) : null}
                endDate={form.endDate ? new Date(form.endDate) : null}
                onChange={([start, end]) => {
                  updateForm((prevForm) => ({
                    ...prevForm,
                    startDate: start ? start.toISOString() : null,
                    endDate: end ? end.toISOString() : null,
                  }));
                }}
                className="w-full"
              />
            </div>
            <div className="">
              <Input
                disabled
                className=" !w-1/2"
                // style={dropCountStyles}
                label="Drop Count:"
                value={form.count || 0}
              />
            </div>
          </div>

          {/* <AdvancedSettings form={form} updateForm={updateForm} /> */}
        </>
      )}
    </div>
  );

  const title = <span className="mx-4">Select a driver</span>;

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={createModalContent}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};

const AvatarClient = (props) => {
  return (
    <div
      onClick={props.onClick}
      className="flex items-center justify-between hover:bg-gray-100 rounded-lg py-2 px-1 cursor-pointer"
    >
      <div className="flex items-center space-x-2">
        <Avatar size="xs" src={props.avatar} />
        <span className="font-semi-bold text-sm">
          {props.fullName}{" "}
          {/* <span className="text-gray-500">{`(${props.slug.toUpperCase()})`}</span> */}
        </span>
      </div>
      <div>
        {props.selected && (
          <XMarkIcon onClick={props.onCancel} className="w-5 h-5" />
        )}
      </div>
    </div>
  );
};

export default CreateModal;
