import { Popover } from "@headlessui/react";
import { ClockIcon, PhoneIcon } from "@heroicons/react/24/outline";
import Badge from "../../shared/Badge";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../shared/Table/table.utils";
import { addressValidations } from "../../constants/validations";
import { getFormattedTime } from "../../utils";

// Config for table and form
const tableConfig = [
  {
    id: "phone",
    cell: ({ getValue }) => {
      return getValue();
    },
  },
  {
    header: "precall",
    id: "precall",
    cell: ({ getValue }) => {
      return getValue() ? <Badge>Precall</Badge> : "";
    },
  },
  {
    id: "note",
    cell: ({ getValue }) => {
      return getValue() ? (
        <div>
          <Popover
            className="w-43"
            position="top"
            trigger={<Badge>Note</Badge>}
            offset={[-12, 0]}
          >
            {<div>{getValue()}</div>}
          </Popover>
        </div>
      ) : (
        ""
      );
    },
  },
  {
    id: "receivingHours",
    header: "hours",
    cell: ({ getValue }) => {
      return getValue()
        ? `${getFormattedTime(getValue()?.open)} - ${getFormattedTime(
            getValue()?.close
          )}`
        : "";
    },
  },
  {
    id: "address",
    accessorKey: "address.fullAddress",
    cell: ({ getValue }) => {
      return getValue() && getValue();
    },
    minWidth: 170,
  },
  { id: "dateUpdated", visible: false },
  { id: "dateCreated", visible: false },
];

export const FORM_OPTIONS = {
  validations: renderValidations([...tableConfig, ...addressValidations]),
};
export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const PAGE_SIZE_OPTIONS = [
  { label: "20 Locations", value: 20 },
  { label: "50 Locations", value: 50 },
  { label: "100 Locations", value: 100 },
  { label: "150 Locations", value: 150 },
];
