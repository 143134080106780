import { Link } from "react-router-dom";

import { renderCustomColumnDefs } from "../../../shared/Table/table.utils";
import {
  capitalizeAll,
  formatDate,
  parseFixed,
  renderAmount,
  renderAddress,
} from "../../../utils";
import { loadTypeOptions } from "../../Jobs/select.config";
import CustomDriverTableEdit from "./CustomDriverTableEdit";

// Config for table and form
const tableConfig = [
  {
    Header: "#",
    accessor: "",
    Cell: (row) => {
      return row.row.id * 1 + 1;
    },
    disableSortBy: true,
    disableFilters: true,
  },
  {
    id: "appointment",
    header: "Pickup Date",
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) {
        return null;
      }
      return formatDate(value?.date, null, true);
    },
    width: 120,
  },
  {
    id: "terminationDate",
    header: "Drop Date",
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) {
        return null;
      }
      return formatDate(value, null, true);
    },
  },
  {
    Header: "Order",
    id: "orderNumber",
    cell: ({ getValue }) => getValue(),
  },
  {
    Header: "Pickup Location",
    accessorFn: (row) => row.route?.origin,
    cell: ({ getValue }) => renderAddress(getValue()),
  },
  {
    Header: "Dropoff Location",
    accessorFn: (row) => row.route?.destination,
    cell: ({ getValue }) => renderAddress(getValue()),
  },
  { id: "weight", cell: ({ getValue }) => getValue() },
  {
    header: "Trailer #",
    id: "equipment",
    cell: ({ getValue }) => getValue(),
  },
  {
    header: "Mileage",
    id: "route",
    // editable: { type: "number" },
    cell: ({ getValue, row }) => {
      const value = getValue();
      if (!value) {
        return null;
      }
      if (row.original.useCustomManifestMileage) {
        return value && <div>{row.original.customManifestMileage} mi</div>;
      }

      return `${parseFixed(
        value.defaultMileage || value.mileage
      ).toLocaleString("en-US")} mi`;
    },
  },
  {
    header: "Job Load #",
    id: "jobId",
    minWidth: 120,
    cell: ({ getValue }) => {
      const value = getValue();
      return value?.loadNumber;
    },
  },
  {
    id: "waitTime",
    minWidth: 100,
    header: "Standby Time",
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) {
        return null;
      }
      return `${value?.total} hrs`;
    },
  },
  { header: "Offce Use", id: "office", visible: false },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
