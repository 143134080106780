import { Popover, Transition } from "@headlessui/react";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import Calendar from "../Calendar";
import Input from "../Input";

export const DatePicker = (props) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    props.value && setValue(props.value);
  }, [props.value]);

  function handleChange(e) {
    const finalValue = formatDate(e);
    const dbValue = e;
    const data = {
      value: dbValue,
    };

    if (props.id) {
      data.id = props.id;
    }

    props.onChange && props.onChange(data);
    !props.onChange && setValue(finalValue);
  }

  function formatDate(value, dbFormat) {
    const newDate = new Date(value);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const date = newDate.getDate();

    if (dbFormat) {
      return `${month}-${date}-${year}`;
    }

    return `${month}-${date}-${year}`;
  }

  const trigger = (
    <Input
      placeholder={props.placeholder}
      value={value}
      label={props.label}
      size={props.size}
      icon={<CalendarDaysIcon className="w-5 h-5" />}
      onBlur={props.onBlur}
    />
  );

  const calendar = <Calendar value={value} onChange={handleChange} />;

  // return <Dropdown align="left" trigger={trigger} data={drop} />;
  return (
    <Popover className="relative">
      <Popover.Button as={Fragment}>{trigger}</Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute z-10">{calendar}</Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default DatePicker;
