import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../shared/Button";
import Table from "../../../../shared/Table";
import { useAuth } from "../../../../hooks/useAuth";
import { updatePayout } from "../../../../redux/reducers/payouts/payoutSlice";
import {
  fetchPayouts,
  payoutUpdated,
  resetPayouts,
} from "../../../../redux/reducers/payouts/payoutsSlice";
import { COLUMN_DEFINITIONS } from "./table.config";
import CreateModal from "../../../Payouts/CreateModal";

const DriverPayouts = ({ driver, payouts }) => {
  const { user } = useAuth();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const payoutsStatus = useSelector((state) => state.payouts.status);

  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    if (user._id && params.id && payoutsStatus === "idle") {
      dispatch(
        fetchPayouts({
          parent: user.parentCompany,
          userId: user._id,
          driverId: params.id,
        })
      );
    }
  }, [payoutsStatus, dispatch, user, driver]);

  useEffect(() => {
    return () => dispatch(resetPayouts());
  }, [dispatch]);

  async function handleUpdate(e, setLoading) {
    const update = {
      parentId: user.parentCompany,
      payoutId: e.row.original._id,
      data: e.data,
    };

    const res = await dispatch(updatePayout(update)).unwrap();

    if (res.status === "success") {
      dispatch(payoutUpdated(res.data));

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  function handleClick() {
    navigate(`/payouts/create`);
  }

  return (
    <div className="xl:col-span-6 col-span-8 max-h-[450px]">
      <Table
        // mainAction={{ onClick: () => setCreateModalOpen(true) }}
        scrollable
        actions={<Button title="Create Payout" onClick={handleClick} />}
        id="jobs"
        title="Payouts"
        loading={payoutsStatus === "loading"}
        columns={COLUMN_DEFINITIONS}
        data={payouts}
        hideCheckbox
        updateData={handleUpdate}
      />
      <CreateModal
        modalOpen={createModalOpen}
        driver={driver}
        setModalOpen={setCreateModalOpen}
      />
    </div>
  );
};

export default DriverPayouts;
