import React, { forwardRef, useCallback } from "react";
import Table from "../../../shared/Table";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";

const JobsTable = forwardRef(({ data, setSelectedJobs }, ref) => {
  const handleRowSelection = useCallback(
    (value) => setSelectedJobs(value),
    [setSelectedJobs]
  );

  return (
    <Table
      ref={ref}
      search
      title="Jobs"
      columns={COLUMN_DEFINITIONS}
      data={data}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      onSelectedRowsChange={handleRowSelection}
      pagination
    />
  );
});

export default JobsTable;
