import React, { useState } from "react";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { MaintenanceTable } from "./Table";

const EquipmentMaintenance = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (args) => {
    setSelectedDate(args.value);
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-between bg-white pb-2">
        <div>
          <h2 className="text-xl font-semibold">Equipment Maintenance</h2>
        </div>
        <div>
          <DatePickerComponent
            value={selectedDate}
            change={handleDateChange}
            placeholder="Select a date"
            format="MM/dd/yyyy"
          />
        </div>
      </div>
      <MaintenanceTable />
    </div>
  );
};

export default EquipmentMaintenance;
