import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const initialState = {
  customers: [],
  status: "idle",
  error: null,
};

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async (obj, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/customers?${queries}`);
    if (!res.data.customers) {
      rejectWithValue();
    }
    return res.data;
  }
);

export const deleteCustomers = createAsyncThunk(
  "customers/deleteCustomers",
  async ({ data, parentId }, { rejectWithValue }) => {
    const res = await request(`/customers?parent=${parentId}`, {
      method: "delete",
      data,
    });
    if (!res.data.customers) {
      rejectWithValue();
    }
    return res.data;
  }
);

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    resetCustomers: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCustomers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.customers = [];
        state.status = "succeeded";
        state.customers = action.payload.data;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteCustomers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteCustomers.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { data } = action.payload;
        const newCustomers = state.customers.filter(
          (x) => !data.includes(x._id)
        );
        state.customers = newCustomers;
      })
      .addCase(deleteCustomers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default customersSlice.reducer;

export const { resetCustomers } = customersSlice.actions;

export const selectAllCustomers = (state) => state.customers.customers;
export const selectAllCustomersOptions = (state) => {
  return state.customers.customers.map(({ name }) => {
    return {
      value: name,
      label: name,
    };
  });
};
export const selectCustomersById = (state, customerId) =>
  state.customers.customers.find((customer) => customer.id === customerId);
