import { useNavigate } from "react-router-dom";
import Tabs from "../../../../shared/Tabs";
// import History from "./History";
// import All from "./All";
import Notes from "./Notes";
import { useEffect, useMemo, useRef, useState } from "react";
import { isAuthorized } from "../../../../utils/authority";

const SidebarContent = ({ job, form, updateForm, onFormChange, ...props }) => {
  const navigate = useNavigate();

  const tabs = [
    // {
    //   id: "All",
    //   content: <All />,
    // },
    {
      id: "Notes",
      content: <Notes job={job} form={form} onFormChange={onFormChange} />,
      hidden: !isAuthorized("owner,admin"),
    },
    // {
    //   id: "History",
    //   content: <History />,
    // },
  ];

  function handleTabClick(_e, _item, path) {
    navigate(path);
  }

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const parentHeight = 0;
  const offsetTop = headerContext?.offsetTop;
  const offsetHeight = headerContext?.offsetHeight;
  const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
  const panelStyle = {
    height: `${parentHeight - offsetTop - offsetHeight - 38}px`,
  };

  const sideTabs = useMemo(() => tabs.filter((item) => !item.hidden), []);

  return (
    <div className="flex flex-grow overflow-hidden">
      <Tabs
        className="w-full overflow-hidden"
        style={wrapperStyle}
        panelStyle={panelStyle}
        useSearchParams
        panelClasses="border-t overflow-y-scroll"
        tabListClasses="bg-white px-2 h-[38px]"
        tabclassName="mx-1"
        id="job-comms"
        onClick={handleTabClick}
        data={sideTabs}
      />
    </div>
  );

  // <>
  //   <div className="flex-grow h-full">
  //     <Tabs
  //       panelClasses="flex-auto bg-white"
  //       panelItemClasses="bg-gray-50 h-full"
  //       useSearchParams
  //       tabListClasses="bg-white px-2 flex-initial border-b"
  //       tabclassName="mx-1"
  //       id="commsMenu"
  //       onClick={handleTabClick}
  //       data={tabs}
  //     />
  //   </div>
  // </>
};

export default SidebarContent;
