import {
  camelCaseToSpaces,
  capitalizeFirstLetter,
  isObject,
} from "../../utils";
import _ from "lodash";

export function renderColumnDefs(arr) {
  if (!arr || arr.length === 0) return [];

  const data = arr;
  let tableKeys;
  data.forEach((item, i) => {
    const objKeys = Object.keys(item);
    const newData = objKeys.map((item) => {
      const tableItemData = data[i][item];

      if (typeof tableItemData === "boolean") {
        return {
          size: item.width || item.size,
          minSize: item.minWidth || item.minSize,
          enableHiding: true,
          accessorKey: item,
          header: camelCaseToSpaces(item),
          cell: tableItemData.toString(),
        };
      }

      if (item.charAt(0) === "_") {
        return {
          size: item.width || item.size,
          minSize: item.minWidth || item.minSize,
          enableHiding: true,
          accessorKey: item,
          header: camelCaseToSpaces(item),
          visible: false,
        };
      }

      if (
        isObject(tableItemData) ||
        Array.isArray(tableItemData) ||
        typeof item === "boolean"
      ) {
        return {
          size: item.width || item.size,
          minSize: item.minWidth || item.minSize,
          enableHiding: true,
          accessorKey: item,
          header: camelCaseToSpaces(item),
          cell: JSON.stringify(tableItemData),
        };
      }

      return {
        ...item,
        size: item.width || item.size,
        minSize: item.minWidth || item.minSize,
        enableHiding: true,
        accessorKey: item.accessor || item.id,
      };
    });

    tableKeys = [...new Set([...newData])];
  });

  return renderCustomColumnDefs(tableKeys || []);
}

export function renderCustomColumnDefs(arr, id) {
  const res = arr || [];

  return res
    .filter((item) => !item.remove)
    .map((item, i) => {
      if (typeof item === "string") {
        return {
          enableHiding: true,
          accessorKey: item,
          header: camelCaseToSpaces(item),
          id: item,
        };
      }

      return {
        ...item,
        size: item.width || item.size,
        minSize: item.minWidth || item.minSize,
        cell: item.cell || item.Cell,
        header: camelCaseToSpaces(item.Header || item.header || item.id),
        accessorKey: item.accessor || item.accessorKey || item.id,
        meta: { editable: item.editable },
      };
    });
}

export function renderAllColumns(cols, data) {
  const fullColDefs = renderColumnDefs(data).map((item) => ({
    ...item,
    size: item.width || item.size,
    minSize: item.minWidth || item.minSize,
    accessorKey: item.accessor || item.id,
    visible: false,
  }));
  const customColDefs = cols;

  const res = _.differenceBy(fullColDefs, customColDefs, "accessorKey");

  return [...res, ...customColDefs];
}

// export function renderDefaultVisible(arr) {
//   return arr.map((key) => {
//     return isObject(key) && key.visible && key.id;
//   });
// }

export function renderValidations(arr) {
  const data = arr;
  const res = {};
  data.forEach((item) => {
    if (isObject(item)) {
      const id = item.id;
      return (
        id &&
        (item.required || item.custom || item.pattern || item.validations) &&
        (res[id] = {
          ...item,
        })
      );
    }
  });

  return res;
}

export function renderDefaultVisible(data) {
  const hidden = data
    .filter((col) => {
      if (typeof col?.visible === "boolean" && !col.visible) {
        return true;
      }
      return false;
    })
    .map(({ accessorKey }) => accessorKey)
    .reduce((a, v) => ({ ...a, [v]: false }), {});
  return hidden;
}

export function renderDefaultSelected(data, options) {
  if (options === "all") {
    return Array.from(Array(data.length).keys()).reduce((acc, current) => {
      return (acc = { ...acc, [current]: true });
    }, {});
  }
  return;
}

export function renderLabelRequired(label) {
  return (
    <div>
      {label} - <span className="text-red-500">Required</span>
    </div>
  );
}

export function renderLabelOptional() {}
