import { formatInTimeZone } from "date-fns-tz";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function formatTimezone(
  date,
  tz = timezone,
  format = "yyyy-MM-dd HH:mm:ss zzz",
  options
) {
  return formatInTimeZone(date, tz, format, options);
}
