import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const equipmentApi = api.injectEndpoints({
  tagTypes: ["Equipment"],
  endpoints: (builder) => ({
    getEquipment: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = getSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `equipment?${query}`;
      },
      transformResponse: (response) => response.data,
      providesTags: (data) =>
        data?.equipment
          ? [
              ...data.equipment.map(({ _id }) => ({
                type: "Equipment",
                id: _id,
              })),
              { type: "Equipment", id: "LIST" },
            ]
          : [{ type: "Equipment", id: "LIST" }],
    }),
    addEquipment: builder.mutation({
      query: (data) => ({
        url: "equipment",
        method: "POST",
        body: data,
      }),
    }),
    deleteEquipment: builder.mutation({
      query: (data) => ({
        url: `equipment/${data._id}`,
        method: "DELETE",
      }),
    }),
    updateEquipment: builder.mutation({
      query: (data) => ({
        url: `equipment/${data._id}`,
        method: "PUT",
        body: data,
      }),
    }),
    getEquipmentById: builder.query({
      query: ({ id, ...rest }) => `equipment/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEquipmentQuery,
  useAddEquipmentMutation,
  useDeleteEquipmentMutation,
  useUpdateEquipmentMutation,
  useGetEquipmentByIdQuery,
} = equipmentApi;
