import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import request from "../utils/request";
import { useAuth } from "./useAuth";
import { toast } from "react-toastify";
import { isObject } from "../utils";

const contextDefaultValues = {
  configs: { parentCompany: {} },
  addConfig: () => {},
  deleteConfig: () => {},
};

export const ConfigContext = createContext(contextDefaultValues);

const ConfigProvider = (props) => {
  const [configs, setConfigs] = useState(props.configJson);
  const { user } = useAuth();

  const parentCompany = useMemo(
    () =>
      isObject(user.parentCompany)
        ? user.parentCompany._id
        : user.parentCompany,
    [user.parentCompany]
  );

  useEffect(() => {
    const fetchConfigs = async () => {
      if (!configs && user.active) {
        try {
          let configData = await request(`/config/${parentCompany}?`);

          if (!configData.data.config) {
            configData = await request(`/config/${parentCompany}?type=parent`, {
              method: "post",
              data: {},
            });
          }

          const {
            data: { config },
          } = configData;

          localStorage.setItem("appType", config.configs.parentCompany.appType);

          setConfigs(config ? config.configs : {});
        } catch (err) {
          // Consider adding error handling here
        }
      }
    };

    fetchConfigs();
  }, [configs, user.active, parentCompany]);

  const addConfig = useCallback(
    async (newConfigItem) => {
      const newConfig = await request(`/config/${parentCompany}`, {
        method: "put",
        data: newConfigItem,
      });

      if (newConfig.data.status === "success") {
        setConfigs(newConfig.data.config.configs);
        toast(newConfig.data.message, {
          toastId: "addConfig.hook." + new Date().toISOString(),
          type: newConfig.data.status,
        });
      }
    },
    [parentCompany]
  );

  const deleteConfig = useCallback(
    async (configData) => {
      const configDelete = await request(`/config/${parentCompany}`, {
        method: "delete",
        data: configData,
      });

      if (configDelete.data.status === "success") {
        setConfigs(configDelete.data.config.configs);
        toast(configDelete.data.message, {
          toastId: "deleteConfig.hook",
          type: configDelete.data.status,
        });
      }
    },
    [parentCompany]
  );

  const contextValue = useMemo(
    () => ({
      configs: configs || {},
      addConfig,
      deleteConfig,
    }),
    [configs, addConfig, deleteConfig]
  );

  return (
    <ConfigContext.Provider value={contextValue}>
      {props.children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  return useContext(ConfigContext);
};

export default ConfigProvider;
