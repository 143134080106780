import TEMP_DATA from "../../TEMP_DATA_GENERATOR";

export const accountType = [
  { label: "Admin", value: "Admin" },
  { label: "Driver", value: "Driver" },
  { label: "Client", value: "Client" },
];
export const accountTypes = accountType;

export const driverSelectOptions = TEMP_DATA(
  (e) => ({
    value: e,
    label: `Driver ${e}`,
  }),
  10
);

export const originTerminateOptions = [
  { label: "APM", value: 1 },
  { label: "Pier A", value: 2 },
  { label: "LBCT", value: 3 },
  { label: "PCT", value: 3 },
  { label: "LAX", value: 3 },
  { label: "Yard", value: 3 },
];

export const destinationOptions = [
  { label: "HFT CACTUS", value: 1 },
  { label: "HFT NANDINA", value: 2 },
  { label: "HFT ", value: 3 },
];

export const authorityOptions = [
  { label: "Admin", value: "admin" },
  { label: "User", value: "user" },
  { label: "Client", value: "client" },
];
