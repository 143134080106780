import classNames from "classnames";
import Card from "../../shared/Card";
import Spinner from "../../shared/Spinner";
import { renderAmount } from "../../utils";

const TopRowCard = ({ title, body, loading, color, money }) => {
  const bodyClasses = classNames(
    "text-center text-3xl md:text-4xl lg:text-3xl xl:text-5xl my-2 whitespace-nowrap overflow-x-auto scrollbar-hide",
    [`text-emerald-500`],
    {
      "!text-red-400": money && body < 0,
      "!text-yellow-400": money && body === 0,
    }
  );
  return (
    <Card className="min-h-full rounded-lg row-span-1 col-span-1 md:col-span-2 lg:col-span-3 flex flex-col font-bold">
      {/* <div className="justify-center col-span-1 items-start"> */}
      <span className="text-xs  md:text-sm uppercase text-gray-500 ">
        {title}
      </span>
      <span className={bodyClasses}>
        {loading ? <Spinner size="md" /> : money ? renderAmount(body) : body}
      </span>
      {/* </div> */}
    </Card>
  );
};

export default TopRowCard;
