import React, { useState, useEffect, useRef } from "react";
import useForm from "../../../hooks/useForm";
import FormLayout from "../../../layouts/FormLayout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Timeline from "../../../shared/Timeline";
import classNames from "classnames";
import Button from "../../../shared/Button";
import CompanyInfoForm from "./Info";
import CompanyUsers from "./Users";
import Review from "./Review";
import CompanyLocations from "./Locations";
import CompanyRates from "./CompanyRates";
import { addNewClient } from "../../../redux/reducers/clients/clientSlice";
import { useAuth } from "../../../hooks/useAuth";
import { FORM_OPTIONS } from "../table.config";
import MultiFormCreate from "../../../shared/MultiFormCreate";
import _ from "lodash";
import { SidebarContainer } from "../../../components";

const ClientOnboarding = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeSlide, setActiveSlide] = useState(1);
  const [activeValid, setActiveValid] = useState(false);
  const {
    form,
    updateForm,
    isValid,
    errors,
    clearForm,
    setFormInit,
    hasBeenInit,
  } = useForm(FORM_OPTIONS);

  function onFormChange(e, options = {}) {
    const { value, id } = e;
    if (id === "users" || id === "locations") {
      options.formArray = true;
    }
    updateForm({ value, id }, options);
  }

  async function handleSubmit() {
    // const formKeys = Object.keys(form);
    // const userData = Object.keys(form.users).map((item) => {
    //   return { ...form.users[item] };
    // });

    const locationData = Object.keys(form.locations).map((item) => {
      return { ...form.locations[item] };
    });

    const submitData = {
      user: form.user,
      locations: locationData,
      name: form.name,
      coowner: form.coowner,
      rates: {
        regular: form.regular,
        hot: form.hot,
        layover: form.layover,
        deadhead: form.deadhead,
        reroute: form.reroute,
        return: form.return,
        internet: form.internet,
        backhaul: form.backhaul,
        standBy: form.standBy,
        fuel: form.fuel,
        baseRate: form.baseRate,
      },
      address: {
        address: form.address,
        city: form.city,
        suite: form.suite,
        zipcode: form.zipcode,
        state: form.state,
        country: form.country,
        notes: form.note,
      },
      email: form.email,
      phone: form.phone,
    };
    const res = await dispatch(
      addNewClient({ data: submitData, parentId: user.parentCompany })
    ).unwrap();
    if (res.status === "success") {
      navigate(`/clients/${res.data._id}`);
    }
  }

  const timeline = [
    {
      id: 1,
      title: "Company Info",
    },
    {
      id: 2,
      title: "Users",
    },
    {
      id: 3,
      title: "Locations",
    },
    {
      id: 4,
      title: "Rates",
    },
    {
      id: 5,
      title: "Review",
    },
  ];

  const timelineLayout = (item, i, self) => {
    const active = item.id === activeSlide;
    const last = self.length === i + 1;

    const dotClass = classNames(
      "flex z-10 justify-center items-center w-4 h-4 rounded-full shrink-0",
      { "bg-primary-200": !active },
      { "bg-primary-500": active }
    );

    const wrapper = classNames(
      "relative my-3 w-full flex lg:flex-col lg:items-start items-center",
      {
        "col-start-1 lg:col-start-2": i === 0,
      }
    );
    return (
      <div className={wrapper}>
        <div className="flex items-center mr-3">
          {!last && (
            <>
              <div className={dotClass}></div>
              <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
            </>
          )}
          {last && <div className={dotClass}></div>}
        </div>
        <div className="flex justify-between">
          <div className="">
            <h3 className="text-lg font-semibold text-gray-900">
              {item.title}
            </h3>
          </div>
        </div>
      </div>
    );
  };

  function handleButtonClick() {
    if (activeSlide === timeline.length) {
      handleSubmit();
      return;
    }
    setActiveSlide(activeSlide + 1);
  }

  function handleBack() {
    if (activeSlide === 1) {
      navigate(-1);
      return;
    }
    setActiveSlide(activeSlide - 1);
  }

  const isInfoValid =
    form.name &&
    form.email &&
    form.address &&
    form.city &&
    form.state &&
    form.country &&
    form.zipcode &&
    Object.keys(errors).filter(
      (key) => !key.startsWith("user") || !key.startsWith("location")
    ).length === 0;

  function doKeysExist(data, id) {
    if (data) {
      const objKeys = Object.keys(data);
      const doesNotExist = objKeys.filter((key) => {
        return !data[key][key + "_" + id];
      });
      if (doesNotExist.length === 0) return true;
    }
  }
  // const userEmail = doKeysExist(form.users, "email");
  // const userName = doKeysExist(form.users, "firstName");
  // const isUserValid =
  //   userEmail &&
  //   userName &&
  //   Object.keys(errors).filter((key) => key.startsWith("user")).length === 0;

  const locAddress = doKeysExist(form.locations, "address");
  const locCity = doKeysExist(form.locations, "city");
  const locState = doKeysExist(form.locations, "state");
  const locCountry = doKeysExist(form.locations, "country");
  const locZip = doKeysExist(form.locations, "zipcode");
  const isLocationValid =
    locAddress &&
    locCity &&
    locState &&
    locCountry &&
    locZip &&
    Object.keys(errors).filter((key) => key.startsWith("location")).length ===
      0;

  function isCurrentFormValid() {
    switch (activeSlide) {
      // case 3:
      //   return isLocationValid;
      // case 2:
      //   return isUserValid;
      case 1:
        return isInfoValid;
      default:
        return true;
    }
  }

  useEffect(() => {
    const isFormValid = isCurrentFormValid();
    setActiveValid(isFormValid);
  });

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = (headerProps) => {
    return (
      <div ref={headerRef} className="p-2 border-b">
        {/* {actions} */}
      </div>
    );
  };

  return (
    <SidebarContainer header={renderHeader} hideSidebar className="w-full">
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = {
          height: `${parentHeight - offsetTop - offsetHeight}px`,

          // minHeight: `${parentHeight - offsetTop - offsetHeight - 5}px`,
        };
        // const panelStyle = {
        //   height: `${parentHeight - offsetTop - offsetHeight}px`,
        // };

        return (
          <div
            style={wrapperStyle}
            className="px-3 pb-3 h-auto overflow-y-auto w-full"
          >
            <FormLayout hideButtons handleSubmit={handleSubmit}>
              <div className="ml-50">
                <Timeline
                  className="!grid-cols-1 lg:!grid-cols-6 my-3"
                  inline
                  layout={timelineLayout}
                  timeline={timeline}
                />
              </div>
              <div>
                {activeSlide === 1 && (
                  <CompanyInfoForm
                    form={form}
                    errors={errors}
                    onFormChange={onFormChange}
                    clearForm={clearForm}
                  />
                )}
                {activeSlide === 2 && (
                  <CompanyUsers
                    handleChange={({ id, value }) =>
                      onFormChange({
                        id: "user",
                        value: { ...form.user, [id]: value },
                      })
                    }
                    form={form}
                    errors={errors}
                  />
                )}
                {activeSlide === 3 && (
                  <MultiFormCreate
                    clearForm={clearForm}
                    data={form.locations}
                    form={form}
                    setFormInit={setFormInit}
                    updateForm={updateForm}
                    onFormChange={onFormChange}
                    type="location"
                    formKey="locations"
                    // isValid={isLocationValid}
                    // onAddForm={onAddForm}
                  >
                    <CompanyLocations errors={errors} />
                  </MultiFormCreate>
                )}
                {activeSlide === 4 && (
                  <CompanyRates form={form} onFormChange={onFormChange} />
                )}
                {activeSlide === 5 && (
                  <Review
                    form={form}
                    onFormChange={onFormChange}
                    setActiveSlide={setActiveSlide}
                  />
                )}
              </div>
              <div className="flex justify-end mt-4">
                <Button
                  className="w-6/12 lg:w-28 mr-3"
                  type="alternative"
                  onClick={handleBack}
                >
                  {activeSlide !== 1 ? "Back" : "Cancel"}
                </Button>
                <Button
                  disabled={!activeValid}
                  className="w-6/12 lg:w-28"
                  onClick={handleButtonClick}
                >
                  {timeline.length === activeSlide ? "Save" : "Next"}
                </Button>
              </div>
            </FormLayout>
          </div>
        );
      }}
    </SidebarContainer>
  );
};
export default ClientOnboarding;
