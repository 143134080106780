import React from "react";
import { Dropdown } from "../shared";

export const AssignedToSelect = ({ value, onChange, error, users = [] }) => {
  const userOptions = users.map((user) => ({
    value: user.id,
    label: user.name,
    body: (
      <div className="flex items-center">
        <span className="text-sm">{user.name}</span>
        {user.role && (
          <span className="ml-2 text-xs text-gray-500">({user.role})</span>
        )}
      </div>
    ),
  }));

  return (
    <Dropdown
      trigger={
        <button className="flex items-center gap-2 w-full text-left rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500">
          <div className="flex -space-x-2">
            {value?.length ? (
              value.map((v, i) => (
                <div
                  key={i}
                  className="w-8 h-8 rounded-full bg-gray-200 border-2 border-white flex items-center justify-center text-sm font-medium"
                >
                  {v.label[0]}
                </div>
              ))
            ) : (
              <div className="w-8 h-8 rounded-full bg-gray-200 border-2 border-white flex items-center justify-center text-sm font-medium">
                T
              </div>
            )}
          </div>
          <span className="text-gray-700">
            {value?.length
              ? value.map((v) => v.label).join(", ")
              : "Select Technicians"}
          </span>
        </button>
      }
      data={userOptions.map((option) => ({
        title: option.body,
        onClick: () => onChange(option),
      }))}
      className="w-full"
    />
  );
};
