import classNames from "classnames";
import { isObject } from "lodash";
import React from "react";
import Card from "../../../../shared/Card";
import { transition } from "../../../../styles/constants";
import { camelCaseToSpaces, capitalizeFirstLetter } from "../../../../utils";

const Review = ({ form, setActiveSlide }) => {
  // const userKeys = Object.keys(form.users);
  // const userData = userKeys
  //   .filter((key) => key.startsWith("user"))
  //   .map((item, i) => {
  //     delete form.users[item].id;
  //     return form.users[item];
  //   });

  const locationKeys = form.locations && Object.keys(form.locations);
  const locationData =
    locationKeys &&
    locationKeys
      .filter((key) => key.startsWith("location"))
      .map((item, i) => {
        delete form.locations[item].id;
        return form.locations[item];
      });

  const infoData = {
    name: form.name,
    email: form.email,
    phone: form.phone,
    address: form.address,
    suite: form.suite,
    city: form.city,
    state: form.state,
    country: form.country,
    zipcode: form.zipcode,
    coowner: form.coowner,
  };

  const rateData = {
    regular: form.regular,
    hot: form.hot,
    layover: form.layover,
    deadhead: form.deadhead,
    reroute: form.reroute,
    return: form.return,
    internet: form.internet,
    backhaul: form.backhaul,
    standBy: form.standBy,
    baseRate: form.baseRate,
    fuel: form.fuel,
  };

  const data = {
    info: infoData,
    user: form.user,
    locations: locationData,
    rates: rateData,
  };

  function handleEditClick(e) {
    let res;
    switch (e) {
      case "rates":
        res = 4;
        break;
      case "locations":
        res = 3;
        break;
      case "users":
        res = 2;
        break;
      default:
        res = 1;
    }

    setActiveSlide(res);
  }

  return (
    <div className="mt-5 w-full">
      {Object.keys(data).map((item, i) => {
        return (
          <InfoCard
            title={capitalizeFirstLetter(item)}
            onEdit={handleEditClick}
            id={item}
            item={data[item]}
          />
        );
      })}
    </div>
  );
};

const InfoCard = ({ item, title, id, onEdit }) => {
  function renderData(data) {
    if (!data) return;
    let map;
    const isArray = Array.isArray(data);
    if (isArray) {
      map = data;
    } else {
      map = Object.keys(data);
    }

    const wrapperClasses = classNames(
      "grid gap-7",
      { "grid-cols-6": !isArray },
      { "grid-cols-1": isArray }
    );

    return (
      <div className={wrapperClasses}>
        {/* {isArray && map.map((item) => JSON.stringify(item))} */}
        {map.map((item) => {
          const isObj = isObject(item);
          const innerWrapperClasses = classNames({
            "flex flex-col w-full": !isObj,
            "grid gap-3 grid-cols-6": isObj,
          });
          return (
            <div className={innerWrapperClasses}>
              {!isObj ? (
                <>
                  <span className="text-gray-500 mb-1 font-semibold">
                    {capitalizeFirstLetter(camelCaseToSpaces(item))}
                  </span>
                  <span className="font-semibold text-lg overflow-x-auto [@media(max-width:767px)]:scrollbar-hide">
                    {data[item]}
                  </span>
                </>
              ) : (
                Object.keys(item).map((i) => {
                  return (
                    <div className="flex flex-col">
                      <span className="text-gray-500 mb-1 font-semibold">
                        {capitalizeFirstLetter(
                          camelCaseToSpaces(i.split("_").pop())
                        )}
                      </span>
                      <span className="font-semibold text-lg overflow-x-auto [@media(max-width:767px)]:scrollbar-hide">
                        {item[i]}
                      </span>
                    </div>
                  );
                })
              )}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <Card className="p-10 my-5">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-xl font-semibold mb-6">{title}</h2>
        </div>
        <div>
          <span
            onClick={() => onEdit(id)}
            className={
              "cursor-pointer hover:underline font-medium text-primary-500 pr-3 " +
              transition
            }
          >
            Edit
          </span>
        </div>
      </div>
      {renderData(item)}
    </Card>
  );
};

export default Review;
