import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import {
  fetchInvoices,
  selectAllInvoices,
  resetInvoices,
  invoiceUpdated,
} from "../../redux/reducers/invoices/invoicesSlice";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CreateModal from "./CreateModal";
import {
  useAddInvoiceMutation,
  useDeleteInvoicesMutation,
  useGetInvoicesQuery,
  useUpdateInvoiceMutation,
} from "../../redux/services/invoices/invoicesApi";
import { usePrefetch } from "../../redux/api";
import { useDebounceSearch } from "../../hooks";
import { isAuthorized } from "../../utils/authority";

const Invoices = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { search, handleSearch } = useDebounceSearch();

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const { data, isLoading, isFetching } = useGetInvoicesQuery({
    page: pageIndex + 1,
    limit: pageSize,
    search: search.useSearch,
    searchTerm: search.term,
  });
  const [updateInvoice, { isLoading: isUpdating }] = useUpdateInvoiceMutation();

  const [deleteInvoices, { isLoading: isDeleting }] =
    useDeleteInvoicesMutation();

  const prefetchPage = usePrefetch("getInvoices");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
      search: search.useSearch,
      searchTerm: search.term,
    });
  }, [prefetchPage, data?.nextPage, pageSize]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);

  const [createModalOpen, setCreateModalOpen] = useState(false);

  function handleEdit(itemId) {
    navigate(`/invoices/${itemId}`);
  }

  async function handleDelete(items) {
    const jobs = items.map((item) => item.jobs.map((i) => i._id)).flat();
    const res = await deleteInvoices({
      data: items.map((item) => item._id),
      jobs,
    });

    toast(res.data.message, {
      type: res.data.status,
      toastId: "delete-invoices",
    });

    return res.data;
  }

  async function handleUpdate(e, setLoading) {
    const updateData = {
      id: e.row.original._id,
      data: e.data,
    };

    const res = await updateInvoice(updateData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: `update-invoice-${res.status}`,
    });
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  function globalFilter(value) {
    setPagination({ pageIndex: 0, pageSize });
    handleSearch(value);
  }

  const cannotCreateOrEdit = !isAuthorized("owner, admin");

  return (
    <>
      <TableLayout
        id="invoices"
        useStyles
        loading={isLoading}
        columns={COLUMN_DEFINITIONS}
        data={data?.invoices}
        pagination={data}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPaginationChange={setPagination}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        onUpdate={handleUpdate}
        fullTextSearch={globalFilter}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
        deleteLoading={isDeleting}
        hideActions={cannotCreateOrEdit}
        hideMainAction={cannotCreateOrEdit}
        hideCheckbox={!isAuthorized("owner,admin")}
      />
      <CreateModal
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
      />
    </>
  );
};

export default Invoices;
