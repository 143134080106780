import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../shared/Table/table.utils";
import { formatDate, renderAmount } from "../../utils";
import _ from "lodash";

// Config for table and form
const tableConfig = [
  // {
  //   id: "loadNumber",
  //   accessorKey: "loadNumber",
  //   header: "Load Number",
  //   cell: (info) => info.getValue(),
  // },
  {
    id: "loadNumber",
    visible: true,
    required: true,
    minWidth: 100,
    editable: true,
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${original._id}`}
        >
          {getValue() || original.orderNumber}
        </Link>
      );
    },
  },
  {
    id: "transactionDate",
    cell: ({ getValue }) => {
      const value = getValue();
      return value && formatDate(new Date(value), null, true);
    },
    width: 100,
    visible: true,
  },
  {
    id: "status",
    // editable: {
    //   type: "select",
    //   options: jobStatusOptions,
    // },
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "client",
    visible: true,
    editable: false,
    cell: ({ getValue }) => {
      return getValue()?.name;
    },
    width: 140,
  },
  {
    id: "legs",
    header: "Drops",
    cell: ({ getValue }) => getValue().length,
    width: 100,
    required: true,
    custom: {
      isValid: (value, obj) => {
        if (!value) return;
        const type = "leg";
        let errors = {};
        let isValid = true;
        const map = Object.keys(value).map((key) => value[key]);
        map.forEach((item, i) => {
          const id = type + "_" + (i + 1);
          const orderNumber = id + "_orderNumber";

          Object.keys(item).forEach((key) => {
            let renderOrderNumberError = obj.hasBeenInit[orderNumber];

            if (obj.valueIsEmpty(renderOrderNumberError)) {
              isValid = false;
              return;
            }
            if (!item[orderNumber]) {
              errors[orderNumber] = "This field is required";
              isValid = false;
            }
          });
        });

        return { isValid, errors };
      },
    },
  },
  {
    id: "totalCharges",
    cell: ({ getValue }) => renderAmount(getValue()),
    minWidth: 140,
  },
  {
    id: "totalMileage",
    visible: false,
  },
  {
    id: "active",
    visible: false,
  },
  {
    id: "completed",
    visible: false,
  },
  {
    id: "author",
    visible: false,
    cell: ({ getValue }) => {
      return getValue()?.fullName;
    },
  },
  {
    id: "dateUpdated",
    visible: false,
  },
  {
    id: "dateCreated",
    visible: false,
  },
  {
    id: "_id",
    header: "id",
    visible: false,
  },
];

export const FORM_OPTIONS = {
  validations: renderValidations(tableConfig, "jobs"),
};
export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Jobs", value: 10 },
  { label: "20 Jobs", value: 20 },
  { label: "50 Jobs", value: 50 },
  { label: "100 Jobs", value: 100 },
  { label: "150 Jobs", value: 150 },
];
