import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const initialState = {
  users: [],
  status: "idle",
  error: null,
};

export const fetchUsers = createAsyncThunk("users/fetchUsers", async (obj) => {
  const queries = renderQueries(obj);
  const res = await request(`/users?${queries}`);
  return res.data;
});

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async ({ parentId, userId }) => {
    const res = await request(`/users/${userId}?parent=${parentId}`, {
      method: "delete",
    });
    return res.data;
  }
);

export const deleteUsers = createAsyncThunk(
  "user/deleteUsers",
  async ({ data, parentId }) => {
    const res = await request(`/users?parent=${parentId}`, {
      method: "delete",
      data,
    });
    return res.data;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: { resetUsers: () => initialState },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = [];
        state.status = "succeeded";
        state.users = state.users.concat(action.payload.users);
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { data } = action.payload;
        const newUsers = state.users.filter((x) => !data.includes(x._id));
        state.users = newUsers;
      })
      .addCase(deleteUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetUsers } = usersSlice.actions;

export default usersSlice.reducer;

export const selectAllUsers = (state) => state.users.users;

export const selectAllUsersOptions = (state) => {
  return state.users.users.map((obj) => {
    return {
      value: obj,
      label: obj ? obj.fullName : "",
    };
  });
};
export const selectUsersById = (state, userId) =>
  state.users.users.find((user) => user.id === userId);
