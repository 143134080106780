import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import useForm from "../../../../hooks/useForm";
import CustomerInfo from "./info";
import {
  fetchCustomer,
  resetCustomer,
  updateCustomer,
} from "../../../../redux/reducers/customers/customerSlice";
import Spinner from "../../../../shared/Spinner";
import { FORM_OPTIONS } from "../table.config";
import { useConfig, useCrumbs } from "../../../../hooks";
import { useGetCustomerQuery } from "../../../../redux/services/customers/customersApi";
import { Badge, EditableText } from "../../../../shared";
import { isAuthorized } from "../../../../utils/authority";
import { formatDate } from "../../../../utils";
import { SidebarContainer } from "../../../../components";
import Tabs from "../../../../shared/Tabs";
import CustomerBilling from "./Billing";

const CustomerShow = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  // Custom Hooks
  const { user } = useAuth();
  const { configs } = useConfig();
  const crumbs = useCrumbs();

  // Form hook
  const { form, updateForm, setFormInit } = useForm(FORM_OPTIONS);

  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  const customerStatus = useSelector((state) => state.customer.status);

  // React Hooks
  const [loading, setLoading] = useState(true);

  // RTKQ API CALLS
  const { data: customerData, isLoading: customerLoading } =
    useGetCustomerQuery(
      {
        id: params.id,
        // parentId: user.parentCompany,
      },
      { skip: !params.id }
    );

  const customer = customerData || {};

  useEffect(
    () => {
      crumbs.setLoading(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (customer._id) {
      crumbs.updateCrumbName({
        name: customer.name,
        path: "/customers/" + customer._id,
      });
      crumbs.setLoading(false);
      setFormInit(customer);
      setTimeout(() => setLoading(false), 500);
    }
  }, [customer._id]);

  useEffect(() => {
    return () => dispatch(resetCustomer());
  }, [updateCustomer]);

  async function handleUpdate(e) {
    const update = { [e.id]: e.value, ...e.data };

    dispatch(
      updateCustomer({
        data: update,
        parentId: user.parentCompany,
        customerId: customer._id,
      })
    );
  }

  //   const { form, clearForm, updateForm } = useForm(FORM_OPTIONS);

  //   function onFormChange(e) {
  //     const { value, id } = e;
  //     updateForm({ value, id });
  //   }

  const tabs = [
    {
      id: "Info",
      content: (
        <CustomerInfo
          // form={{ form, setFormInit, clearForm, updateForm }}
          customer={customer}
          // editing={editing}
          // isJobActive={isJobActive}
          // isNew={isNew}
          // isPending={isPending}
          loading={customerLoading || loading}
          setLoading={setLoading}
        />
      ),
    },
    // {
    //   id: "Legs",
    //   title: `Legs (${job.legs?.length || 0})`,
    //   content: (
    //     <LegsTable
    //       job={job}
    //       setVisible={setLegModalVisible}
    //       visible={legModalVisible}
    //       renderHeader={() => renderHeader(job)}
    //       form={legForm}
    //       editing={editing}
    //     />
    //   ),
    //   hidden: "New,Pending,Booked",
    // },
    {
      id: "Billing",
      content: (
        <CustomerBilling
          user={user}
          customer={customer}
          loading={customerLoading || loading}
          setLoading={setLoading}
        />
      ),
    },
    // {
    //   id: "Files",
    //   content: (
    //     <Files
    //       user={user}
    //       jobId={job?._id}
    //       files={job?.files}
    //       editing={editing}
    //     />
    //   ),
    //   hidden: "New",
    // },
  ].filter((item) => {
    if (item.hidden?.includes(customer.status)) {
      return false;
    }
    return true;
  });

  function handleTabClick(_e, _item, path) {
    // location.hash = e.target.innerHTML;
    navigate(path);
  }

  function getDefaultTab() {
    if (location.hash) {
      const tabHash = location.hash.slice(1);
      return tabs.findIndex((tab) => tab.id === tabHash);
    }
    return;
  }

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const title = (
    <div className="text-gray-800 font-semibold text-lg flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="orderNumber"
        value={customer.name}
        size="md"
        disabled={!isAuthorized("owner,admin")}
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        // loading={loading}
        onSave={(e) =>
          handleManifestUpdate({ id: "manifestId", value: e.value })
        }
      />
      <Badge
        // color={stage?.color}
        label={customer.status}
        size="lg"
        className="ml-2"
      />
      {/* {isAuthorized("owner,admin") && manifest.payoutId && (
        <Badge
          link={`/payouts/${manifest.payoutId._id}`}
          label={`Payout: ${manifest.payoutId.payoutId}`}
          size="lg"
          className="ml-2"
        />
      )} */}
    </div>
  );

  const renderTabHeader = (headerProps) => {
    return (
      <div ref={headerRef}>
        <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
          <div>{headerProps.title}</div>
          <div className="flex items-center space-x-3">
            <div className="md:flex items-center space-x-3 text-gray-800 hidden">
              {headerProps.actions}
            </div>
          </div>
        </header>
        <div className="flex justify-between md:hidden space-x-5 px-3 py-3 bg-white border-b">
          {headerProps.actions}
        </div>
      </div>
    );
  };

  const actions = (
    <>
      {/* <span className="mr-5">
        Created: {formatDate(manifest.dateCreated, null, true)}
      </span>
      <span>Updated: {formatDate(manifest.dateUpdated, null, true)}</span> */}
    </>
  );

  return customerStatus === "loading" ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <SidebarContainer
      title={title}
      hideSidebar
      header={renderTabHeader}
      actions={actions}
    >
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 38}px`,
        };

        return (
          <div style={wrapperStyle} className="h-auto w-full">
            {/* {isLoading ? (
              <div>
                <Spinner />
              </div>
            ) : ( */}
            <Tabs
              className={`w-full overflow-hidden`}
              style={wrapperStyle}
              panelStyle={panelStyle}
              useSearchParams
              panelClasses={`p-2 border-t overflow-y-scroll`}
              tabListClasses="bg-white px-2 h-[38px]"
              tabclassName="mx-1"
              id="customer-show"
              data={tabs}
            />
            {/* )} */}
          </div>
        );
      }}
    </SidebarContainer>
  );
};

export default CustomerShow;
