import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../../shared/Table/table.utils";
import { formatDate, renderAmount } from "../../../utils";

// Config for table and form
const tableConfig = [
  {
    accessorKey: "loadNumber",
    id: "loadNumber",
    visible: true,
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "status",
    cell: ({ getValue }) => {
      return getValue();
    },
  },
  {
    id: "transactionDate",
    cell: ({ getValue }) => {
      return formatDate(getValue(), null, true);
    },
  },
  {
    id: "dateUpdated",
    visible: false,
  },
  {
    id: "totalCharges",
    cell: ({ getValue }) => renderAmount(getValue()),
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = { validations: renderValidations(tableConfig) };
export const PAGE_SIZE_OPTIONS = [{ label: "12 Jobs", value: 12 }];
