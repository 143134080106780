import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../shared/Table/table.utils";

// Config for table and form
const tableConfig = [
  {
    id: "name",
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/organizations/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  "type",
  {
    id: "employees",
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) return "";
      return value.length;
    },
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = { validations: renderValidations(tableConfig) };
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Jobs", value: 10 },
  { label: "20 Jobs", value: 20 },
  { label: "50 Jobs", value: 50 },
  { label: "100 Jobs", value: 100 },
  { label: "150 Jobs", value: 150 },
];
const exportObj = { COLUMN_DEFINITIONS, FORM_OPTIONS, PAGE_SIZE_OPTIONS };
export default exportObj;
