import { useState } from "react";
// import { useDispatch } from "react-redux";
import { Button } from "../../../../shared/Button";
import useForm from "../../../../hooks/useForm";
import LocationCreateModal from "../../../Locations/LocationCreateModal";

export const EmptyLocationSearch = ({ text, clientId }) => {
  // const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const { form } = useForm();

  function showModal() {
    setModal(true);
  }
  // function closeModal() {
  //   clearForm();
  //   setModal(false);
  // }
  // function handleSubmit() {
  //   dispatch();
  // }

  function handleUpdate(e) {
    // console.log(e);
  }

  const content = (
    <LocationCreateModal
      visible={modal}
      setVisible={setModal}
      clientId={clientId}
      initData={{ name: text }}
      form={form}
      onChange={handleUpdate}
    />
  );
  // const footer = (
  //   <div className="flex items-center space-x-1">
  //     <Button onClick={closeModal} type="alternative">
  //       Cancel
  //     </Button>
  //     <Button onClick={handleSubmit}>Submit</Button>
  //   </div>
  // );

  return (
    <>
      <span>
        No location exists with that name,{" "}
        <Button onClick={showModal} type="link">
          click here
        </Button>{" "}
        to create it.
      </span>
      {content}
      {/* <Modal
        visible={modal}
        setVisible={setModal}
        // onClose={() => setModal(false)}
        title="Table Preferences"
        footer={footer}
        content={content}
        size="lg"
        footerPosition="right"
      /> */}
    </>
  );
};

export default EmptyLocationSearch;
