import classNames from "classnames";
import React from "react";

const colorVariants = {
  blue: "bg-blue-600 hover:bg-blue-500",
  red: "bg-red-600 hover:bg-red-500",
  green: "bg-green-600 hover:bg-green-500",
};

export const Progress = (props) => {
  const percentage = props.percent
    ? props.percent >= 100
      ? 100
      : props.percent
    : 0;

  const wrapperClasses = classNames(
    "w-full bg-gray-200 rounded-full",
    { "h-1.5 ": props.size === "sm" },
    { "h-2.5 ": props.size === "md" || !props.size },
    { "h-4": props.size === "lg" },
    { "h-6": props.size === "xl" }
  );

  const defaultColor = props.color || "blue";
  const color = percentage >= 100 ? "green" : defaultColor;

  const progressClasses = classNames(
    "rounded-full",
    colorVariants[color],
    { "h-1.5": props.size === "sm" },
    { "h-2.5": props.size === "md" || !props.size },
    { "h-4 ": props.size === "lg" },
    { "h-6 ": props.size === "xl" }
  );

  return (
    <>
      {props.title && (
        <div className="mb-1 text-base font-medium">{props.title}</div>
      )}
      <div className={wrapperClasses}>
        <div className={progressClasses} style={{ width: `${percentage}%` }} />
      </div>
    </>
  );
};

export default Progress;
