import { useConfig } from "../../hooks/useConfig";
import RatesSettingsLayout from "../../layouts/RatesSettingLayout";
import { SettingsPanel, Input } from "../../shared";
import useForm from "../../hooks/useForm";
import { SettingsHeader } from ".";

const Rates = (props) => {
  const { deleteConfig, addConfig, configs } = useConfig();
  const { form, updateForm } = useForm();

  async function handleDelete(data) {
    const rateData = {
      configKey: "rates",
      type: "object",
      accessorKey: data.type,
    };
    deleteConfig(rateData);
  }

  async function handleAddRate(data, type) {
    addConfig(data);
  }

  async function handleSave(data, type) {
    addConfig({
      data: { [type]: data.target.value },
      configKey: "job",
      type: "object",
    });
  }

  const basicSettings = () => {
    const waitTimeOffset =
      "waitTimeOffset" in form
        ? form.waitTimeOffset
        : configs?.job?.waitTimeOffset;
    return [
      {
        title: "Wait Time Offset",
        subtitle: "Sets the amount of free hours for a job's wait time.",
        content: (
          <Input
            id="waitTimeOffset"
            onChange={updateForm}
            value={waitTimeOffset}
            size="md"
            placeholder="Wait Time Offset"
            onBlur={(e) => handleSave(e, "waitTimeOffset")}
            type="number"
          />
        ),
      },
    ];
  };

  return (
    <>
      <SettingsHeader title="Rates" />
      <div className="pb-4">
        <SettingsPanel title="Basic Job Settings" data={basicSettings()} />
      </div>
      <RatesSettingsLayout
        title="Global Rates"
        subtitle="These rates will be used across all clients and will fallback if none are set for a client"
        rates={configs?.rates}
        deleteRate={handleDelete}
        addRate={handleAddRate}
        updateRate={handleAddRate}
      />
    </>
  );
};

export default Rates;
