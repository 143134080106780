import Card from "../../../../shared/Card";
import { useEffect, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import useForm from "../../../../hooks/useForm";
import AccessoryTable from "./AccessoryTable";
import AddAccModal from "./AddAccModal";
import RemoveAccModal from "./RemoveAccModal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useDeleteJobAccessoryMutation,
  useUpdateJobAccessoriesMutation,
} from "../../../../redux/services/jobs/jobsApi";
import { isAuthorized } from "../../../../utils/authority";

const Accessories = ({ user, jobId, accessories, type }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [updateAccessory, { isLoading: updatingAccessory }] =
    useUpdateJobAccessoriesMutation();
  const [deleteAccessory, { isLoading: deletingAccessory }] =
    useDeleteJobAccessoryMutation();

  const form = useForm();

  useEffect(() => {
    if (accessories && accessories.length > 0) {
      setData(accessories);
    }

    if (accessories && accessories.length === 0) {
      setData([]);
    }
  }, [accessories]);

  async function handleAddAccessory() {
    const data = {
      type: form.form.type,
      quantity: form.form.quantity,
      unitPrice: form.form.unitPrice,
      jobId,
    };

    let method = "post";
    if (form.form.edit) {
      method = "put";
      data._id = form.form._id;
    }

    const res = await updateAccessory({ method, data }).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "added-accessory-" + res.data._id,
    });

    if (res.status === "success") {
      form.clearForm();
      setAddModalVisible(false);
    }
  }

  function editAcc(e) {
    form.setFormInit({ edit: true, ...e });
    setAddModalVisible(true);
  }

  function removeAcc(e) {
    form.setFormInit(e);
    setRemoveModalVisible(true);
  }

  async function handleRemoveAccessory() {
    const res = await deleteAccessory({
      data: {
        jobId,
        _id: form.form._id,
      },
    }).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "added-accessory-" + res.data._id,
    });

    if (res.status === "success") {
      setRemoveModalVisible(false);
      form.clearForm();
    }
  }

  function handleCancel() {
    setLoading(false);
    form.clearForm();
  }

  return (
    <>
      <Card className="row-span-2 p-5">
        <div className="flex justify-between items-start">
          <div className="mb-4">
            <h2 className="text-2xl font-semibold">Accessories</h2>
          </div>
          {isAuthorized("owner,admin") && (
            <div
              onClick={() => setAddModalVisible(true)}
              className="text-primary-600 flex hover:underline cursor-pointer"
            >
              <PlusCircleIcon className="w-6 h-6 mr-1" /> Add Accessory
            </div>
          )}
        </div>
        <AccessoryTable data={data} editAcc={editAcc} removeAcc={removeAcc} />
      </Card>
      <AddAccModal
        form={form}
        visible={addModalVisible}
        setVisible={setAddModalVisible}
        submit={handleAddAccessory}
        loading={updatingAccessory}
        cancel={handleCancel}
      />
      <RemoveAccModal
        form={form}
        visible={removeModalVisible}
        setVisible={setRemoveModalVisible}
        submit={handleRemoveAccessory}
        loading={deletingAccessory}
        cancel={handleCancel}
      />
    </>
  );
};

export default Accessories;
