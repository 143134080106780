import { useState } from "react";
import Input from "../../../../shared/Input";
import Select from "../../../../shared/Select";
import Toggle from "../../../../shared/Toggle";
import OrgCreateForm from "../../CreateForm";

const DriverCreateForm = ({
  form = {},
  updateForm,
  errors = {},
  driverOrg,
  setDriverOrg,
  orgs,
  clearForm,
  hideOrg,
  orgOptions,
  user,
}) => {
  const [createOrg, setCreateOrg] = useState(false);

  function handleOrgClick() {
    setCreateOrg(!createOrg);
    clearForm("organization");
  }

  function setOrg(e) {
    updateForm({
      id: "organization",
      value: { ...form.organization, [e.id]: e.value },
    });
  }

  return (
    <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-3">
      <div>
        <Input
          id="firstName"
          label="First Name"
          placeholder="First Name"
          onChange={updateForm}
          value={form.firstName}
          error={errors.firstName}
        />
      </div>
      <div>
        <Input
          id="lastName"
          label="Last Name"
          placeholder="Last Name"
          value={form.lastName}
          onChange={updateForm}
        />
      </div>
      <div>
        <Input
          id="email"
          label="Email"
          placeholder="Email"
          onChange={updateForm}
          value={form.email}
          error={errors.email}
        />
      </div>
      <div>
        <Input
          id="phone"
          label="Phone"
          value={form.phone}
          placeholder="Phone"
          onChange={updateForm}
          type="tel"
        />
      </div>
      <div>
        <Input
          id="license"
          label="License"
          placeholder="License"
          onChange={updateForm}
          value={form.license}
        />
      </div>
      <div>
        <Input
          id="hourlyRate"
          label="Hourly Rate"
          placeholder="Hourly Rate"
          value={form.hourlyRate}
          onChange={updateForm}
          type="number"
        />
      </div>
      <div>
        <Input
          id="mileageRate"
          value={form.mileageRate}
          label="Mileage Rate"
          placeholder="Mileage Rate"
          onChange={updateForm}
          type="number"
        />
      </div>
      {hideOrg ? null : (
        <div className="lg:row-start-5 col-span-2">
          <div className="flex items-center my-4">
            <h3 className="block mr-3 text-lg font-medium text-gray-900">
              Driver Organization
            </h3>
            <Toggle
              size="sm"
              checked={driverOrg}
              onChange={() => {
                setDriverOrg(!driverOrg);
                setCreateOrg(false);
                clearForm("organization");
              }}
            />
          </div>
          {driverOrg && (
            <div className="space-y-1">
              {createOrg ? (
                <OrgCreateForm
                  user={user}
                  hideEmployees
                  form={{ form, updateForm: setOrg, errors }}
                />
              ) : (
                <Select
                  value={form.organization?.name}
                  id="organization"
                  label="Organization"
                  placeholder="Driver Organization"
                  onChange={updateForm}
                  options={orgOptions}
                />
              )}
              <div>
                <span
                  className="text-sm hover:underline cursor-pointer text-gray-600"
                  onClick={handleOrgClick}
                >
                  {!createOrg
                    ? "Create new organization"
                    : "Select an organization"}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DriverCreateForm;
