import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../../utils/request";

const initialState = {
  feedback: {},
  status: "idle",
  error: null,
};

export const fetchFeedback = createAsyncThunk(
  "feedback/fetchFeedback",
  async ({ feedbackId }) => {
    const res = await request(`/feedback/${feedbackId}`);
    return res.data;
  }
);

export const updateFeedback = createAsyncThunk(
  "feedback/updateFeedback",
  async ({ parentId, feedbackId, data }) => {
    const res = await request(`/feedback/${feedbackId}?parent=${parentId}`, {
      method: "put",
      data,
    });
    return res.data;
  }
);

export const addNewFeedback = createAsyncThunk(
  "feedback/addNewFeedback",
  async (data) => {
    const res = await request("/feedback", {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const sendEmail = createAsyncThunk(
  "feedback/sendEmail",
  async ({ data, ...obj }) => {
    const queries = renderQueries(obj);
    const res = await request(`/feedback/email?${queries}`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

// export const feedbackSlice = createSlice({
//   name: "feedback",
//   initialState,
//   reducers: {},
//   extraReducers(builder) {
//     builder.addCase(addNewFeedback.fulfilled, (state, action) => {
//       state.customers.push(action.payload);
//     });
//   },
// });

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    resetFeedback: () => initialState,
    setFeedback: (state, action) => {
      action.payload;
      state.feedback = action.payload;
    },
    addedMessage: (state, action) => {
      state.feedback.messages.push(action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFeedback.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchFeedback.fulfilled, (state, action) => {
        state.feedback = {};
        state.status = "succeeded";
        state.feedback = action.payload.data;
      })
      .addCase(fetchFeedback.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewFeedback.fulfilled, (state, action) => {
        state.feedback = action.payload;
      })
      .addCase(updateFeedback.fulfilled, (state, action) => {
        state.feedback = {};
        state.status = "succeeded";
        state.feedback = action.payload.data;
      });
  },
});

export const { resetFeedback, addedMessage, setFeedback } =
  feedbackSlice.actions;
export default feedbackSlice.reducer;

export const selectAllFeedbacks = (state) => state.feedback.feedback;
