import { useMemo } from "react";
import TopRowCard from "./TopRowCard";

export const TotalsOverView = (props) => {
  const data = useMemo(() => props.data, [props.loading]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
      <div>
        <TopRowCard
          title="Total Jobs"
          body={data.jobCount}
          color="green"
          loading={props.loading}
        />
      </div>
      <div>
        <TopRowCard
          title="Income"
          body={data.income}
          color="green"
          loading={props.loading}
          money
        />
      </div>
      <div>
        <TopRowCard
          title="Payout"
          body={data.payout}
          color="green"
          loading={props.loading}
          money
        />
      </div>
      <div>
        <TopRowCard
          title="Profit"
          body={data.profit}
          color="green"
          loading={props.loading}
          money
        />
      </div>
    </div>
  );
};
