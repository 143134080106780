import { useState, useEffect, useMemo, memo, useCallback } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  LoadScript,
  useLoadScript,
} from "@react-google-maps/api";
import {
  XMarkIcon,
  ClockIcon,
  TruckIcon,
  MapIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { Modal } from "../../shared/Modal";
import { formatAddress, formatDate } from "../../utils";

const libraries = ["geometry"];

const EventModal = ({ event, onClose }) => {
  const googleMapsApiKey = "AIzaSyAzGAcsvS8jrtFme5gTH5xZtF6hZdz-UQ0";

  const [directions, setDirections] = useState(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
    libraries,
  });
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0); // Add this line to track progress
  const [modalVisible, setModalVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  const [driverLocation, setDriverLocation] = useState(null);
  const [totalDistance, setTotalDistance] = useState(0);
  const [distanceTraveled, setDistanceTraveled] = useState(0);

  const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 }); // Default to San Francisco

  const handleSubmit = () => {
    // Add your submit logic here
    setLoading(true);
    // Simulating an async operation
    setTimeout(() => {
      setLoading(false);
      setModalVisible(false);
    }, 1000);
  };

  const fetchDirections = useCallback(async () => {
    if (!isLoaded || !event.OriginAddress || !event.DestinationAddress) return;

    setLoading(true);
    setError(null);

    try {
      const directionsService = new window.google.maps.DirectionsService();
      const result = await directionsService.route({
        origin: event.OriginAddress,
        destination: event.DestinationAddress,
        travelMode: window.google.maps.TravelMode.DRIVING,
      });

      setDirections(result);

      const totalDist = result.routes[0].legs.reduce(
        (acc, leg) => acc + leg.distance.value,
        0
      );
      setTotalDistance(totalDist);

      if (result.routes[0] && result.routes[0].bounds) {
        const bounds = result.routes[0].bounds;
        const center = {
          lat: (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2,
          lng: (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2,
        };
        setMapCenter(center);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [isLoaded, event.OriginAddress, event.DestinationAddress]);

  const updateDriverLocation = useCallback(() => {
    // In a real scenario, you would get the driver's location from a GPS or tracking system
    // For this example, we'll simulate the driver's progress
    const simulatedProgress = Math.random(); // 0 to 1
    const simulatedLocation = window.google.maps.geometry.spherical.interpolate(
      new window.google.maps.LatLng(event.OriginAddress),
      new window.google.maps.LatLng(event.DestinationAddress),
      simulatedProgress
    );

    setDriverLocation(simulatedLocation);

    // Calculate distance traveled
    const distanceTraveled =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(event.OriginAddress),
        simulatedLocation
      );

    setDistanceTraveled(distanceTraveled);
    setProgress((distanceTraveled / totalDistance) * 100);
  }, [event.OriginAddress, event.DestinationAddress, totalDistance]);

  useEffect(() => {
    if (isLoaded && totalDistance > 0) {
      updateDriverLocation();
      // In a real scenario, you would set up a periodic update here
      // For example: const intervalId = setInterval(updateDriverLocation, 60000); // Update every minute
      // Don't forget to clear the interval in the cleanup function
    }
  }, [isLoaded, totalDistance, updateDriverLocation]);

  // ... rest of the component code ...

  const mapOptions = useMemo(
    () => ({
      mapContainerStyle: { height: "100%", width: "100%" },
      zoom: 10,
      center: mapCenter,
    }),
    [mapCenter]
  );

  const GoogleMapComponent = useMemo(() => {
    if (!isLoaded) return null;

    return (
      <GoogleMap {...mapOptions}>
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              polylineOptions: {
                strokeColor: "#0D9488", // Tailwind teal-600
                strokeWeight: 5,
              },
            }}
          />
        )}
      </GoogleMap>
    );
  }, [isLoaded, mapOptions, directions]);

  // const renderFields = () => {
  //   const fieldsToShow = [
  //     // "Id",
  //     // "Subject",
  //     // "Origin",
  //     // "Destination",
  //     // "Mileage",
  //     // "Duration",
  //     // "StartTime",
  //     // "EndTime",
  //     // "IsAllDay",
  //     // "driverId",
  //     // "Guid",
  //   ];
  //   return fieldsToShow.map((key) => {
  //     let value = event[key];
  //     // Convert Date objects to strings
  //     if (value instanceof Date) {
  //       value = value.toLocaleString();
  //     }
  //     // Convert any other non-primitive types to strings
  //     if (typeof value === "object" && value !== null) {
  //       value = JSON.stringify(value);
  //     }
  //     return (
  //       <div key={key} className="mb-2">
  //         <span className="font-semibold">{key}:</span> {value}
  //       </div>
  //     );
  //   });
  // };

  const resetState = useCallback(() => {
    setDirections(null);
    setLoading(false);
    setError(null);
    setProgress(0);
    setDriverLocation(null);
    setTotalDistance(0);
    setDistanceTraveled(0);
  }, []);

  const handleClose = useCallback(() => {
    resetState();
    setModalVisible(false);
    onClose();
    console.log("closed from component");
  }, [resetState, onClose]);

  useEffect(() => {
    if (modalVisible && event) {
      fetchDirections();
    } else {
      resetState();
      // setModalVisible(false);
    }
  }, [modalVisible, event, fetchDirections, resetState]);

  const footer = <div></div>;

  return (
    <Modal
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      title={event.Subject}
      visible={modalVisible}
      setVisible={setModalVisible}
      submit={handleSubmit}
      loading={loading}
      onClose={handleClose}
      footer={<div></div>}
    >
      <div className="w-full max-w-2xl mx-auto">
        <div className="w-full h-64 mb-4">
          {!isLoaded ? <div>Loading Maps...</div> : GoogleMapComponent}
        </div>

        <div className="mb-4 w-full">
          {/* <h4 className="font-semibold mb-2 text-primary-600">
            Journey Progress
          </h4> */}
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-primary-600">
                  Origin
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-primary-600">
                  Destination
                </span>
              </div>
            </div>
            <div className="relative overflow-hidden h-2 mb-4 text-xs flex rounded bg-primary-200">
              <div
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-500"
              ></div>
            </div>
            <div
              className="absolute top-10 transform -translate-y-1/2 z-10"
              style={{ left: `calc(${progress}% - 10px)` }}
            >
              <TruckIcon className="h-6 w-6 text-primary-600" />
            </div>
            <div className="flex mb-2 items-center justify-between text-xs">
              <div className="w-1/2 pr-2">
                <div>{event.Origin}</div>
                <div>{formatAddress(event.OriginAddress)}</div>
              </div>
              <div className="w-1/2 pl-2 text-right">
                <div>{event.Destination}</div>
                <div>{formatAddress(event.DestinationAddress)}</div>
              </div>
            </div>
          </div>
        </div>

        {/* <h3 className="text-xl font-semibold mb-4 text-primary-600">
          Event Details
        </h3> */}

        {loading && <p className="text-gray-600">Loading directions...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}

        <div className="bg-gray-100 rounded-lg p-4 mb-4">
          <div className="flex items-center mb-2">
            <ClockIcon className="h-5 w-5 mr-2 text-primary-600 flex-shrink-0" />
            <span className="font-semibold">Time:</span>
            <span className="ml-2">
              {formatDate(event.StartTime)} - {formatDate(event.EndTime)}
            </span>
          </div>
          <div className="flex items-center mb-2">
            <TruckIcon className="h-5 w-5 mr-2 text-primary-600 flex-shrink-0" />
            <span className="font-semibold">Driver:</span>
            <span className="ml-2">{event.driverId.fullName}</span>
          </div>
          <div className="flex items-center">
            <MapIcon className="h-5 w-5 mr-2 text-primary-600 flex-shrink-0" />
            <span className="font-semibold">Route:</span>
            <span className="ml-2">{event.Mileage} miles</span>
          </div>
        </div>
      </div>

      {/* <div className="text-sm">{renderFields()}</div> */}
    </Modal>
  );
};

export default EventModal;
