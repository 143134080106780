import React, { useState } from "react";
import useForm from "../../hooks/useForm";
import Form from "../../shared/Form";
import FormLayout from "../../layouts/FormLayout";
import Input from "../../shared/Input";
import Select from "../../shared/Select";
import Toggle from "../../shared/Toggle";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { FORM_OPTIONS } from "./table.config";
import { accountType } from "./select.config";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { addNewUser } from "../../redux/reducers/users/userSlice";
import { useEffect } from "react";
import {
  fetchClients,
  selectAllClients,
  selectClientsOptions,
} from "../../redux/reducers/clients/clientsSlice";
import { toast } from "react-toastify";
import OrgCreateForm from "../Organizations/CreateForm";
import {
  fetchOrganizations,
  selectOrganizationsOptions,
} from "../../redux/reducers/organizations/organizationsSlice";

const UserCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { form, updateForm, isValid, errors, clearForm } =
    useForm(FORM_OPTIONS);
  const clients = useSelector(selectAllClients);
  const clientOptions = useSelector(selectClientsOptions);
  const clientStatus = useSelector((state) => state.users.status);
  const [checkedParentCompany, setCheckedParentCompany] = useState(false);

  const [createOrg, setCreateOrg] = useState(false);
  const [driverOrg, setDriverOrg] = useState(false);

  const orgs = useSelector((state) => state.organizations);
  const orgOptions = useSelector(selectOrganizationsOptions);

  useEffect(() => {
    if (form.accountType === "Client" && clientStatus === "idle") {
      dispatch(fetchClients(user.parentCompany));
    }
  }, [clientStatus, dispatch, user, form.accountType]);

  useEffect(() => {
    if (driverOrg && orgs.status === "idle") {
      dispatch(fetchOrganizations({ parent: user.parentCompany }));
    }
  }, [driverOrg, orgs.status]);

  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  function onCheckedParentCompany(e) {
    setCheckedParentCompany(e.target.checked);
  }

  async function handleSubmit() {
    let submitData = {
      ...form,
      type: form.accountType.toLowerCase(),
    };

    if (submitData.type === "driver") {
      submitData.meta = {
        license: form.license,
        hourlyRate: form.hourlyRate,
        mileageRate: form.mileageRate,
      };
    }
    if (submitData.type === "client") {
      submitData.meta = {
        client: form.client.name,
      };
      submitData.client = form.client._id;
    }

    const res = await dispatch(
      addNewUser({ data: submitData, parent: user.parentCompany })
    ).unwrap();

    if (res.status === "success") {
      if (res.new) {
        navigate(`/users/${res.user._id}`);
      } else {
        toast("User with that email already exists", {
          toastId: "userExists",
          type: "warning",
        });
      }
    }
  }

  function handleOrgClick() {
    setCreateOrg(!createOrg);
    clearForm("organization");
  }

  function setOrg(e) {
    updateForm({
      id: "organization",
      value: { ...form.organization, [e.id]: e.value },
    });
  }

  return (
    <div className="px-3 pb-3 h-auto">
      <FormLayout
        cancelDisabled={!form.accountType}
        cancel={() => clearForm()}
        handleSubmit={handleSubmit}
        isValid={!isValid}
      >
        <div>
          {!form.accountType ? (
            <div>
              <Select
                id="accountType"
                onChange={onFormChange}
                label="Account Type"
                placeholder="Select an Account Type"
                options={accountType}
              />
            </div>
          ) : (
            <div className="text-lg my-4 flex items-center">
              Account Type:{" "}
              <span className="font-bold text-2xl ml-2 flex items-center">
                {form.accountType}{" "}
                <XMarkIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => clearForm()}
                />
              </span>
            </div>
          )}
        </div>
        <div>
          {!form.client ? (
            <div>
              {form.accountType === "Client" && (
                <Select
                  id="client"
                  loading={clientStatus === "loading"}
                  onChange={onFormChange}
                  label="Client"
                  options={clientOptions}
                  placeholder="Select a client"
                />
              )}
            </div>
          ) : (
            <div className="text-lg my-4 flex items-center">
              Client:{" "}
              <span className="font-bold text-2xl ml-2 flex items-center">
                {form.client.name}{" "}
                <XMarkIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => clearForm("client")}
                />
              </span>
            </div>
          )}
        </div>
        {((form.accountType === "Client" && form.client) ||
          (form.accountType && form.accountType !== "Client")) && (
          <Form className="grid grid-cols-1 gap-1 lg:grid-cols-2 lg:gap-3">
            <div>
              <Input
                id="email"
                label="Email"
                placeholder="Email"
                onChange={onFormChange}
                value={form.email}
                error={errors.email}
              />
            </div>
            <div>
              <Input
                id="firstName"
                label="First Name"
                placeholder="First Name"
                onChange={onFormChange}
              />
            </div>
            <div>
              <Input
                id="lastName"
                label="Last Name"
                placeholder="Last Name"
                onChange={onFormChange}
              />
            </div>
            <div>
              <Input
                id="phone"
                label="Phone"
                placeholder="Phone"
                onChange={onFormChange}
                type="tel"
              />
            </div>
            <div>
              {form.accountType === "Driver" && (
                <div>
                  <div>
                    <Input
                      id="license"
                      label="License"
                      placeholder="License"
                      onChange={onFormChange}
                    />
                    <Input
                      id="hourlyRate"
                      label="Hourly Rate"
                      placeholder="Hourly Rate"
                      onChange={onFormChange}
                    />
                    <Input
                      id="mileageRate"
                      label="Mileage Rate"
                      placeholder="Mileage Rate"
                      onChange={onFormChange}
                    />
                  </div>

                  <div className="lg:row-start-5 col-span-2">
                    <div className="flex items-center my-4">
                      <h3 className="block mr-3 text-lg font-medium text-gray-900">
                        Driver Organization
                      </h3>
                      <Toggle
                        size="sm"
                        checked={driverOrg}
                        onChange={() => {
                          setDriverOrg(!driverOrg);
                          setCreateOrg(false);
                          clearForm("organization");
                        }}
                      />
                    </div>
                    {driverOrg && (
                      <div className="space-y-1">
                        {createOrg ? (
                          <OrgCreateForm
                            user={user}
                            hideEmployees
                            form={{ form, updateForm: setOrg, errors }}
                          />
                        ) : (
                          <Select
                            value={form.organization?.name}
                            id="organization"
                            label="Organization"
                            placeholder="Driver Organization"
                            onChange={updateForm}
                            options={orgOptions}
                          />
                        )}
                        <div>
                          <span
                            className="text-sm hover:underline cursor-pointer text-gray-600"
                            onClick={handleOrgClick}
                          >
                            {!createOrg
                              ? "Create new organization"
                              : "Select an organization"}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
      </FormLayout>
    </div>
  );
};

export default UserCreate;
