import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "../../../../shared/Table";
import { useAuth } from "../../../../hooks/useAuth";
import { updateJob } from "../../../../redux/reducers/jobs/jobSlice";
import {
  fetchLegs,
  selectAllLegs,
} from "../../../../redux/reducers/legs/legsSlice";
import {
  resetJobs,
  jobUpdated,
} from "../../../../redux/reducers/jobs/jobsSlice";
import { COLUMN_DEFINITIONS } from "./table.config";

const DriverJobs = ({ driver }) => {
  const { user } = useAuth();
  const params = useParams();
  const dispatch = useDispatch();
  const jobs = useSelector(selectAllLegs);
  const jobsStatus = useSelector((state) => state.legs.status);

  useEffect(() => {
    if (user._id && params.id && jobsStatus === "idle") {
      dispatch(
        fetchLegs({
          parent: user.parentCompany,
          userId: user._id,
          driverId: params.id,
        })
      );
    }
  }, [jobsStatus, dispatch, user, driver]);

  useEffect(() => {
    return () => dispatch(resetJobs());
  }, [dispatch]);

  async function handleUpdate(e, setLoading) {
    const updateData = {
      parentId: user.parentCompany,
      jobId: e.row.original._id,
      data: e.data,
    };

    const res = await dispatch(updateJob(updateData)).unwrap();

    if (res.status === "success") {
      dispatch(jobUpdated(res.data));

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }
  return (
    <div className="col-span-8">
      <Table
        id="jobs"
        search
        title="Jobs"
        loading={jobsStatus === "loading"}
        columns={COLUMN_DEFINITIONS}
        data={jobs}
        pagination
        pageSize={8}
        hideFooter
        updateData={handleUpdate}
      />
    </div>
  );
};

export default DriverJobs;
