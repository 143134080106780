import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useState } from "react";
import Button from "../Button";
import Dropdown from "../Dropdown";

export const ButtonDropdown = (props) => {
  const [dropShow, setDropShow] = useState(false);

  function handleClick() {
    setDropShow(!dropShow);
  }

  const trigger = (
    <Button
      {...props}
      className="flex items-center"
      type={props.type}
      onClick={handleClick}
      size={props.size}
    >
      {props.children || props.title}
      <ChevronDownIcon className="h-4 w-4" />
    </Button>
  );

  return (
    <Dropdown
      {...props}
      id="button-dropdown"
      closeOnClick
      // visible={dropShow}
      data={props.data || props.options}
      trigger={trigger}
    />
  );
};

export default ButtonDropdown;
