import { parseFixed } from "../../utils";

// convert meters to miles
export function getMilesFromMeters(meters) {
  return Math.ceil(parseFixed(meters * 0.000621371192));
}

export function renderTotalMileage(mileage, numOnly) {
  const totalMileage = mileage || 0;
  const res = parseFloat(totalMileage).toFixed(2);
  if (numOnly) return Math.ceil(res);
  return Math.ceil(res) + " mi";
}
