import { PlusIcon } from "@heroicons/react/24/outline";
import { Button, Card, Table } from "../../../shared";

const EquipmentOverview = ({ equipment }) => {
  const COLUMNS = [
    {
      header: "Date",
      accessorKey: "date",
      cell: ({ getValue }) => new Date(getValue()).toLocaleDateString(),
    },
    {
      header: "Type",
      accessorKey: "maintenanceType",
    },
    {
      header: "Description",
      accessorKey: "description",
    },
    {
      header: "Performed By",
      accessorKey: "performedBy.name",
    },
    {
      header: "Cost",
      accessorKey: "cost",
      cell: ({ getValue }) =>
        getValue() ? `$${getValue().toFixed(2)}` : "N/A",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Next Maintenance",
      accessorKey: "nextMaintenanceDate",
      cell: ({ getValue }) =>
        getValue() ? new Date(getValue()).toLocaleDateString() : "N/A",
    },
    {
      header: "Client",
      accessorKey: "client.name",
    },
    {
      header: "Parent Company",
      accessorKey: "parentCompany.name",
    },
  ];

  const TEMP_DATA = [
    {
      id: 1,
      equipment: { id: "eq1", name: "Forklift A" },
      maintenanceType: "Routine",
      description: "Oil change and filter replacement",
      performedBy: { id: "user1", name: "John Doe" },
      date: new Date("2023-05-15"),
      cost: 150.0,
      notes: "Regular maintenance performed",
      status: "Completed",
      nextMaintenanceDate: new Date("2023-08-15"),
      parts: [
        { name: "Oil Filter", quantity: 1, cost: 20.0 },
        { name: "Engine Oil", quantity: 5, cost: 10.0 },
      ],
      client: { id: "client1", name: "ABC Corp" },
      parentCompany: { id: "customer1", name: "XYZ Holdings" },
    },
    {
      id: 2,
      equipment: { id: "eq2", name: "Crane B" },
      maintenanceType: "Repair",
      description: "Replace hydraulic hose",
      performedBy: { id: "user2", name: "Jane Smith" },
      date: new Date("2023-04-02"),
      cost: 300.0,
      notes: "Emergency repair due to leak",
      status: "Completed",
      nextMaintenanceDate: new Date("2023-07-02"),
      parts: [{ name: "Hydraulic Hose", quantity: 1, cost: 150.0 }],
      client: { id: "client2", name: "DEF Industries" },
      parentCompany: { id: "customer2", name: "GHI Enterprises" },
    },
    {
      id: 3,
      equipment: { id: "eq3", name: "Excavator C" },
      maintenanceType: "Inspection",
      description: "Annual safety inspection",
      performedBy: { id: "user3", name: "Mike Johnson" },
      date: new Date("2023-03-10"),
      cost: 100.0,
      notes: "All systems checked, no issues found",
      status: "Completed",
      nextMaintenanceDate: new Date("2024-03-10"),
      client: { id: "client3", name: "JKL Construction" },
      parentCompany: { id: "customer3", name: "MNO Group" },
    },
    {
      id: 4,
      equipment: { id: "eq4", name: "Bulldozer D" },
      maintenanceType: "Upgrade",
      description: "Install new GPS tracking system",
      performedBy: { id: "user4", name: "Sarah Brown" },
      date: new Date("2023-02-20"),
      cost: 500.0,
      notes: "Upgrade completed successfully",
      status: "Completed",
      parts: [
        { name: "GPS Unit", quantity: 1, cost: 400.0 },
        { name: "Mounting Bracket", quantity: 1, cost: 50.0 },
      ],
      client: { id: "client4", name: "PQR Builders" },
      parentCompany: { id: "customer4", name: "STU Corporation" },
    },
    {
      id: 5,
      equipment: { id: "eq5", name: "Loader E" },
      maintenanceType: "Routine",
      description: "Tire rotation and balance",
      performedBy: { id: "user5", name: "Chris Lee" },
      date: new Date("2023-01-05"),
      cost: 80.0,
      notes: "Regular maintenance performed",
      status: "Completed",
      nextMaintenanceDate: new Date("2023-07-05"),
      client: { id: "client5", name: "VWX Contractors" },
      parentCompany: { id: "customer5", name: "YZA Conglomerate" },
    },
  ];
  return (
    <div className="space-y-4">
      <Card className="w-full bg-white rounded-lg shadow-sm p-3 mb-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Equipment Details</h2>
          <div className="flex space-x-2">
            <Button onClick={() => null} className="min-w-24" size="sm">
              Edit Details
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <h3 className="text-sm font-medium text-gray-500">Type</h3>
            <p className="text-lg font-semibold">{equipment.type}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Model</h3>
            <p className="text-lg font-semibold">{equipment.model || "N/A"}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Manufacturer</h3>
            <p className="text-lg font-semibold">
              {equipment.manufacturer || "N/A"}
            </p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Serial Number</h3>
            <p className="text-lg font-semibold">
              {equipment.serialNumber || "N/A"}
            </p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Purchase Date</h3>
            <p className="text-lg font-semibold">
              {equipment.purchaseDate
                ? new Date(equipment.purchaseDate).toLocaleDateString()
                : "N/A"}
            </p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">
              Last Maintenance Date
            </h3>
            <p className="text-lg font-semibold">
              {equipment.lastMaintenanceDate
                ? new Date(equipment.lastMaintenanceDate).toLocaleDateString()
                : "N/A"}
            </p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">
              Next Maintenance Date
            </h3>
            <p className="text-lg font-semibold">
              {equipment.nextMaintenanceDate
                ? new Date(equipment.nextMaintenanceDate).toLocaleDateString()
                : "N/A"}
            </p>
          </div>
        </div>
      </Card>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Maintenance History</h2>
            <Button
              onClick={() => null}
              className="flex flex-row items-center space-x-2"
              size="sm"
            >
              <PlusIcon className="w-4 h-4" />
              Add Record
            </Button>
          </div>
          <Table data={TEMP_DATA} columns={COLUMNS} />
        </div>
        <Card className="bg-white rounded-lg shadow-sm p-6 col-span-2">
          <h2 className="text-xl font-semibold mb-4">Associated Client</h2>
          {equipment.client ? (
            <>
              <div className="flex items-center space-x-4 mb-6">
                <Avatar src={equipment.client.avatar} size="large" />
                <div>
                  <h3 className="text-lg font-semibold">
                    {equipment.client.name}
                  </h3>
                  <p className="text-gray-500">{equipment.client.company}</p>
                </div>
              </div>
              {/* Add more client details here */}
            </>
          ) : (
            <p>No client associated with this equipment</p>
          )}
        </Card>
      </div>
    </div>
  );
};

export default EquipmentOverview;
