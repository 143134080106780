import React, { useCallback } from "react";
import Table from "../../../shared/Table";
import { renderDefaultSelected } from "../../../shared/Table/table.utils";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./drivertable.config";

const DriverTable = ({ selectedData, setSelectedItems }) => {
  const tableData = React.useMemo(() => selectedData, [selectedData]);
  const handleRowSelection = useCallback(
    (value) => setSelectedItems(value),
    [setSelectedItems]
  );

  return (
    <>
      <Table
        defaultSelected={renderDefaultSelected(tableData, "all")}
        search
        title="Drivers"
        columns={COLUMN_DEFINITIONS}
        data={tableData}
        onSelectedRowsChange={handleRowSelection}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        pagination
      />
    </>
  );
};

export default DriverTable;
