import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ServiceCreateForm } from "./Form";
import { useAuth } from "../../../../hooks/useAuth";
import {
  driverSelectOptions,
  fetchDrivers,
} from "../../../../redux/reducers/drivers/driversSlice";
import {
  fetchLocations,
  selectJobLocationOptions,
} from "../../../../redux/reducers/locations/locationsSlice";
import { formatDate } from "../../../../utils";
import { useGetDriversQuery } from "../../../../redux/services/drivers/driversApi";
import { useGetLocationsQuery } from "../../../../redux/services/locations/locationsApi";
import { useGetEquipmentQuery } from "../../../../redux/services/equipment";

export const ServiceCreate = ({
  errors,
  client,
  form,
  updateForm,
  setFormInit,
  ...props
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  // Set inital form data
  // appointmentDate, client, flatLeg
  useEffect(() => {
    if (client) {
      const init = { client };
      if (!form.date) {
        const apptDate = formatDate(form.job?.date || null, null, true);
        init.date = apptDate;
      }

      // setFormInit(init);
    }
  }, [client]);

  /*
    Fetch Locations
  */
  const {
    data: locations,
    isLoading: locationsLoading,
    isFetching: locationsFetching,
  } = useGetLocationsQuery({
    page: 1,
    limit: 100,
    clientId: client._id,
  });
  const locationOptions = selectJobLocationOptions({
    locations: locations || [],
  });

  /*
    Fetch Drivers
  */
  const {
    data: drivers,
    isLoading,
    isFetching,
  } = useGetDriversQuery({
    page: 1,
    limit: 100,
  });

  /*
    Render Driver Options
  */
  const driverOptions =
    isLoading || isFetching
      ? []
      : drivers.drivers.map((driver) => ({
          label: driver.fullName,
          value: driver,
        }));

  /*
    Fetch Equipment
  */
  const {
    data: equipment,
    isLoading: equipmentLoading,
    isFetching: equipmentFetching,
  } = useGetEquipmentQuery({ page: 1, limit: 100, clientId: client._id });

  const equipmentOptions =
    equipmentLoading || equipmentFetching
      ? []
      : equipment?.map((equipment) => ({
          value: equipment,
          label: (
            <div className="flex flex-col text-sm">
              <div>{equipment.name}</div>
              <div className="text-gray-500">
                {equipment.make} {equipment.model} • {equipment.serialNumber}
              </div>
            </div>
          ),
        }));

  /*
    Set form helper
  */
  function setForm(obj, custom) {
    setFormInit(custom ? obj : {}, { setErrors: true, reset: true });
    return;
  }

  return (
    <ServiceCreateForm
      handleChange={updateForm}
      errors={errors}
      form={form}
      driverOptions={driverOptions}
      locationOptions={locationOptions}
      setForm={setForm}
      equipmentOptions={equipmentOptions}
      {...props}
    />
  );
};

export default ServiceCreate;
