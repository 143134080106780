import { stateOptions } from "../../constants/select";
import Input from "../../shared/Input";
import Select from "../../shared/Select";

export const AddressForm = ({
  form = {},
  errors = {},
  updateForm,
  inputSize = "sm",
}) => {
  function onFormChange(e) {
    updateForm(e);
  }

  return (
    <div className="grid grid-cols-4 gap-3">
      <div className="col-span-2 xl:row-start-2">
        <Input
          size={inputSize}
          id="address"
          value={form.address}
          error={errors.address}
          label="Street Address"
          placeholder="1234 Example way"
          onChange={onFormChange}
        />
      </div>
      <div className="col-span-1 xl:row-start-2">
        <Input
          size={inputSize}
          id="suite"
          value={form.suite}
          error={errors.suite}
          label="Suite"
          placeholder="Suite"
          onChange={onFormChange}
        />
      </div>
      <div className="col-span-2 xl:row-start-3">
        <Input
          size={inputSize}
          id="city"
          value={form.city}
          error={errors.city}
          label="City"
          placeholder="City"
          onChange={onFormChange}
        />
      </div>
      <div className="col-span-2 xl:row-start-3">
        <Select
          size={inputSize}
          id="state"
          value={form.state}
          error={errors.state}
          label="State/Province"
          placeholder="State/Province"
          onChange={onFormChange}
          search
          options={stateOptions}
          autoComplete="state"
        />
      </div>
      <div className="col-span-2 xl:row-start-4">
        <Input
          size={inputSize}
          id="country"
          value={form.country}
          error={errors.country}
          label="Country"
          placeholder="Country"
          onChange={onFormChange}
        />
      </div>
      <div className="col-span-2 xl:row-start-4">
        <Input
          size={inputSize}
          id="zipcode"
          value={form.zipcode}
          error={errors.zipcode}
          label={"Zipcode"}
          placeholder="Zipcode"
          onChange={onFormChange}
        />
      </div>
    </div>
  );
};

export const AddressText = (props) => {
  return (
    <div className={props.className + " text-sm text-gray-800 font-semibold"}>
      <div>{props.address}</div>
      <div>
        {props.city}, {props.state} {props.zipcode}
      </div>
      <div>{props.phone}</div>
      <div>{props.email}</div>
    </div>
  );
};
