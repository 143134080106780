import { SettingsHeader } from "../";
import { useAuth } from "../../../hooks";
import {
  useGetCustomerInvoicesQuery,
  useGetCustomerQuery,
} from "../../../redux/services/customers/customersApi";
import { Button, Card, Spinner, Table } from "../../../shared";
import { CardHeader } from "../../../shared/Card/CardHeader";
import { capitalizeFirstLetter } from "../../../utils";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { COLUMN_DEFINITIONS } from "./table.config";

export const Billing = (props) => {
  const { user } = useAuth();
  // RTKQ API CALLS
  const { data: customerData, isLoading: customerLoading } =
    useGetCustomerQuery({
      id: user.parentCompany,
      billing: true,
      subscription: true,
    });
  const customer = customerData?.customer || {};

  const { data: customerInvoicesData, isLoading: invoicesLoading } =
    useGetCustomerInvoicesQuery(
      {
        id: customer?._id,
        stripeId: customer?.metadata?.stripeId,
      },
      { skip: !customer?.metadata?.stripeId }
    );

  const sub = customer.subscription;
  const cycle = sub?.plan.interval;
  const cost = sub?.plan.amount;

  const planCost = (cost / 100).toFixed(2);

  const planSummary = customerLoading ? (
    <Spinner />
  ) : (
    <div>
      <div className="grid grid-cols-3">
        <div>
          <div className="text-sm text-gray-500">Plan Name</div>
          <div className="font-semibold text-gray-800">Standard</div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Billing Cycle</div>
          <div className="font-semibold text-gray-800">
            {capitalizeFirstLetter(cycle)}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Plan Cost</div>
          <div className="font-semibold text-gray-800">${planCost}</div>
        </div>
      </div>
    </div>
  );

  const selectedPaymentMethod = customer?.billing?.[0];
  const payMethodType = selectedPaymentMethod?.type;
  const payMethod = selectedPaymentMethod?.[payMethodType] || {};

  const isCard = payMethodType === "card";
  const isBank = payMethodType === "us_bank_account";

  const paymentMethod = customerLoading ? (
    <Spinner />
  ) : (
    <div className="grid grid-cols-5 border p-1">
      {/* <div className="">Icon</div> */}
      <div className="col-span-4">
        <div className="text-sm font-semibold text-gray-700">
          {capitalizeFirstLetter(payMethod.brand)}
        </div>
        <div className="text-xs font-semibold text-gray-700">
          {isBank && payMethod.bank_name}
          {isCard && `**** **** **** ${payMethod.last4}`}
        </div>
        <div className="text-xs text-gray-500">
          {isBank && `Ending in ${payMethod.last4}`}
          {isCard &&
            `Expiry on ${`${payMethod.exp_month} ${payMethod.exp_year}`}`}
        </div>
        <div className="text-xs text-gray-500 flex">
          <EnvelopeIcon className="w-3 mr-1" /> {customer.email}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SettingsHeader title="Billing" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 auto-rows-fr gap-3">
        <div>
          <Card
            content={planSummary}
            header={
              <CardHeader
                title="Current Plan Summary"
                actions={<Button title="Upgrade" size="xs" />}
              />
            }
          />
        </div>
        <div>
          <Card
            content={paymentMethod}
            header={
              <CardHeader
                title="Payment Method"
                actions={<Button title="Change" type="light" size="xs" />}
              />
            }
          />
        </div>
      </div>
      <div>
        <Table columns={COLUMN_DEFINITIONS} />
      </div>
    </>
  );
};
