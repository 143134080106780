import isStrongPassword from "validator/lib/isStrongPassword";
import useForm from "../../../hooks/useForm";
import { Input } from "../../../shared";
import Progress from "../../../shared/Progress";
import isAlpha from "validator/lib/isAlpha";
import { renderValidations } from "../../../shared/Table/table.utils";
import { useState } from "react";

export const NameAndPassword = ({
  form,
  updateForm,
  setFormInit,
  isValid,
  errors,
  handleForm,
}) => {
  const [passError, setPassError] = useState("");

  function handlePassword({ id, value }) {
    const strength = isStrongPassword(value, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      returnScore: true,
      pointsPerUnique: 5,
      pointsPerRepeat: 5,
      pointsForContainingLower: 15,
      pointsForContainingUpper: 15,
      pointsForContainingNumber: 15,
      pointsForContainingSymbol: 15,
    });

    setPassError("");
    if (value && !form.confirm) {
      setPassError("Please confirm your password");
    }
    if (isAlpha(value)) {
      setPassError("Password must contain at least 1 number");
    }
    if (!/[A-Z]/.test(value)) {
      setPassError("Password must contain at least 1 uppercase");
    }
    if (value.length < 8) {
      setPassError("Password must be longer than 8 characters");
    }
    if (!value) {
      setPassError("Please provide a password");
    }

    setFormInit(
      {
        ...form,
        [id]: value,
        strength,
      },
      { setErrors: true }
    );
  }

  function handleConfirmPassword({ id, value }) {
    setPassError("");
    if (!value) {
      setPassError("Please confirm password");
    }
    if (form.password !== value) {
      setPassError("Passwords do not match");
    }

    setFormInit(
      {
        ...form,
        [id]: value,
      },
      { setErrors: true }
    );
  }

  return (
    <>
      <div className="pt-10 w-full">
        <div className="text-left pb-2 text-gray-600">What is your name?</div>
        <div className="grid grid-cols-2 gap-3">
          <Input
            onChange={handleForm}
            value={form.firstName}
            placeholder="First Name"
            id="firstName"
          />
          <Input
            onChange={handleForm}
            value={form.lastName}
            placeholder="Last Name"
            id="lastName"
          />
          <Input
            id="phone"
            onChange={handleForm}
            value={form.phone}
            placeholder="Phone"
          />
        </div>
      </div>
      <div className="pt-10 w-full text-left">
        <div className="pb-2 text-gray-600">Please create a password</div>
        <div className="grid grid-cols-2 gap-3">
          <Input
            onChange={handlePassword}
            type="password"
            id="password"
            placeholder="Password"
          />
          <div className="row-start-2">
            <Input
              onChange={handleConfirmPassword}
              type="password"
              id="confirm"
              placeholder="Confirm Password"
            />
          </div>
          <div className="row-start-3">
            {(form.password || form.confirm) && (
              <Progress
                color={passError ? "red" : ""}
                percent={form.strength}
                size="sm"
              />
            )}
            <div className="text-gray-700">{passError}</div>
          </div>
        </div>
      </div>
    </>
  );
};
