import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { renderCustomColumnDefs } from "../../../shared/Table/table.utils";
import { COLUMN_DEFINITIONS } from "./table.config";
import { useAuth } from "../../../hooks/useAuth";
import { Table } from "../../../shared";
import ExpandedLeg from "./ExpandedLeg";
import { LegCreateModal } from "./LegCreate/Modal";
import Accessories from "./LegAccessoriesModal";
import _ from "lodash";
import { isAuthorized } from "../../../utils/authority";
import { Badge } from "../../../shared";

export const LegsTable = ({
  job,
  form = {},
  visible = false,
  setVisible,
  ...props
}) => {
  const tableRef = useRef(null);
  const { user } = useAuth();
  const [addVisible, setAddVisible] = useState(false);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const legs =
    job?.legs &&
    job.legs?.map((item) => {
      const data = {
        ...item,
        status: item.status,
        jobId: job._id,
      };
      return {
        ...data,
        subItem: [data],
      };
    });

  const newTableData = [
    // {
    //   id: "status",
    //   size: 70,
    //   cell: ({ getValue }) => (
    //     <Badge className="!px-1" color="blue">
    //       {getValue()}
    //     </Badge>
    //   ),
    // },
    {
      id: "orderNumber",
      required: true,
      cell: ({ row, getValue, table }) => {
        const value = getValue();

        const data = row.original;
        return (
          <div>
            {!isAuthorized("owner, admin") ? (
              value
            ) : (
              <div
                className="text-primary-500 hover:underline cursor-pointer"
                onClick={() => {
                  row.toggleExpanded();
                  handleEditLeg(row.original, table.options.meta.job);
                }}
              >
                {value}
              </div>
            )}
            {(data.isHandoff || data.hasHandoff) && (
              <Badge>
                {data.isHandoff && "Handoff"}
                {data.hasHandoff && "Has Handoff"}
              </Badge>
            )}
          </div>
        );
      },
    },
    ...COLUMN_DEFINITIONS,
  ];

  const NEW_COLUMN_DEFINITIONS = renderCustomColumnDefs(newTableData);

  function handleEditLeg(e, jobData) {
    const updateItem = {
      ...e,
      client: jobData?.client,
      origin: e.route?.origin,
      destination: e.route?.destination,
      totalMiles: e.route?.mileage,
      customMileage: e.route?.customMileage,
      useCustomMileage: !!e.route?.customMileage,
      appointmentDate: e.appointmentDate,
      appointmentTime: e.appointment?.time,
      totalWaitTime: e.waitTime?.total,
      waitStart: e.waitTime?.start,
      waitEnd: e.waitTime?.end,
      base: jobData.base || jobData.baseRate,
      fuelRate: jobData.fuelRate,
      orderNumber: e.orderNumber,
      useLegFlatRate: jobData.useFlatRate || e.useLegFlatRate,
      job: jobData,
      edit: true,
    };
    form.setFormInit(updateItem, { setErrors: true });
    setVisible(true);
  }

  function handleAddAccessoryToLeg(e) {
    const updateItem = {
      ...e,
      client: job?.client,
      edit: true,
    };
    form.setFormInit(updateItem, { setErrors: true });
    setAddVisible(true);
  }

  function onModalCancel() {
    form.clearForm();
    setVisible(false);
  }

  function resetExpandedRows() {
    tableRef.current.resetExpandedRows(false);
  }

  // console.log(form);

  return (
    <>
      <JobItemsTable
        id="legs"
        tableData={legs}
        columns={NEW_COLUMN_DEFINITIONS}
        loading={loading}
        pageSize={100}
        globalSearchFilters={["transactionDate", "loadNumber", "orderNumber"]}
        job={job}
        expandedLayout={(e) => (
          <ExpandedLeg
            {...e}
            {...props}
            job={job}
            loading={loading}
            setLoading={setLoading}
            addAccessory={handleAddAccessoryToLeg}
            editLeg={handleEditLeg}
            resetExpandedRows={resetExpandedRows}
            // deleteLeg={handleDeleteLegs}
          />
        )}
        {...props}
      />
      <LegCreateModal
        visible={visible}
        setVisible={setVisible}
        onCancel={onModalCancel}
        form={form}
        job={job}
      />

      <Accessories
        user={user}
        legId={form._id}
        accessories={form.accessories}
        visible={addVisible}
        setVisible={setAddVisible}
        removeVisible={removeVisible}
        setRemoveVisible={setRemoveVisible}
      />
    </>
  );
};

export default LegsTable;
