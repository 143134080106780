import { useEffect, useRef, useState } from "react";

export const useDebounceSearch = (delay = 500) => {
  const [search, setSearch] = useState({
    term: "",
    useSearch: false,
  });

  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup the previous timeout on re-render
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleSearch = (value) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (value.length > 1 && value) {
        setSearch({ term: value, useSearch: true });
      } else {
        setSearch({
          term: "",
          useSearch: false,
        });
      }
    }, delay);
  };

  return { search, setSearch, handleSearch };
};
