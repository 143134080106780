import React from "react";
import { bgHoverPrimary } from "../../../styles/constants";

const ClientJobs = ({ totalJobs }) => {
  return (
    <div className="flex flex-col">
      <div className="text-gray-800 font-bold text-base sm:text-xl lg:text-2xl p-4">
        Jobs
      </div>
      <div className="text-center font-bold text-4xl sm:text-5xl flex justify-center">
        <div
          className={
            "p-2 rounded-lg cursor-default min-w-[40%] " + bgHoverPrimary
          }
        >
          {totalJobs}
        </div>
      </div>
    </div>
  );
};

export default ClientJobs;
