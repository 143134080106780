import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import request, { renderQueries } from "../../../utils/request";

const initialState = {
  legs: [],
  status: "idle",
  error: null,
};

export const fetchLegs = createAsyncThunk("legs/fetchLegs", async (obj) => {
  const queries = renderQueries(obj);
  const res = await request(`/legs?${queries}`);

  return res.data;
});

export const deleteLegs = createAsyncThunk(
  "jobs/deleteLegs",
  async ({ data, ...rest }) => {
    const queries = renderQueries(rest);
    const res = await request(`/legs?${queries}`, {
      method: "delete",
      data,
    });
    return res.data;
  }
);

export const legsSlice = createSlice({
  name: "legs",
  initialState,
  reducers: {
    resetLegs: () => initialState,
    setLegs(state, action) {
      state.legs = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLegs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchLegs.fulfilled, (state, action) => {
        state.legs = [];
        state.status = "succeeded";
        state.legs = action.payload.data;
      })
      .addCase(fetchLegs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteLegs.fulfilled, (state, action) => {
        state.legs = [];
        state.status = "succeeded";
        state.legs = action.payload.data;
      });
  },
});

export const { resetLegs, setLegs } = legsSlice.actions;

export default legsSlice.reducer;

export const selectAllLegs = (state) => state.legs.legs;
export const selectAllJobsOptions = (state) => {
  return state.jobs.jobs.map(({ name }) => {
    return {
      value: name,
      label: name,
    };
  });
};
export const selectLegsById = (state, legId) =>
  state.legs.legs.find((leg) => leg.id === legId);
