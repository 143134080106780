import React, { useEffect, useRef, useState } from "react";
import useForm from "../../../hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import { FORM_OPTIONS } from "../table.config";
import { useAuth } from "../../../hooks/useAuth";
import FormLayout from "../../../layouts/FormLayout";
import LegsTable from "../Create/LegsTable";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import SelectedLegsTable from "../Create/SelectedLegsTable";
import { renderAmount } from "../../../utils";
import Spinner from "../../../shared/Spinner";
import Tabs from "../../../shared/Tabs";
import { SidebarContainer } from "../../../components";
import {
  useGetPayoutQuery,
  useUpdatePayoutMutation,
} from "../../../redux/services/payouts/payoutsApi";
import { useGetLegsQuery } from "../../../redux/services/legs/legsApi";
import { toast } from "react-toastify";
import { Button } from "../../../shared";

const PayoutEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const table1Ref = useRef();
  const table2Ref = useRef();

  const { user } = useAuth();
  const { form, updateForm, setFormInit } = useForm(FORM_OPTIONS);

  const [legsData, setLegsData] = useState([]);
  const [payoutItems, setPayoutItems] = useState([]);
  const [selectedOrganizationDrivers, setSelectedOrganizationDrivers] =
    useState([]);
  const [type, setType] = useState("Individual");
  const [selectedLegs, setSelectedLegs] = useState([]);
  const [selectedPayoutItems, setSelectedPayoutItems] = useState([]);

  // Update Payout function
  const [updatePayout, { isLoading: payoutUpdating }] =
    useUpdatePayoutMutation();

  // Get payout query
  const { data: payoutData, isLoading } = useGetPayoutQuery(
    {
      id: params.id,
    },
    { skip: !params.id }
  );
  const payout = payoutData || {};

  // Legs Query
  const { data } = useGetLegsQuery(
    {
      driverId: payout?.driver?._id,
      payoutId: false,
      route: true,
      customQuery:
        type === "Organization" &&
        form.organization &&
        selectedOrganizationDrivers.length > 0
          ? JSON.stringify({
              driver: {
                $in: selectedOrganizationDrivers.map((i) => i._id),
              },
            })
          : undefined,
    },
    { skip: !payout?.driver?._id }
  );
  const legs = data || [];

  useEffect(() => {
    if (legs.length > 0 && legsData.length === 0) {
      setLegsData(legs);
    }
  }, [legs.length]);

  useEffect(() => {
    if (payout._id) {
      setFormInit({
        ...payout,
        totalpayout: form.total,
        hours: payout.hours || 0,
      });
      setPayoutItems(payout.legs);
      payout.organization && setSelectedOrganizationDrivers(payout.drivers);
      setType(payout.organization ? "Organization" : "Individual");
    }
  }, [payout._id]);

  useEffect(() => {
    let payoutTotal = renderPayoutTotal();
    const payoutMileage = renderPayoutMileage();
    const payoutWaitTime = renderPayoutWaitTime();

    setFormInit({
      hours: payoutWaitTime,
      payoutMileage: payoutMileage,
      totalPayout: payoutTotal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    payoutItems.length,
    form.totalPayout,
    form.payoutMileage,
    form.totalMileage,
    form.hours,
    form.rate,
    form.mileageRate,
  ]);

  function add() {
    const newData = legsData.filter((x) => !selectedLegs.includes(x));
    onFormChange({ id: "legs", value: [...payoutItems, ...selectedLegs] });
    setLegsData(newData);
    setPayoutItems([...payoutItems, ...selectedLegs]);
    table1Ref.current.resetSelectedRows(false);
  }

  function remove() {
    const newData = [...legsData, ...selectedPayoutItems];
    const newItems = payoutItems.filter(
      (x) => !selectedPayoutItems.includes(x)
    );
    setLegsData(newData);
    setPayoutItems(newItems);
    table2Ref.current.resetSelectedRows(false);
  }

  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  async function handleSubmit() {
    const data = {
      ...form,
      type: form.type,
      legs: payoutItems.map((i) => ({ _id: i._id })),
    };

    const res = await updatePayout({
      data,
      id: params.id,
      legacy: true,
    }).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "payout-updated",
    });

    if (res.status === "success") {
      navigate(`/payouts/${res.data._id}`);
    }
  }

  const renderPayoutMileage = () => {
    let totalPayoutMileage = payoutItems
      .map((item) => {
        return item.route?.mileage || 0;
      })
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    return Math.ceil(totalPayoutMileage);
  };

  const renderPayoutWaitTime = () => {
    let total = payoutItems
      .map((item) => {
        return item.waitTime?.total || 0;
      })
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    return form.hours || total;
  };

  const renderHourlyTotal = () => {
    let totalHourly = Number(form.rate) * Number(form.hours);
    return totalHourly;
  };

  const renderMileageTotal = () => {
    let mileageRate = form.mileageRate;
    const res = Number(form.payoutMileage) * Number(mileageRate);
    return res;
  };

  const renderPayoutTotal = () => {
    const hourlyTotal = renderHourlyTotal() || 0;
    const mileageTotal = renderMileageTotal() || 0;
    let totalPayoutCharges = Number(hourlyTotal) + Number(mileageTotal);
    return totalPayoutCharges;
  };

  const totalCharges = (
    <DisplayTotal title="Payout Total" value={renderAmount(form.totalPayout)} />
  );

  const legsSelector = (
    <div>
      <div className="flex flex-col xl:flex-row justify-evenly justify-items-stretch space-x-0 xl:space-x-2">
        <div className="flex-grow w-full">
          <LegsTable
            data={legsData}
            setData={setSelectedLegs}
            client={form.client}
            ref={table1Ref}
          />
        </div>
        <div className="flex-grow-0 self-center z-10">
          <div className="hidden xl:block">
            <IconButton icon={ChevronRightIcon} onClick={add} />
            <IconButton icon={ChevronLeftIcon} onClick={remove} />
          </div>
          <div className="flex xl:hidden">
            <IconButton icon={ChevronUpIcon} onClick={remove} />
            <IconButton icon={ChevronDownIcon} onClick={add} />
          </div>
        </div>
        <div className="flex-grow w-full">
          <SelectedLegsTable
            setSelectedItems={setSelectedPayoutItems}
            selectedData={payoutItems}
            client={form.client}
            ref={table2Ref}
          />
        </div>
      </div>
      <DisplayTotal title="# of Drops" value={payoutItems.length} />
      <div className="flex justify-end mt-4">
        <Button
          className="w-6/12 lg:w-28 mr-3"
          type="alternative"
          onClick={() => navigate(`/payouts/${params.id}`)}
        >
          Cancel
        </Button>
        <Button
          loading={payoutUpdating}
          className="w-6/12 lg:w-28"
          disabled={payoutUpdating}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );

  const tabs = [
    {
      id: "Edit",
      content: legsSelector,
    },
  ];

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderTabHeader = (headerProps) => {
    return <div ref={headerRef}></div>;
  };

  return (
    <SidebarContainer
      // title={title}
      // hideHeader
      hideSidebar
      header={renderTabHeader}
      // actions={actions}
      // sidebarContent={sidebarContent}
      // sidebarTitle={<h2 className="text-xl bg-white py-2">Communications</h2>}
    >
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 38}px`,
        };
        return (
          <div style={wrapperStyle} className="h-auto w-full">
            {isLoading ? (
              <div>
                <Spinner />{" "}
              </div>
            ) : (
              <Tabs
                className={`w-full overflow-hidden`}
                style={wrapperStyle}
                panelStyle={panelStyle}
                useSearchParams
                panelClasses={`p-2 border-t overflow-y-scroll`}
                tabListClasses="bg-white px-2 h-[38px]"
                tabclassName="mx-1"
                id="payout-edit"
                hideTabs
                // onClick={handleTabClick}
                data={tabs}
              />
            )}
          </div>
        );
      }}
    </SidebarContainer>
  );
};

const IconButton = (props) => {
  const Icon = props.icon;
  return (
    <div className="flex flex-col items-center my-4 mx-1">
      <div
        onClick={props.onClick}
        className="border ease-in-out duration-300 hover:border-gray-300 rounded-full p-2 hover:bg-gray-200 cursor-pointer"
      >
        <Icon className="w-6 h-6" />
      </div>
      {props.label && <p className="font-semibold">{props.label}</p>}
    </div>
  );
};

export const DisplayTotal = (props) => {
  return (
    <div className="flex items-center">
      {props.title}:{" "}
      <span className="font-bold pl-2 text-xl">{props.value}</span>
    </div>
  );
};

export default PayoutEdit;
