import Input from "../../shared/Input";
import Select from "../../shared/Select";
import TimeInput from "../../shared/TimeInput";
import Toggle from "../../shared/Toggle";
import { stateOptions } from "../../constants/select";

const LocationCreateForm = ({ form, onChange }) => {
  return (
    <div className="grid grid-cols-2 gap-3">
      <div className="col-span-2">
        <Input
          id="name"
          size="md"
          value={form.name}
          label="Name"
          placeholder="Name"
          onChange={onChange}
        />
      </div>
      <div className="col-span-1">
        <TimeInput
          id="open"
          size="md"
          value={form.open}
          label="Open"
          placeholder="Open"
          onChange={onChange}
        />
      </div>
      <div className="col-span-1">
        <TimeInput
          id="close"
          size="md"
          value={form.close}
          label="Close"
          placeholder="Close"
          onChange={onChange}
        />
      </div>
      <div className="col-span-2">
        <Input
          id="phone"
          size="md"
          value={form.phone}
          label="Phone"
          placeholder="Phone"
          onChange={onChange}
        />
      </div>
      <div className="col-span-2">
        <Input
          id="note"
          size="md"
          value={form.note}
          label="Note"
          placeholder="Note"
          onChange={onChange}
        />
      </div>
      <div className="col-span-2">
        <Toggle
          id="precall"
          size="md"
          checked={form.precall}
          label="Precall"
          placeholder="Precall"
          onChange={(e) => onChange({ id: "precall", value: e.target.checked })}
        />
      </div>
      <div className="col-span-2">
        <Input
          id="address"
          size="md"
          value={form.address}
          label="Address"
          placeholder="Address"
          onChange={onChange}
        />
      </div>
      <div className="col-span-1">
        <Input
          id="suite"
          value={form.suite}
          label="Suite"
          placeholder="Suite"
          onChange={onChange}
        />
      </div>
      <div className="col-span-1">
        <Input
          id="city"
          value={form.city}
          label="City"
          placeholder="City"
          onChange={onChange}
        />
      </div>
      <div className="col-span-1">
        <Select
          id="state"
          value={form.state}
          label="State/Province"
          placeholder="State/Province"
          onChange={onChange}
          search
          options={stateOptions}
          autoComplete="state"
        />
      </div>
      <div className="col-span-1">
        <Input
          id="country"
          value={form.country}
          label="Country"
          placeholder="Country"
          onChange={onChange}
        />
      </div>
      <div className="col-span-1">
        <Input
          id="zipcode"
          value={form.zipcode}
          label="Zipcode"
          placeholder="Zipcode"
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default LocationCreateForm;
