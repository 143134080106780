import React from "react";
import GlobalFilter from "./GlobalFilter";

const TableSearch = ({ globalFilter, setGlobalFilter, table }) => {
  const search = table.options.meta?.fullTextSearch;

  return (
    <span className="lg:w-1/2">
      <GlobalFilter
        value={globalFilter ?? ""}
        onChange={(value) =>
          search ? search(String(value)) : setGlobalFilter(String(value))
        }
      />
    </span>
  );
};

export default TableSearch;
