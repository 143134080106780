import { api } from "../../api";

const qrApi = api.injectEndpoints({
  tagTypes: ["Qr"],
  endpoints: (builder) => ({
    getQrById: builder.query({
      query: ({ id }) => {
        return `qr/${id}`;
      },
      providesTags: (data) =>
        data?.qr
          ? [
              ...data.qr.map(({ _id }) => ({ type: "Qr", id: _id })),
              { type: "Qr", id: "LIST" },
            ]
          : [{ type: "Qr", id: "LIST" }],
    }),
    addQr: builder.mutation({
      query(body) {
        return {
          url: "qr",
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, _error, data) => {
        return [{ type: "Qr", id: "LIST" }];
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetQrByIdQuery, useAddQrMutation } = qrApi;
