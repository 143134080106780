import { ChevronRightIcon } from "@heroicons/react/24/outline";
import classnames from "classnames";

export const DropArrow = ({ row, ...rest }) => {
  const classNames = classnames(rest.className, "w-4 h-4 cursor-pointer", {
    "rotate-90": row.getIsExpanded(),
  });

  return (
    <button className="cursor-pointer mr-3">
      <ChevronRightIcon className={classNames} />
    </button>
  );
};

export default DropArrow;
