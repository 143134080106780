import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../../utils/request";

const initialState = {
  jobs: [],
  status: "idle",
  error: null,
};

export const fetchMedias = createAsyncThunk(
  "medias/fetchMedias",
  async (parentId) => {
    const res = await request(`/jobs?parent=${parentId}`);
    return res.data;
  }
);

export const deleteMedias = createAsyncThunk(
  "medias/deleteMedias",
  async ({ data, parentId }) => {
    const res = await request(`/media?parent=${parentId}`, {
      method: "delete",
      data,
    });
    return res.data;
  }
);

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMedias.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMedias.fulfilled, (state, action) => {
        state.jobs = [];
        state.status = "succeeded";
        state.jobs = state.jobs.concat(action.payload.jobs);
      })
      .addCase(fetchMedias.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteMedias.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteMedias.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { data } = action.payload;
        const newMedias = state.medias.filter((x) => !data.includes(x._id));
        state.medias = newMedias;
      })
      .addCase(deleteMedias.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default jobsSlice.reducer;

export const selectAllJobs = (state) => state.jobs.jobs;
export const selectAllJobsOptions = (state) => {
  return state.jobs.jobs.map(({ name }) => {
    return {
      value: name,
      label: name,
    };
  });
};
export const selectJobsById = (state, jobId) =>
  state.jobs.jobs.find((job) => job.id === jobId);
