import { useState } from "react";
import { Button, Modal } from "../../../shared";
import AccessoryTable from "../Show/Accessories/AccessoryTable";

const LegAccessories = ({ form, visible, setVisible, submit, loading }) => {
  const [data, _setData] = useState([
    {
      // parentCompany: "String",
      // type: "String",
      // quantity: 0,
      // total: 0,
      // unitPrice: 0,
      // jobId: "String",
      // refId: "String",
      // createdAt: "",
      // updatedAt: "",
      editing: true,
    },
  ]);

  function handleCancelModal() {
    setVisible(false);
    form.clearForm();
  }

  function editAcc(e) {
    form.setFormInit({ edit: true, ...e });
    setAddModalVisible(true);
  }

  function removeAcc(e) {
    form.setFormInit(e);
    setRemoveModalVisible(true);
  }

  const modalFooter = (
    <>
      <Button onClick={handleCancelModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={loading}
        disabled={loading}
        onClick={submit}
        type="primary"
      >
        Submit
      </Button>
    </>
  );

  const modalContent = (
    <AccessoryTable data={data} editAcc={editAcc} removeAcc={removeAcc} />
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={() => setVisible(false)}
      title="Accessories"
      footer={modalFooter}
      content={modalContent}
      size="xl"
      footerPosition="right"
      // contentWrapperclassName="p-3"
    />
  );
};

export default LegAccessories;
