import React from "react";
import { capitalizeFirstLetter } from "../../utils";

const Form = ({
  form,
  onFormChange,
  id,
  formId,
  remove,
  data,
  type,
  formIds,
  setFormInit,
  formKey,
  children,
  customHeader,
  ...rest
}) => {
  const itemId = formId;
  const formData = data;

  function renderId(key) {
    return itemId + "_" + key;
  }

  function renderValue(key) {
    return formData[itemId]?.[renderId(key)];
  }

  function setForm(obj, custom) {
    setFormInit(
      custom
        ? obj
        : {
            ...form,
            [formKey]: {
              ...form[formKey],
              [itemId]: {
                ...formData,
                [renderId(obj.id)]: obj.value,
              },
            },
          },
      { setErrors: true }
    );
  }

  function handleChange({ value, id }) {
    onFormChange({
      id: formKey,
      value: {
        ...form[formKey],
        [itemId]: { ...formData[itemId], [id]: value },
      },
    });
  }
  const title = capitalizeFirstLetter(itemId.split("_").join(" "));

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        renderId,
        renderValue,
        setForm,
        handleChange,
        form,
        onFormChange,
        id,
        remove,
        data,
        formData,
        type,
        setFormInit,
        formKey,
        children,
        title,
        formId,
        ...rest,
      });
    }
    return child;
  });

  return (
    <div className="my-5">
      {customHeader ? (
        childrenWithProps
      ) : (
        <>
          <div className="flex items-center pb-2">
            <h3 className="font-bold text-xl mr-3">{title}</h3>
            {Object.keys(data).length > 1 && (
              <span
                className="text-red-500 cursor-pointer"
                onClick={() => remove(itemId)}
              >
                Delete
              </span>
            )}
          </div>
          {childrenWithProps}
          <div className="mt-5 border-b" />
        </>
      )}
    </div>
  );
};

export default Form;
