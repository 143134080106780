import React, { useEffect, useRef, useState } from "react";
import Input from "../../../shared/Input";
import Avatar from "../../../shared/Avatar";
import Button from "../../../shared/Button";
import useForm from "../../../hooks/useForm";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../redux/reducers/users/userSlice";
import { useAuth } from "../../../hooks";
import { toast } from "react-toastify";

const UserInfo = ({ parentId }) => {
  const { user } = useAuth();
  const { form, updateForm, setFormInit } = useForm();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [avatar, setAvatar] = useState("");
  const id = user._id;

  useEffect(() => {
    if (!form.email && user) {
      setFormInit(user);
      setAvatar(user.avatar);
    }
  }, [user, setFormInit, form.email]);

  function onFormChange({ id, value }) {
    updateForm({ id, value });
  }

  async function handleSubmit() {
    const res = await dispatch(
      updateUser({ parentId, id, user: form })
    ).unwrap();

    toast(res.message, {
      toastId: "account-information",
      type: res.status,
    });
  }

  function uploadAvatar() {
    inputRef.current.click();
  }

  function handleFileChange(event) {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    onFormChange({ id: "avatar", value: fileObj });
  }

  if (!user) {
    return "Loading...";
  }

  return (
    <div>
      <h3 className="text-2xl font-semibold text-gray-900">
        Account Information
      </h3>
      <div className="flex flex-col-reverse md:grid grid-cols-7 py-3">
        <div className="col-span-3">
          <div className="py-2">
            <Input
              id="email"
              onChange={onFormChange}
              value={form.email}
              size="sm"
              placeholder="Email"
              label="Email"
            />
          </div>
          <div className="grid grid-cols-2 gap-x-3.5 py-2">
            <div className="flex flex-col">
              <Input
                id="firstName"
                onChange={onFormChange}
                value={form.firstName}
                size="sm"
                placeholder="First Name"
                label="First Name"
              />
            </div>
            <div className="flex flex-col">
              <Input
                id="lastName"
                onChange={onFormChange}
                value={form.lastName}
                size="sm"
                placeholder="Last Name"
                label="Last Name"
              />
            </div>
          </div>
          <div className="py-2">
            <Input
              id="fullName"
              onChange={onFormChange}
              value={form.fullName}
              size="sm"
              placeholder="Full Name"
              label="Full Name"
            />
          </div>
          <div className="py-2">
            <Input
              id="phone"
              onChange={onFormChange}
              value={form.phone}
              size="sm"
              placeholder="Phone"
              label="Phone"
            />
          </div>
          <div className="flex justify-end">
            <Button
              disabled={Object.keys(form).length < 1}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="col-start-4 col-end-6 px-12">
          <div className="flex flex-col items-center">
            <span className="text-xl mb-2">Avatar</span>
            <Avatar loading={!avatar} src={avatar} size="lg" />
            {/* <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <Button className="mt-3" onClick={uploadAvatar}>
              Upload Image
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
