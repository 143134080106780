export const containerSizeOptions = [
  { label: "20HC", value: "20HC" },
  { label: "40HC", value: "40HC" },
];

export const loadTypeOptions = [
  {
    label: "Regular",
    value: "Regular",
    description: "This rate is the default drop charge.",
    id: "regular",
    type: "normal",
    effects: "dropCharges",
  },
  {
    label: "Hot",
    value: "Hot",
    description:
      "This rate will be added to Other Charges with the regular rate for drop charges.",
    id: "hot",
    type: "flat",
    effects: "baseCharges",
  },
  {
    label: "Layover",
    value: "Layover",
    description:
      "This is a flat rate that will override any base charge calculations.",
    id: "layover",
    base: true,
  },
  {
    label: "Deadhead",
    value: "Deadhead",
    description:
      "This is a flat rate that will override any base charge calculations.",
    id: "deadhead",
    base: true,
  },
  {
    label: "Reroute",
    value: "Reroute",
    description:
      "This rate will be added to Other Charges with the regular rate for drop charges.",
    id: "reroute",
  },
  {
    label: "Return",
    value: "Return",
    description:
      "This rate will be added to Other Charges with the regular rate for drop charges.",
    id: "return",
  },
  {
    label: "Internet",
    value: "Internet",
    description: "This rate affects the Drop Charge",
    id: "internet",
  },
  {
    label: "Backhaul",
    value: "Backhaul",
    description: "This rate affects the Drop Charge",
    id: "backhaul",
  },
  {
    label: "Empty",
    value: "Empty",
    description: "This rate affects the Drop Charge",
    id: "empty",
  },
];

export const originTerminateOptions = [
  { label: "APM", value: "APM" },
  { label: "Pier A", value: "Pier A" },
  { label: "LBCT", value: "LBCT" },
  { label: "PCT", value: "PCT" },
  { label: "LAX", value: "LAX" },
  { label: "Yard", value: "Yard" },
];
