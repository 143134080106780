import React, { useEffect, useRef, useState } from "react";
import Input from "../../../shared/Input";
import Select from "../../../shared/Select";
import Avatar from "../../../shared/Avatar";
import Button from "../../../shared/Button";
import useForm from "../../../hooks/useForm";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../redux/reducers/users/userSlice";
import { FORM_OPTIONS } from "./form.config";
import { useNavigate } from "react-router-dom";
import Toggle from "../../../shared/Toggle";
import { toast } from "react-toastify";
import { authorityOptions } from "../select.config";
import { capitalizeFirstLetter, isObject } from "../../../utils";

const UserInfo = ({ user, parentId, currentUser }) => {
  const { form, updateForm, setFormInit, errors, isValid } =
    useForm(FORM_OPTIONS);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState("");
  const [formSet, setFormSet] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (!formSet && !form.email && user) {
      setFormInit(user, { setErrors: true });
      setAvatar(user.avatar);
      setFormSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setFormInit, form.email]);

  function onFormChange({ id, value }) {
    if (!hasChanged) {
      setHasChanged(true);
    }
    updateForm({ id, value });
  }

  async function handleSubmit() {
    delete form.meta;
    delete form.organization;
    const data = {
      ...form,
      authority: isObject(form.authority)
        ? form.authority.value
        : form.authority,
    };
    const res = await dispatch(updateUser({ parentId, user: data })).unwrap();
    if (res.status === "success") {
      toast("User updated", {
        type: "success",
        toastId: "clientsDeleted",
      });
    }
  }

  const actions = (
    <div className="flex items-center">
      <span className="mr-1 font-semibold">Active</span>
      <Toggle
        id="active"
        checked={form.active}
        onChange={(e) =>
          onFormChange({ id: "active", value: e.target.checked })
        }
        size="sm"
      />
    </div>
  );

  if (!user) {
    return "Loading...";
  }
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="lg:col-span-1">
        <div className="flex items-center justify-between">
          <h3 className="text-2xl font-semibold text-gray-900">Edit User</h3>
          {actions}
        </div>
      </div>
      <div className="lg:col-span-1 lg:row-span-2">
        <div className="flex flex-col items-center">
          <span className="text-xl mb-2">Avatar</span>
          <Avatar loading={!avatar} src={avatar} size="lg" />
          {/* <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
          />
          <Button className="mt-3" onClick={uploadAvatar}>
            Upload Image
          </Button> */}
        </div>
      </div>
      <div className="flex flex-col  py-3">
        <div className="py-2">
          <Input
            id="email"
            onChange={onFormChange}
            value={form.email}
            size="sm"
            placeholder="Email"
            label="Email"
            error={errors.email}
          />
        </div>
        <div className="grid grid-cols-2 gap-x-3.5 py-2">
          <div className="flex flex-col">
            <Input
              id="firstName"
              onChange={onFormChange}
              value={form.firstName}
              size="sm"
              placeholder="First Name"
              label="First Name"
            />
          </div>
          <div className="flex flex-col">
            <Input
              id="lastName"
              onChange={onFormChange}
              value={form.lastName}
              size="sm"
              placeholder="Last Name"
              label="Last Name"
            />
          </div>
        </div>
        <div className="py-2">
          <Input
            id="fullName"
            onChange={onFormChange}
            value={form.fullName}
            size="sm"
            placeholder="Full Name"
            label="Full Name"
          />
        </div>
        <div className="py-2">
          <Input
            id="phone"
            onChange={onFormChange}
            value={form.phone}
            size="sm"
            placeholder="Phone"
            label="Phone"
          />
        </div>
        {(currentUser.authority === "admin" ||
          currentUser.authority === "superAdmin") && (
          <div className="py-2">
            <Select
              id="authority"
              onChange={(e) =>
                onFormChange({ id: "authority", value: e.value })
              }
              value={form.authority}
              size="sm"
              placeholder="Authority"
              label="Authority"
              options={authorityOptions}
            />
          </div>
        )}
        <div className=" flex justify-end">
          <Button
            className="mr-3"
            type="alternative"
            onClick={() => navigate("/users")}
          >
            Cancel
          </Button>
          <Button disabled={!hasChanged || !isValid} onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
