import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
  map: {},
  status: "idle",
  error: null,
};

export const getDistance = createAsyncThunk("map/getDistance", async (data) => {
  const res = await request("/maps/distance", {
    method: "post",
    data,
  });
  return res.data;
});

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    resetMap: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getDistance.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDistance.fulfilled, (state, action) => {
        state.map = {};
        state.status = "succeeded";
        state.map = action.payload;
      })
      .addCase(getDistance.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetMap } = mapSlice.actions;

export default mapSlice.reducer;
