import React, { forwardRef, useCallback } from "react";
import Table from "../../../shared/Table";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";

const SelcetedLegsTable = forwardRef(
  ({ selectedData, setSelectedItems }, ref) => {
    const tableData = React.useMemo(() => selectedData, [selectedData]);
    const handleRowSelection = useCallback(
      (value) => setSelectedItems(value),
      [setSelectedItems]
    );

    return (
      <>
        <Table
          ref={ref}
          search
          // loading={loading}
          title="Manifest"
          columns={COLUMN_DEFINITIONS}
          data={tableData}
          onSelectedRowsChange={handleRowSelection}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          pagination
        />
      </>
    );
  }
);

export default SelcetedLegsTable;
