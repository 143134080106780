import { useCallback, useEffect, useState } from "react";
import Button from "../../../../shared/Button";
import Modal from "../../../../shared/Modal";
import LegCreate from ".";
import { useAuth } from "../../../../hooks/useAuth";
import { parse, stringify } from "flatted";
import {
  useAddLegMutation,
  useUpdateLegMutation,
} from "../../../../redux/services/legs/legsApi";
import { toast } from "react-toastify";
import useEmblaCarousel from "embla-carousel-react";
import "./style.css";
import { formatDate } from "../../../../utils";
import useForm from "../../../../hooks/useForm";

export const LegCreateModal = ({
  visible,
  setVisible,
  form,
  job,
  onCancel,
}) => {
  // Get current user data
  const { user } = useAuth();

  // Main modal title
  const [modalTitle, setModalTitle] = useState("Add");
  // Submit button title
  const [submitButton, setSubmitButton] = useState("Submit");
  // Cancel button title
  const [cancelButton, setCancelButton] = useState("Cancel");

  // Handoff form hook
  const handoffForm = useForm();

  // Add leg hook
  const [addLeg, { isLoading: addingLeg }] = useAddLegMutation();
  // Update leg hook
  const [updateLeg, { isLoading: updatingLeg }] = useUpdateLegMutation();

  // Carousel hook
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    watchDrag: false,
  });

  // Handle carousel back
  const scrollPrev = useCallback(() => {
    setSubmitButton("Next");
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  // Handle carousel next
  const scrollNext = useCallback(() => {
    setSubmitButton("Submit");
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  // Close job leg modal
  function closeModal() {
    form.clearForm();
    onCancel(false);
  }

  function getApptDate(date, time) {
    const apptDate = new Date(date);

    if (time) {
      const timeArr = time.split(":");

      apptDate.setHours(timeArr[0]);
      apptDate.setMinutes(timeArr[1]);
    }
    return apptDate;
  }

  // Handle leg update
  async function handleUpdate(e) {
    let updateData = {
      parent: user.parentCompany,
      data: e,
    };

    if (e.column) {
      updateData = e.column.editable.update
        ? e.column.editable.update({ user, ...e })
        : {
            ...updateData,
            legId: e.row.original._id,
            data: e.data,
          };
    } else {
      updateData = {
        parent: user.parentCompany,
        legId: e._id,
        jobId: job._id,
        data: {
          ...e,
          driver: e.driver?._id,
          client: job.client?._id,
          route: {
            origin: e.origin?._id,
            destination: e.destination?._id,
            mileage: Number(e.totalMiles),
            customMileage: Number(e.customMileage),
            _id: form.form.route?._id,
          },
          appointmentDate: getApptDate(e.appointmentDate, e.appointmentTime),
          // appointment: { date: e.appointmentDate, time: e.appointmentTime },
          waitTime: {
            total: e.totalWaitTime,
            start: e.waitStart,
            end: e.waitEnd,
          },
        },
      };
      delete updateData.origin;
      delete updateData.destination;
      // delete updateData.appointmentDate;
      // delete updateData.appointment;
      delete updateData.author;
      delete updateData.dateCreated;
      delete updateData.dateUpdated;
      delete updateData.parentCompany;
      delete updateData.payoutId;
      delete updateData.status;
    }
    const dataString = stringify(updateData);
    const dataToSend = parse(dataString);

    let res = await updateLeg(dataToSend).unwrap();

    if (res.status === "success") {
      closeModal();
    }
  }

  // Reset handoff data
  function resetHandoff() {
    setSubmitButton("Submit");
    handoffForm.clearForm();
  }

  // Toggle handoff from main form
  function toggleHandoff(e) {
    // Set hasHandoff
    form.updateForm(e);

    if (e.value) {
      // Set submit button title if handoff is checked true
      setSubmitButton("Next");
    } else {
      // Reset handoff if toggled off
      resetHandoff();
      return;
    }
  }

  // Handle next if handoff is selected
  function handleNext() {
    // Set main button to submit
    setSubmitButton("Submit");

    // Set handoffs appointment date
    const apptDate = formatDate(form.job?.transactionDate || null, null, true);

    // Set inital handoff form data
    handoffForm.setFormInit({
      job,
      useLegFlatRate: false,
      origin: form.form.destination,
      orderNumber: form.form.orderNumber,
      loadNumber: form.form.loadNumber,
      loadType: form.form.loadType,
      destination: null,
      weight: form.form.weight,
      driver: {},
      appointmentTime: null,
      appointmentDate: apptDate,
      waitEnd: null,
      waitStart: null,
      waitTime: false,
      totalWaitTime: 0,
      baseCharge: 0,
      fuelSurcharge: 0,
      otherCharges: 0,
      dropCharge: 0,
      useCustomFuelSurcharge: false,
      useCustomBaseCharge: false,
      useCustomDropCharge: false,
      useCustomOtherCharges: false,
      totalMiles: 0,
      totalCharges: 0,
      hasHandoff: null,
      isHandoff: true,
    });

    // Handle carousel next
    scrollNext();
  }

  async function saveHandoff(handoffParent) {
    const handoffData = {
      ...handoffForm.form,
      pickupDate: handoffForm.form.job.transactionDate,
      driver: handoffForm.form.driver?._id,
      destination: handoffForm.form.destination?._id,
      origin: handoffForm.form.origin?._id,
      jobId: handoffForm.form.job._id,
      handoffParent,
    };

    delete handoffData.job;

    const res = await addLeg({
      data: handoffData,
      client: job.client._id,
      jobId: handoffData.jobId,
      addToJob: true,
    }).unwrap();

    // Main leg toast
    toast("Successfully created handoff leg", {
      type: res.status,
      toastId: "leg-handoff-" + res.data.leg.orderNumber + "-added",
    });

    return res;
  }

  function postSaveCleanUp() {
    closeModal();
    setModalTitle("Add");
    resetHandoff();
  }

  // Save form with no handoff
  async function save() {
    // Main leg data
    const data = {
      ...form.form,
      pickupDate: form.form.job.transactionDate,
      driver: form.form.driver?._id,
      destination: form.form.destination?._id,
      origin: form.form.origin?._id,
      jobId: form.form.job._id,
    };

    // Remove job before sending leg to create
    delete data.job;

    // Create main leg
    const res = await addLeg({
      data,
      client: job.client._id,
      jobId: data.jobId,
      addToJob: true,
    }).unwrap();

    // Main leg toast
    toast("Successfully created leg", {
      type: res.status,
      toastId: "leg-" + res.data.leg.orderNumber + "-added",
    });

    if (data.hasHandoff) {
      const handoffSaved = await saveHandoff(res.data.leg._id);

      if (res.status === "success" && handoffSaved.status === "success") {
        postSaveCleanUp();
      }
      return;
    }

    if (res.status === "success") {
      postSaveCleanUp();
    }
  }

  // Main modal button handler
  function handleSubmit() {
    // If main form has handoff handle next form
    if (form.form.hasHandoff && emblaApi?.canScrollNext()) {
      handleNext();
      return;
    }

    // If not handoff save
    if (!form.form.edit) {
      return save();
    }

    // Handle update
    return handleUpdate(form.form);
  }

  // Handle cancel button
  function handleCancel() {
    closeModal();
    resetHandoff();
  }

  // Main form title
  const title = form.form.orderNumber || "New Leg";

  // Main form title
  const handoffTitle = handoffForm.form.orderNumber || "New Handoff Leg";

  // Form footer
  const footer = (
    <div>
      {/* Cancel button */}
      <Button type="alternative" className="mr-2" onClick={handleCancel}>
        {cancelButton}
      </Button>
      {/* Back button */}
      {submitButton === "Submit" && handoffForm.form.isHandoff && (
        <Button type="alternative" className="mr-2" onClick={scrollPrev}>
          Back
        </Button>
      )}
      {/* Main modal button */}
      <Button
        id="submit-leg"
        loading={addingLeg || updatingLeg}
        disabled={addingLeg || updatingLeg || !form.isValid}
        onClick={handleSubmit}
      >
        {submitButton}
      </Button>
    </div>
  );

  const content = (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        <div className="embla__slide">
          <LegCreate
            {...form}
            toggleHandoff={toggleHandoff}
            type={modalTitle}
            job={job}
            client={job?.client}
          />
        </div>
        {form.form.hasHandoff && (
          <div className="embla__slide">
            <LegCreate
              {...handoffForm}
              type={handoffTitle}
              job={job}
              client={job?.client}
            />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={content}
      size="xl"
      footerPosition="right"
      contentWrapperClass="p-3 lg:px-6 space-y-6"
    />
  );
};
