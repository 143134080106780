import React, { forwardRef, useCallback } from "react";
import Table from "../../../shared/Table";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";

const LegsTable = forwardRef(({ data, setData }, ref) => {
  const tableData = React.useMemo(() => data, [data]);

  const handleRowSelection = useCallback((value) => setData(value), [setData]);

  return (
    <>
      <Table
        ref={ref}
        search
        // loading={loading}
        title="Legs"
        columns={COLUMN_DEFINITIONS}
        data={tableData}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onSelectedRowsChange={handleRowSelection}
        pagination
      />
    </>
  );
});

export default LegsTable;
