import React from "react";

export const EventDetailsPopover = ({
  event,
  onClose,
  position,
  renderContent,
}) => {
  console.log("EventDetailsPopover: Received event", event);

  return renderContent(event, onClose);
};

export default EventDetailsPopover;
