import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../utils/request";

const initialState = {
  dashboard: {},
  status: "idle",
  error: null,
};
export const getDashboard = createAsyncThunk(
  "dashboard/getDashboard",
  async (obj) => {
    let queryObject = { ...obj };
    const queries = renderQueries(queryObject);
    const res = await request("/dashboard?" + queries);
    return res.data;
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetDashboard: () => initialState,
    dashJobsUpdated(state, action) {
      const { _id } = action.payload;
      const index = state.dashboard.jobs.findIndex((item) => item._id === _id);
      state.dashboard.jobs[index] = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDashboard.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.dashboard = {};
        state.status = "succeeded";
        state.dashboard = action.payload.data;
      })
      .addCase(getDashboard.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetDashboard, dashJobsUpdated } = dashboardSlice.actions;

export default dashboardSlice.reducer;
