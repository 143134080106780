import { useRef, useState } from "react";
import Dropdown from "../Dropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const FilterItem = (props) => {
  const [defaultOpen, setDefaultOpen] = useState(true);
  const [position, setPosition] = useState("right");

  function handleRemove() {
    setDefaultOpen(false);
    props.remove();
    setTimeout(() => setDefaultOpen(true), 200);
  }

  const triggerRef = useRef(null);

  const handleClick = (e) => {
    const divWidth = triggerRef.current.getBoundingClientRect().width;
    const halfDivWidth = divWidth / 2;
    const mouseXPos = e.nativeEvent.offsetX;

    if (mouseXPos <= halfDivWidth) {
      // setPosition("left");
    } else {
      setPosition("right");
    }
  };

  return (
    <>
      <Dropdown
        id="test"
        className="!w-max"
        dataWrapperClassName="w-max"
        // closeOnClick
        defaultOpen={defaultOpen}
        align={position}
        data={props.dropdown}
        trigger={
          <div
            ref={triggerRef}
            onClick={handleClick}
            className="flex items-center border rounded-lg py-2 px-2 space-x-1 text-xs cursor-default bg-white"
          >
            <span className="text-gray-600">{props.title}:</span>
            <span className="font-semibold">
              {props.displayText || props.value}
            </span>
            <XMarkIcon onClick={handleRemove} className="w-4 h-4" />
          </div>
        }
      />
    </>
  );
};
