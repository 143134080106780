import axios from "axios";
import { getParentCompany, getToken } from "./authority";
import { ROOT_API_URL, ROOT_URL } from "../config/defaultSettings";
import join from "join-path";

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  function (response) {
    try {
      /*
      Set Updated version
    */
      if (response.headers["x-version"] > import.meta.env.PACKAGE_VERSION) {
        window.localStorage.setItem("version-update-needed", "true");
      }
      window.localStorage.setItem("version", import.meta.env.PACKAGE_VERSION);
    } catch (err) {
      console.error("[ AXIOS Interceptor ]", err);
    }
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
      !window.location.href.includes("/login")
    ) {
      window.location.href = join(ROOT_URL + "/login");
    }
    // Do something with request error
    return Promise.reject(error);
  }
);

async function request(url, options) {
  const ROOT = (options && options.baseURL) || ROOT_API_URL;
  const baseOptions = {
    baseURL: ROOT,
    ...options,
  };

  const headers = {
    headers: {
      "Customer-Context": getParentCompany(),
    },
  };

  const newOptions = {
    ...baseOptions,
    ...headers,
  };

  const res = await axios(url, newOptions);
  return res;
}

export default request;

/*
  Create search URLSearchParams
*/
export function getSearchParams(obj) {
  const params = new URLSearchParams(obj);
  return params;
}

/*
 * Legacy create search URLSearchParams
 * @deprecated
 */
export function renderQueries(obj) {
  const queryKeys = Object.keys(obj);

  const res = queryKeys.map((key) => {
    return `${key}=${obj[key]}`;
  });

  return res.join("&");
}
