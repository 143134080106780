import React from "react";
// import dateFns from "date-fns";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import duration from "dayjs/plugin/duration";
import "./index.css";

dayjs.extend(duration);
dayjs.extend(weekday);

class Calendar extends React.Component {
  state = {
    currentMonth: new Date(),
    selectedDate: new Date(),
  };

  renderHeader() {
    const dateFormat = "MMMM YYYY";
    const date = dayjs(this.state.currentMonth).format(dateFormat);

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={this.prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="col col-center">
          <span>{date}</span>
        </div>
        <div className="col col-end" onClick={this.nextMonth}>
          <div className="icon">chevron_right</div>
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = "dddd";
    const days = [];

    let startDate = dayjs(this.state.currentMonth).weekday(0);

    for (let i = 0; i < 7; i++) {
      const date = startDate.add(dayjs.duration({ days: i }));
      days.push(
        <div className="col col-center" key={i}>
          {date.format(dateFormat)}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  }

  renderCells() {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = dayjs(currentMonth).startOf("month"); // dateFns.startOfMonth(currentMonth);
    const monthEnd = dayjs(monthStart).endOf("year"); // dateFns.endOfMonth(monthStart);
    const startDate = dayjs(monthStart).startOf("week"); // dateFns.startOfWeek(monthStart);
    const endDate = dayjs(monthEnd).endOf("week"); // dateFns.endOfWeek(monthEnd);

    const dateFormat = "D";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dayjs(day).format(dateFormat);
        const cloneDay = day;

        days.push(
          <div
            className={`col cell ${
              !dayjs(day).isSame(monthStart, "month")
                ? "disabled"
                : dayjs(day).isSame(selectedDate, "day")
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => this.onDateClick(dayjs(cloneDay))}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
          </div>
        );
        // day = dateFns.addDays(day, 1);
        day = dayjs(day).add(dayjs.duration({ days: 1 }));
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    rows.length = 6;
    return <div className="body">{rows}</div>;
  }

  onDateClick = (day) => {
    this.setState({
      selectedDate: day,
    });
  };

  nextMonth = () => {
    this.setState({
      // currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
      currentMonth: dayjs(this.state.currentMonth).add(
        dayjs.duration({ month: 1 })
      ),
    });
  };

  prevMonth = () => {
    this.setState({
      // currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
      currentMonth: dayjs(this.state.currentMonth).subtract(
        dayjs.duration({ month: 1 })
      ),
    });
  };

  render() {
    return (
      <div className="px-3 pb-3 h-auto">
        <div className="calendar">
          {this.renderHeader()}
          {this.renderDays()}
          {this.renderCells()}
        </div>
      </div>
    );
  }
}

export default Calendar;
