import { Badge } from "../../../shared";
import { renderCustomColumnDefs } from "../../../shared/Table/table.utils";
import { formatDate, renderAmount } from "../../../utils";
import { isAuthorized } from "../../../utils/authority";

// Config for table and form
const tableConfig = [
  // {
  //   id: "date",
  //   header: "Service Date",
  //   minWidth: 170,
  //   cell: ({ getValue }) => {
  //     const value = getValue();
  //     return value && formatDate(value, null, true);
  //   },
  // },
  {
    id: "equipment",
    header: "Equipment",
    minWidth: 170,
    cell: ({ getValue }) => {
      const value = getValue();
      return value?.name || "";
    },
  },
  {
    id: "serviceType",
    header: "Service Type",
    minWidth: 150,
  },
  // {
  //   id: "status",
  //   header: "Status",
  //   minWidth: 120,
  //   cell: ({ getValue }) => {
  //     const value = getValue();
  //     return <Badge label={value} />;
  //   },
  // },
  {
    id: "description",
    header: "Description",
    minWidth: 200,
    visible: false,
  },
  {
    id: "performedBy",
    header: "Performed By",
    minWidth: 170,
    cell: ({ getValue }) => {
      const value = getValue();
      return value?.map((user) => user.name).join(", ") || "";
    },
  },
  {
    id: "serviceCharge",
    header: "Service Charge",
    minWidth: 120,
    remove: !isAuthorized("owner,admin"),
    cell: ({ getValue }) => {
      return <span>{renderAmount(getValue())}</span>;
    },
  },
  {
    id: "nextServiceDate",
    header: "Next Service Date",
    minWidth: 170,
    cell: ({ getValue }) => {
      const value = getValue();
      return value && formatDate(value, null, true);
    },
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
