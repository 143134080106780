import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../../utils/request";

const initialState = {
  customer: {},
  status: "idle",
  error: null,
};

export const fetchCustomer = createAsyncThunk(
  "customer/fetchCustomer",
  async ({ parentId, customerId }) => {
    const res = await request(`/customers/${customerId}?parent=${parentId}`);
    return res.data;
  }
);

export const addNewCustomer = createAsyncThunk(
  "customer/addNewCustomer",
  async (initialPost) => {
    const res = await request("/onboarding/customer", {
      method: "post",
      data: initialPost,
    });
    return res.data;
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async ({ parentId, driverId, data }) => {
    const res = await request(`/users/${customerId}?parent=${parentId}`, {
      method: "put",
      data: data,
    });
    return res.data;
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    resetCustomer: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCustomer.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.customer = {};
        state.status = "succeeded";
        state.customer = action.payload.data;
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewCustomer.fulfilled, (state, action) => {
        state.customer = action.payload;
      })
      .addCase(updateCustomer.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.customer = {};
        state.status = "succeeded";
        state.customer = action.payload.data;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetCustomer } = customerSlice.actions;
export default customerSlice.reducer;

export const selectAllCustomers = (state) => state.customer.customer;
