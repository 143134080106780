const TEMP_DATA = (item, num, options) => {
  const arr = Array.from(Array(num).keys()).map((key, i) => {
    if (options) {
      if (options.numbers) return key;
      if (options.letters) return String.fromCharCode(key + 64);
    }
    return item && item(key, i);
  });
  return arr;
};
export default TEMP_DATA;
