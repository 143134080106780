import {
  ScheduleComponent,
  Day,
  Inject,
  Week,
  WorkWeek,
  Month,
  Agenda,
  TimelineViews,
  ViewDirective,
  ViewsDirective,
  TimelineMonth,
  ResourcesDirective,
  ResourceDirective,
} from "@syncfusion/ej2-react-schedule";
import EventDetailsPopover from "./EventDetailsPopover";
// import EventDetailsPopover from "../../components/EventDetailsPopover";
import "./index.scss";

import { useCallback, useState } from "react";

export const Scheduler = (props) => {
  function handleSelectDate(args) {
    props.setSelectedDate && props.setSelectedDate(args);
  }

  const [showPopover, setShowPopover] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });

  const eventFields = props.eventFields || [];

  const eventSettings = {
    dataSource: props.data || [],
    fields: {
      id: "Id",
      subject: { name: "Subject" },
      startTime: { name: "StartTime" },
      endTime: { name: "EndTime" },
      isAllDay: { name: "IsAllDay" },
    },
  };

  console.log("eventSettings", eventSettings);

  console.log("props", props.data);

  const onRenderCell = (args) => {
    // console.log(args);
  };

  const onEventClick = useCallback((args) => {
    console.log("Event clicked:", args);
    if (args.event) {
      setSelectedEvent(args.event);
      // Ensure we're getting clientX and clientY from the correct object
      const { clientX, clientY } = args.event;
      setPopoverPosition({ x: clientX || 0, y: clientY || 0 });
      setShowPopover(true);
    } else {
      console.warn("Event clicked but no event data found");
    }
    args.cancel = true; // Prevent default action
  }, []);

  const closePopover = () => {
    setShowPopover(false);
    setSelectedEvent(null);
  };

  const resourceData = props.group || [];

  // Add this check
  const groupResources = props.name ? [props.name] : [];

  console.log("eventSettings", eventSettings);
  console.log("resourceData", resourceData);
  console.log("groupResources", groupResources);

  return (
    <>
      <ScheduleComponent
        currentView={props.currentView}
        navigating={props.navigating}
        width={props.width || "100%"}
        height={props.height}
        eventSettings={eventSettings}
        group={{ resources: groupResources }}
        eventClick={onEventClick}
        popupOpen={(args) => {
          if (args.type === "EventContainer") {
            args.cancel = true; // Prevent default popover
          }
        }}
        selectedDate={props.selectedDate}
        renderCell={onRenderCell}
        created={() => {
          const dateSelector = document.querySelectorAll(
            ".e-schedule-toolbar .e-date-range"
          )[0];

          dateSelector.click();

          var calendar =
            document.querySelectorAll(".e-header-calendar")[0].ej2_instances[0];

          calendar.change = function (args) {
            handleSelectDate(args);
          };

          dateSelector.click();
          dateSelector.children[0]?.blur();
        }}
      >
        <ResourcesDirective>
          {resourceData.length > 0 ? (
            <ResourceDirective
              field={props.field}
              title={props.title}
              name={props.name}
              allowMultiple={true}
              dataSource={resourceData}
              textField="text"
              idField="driverId"
            />
          ) : (
            <p>No resources available</p>
          )}
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective option="TimelineDay" />
          <ViewDirective option="TimelineWeek" />
          <ViewDirective option="TimelineWorkWeek" />
          <ViewDirective option="TimelineMonth" />
          <ViewDirective option="Agenda" />
        </ViewsDirective>
        <Inject
          services={[
            Day,
            Week,
            WorkWeek,
            Month,
            TimelineViews,
            TimelineMonth,
            Agenda,
          ]}
        />
      </ScheduleComponent>
      {selectedEvent && showPopover && (
        <EventDetailsPopover
          event={selectedEvent}
          onClose={closePopover}
          position={popoverPosition}
          fields={eventFields}
          renderContent={props.renderPopoverContent || (() => null)}
        />
      )}
    </>
  );
};
