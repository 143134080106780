import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const initialState = {
  user: {},
  status: "idle",
  error: null,
};

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ parentId, userId }) => {
    const res = await request(`/users/${userId}?parent=${parentId}`);
    return res.data;
  }
);

export const addNewUser = createAsyncThunk(
  "user/addNewUser",
  async ({ data, ...obj }) => {
    const queries = renderQueries(obj);
    const res = await request(`/users?${queries}`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ parentId, user }) => {
    const res = await request(`/users/${user._id}?parent=${parentId}`, {
      method: "put",
      data: { ...user },
    });
    return res.data;
  }
);

export const sendEmail = createAsyncThunk(
  "user/sendEmail",
  async ({ userId, ...obj }) => {
    const queries = renderQueries(obj);
    const res = await request(`/users/email/${userId}?${queries}`, {
      method: "post",
    });
    return res.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = {};
        state.status = "succeeded";
        state.user = action.payload.user;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = {};
        state.status = "succeeded";
        state.user = action.payload.data;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetUser } = userSlice.actions;
export default userSlice.reducer;

export const selectUser = (state) => state.user.user;
