import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import Cookies from "js-cookie";
import { ROOT_API_URL } from "../config/defaultSettings";
import { getAppType, getParentCompany, getToken } from "../utils/authority";

const REDUCER_NAME = "api";
const CACHE_STORE_TIME = 30;

export const api = createApi({
  reducerPath: REDUCER_NAME,
  baseQuery: fetchBaseQuery({
    credentials: "include",
    baseUrl: ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("Customer-Context", getParentCompany());
      headers.set("App-Type", getAppType());
      return headers;
    },
  }),
  keepUnusedDataFor: CACHE_STORE_TIME,
  endpoints: () => ({}),
});

export const { usePrefetch } = api;
