import React from "react";
import Input from "../../../../shared/Input";
import Select from "../../../../shared/Select";
import { stateOptions } from "../../../../constants/select";
import Coowner from "./Coowner";
import { renderLabelRequired } from "../../../../shared/Table/table.utils";

const CompanyInfoForm = ({ onFormChange, form, clearForm, errors }) => {
  return (
    <div className="grid grid-cols-2 gap-2 lg:grid-cols-6 lg:gap-3">
      <div className="col-span-2">
        <Input
          id="name"
          value={form.name}
          error={errors.name}
          label={renderLabelRequired("Name")}
          placeholder="Name"
          onChange={onFormChange}
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-2 xl:col-span-1">
        <Input
          id="email"
          value={form.email}
          error={errors.email}
          label={"Email"}
          placeholder="Email"
          onChange={onFormChange}
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-2 xl:col-span-1">
        <Input
          id="phone"
          value={form.phone}
          error={errors.phone}
          label="Phone"
          placeholder="Phone"
          onChange={onFormChange}
        />
      </div>
      <div className="col-span-2 xl:row-start-2">
        <Input
          id="address"
          value={form.address}
          error={errors.address}
          label={renderLabelRequired("Address")}
          placeholder="Address"
          onChange={onFormChange}
        />
      </div>
      <div className="col-span-1 xl:row-start-2">
        <Input
          id="suite"
          value={form.suite}
          error={errors.suite}
          label="Suite"
          placeholder="Suite"
          onChange={onFormChange}
        />
      </div>
      <div className="col-span-1 xl:row-start-3">
        <Input
          id="city"
          value={form.city}
          error={errors.city}
          label={renderLabelRequired("City")}
          placeholder="City"
          onChange={onFormChange}
        />
      </div>
      <div className="col-span-1 xl:row-start-3">
        <Select
          id="state"
          value={form.state}
          error={errors.state}
          label={renderLabelRequired("State/Province")}
          placeholder="State/Province"
          onChange={onFormChange}
          search
          options={stateOptions}
          autoComplete="state"
        />
      </div>
      <div className="col-span-1 xl:row-start-4">
        <Input
          id="country"
          value={form.country}
          error={errors.country}
          label={renderLabelRequired("Country")}
          placeholder="Country"
          onChange={onFormChange}
        />
      </div>
      <div className="col-span-1 xl:row-start-4">
        <Input
          id="zipcode"
          value={form.zipcode}
          error={errors.zipcode}
          label={renderLabelRequired("Zipcode")}
          placeholder="Zipcode"
          onChange={onFormChange}
        />
      </div>
      <div className="col-span-2 xl:row-start-5">
        <Coowner
          form={form}
          onFormChange={onFormChange}
          clearForm={clearForm}
        />
      </div>
    </div>
  );
};

export default CompanyInfoForm;
