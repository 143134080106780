import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFormattedTime } from "../../../utils";
import request, { renderQueries } from "../../../utils/request";
import _ from "lodash";

const initialState = {
  locations: [],
  status: "idle",
  error: null,
};

export const fetchLocations = createAsyncThunk(
  "locations/fetchLocations",
  async ({ parentId, clientId }) => {
    const res = await request(
      `/locations?parent=${parentId}&clientId=${clientId}`
    );
    return res.data;
  }
);

export const deleteLocations = createAsyncThunk(
  "locations/deleteLocations",
  async ({ data, ...rest }) => {
    const queries = renderQueries(rest);
    const res = await request(`/locations?${queries}`, {
      method: "delete",
      data,
    });
    return res.data;
  }
);

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    locationAdded(state, action) {
      const { location } = action.payload;
      state.locations = state.locations.concat(location);
    },
    locationDeleted(state, action) {
      const { location } = action.payload;
      const index = state.locations.findIndex(
        (item) => item._id === location._id
      );
      state.locations.splice(index, 1);
      const newLocations = state.locations;
      state.locations = newLocations;
    },
    locationUpdated(state, action) {
      const { location } = action.payload;
      const index = state.locations.findIndex(
        (item) => item._id === location._id
      );
      state.locations[index] = location;
    },
    resetLocations() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLocations.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.locations = [];
        state.status = "succeeded";
        state.locations = state.locations.concat(action.payload.locations);
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteLocations.fulfilled, (state, action) => {
        const deleted = action.payload.data;
        const newLocations = _.differenceBy(state.locations, deleted, "_id");
        state.locations = newLocations;
      });
  },
});

export const {
  locationAdded,
  locationDeleted,
  locationUpdated,
  resetLocations,
} = locationsSlice.actions;

export default locationsSlice.reducer;

export const selectAllLocations = (state) =>
  _.sortBy(state.locations.locations, (o) => o.address.name?.toLowerCase());

export const selectLocationOptions = (state) => {
  return state.locations.locations.map((obj) => {
    return {
      value: obj,
      label: obj.address?.fullAddress,
    };
  });
};
export const selectJobLocationOptions = (state) => {
  return _.sortBy(state.locations.locations, (o) =>
    o.address.name?.toLowerCase()
  ).map((obj) => {
    return {
      value: obj,
      label: (
        <div className="flex flex-col">
          <div className="flex items-center justify-between">
            <div className="text-lg font-bold">{obj.name}</div>
            {obj.receivingHours && (
              <div className="text-sm font-normal flex">
                {getFormattedTime(obj.receivingHours.open)} -{" "}
                {getFormattedTime(obj.receivingHours.close)}
              </div>
            )}
          </div>
          <div className="text-sm font-normal">{obj.address?.fullAddress}</div>
        </div>
      ),
    };
  });
};
