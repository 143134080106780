import { useState, useCallback } from "react";
import { useForm, useConfig } from "../../../../hooks/";
import TruckingJobInfo from "./Trucking";
import ServiceRepairJobInfo from "./ServiceRepair";

const JobInfo = ({ job = {}, ...props }) => {
  const form = useForm();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const { configs } = useConfig();

  const showCreateModal = useCallback((vis) => {
    setCreateModalVisible(vis === false ? false : true);
  }, []);

  switch (configs.parentCompany?.appType) {
    case "trucking":
      return (
        <TruckingJobInfo
          job={job}
          legForm={form}
          {...props}
          form={props.form}
          showCreateModal={showCreateModal}
          createModalVisible={createModalVisible}
        />
      );
    case "service":
    default:
      return (
        <ServiceRepairJobInfo
          job={job}
          {...props}
          serviceForm={form}
          form={props.form}
          showCreateModal={showCreateModal}
          createModalVisible={createModalVisible}
        />
      );
      return "Something went wrong, please contact support.";
  }
};

export default JobInfo;
