import React from "react";
import InvoiceTable from "./Table";
import { formatDate } from "../../utils";
import PdfGenerator from "../../shared/PdfGenerator";
import TotalsTable from "./TotalsTable";

const InvoiceLayout = ({
  totalColumns,
  invoice,
  children,
  trigger,
  ...props
}) => {
  const parentCompany = invoice.parentCompany;
  const parentAddress = parentCompany?.address;
  const customer = invoice.client;
  const invoiceId = invoice.invoiceId;

  function didParseCell({ cell, section, row, ...rest }) {
    cell.styles.fontSize = 9;
    if (section === "head") {
      cell.styles.fontSize = 9;
    }
    if (section === "body") {
      if (cell.text[0].startsWith("Date")) {
        cell.styles.fillColor = [255, 255, 255];
        cell.styles.fontSize = 8;
      }
      if (
        cell.text[0].startsWith("Dest") ||
        cell.text[0].startsWith("Origin")
      ) {
        cell.styles.fillColor = [255, 255, 255];
        cell.styles.fontSize = 8;
        cell.styles.cellPadding = { top: 2, right: 0, bottom: 2, left: 0 };
      }
      if (cell.text[0].startsWith("*")) {
        cell.styles.fontSize = 7;
        cell.styles.lineWidth = { top: 0, right: 0, bottom: 0.1, left: 0 };
        cell.styles.cellPadding = { top: 0, right: 0, bottom: 4, left: 0 };
        cell.styles.halign = "center";
        cell.styles.fillColor = [255, 255, 255];
      }
    }
  }

  function didDrawPage({ pageCount, pageNumber, settings, doc, ...rest }) {
    var totalPagesExp = "{total_pages_count_string}";
    // FOOTER
    var str = `${parentCompany} ${invoiceId}` + pageCount;
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp;
    }
    doc.setFontSize(10);
    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    doc.text(str, settings.margin.left, pageHeight - 5);

    // return doc;
  }

  function createHeader(pdf) {
    // INVOICE NUMBER
    pdf.setFontSize(9);
    pdf.getFontList();
    pdf.setFont("Times New Roman", "bold");

    pdf.setTextColor(0, 0, 0);
    pdf.text(`Invoice ${invoice.invoiceId}`, 105, 5, "center");

    // COMPANY NAME
    pdf.setFontSize(25);
    pdf.setFont("Times New Roman", "bold");
    pdf.text(parentCompany?.name.toUpperCase(), 5, 20, { maxWidth: 85 });

    // COMPANY ADDRESS
    const address = `${parentAddress?.address || ""} ${
      parentAddress?.suite || ""
    }`;
    const suite = `${parentAddress?.suite || ""}`;
    const city = `${parentAddress?.city || ""}`;
    const state = `${parentAddress?.state || ""}`;
    const zipcode = `${parentAddress?.zipcode || ""}`;
    const phone = `${parentCompany?.phone}`;

    const addressWithSuite = `${address} `;

    const parentCompanyAddress = `${parentAddress?.city}, ${parentAddress?.state} ${parentAddress?.zipcode}`;
    const customerAddress = `${customer?.address.city}, ${customer?.address.state} ${customer?.address.zipcode}`;
    const coowner = customer.coowner ? `C/o: ${customer?.coowner}` : "";
    const addressY = customer.coowner ? 52 : 48;
    const customerAddressY = customer.coowner ? 56 : 52;

    pdf.setFontSize(9);
    pdf.setFont("times", "normal");
    pdf.text(address.toUpperCase(), 5, 34);
    pdf.text(parentCompanyAddress.toUpperCase(), 5, 38);
    // pdf.text(state, 15, 38);
    pdf.text(phone, 69, 34, "center");

    // BILLING INFO
    pdf.setFontSize(9);
    pdf.setTextColor(0, 0, 0);
    pdf.text("BILL TO: ", 5, 44);
    pdf.setTextColor("black");
    pdf.text(`${customer?.name.toUpperCase()}`, 25, 44);

    customer.coowner && pdf.text(coowner.toUpperCase(), 25, 48);
    pdf.text(`${customer?.address.address.toUpperCase()}`, 25, addressY);
    pdf.text(customerAddress.toUpperCase(), 25, customerAddressY);
  }

  let addToY = 0;
  function willDrawCell({ cell, row, table, ...props }) {
    if (cell.text.length > 0) {
      cell.styles.fontSize = 8;
      if (props.section === "body") {
        const cellText = cell.text[0];
        if (cellText.startsWith("Origin")) {
          cell.styles.halign = "left";
          cell.x = cell.x + 48;
        }
        if (cellText.startsWith("Dest")) {
          cell.styles.halign = "left";
          cell.x = cell.x + 15;
        }
      }
    }
  }

  const layout = (
    <>
      <TotalsTable {...props} columns={totalColumns} tableData={invoice} />
      <InvoiceTable {...props} tableData={invoice.jobs} />
    </>
  );

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });

  const pageData = "|  " + parentCompany?.name + "  |  Invoice  " + invoiceId;
  const pdfFileName =
    invoice.invoiceId + "_" + formatDate(new Date(), null, true);

  return (
    <PdfGenerator
      startY={62}
      didDrawPage={didDrawPage}
      didParseCell={didParseCell}
      createHeader={createHeader}
      willDrawCell={willDrawCell}
      fileName={pdfFileName}
      id="invoice-table"
      rowPageBreak="avoid"
      pageData={pageData}
      // createFooter={createFooter}
      layout={layout}
      columnStyles={{ text: { cellWidth: "auto" } }}
    >
      <Layout download={props.download}>{childrenWithProps}</Layout>
    </PdfGenerator>
  );
};

const Layout = (props) => {
  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
    s;
  });
  return childrenWithProps;
};

export default InvoiceLayout;
