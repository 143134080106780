import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../../shared/Spinner";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import { updateLeg } from "../../../redux/reducers/legs/legSlice";
import { formatDate } from "../../../utils";
import { FORM_OPTIONS } from "../../Jobs/table.config";
import Info from "./Info";
import { toast } from "react-toastify";
import DataTable from "./DataTable";
import { COLUMN_DEFINITIONS } from "./pdf-table.config";
import Totals from "./Totals";
import { SidebarContainer } from "../../../components";
import Tabs from "../../../shared/Tabs";
import { Badge, EditableText } from "../../../shared";
import ManifestLayout from "../../../layouts/ManifestLayout";
import { useCrumbs } from "../../../hooks/useCrumbs";
import {
  useDeleteManifestsMutation,
  useGetManifestQuery,
  useUpdateManifestMutation,
} from "../../../redux/services/manifests/manifestsApi";
import { isAuthorized } from "../../../utils/authority";

const ManifestsShow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { user } = useAuth();
  const crumbs = useCrumbs();
  const { form, clearForm, updateForm, setFormInit } = useForm(FORM_OPTIONS);

  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  // Get manifest query
  const { data: manifestData, isLoading } = useGetManifestQuery(
    { id: params.id },
    { skip: !params.id }
  );
  const manifest = manifestData || {};
  // Delete manifest query
  const [deleteManifests, { isLoading: isDeleting }] =
    useDeleteManifestsMutation();

  // Update manifest query
  const [updateManifest, { isLoading: updatingManifest }] =
    useUpdateManifestMutation();

  useEffect(() => {
    crumbs.setLoading(true);
  }, []);

  useEffect(() => {
    if (manifest._id) {
      crumbs.updateCrumbName({
        name: manifest.manifestId,
        path: "/manifests/" + manifest._id,
      });
      setFormInit(manifest);
      crumbs.setLoading(false);
    }
  }, [manifest._id]);

  async function handleUpdate(e) {
    const update = {
      [e.id]: e.value,
      ...e.data,
    };

    dispatch(
      updateLeg({
        data: update,
        jobId: e.row.original._id,
        parentId: user.parentCompany,
        payoutId: payout._id,
      })
    );
  }

  async function handleManifestUpdate(e) {
    const update = { [e.id]: e.value, ...e.data };
    const res = await updateManifest({
      id: manifest._id,
      data: update,
    }).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "updated-manifest",
    });

    // dispatch(
    //   updateManifest({
    //     data: update,
    //     parentId: user.parentCompany,
    //     manifestId: manifest._id,
    //     // payoutId: update._id,
    //   })
    // );
  }

  async function handleDelete(ids) {
    const res = await deleteManifests({ data: ids });

    toast(res.data.message, {
      type: res.data.status,
      toastId: "delete-manifest-" + res.data._id,
    });

    if (res.data.status === "success") {
      navigate("/manifests");
    }
  }

  const tabs = [
    {
      id: "Info",
      content: (
        <div className="flex flex-col">
          <div className="grid grid-cols-1 gap-4">
            {manifest._id && (
              <ManifestLayout
                columns={COLUMN_DEFINITIONS}
                // totalColumns={TOTAL_COLUMN_DEFINITIONS}
                manifest={manifest}
              >
                <Info
                  clearForm={clearForm}
                  onFormChange={onFormChange}
                  delete={handleDelete}
                  form={form}
                  handleUpdate={handleUpdate}
                  info={manifest}
                  handleManifestUpdate={handleManifestUpdate}
                />
                <Totals
                  form={form}
                  handleManifestUpdate={handleManifestUpdate}
                  info={manifest}
                />
              </ManifestLayout>
            )}
            {manifest._id && (
              <>
                <DataTable info={manifest} />
              </>
            )}
          </div>
        </div>
      ),
    },
  ];

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const title = (
    <div className="text-gray-800 font-semibold text-lg flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="orderNumber"
        value={manifest.manifestId}
        size="md"
        disabled={!isAuthorized("owner,admin")}
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        // loading={loading}
        onSave={(e) =>
          handleManifestUpdate({ id: "manifestId", value: e.value })
        }
      />
      <Badge
        // color={stage?.color}
        label={manifest.status}
        size="lg"
        className="ml-2"
      />
      {isAuthorized("owner,admin") && manifest.payoutId && (
        <Badge
          link={`/payouts/${manifest.payoutId._id}`}
          label={`Payout: ${manifest.payoutId.payoutId}`}
          size="lg"
          className="ml-2"
        />
      )}
    </div>
  );

  const renderTabHeader = (headerProps) => {
    return (
      <div ref={headerRef}>
        <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
          <div>{headerProps.title}</div>
          <div className="flex items-center space-x-3">
            <div className="md:flex items-center space-x-3 text-gray-800 hidden">
              {headerProps.actions}
            </div>
          </div>
        </header>
        <div className="flex justify-between md:hidden space-x-5 px-3 py-3 bg-white border-b">
          {headerProps.actions}
        </div>
      </div>
    );
  };

  const actions = (
    <>
      <span className="mr-5">
        Created: {formatDate(manifest.dateCreated, null, true)}
      </span>
      <span>Updated: {formatDate(manifest.dateUpdated, null, true)}</span>
    </>
  );

  return (
    <SidebarContainer
      title={title}
      hideSidebar
      header={renderTabHeader}
      actions={actions}
    >
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 38}px`,
        };

        return (
          <div style={wrapperStyle} className="h-auto w-full">
            {isLoading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <Tabs
                className={`w-full overflow-hidden`}
                style={wrapperStyle}
                panelStyle={panelStyle}
                useSearchParams
                panelClasses={`p-2 border-t overflow-y-scroll`}
                tabListClasses="bg-white px-2 h-[38px]"
                tabclassName="mx-1"
                id="manifest-show"
                data={tabs}
              />
            )}
          </div>
        );
      }}
    </SidebarContainer>
  );
};

export default ManifestsShow;
