import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LegCreateForm } from "./Form";
import { useAuth } from "../../../../hooks/useAuth";
import {
  driverSelectOptions,
  fetchDrivers,
} from "../../../../redux/reducers/drivers/driversSlice";
import {
  fetchLocations,
  selectJobLocationOptions,
} from "../../../../redux/reducers/locations/locationsSlice";
import { formatDate } from "../../../../utils";
import { useGetDriversQuery } from "../../../../redux/services/drivers/driversApi";
import { useGetLocationsQuery } from "../../../../redux/services/locations/locationsApi";

export const LegCreate = ({
  errors,
  client,
  form,
  updateForm,
  setFormInit,
  ...props
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  // Set inital form data
  // appointmentDate, client, flatLeg
  useEffect(() => {
    if (client) {
      const init = { client };
      if (!form.appointmentDate) {
        const apptDate = formatDate(
          form.job?.transactionDate || null,
          null,
          true
        );
        init.appointmentDate = apptDate;
      }

      setFormInit(init);
    }
  }, [client]);

  /*
    Fetch Locations
  */
  const {
    data: locations,
    isLoading: locationsLoading,
    isFetching: locationsFetching,
  } = useGetLocationsQuery({
    page: 1,
    limit: 100,
    clientId: client._id,
  });
  const locationOptions = selectJobLocationOptions({
    locations: locations || [],
  });

  /*
    Fetch Drivers
  */
  const {
    data: drivers,
    isLoading,
    isFetching,
  } = useGetDriversQuery({
    page: 1,
    limit: 100,
  });

  /*
    Render Driver Options
  */
  const driverOptions =
    isLoading || isFetching
      ? []
      : drivers.drivers.map((driver) => ({
          label: driver.fullName,
          value: driver,
        }));

  /*
    Set form helper
  */
  function setForm(obj, custom) {
    setFormInit(custom ? obj : {}, { setErrors: true, reset: true });
    return;
  }

  return (
    <LegCreateForm
      handleChange={updateForm}
      errors={errors}
      form={form}
      driverOptions={driverOptions}
      locationOptions={locationOptions}
      setForm={setForm}
      {...props}
    />
  );
};

export default LegCreate;
