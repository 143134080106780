import classNames from "classnames";

export const SettingsPanel = (props) => {
  function renderContent() {
    if (props.data.length === 0) {
      return props.empty?.content;
    }
    return props.data.map((item, i) => {
      if (!item) {
        return null;
      }
      if (item.layout) {
        return item.layout(item, i);
      }
      if (props.itemLayout) {
        return props.itemLayout(item, i);
      }
      if (!item.title) {
        return (
          <div className="bg-white p-4 rounded-b-xl" key={i}>
            {item.content}
          </div>
        );
      } else {
        const wrapperClasses = classNames("grid grid-cols-2 p-4 bg-white", {
          "rounded-b-xl": props.data.length - 1 === i,
        });
        return (
          <div className={wrapperClasses} key={i}>
            <div className="flex flex-col">
              <div className="font-semibold text-slate-800">{item.title}</div>
              {item.subtitle && (
                <div className="text-slate-400 text-xs">{item.subtitle}</div>
              )}
            </div>
            <div>{item.content}</div>
          </div>
        );
      }
    });
  }
  return (
    <div className="border rounded-lg divide-y">
      {props.header ? (
        props.header({
          title: props.title,
          subtitle: props.subtitle,
          actions: props.actions,
        })
      ) : (
        <div className="p-4 flex justify-between items-center bg-gray-50 rounded-t-lg">
          {props.headerContent ? (
            props.headerContent({
              title: props.title,
              subtitle: props.subtitle,
              actions: props.actions,
            })
          ) : (
            <div>
              <div className="text-sm text-slate-800 font-semibold">
                {props.title}
              </div>
              <div className="text-slate-400 text-xs">{props.subtitle}</div>
            </div>
          )}
          {props.actions && <div>{props.actions}</div>}
        </div>
      )}
      {renderContent()}
    </div>
  );
};

export default SettingsPanel;
