import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../hooks/useAuth";
import TableLayout from "../../../layouts/TableLayout";
import { updateInvoice } from "../../../redux/reducers/invoices/invoiceSlice";
import { invoiceUpdated } from "../../../redux/reducers/invoices/invoicesSlice";
import {
  COLUMN_DEFINITIONS,
  PAGE_SIZE_OPTIONS,
} from "../../Invoices/table.config";
import { useNavigate } from "react-router-dom";
import CreateModal from "../../Invoices/CreateModal";

const ClientInvoicesTable = ({ invoices, client }) => {
  const invoicesStatus = useSelector((state) => state.invoices.status);
  const dispatch = useDispatch();
  const { user } = useAuth();

  const [createModalOpen, setCreateModalOpen] = useState(false);

  async function handleUpdate(e, setLoading) {
    const updateData = {
      parentId: user.parentCompany,
      invoiceId: e.row.original._id,
      data: e.data,
    };

    const res = await dispatch(updateInvoice(updateData)).unwrap();

    if (res.status === "success") {
      dispatch(invoiceUpdated(res.data));

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  // const createPath = "invoices/create";

  return (
    <div>
      <TableLayout
        pagination
        search
        // hideSettings
        title="Invoices"
        loading={invoicesStatus === "loading"}
        columns={COLUMN_DEFINITIONS}
        data={invoices}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        updateData={handleUpdate}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
      />
      <CreateModal
        client={client}
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
      />
    </div>
  );
};

export default ClientInvoicesTable;
