import Split from "react-split";
import { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { Scheduler } from "../../shared";
import { SidebarContainer } from "../../components";
import { useGetDispatchListQuery } from "../../redux/services/dispatch/dispatchApi";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import DispatchLayout from "../../layouts/DispatchLayout";
import { useGetDriversQuery } from "../../redux/services/drivers/driversApi";
import EventModal from "./EventModal";
import "./index.css";

export const Dispatch = () => {
  const [splitSizes, setSplitSizes] = useState([50, 50]);
  const [currentView, setCurrentView] = useState("Week");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  function getStart() {
    if (currentView.includes("Week")) {
      return startOfWeek(selectedDate);
    }
    if (currentView.includes("Month") || currentView === "Agenda") {
      return startOfMonth(selectedDate);
    }
    return startOfDay(selectedDate);
  }

  function getEnd() {
    if (currentView.includes("Week")) {
      return endOfWeek(selectedDate);
    }
    if (currentView.includes("Month") || currentView === "Agenda") {
      return endOfMonth(selectedDate);
    }
    return endOfDay(selectedDate);
  }

  const { data: listData, refetch: refetchList } = useGetDispatchListQuery({
    startDate: getStart(),
    endDate: getEnd(),
  });

  const list = listData || [];
  const memoizedList = useMemo(() => {
    return (
      list?.map((i) => ({
        Id: i._id,
        Subject: i.driver?.fullName || "Unassigned",
        Origin: i.route?.origin?.name,
        Mileage: i.route?.mileage,
        Duration: i.route?.duration,
        OriginAddress: i.route?.origin?.address.fullAddress,
        Destination: i.route?.destination?.name,
        DestinationAddress: i.route?.destination?.address.fullAddress,
        StartTime: new Date(i.appointmentDate),
        EndTime: new Date(i.endDate),
        IsAllDay: false,
        driverId: i.driver?._id,
      })) || []
    );
  }, [list]);

  console.log("list", list);
  console.log("list", memoizedList);

  const {
    data: driversData,
    isLoading,
    isFetching,
  } = useGetDriversQuery({
    page: 1,
    limit: 100,
  });

  const drivers = driversData || [];

  const driverList = useMemo(() => {
    return drivers?.drivers?.map((driver) => ({
      text: driver.fullName,
      id: driver._id,
      driverId: driver._id,
    }));
  }, [drivers?.length, isLoading, isFetching]);

  const handleDataUpdate = useCallback(
    async (updatedLeg) => {
      // Refetch the legs data to update the scheduler
      await refetchList(updatedLeg);

      // If you need to do any additional processing with the updated leg data,
      // you can do it here
      console.log("Leg updated:", updatedLeg);

      // If you have any local state that needs updating, do it here
      // setLocalState(prevState => ...);
    },
    [refetchList]
  );

  const handleEventClick = useCallback((args) => {
    const { event } = args;
    setSelectedEvent(event);
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    console.log("closed from index");
  };

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  const [contentHeight, setContentHeight] = useState("100%");

  useEffect(() => {
    const updateContentHeight = () => {
      if (headerRef.current) {
        const windowHeight = window.innerHeight;
        const headerHeight = headerRef.current.offsetHeight;
        const newContentHeight = `${windowHeight - headerHeight}px`;
        setContentHeight(newContentHeight);
      }
    };

    updateContentHeight();
    window.addEventListener("resize", updateContentHeight);

    return () => window.removeEventListener("resize", updateContentHeight);
  }, []);

  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = () => {
    return <div ref={headerRef}></div>;
  };

  function handleSelectedDate(e) {
    setSelectedDate(e.value);
  }

  function handleViewChange(e) {
    console.log(e);
    e.action === "view" && setCurrentView(e.currentView);
  }

  const eventFields = [
    { key: "Subject", label: "Subject" },
    { key: "Origin", label: "Origin" },
    { key: "Destination", label: "Destination" },
    {
      key: "StartTime",
      label: "Start Time",
      format: (value) => new Date(value).toLocaleString(),
    },
    {
      key: "EndTime",
      label: "End Time",
      format: (value) => new Date(value).toLocaleString(),
    },
    { key: "Mileage", label: "Mileage", format: (value) => `${value} mi` },
    { key: "Duration", label: "Duration", format: (value) => `${value} min` },
  ];

  const ROW_HEIGHT = 80;
  const VISIBLE_ROWS = 5;
  const HEADER_HEIGHT = 100;

  const renderContent = ({ parentHeight }) => {
    const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
    const contentHeight = `${parentHeight - headerHeight}px`;

    return (
      <div
        className="overflow-y-auto w-full relative"
        style={{ height: contentHeight }}
      >
        <Split
          sizes={splitSizes}
          minSize={100}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="vertical"
          cursor="row-resize"
          onDragEnd={(sizes) => setSplitSizes(sizes)}
          style={{ height: "100%" }}
        >
          <div style={{ height: "100%", overflow: "auto" }}>
            <Scheduler
              currentView={currentView}
              navigating={handleViewChange}
              eventFields={eventFields}
              selectedDate={selectedDate}
              setSelectedDate={handleSelectedDate}
              onEventClick={handleEventClick}
              height="100%"
              data={memoizedList || []}
              group={driverList}
              name="driverId"
              field="driverId"
              title="Drivers"
              renderPopoverContent={(selectedEvent) => (
                <EventModal event={selectedEvent} onClose={handleCloseModal} />
              )}
            />
          </div>
          <div style={{ height: "100%", overflow: "auto" }}>
            <DispatchLayout
              data={listData}
              drivers={drivers}
              selectedDate={selectedDate}
              onDataUpdate={handleDataUpdate}
            />
          </div>
        </Split>
        {isModalOpen && selectedEvent && (
          <EventModal
            event={selectedEvent}
            visible={isModalOpen}
            setVisible={setIsModalOpen}
            onClose={handleCloseModal}
          />
        )}
      </div>
    );
  };

  return (
    <SidebarContainer
      loading={isLoading}
      header={renderHeader}
      hideSidebar
      className="w-full"
    >
      {renderContent}
    </SidebarContainer>
  );
};
{
  /* {isModalOpen && selectedEvent && (
                <DispatchPopover
                  event={selectedEvent}
                  onClose={handleCloseModal}
                />
              )} */
}
{
  /* 
              {selectedEvent && (
                <div
                  style={{
                    position: "absolute",
                    left: `${popoverPosition.x}px`,
                    top: `${popoverPosition.y}px`,
                    zIndex: 1000,
                  }}
                >
                  <EventDetailsPopover
                    event={selectedEvent}
                    onClose={handleClosePopover}
                    position={popoverPosition}
                    renderContent={DispatchPopover}
                  />
                </div>
              )} */
}
