// export const jobStages = {
//   1: {
//     order: 1,
//     id: "new",
//     title: "New",
//     next: "pending",
//     button: "Publish",
//     color: "teal",
//   },
//   2: {
//     order: 2,
//     id: "pending",
//     title: "Pending",
//     next: "booked",
//     button: "Book",
//     color: "orange",
//   },
//   3: {
//     order: 3,
//     id: "booked",
//     title: "Booked",
//     next: "enRoute",
//     button: "Start Job",
//     color: "yellow",
//   },
//   4: {
//     order: 4,
//     id: "enRoute",
//     title: "En Route",
//     next: "delivered",
//     button: "Mark Delivered",
//     color: "blue",
//   },
//   5: {
//     order: 5,
//     id: "delivered",
//     title: "Delivered",
//     next: "invoiced",
//     color: "cyan",
//   },
//   6: {
//     order: 6,
//     id: "invoiced",
//     title: "Invoiced",
//     next: "paid",
//     color: "purple",
//   },
//   7: { order: 7, id: "paid", title: "Paid" },
// };
export const jobStages = {
  trucking: {
    1: {
      order: 1,
      id: "new",
      title: "New",
      next: "pending",
      button: "Publish",
    },
    2: {
      order: 2,
      id: "pending",
      title: "Pending",
      next: "enRoute",
      button: "Start Job",
      color: "orange",
    },
    3: {
      order: 3,
      id: "enRoute",
      title: "En Route",
      next: "delivered",
      button: "Mark Delivered",
      color: "blue",
    },
    4: {
      order: 4,
      id: "delivered",
      title: "Delivered",
      next: "invoiced",
      // button: "Create Invoice",
      // modal: "Are you sure you want to create an invoice from this job?",
      color: "cyan",
    },
    5: {
      order: 5,
      id: "invoiced",
      title: "Invoiced",
      color: "purple",
    },
  },
  serviceRepair: {
    1: {
      order: 1,
      id: "new",
      title: "New",
      next: "pending",
      button: "Publish",
    },
    2: {
      order: 2,
      id: "pending",
      title: "Pending",
      next: "booked",
      button: "Book",
      color: "orange",
    },
    3: {
      order: 3,
      id: "booked",
      title: "Booked",
      next: "inProgress",
      button: "Start Job",
      color: "blue",
    },
    4: {
      order: 4,
      id: "inProgress",
      title: "In Progress",
      next: "completed",
      button: "Mark Completed",
      color: "cyan",
    },
    5: {
      order: 5,
      id: "completed",
      title: "Completed",
      next: "invoiced",
      color: "cyan",
    },
    6: {
      order: 6,
      id: "invoiced",
      title: "Invoiced",
    },
  },
};

export const getStage = (key, type = "trucking") => {
  let res = {};
  if (typeof key === "string") {
    const current = Object.keys(jobStages[type]).find(
      (i) => jobStages[type][i].id === key || jobStages[type][i].title === key
    );
    res = jobStages[type][current] || {};
  } else {
    res = jobStages[type][key] || {};
  }
  return res;
};

export const getNextStage = (key, type = "trucking") => {
  if (typeof key === "string") {
    const current = Object.keys(jobStages[type]).find(
      (i) => jobStages[type][i].id === key
    );
    if (!jobStages[type][current].next) {
      return null;
    }
    return getStage(Number(jobStages[type][current].order) + 1, type);
  }
  return getStage(Number(key) + 1, type);
};
