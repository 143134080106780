import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useQueryNavigation = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParam = useCallback(
    (e) => {
      setSearchParams(e);
    },
    [setSearchParams]
  );

  const getParam = useCallback(
    (id) => {
      return searchParams.get(id);
    },
    [searchParams]
  );

  return { setParam, getParam, params: searchParams };
};
