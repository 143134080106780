import { useCallback, useEffect, useState } from "react";
import { useGetClientsQuery } from "../redux/services/clients/clientsApi";
import { Checkbox } from "../shared";
import { uniqBy } from "lodash";

export const CheckboxList = ({ data = [], ...props }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (props.value && selected.length === 0) {
      const defaultValues = data
        .filter((item) => props.value.includes(item[props.objectKey]))
        .map((i) => ({ ...i, checked: true }));

      setSelected(defaultValues);
    }
  }, []);

  const handleCheckboxChange = (newItem) => {
    let newArray = [...selected, newItem];

    const foundInArray = selected.findIndex(
      (item) => item.name === newItem.name
    );

    if (foundInArray > -1) {
      newArray = newArray.filter((item) => item.name !== newItem.name);
    }

    props.onChange(newArray);
    setSelected(newArray);
  };

  const handleCheckbox = useCallback(handleCheckboxChange, [selected.length]);

  return (
    <div className="flex">
      <div className="px-4 py-2">
        {data.map((item) => {
          const isChecked = selected.findIndex((i) => i.name === item.name);
          return (
            <Checkbox
              label={item.name}
              id={item.id || item._id}
              checked={isChecked > -1}
              className="!w-3"
              onChange={(e) => handleCheckbox({ checked: e.checked, ...item })}
            />
          );
        })}
      </div>
    </div>
  );
};
