import { ServiceInfo } from "./ServiceInfo";
import { jobStages } from "../../../job-stages";
import { useConfig } from "../../../../../hooks";
import Timeline from "../../Timeline";

const ServiceRepairJobInfo = ({
  job,
  form,
  showCreateModal,
  createModalVisible,
  ...props
}) => {
  const { configs } = useConfig();

  const stages = jobStages[configs?.parentCompany?.appType] || {};
  const timelineItems = Object.values(stages || {}).map((stage) => ({
    label: stage.title,
    active: job.status === stage.title,
    icon: stage.icon || (
      <path
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
  }));

  return (
    <div className="grid grid-cols-6 gap-4">
      <div className="col-span-6 ">
        <div className="relative py-4">
          <Timeline jobStatus={job?.status} timeline={timelineItems} />
        </div>
        <ServiceInfo
          job={job}
          {...form}
          {...props}
          setModalVisible={showCreateModal}
          modalVisible={createModalVisible}
        />
      </div>
    </div>
  );
};

export default ServiceRepairJobInfo;
