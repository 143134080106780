import React from "react";
import { useLocation } from "react-router-dom";

export default function useVersionCheck() {
  let location = useLocation();
  React.useLayoutEffect(() => {
    if (
      !location.state &&
      window.localStorage.getItem("version-update-needed")
    ) {
      window.localStorage.removeItem("version-update-needed");
      window.location.reload(true);
    }
  }, [location]);
}
