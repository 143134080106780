import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import request, { renderQueries } from "../../../utils/request";

const initialState = {
  leg: {},
  status: "idle",
  error: null,
};

export const updateLeg = createAsyncThunk(
  "leg/updateLeg",
  async ({ data, ...obj }, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/legs/${obj.legId}?${queries}`, {
      method: "put",
      data: data,
    });
    if (!res.data) {
      rejectWithValue(res.data);
      return;
    }
    return res.data;
  }
);

export const addNewLeg = createAsyncThunk(
  "legs/addNewLeg",
  async ({ data, ...rest }) => {
    const queries = renderQueries(rest);
    const res = await request(`/legs?${queries}`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const updateRoute = createAsyncThunk("leg/updateRoute", async (obj) => {
  const res = await request(`/routes/${obj.routeId}`, {
    method: "post",
    data: obj.data,
  });

  return res.data;
});

export const legsSlice = createSlice({
  name: "leg",
  initialState,
  reducers: {
    resetLeg: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(updateLeg.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateLeg.fulfilled, (state, action) => {
        state.leg = {};
        state.status = "succeeded";
        state.leg = action.payload.leg;
      })
      .addCase(updateLeg.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateRoute.fulfilled, (state, action) => {
        state.leg = action.payload.leg;
      })
      .addCase(addNewLeg.fulfilled, (state, action) => {
        state.leg = action.payload.data;
      });
  },
});

export const { resetLeg } = legsSlice.actions;

export default legsSlice.reducer;
