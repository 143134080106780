import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { renderQueries } from "../../../utils/request";

const ROOT = "http://localhost:8080/api/v1";

const initialState = {
  drivers: [],
  status: "idle",
  error: null,
};

export const fetchDrivers = createAsyncThunk(
  "drivers/fetchDrivers",
  async (obj, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/drivers?${queries}`);
    if (!res.data.drivers) {
      rejectWithValue(res.data);
      return;
    }
    return res.data;
  }
);

export const driversSlice = createSlice({
  name: "drivers",
  initialState,
  reducers: {
    resetDrivers: () => initialState,
    setDrivers(state, action) {
      state.drivers = action.payload;
    },
    driverAdded(state, action) {
      const { user } = action.payload;
      state.drivers = state.drivers.concat(user);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDrivers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchDrivers.fulfilled, (state, action) => {
        state.drivers = [];
        state.status = "succeeded";
        state.drivers = state.drivers.concat(action.payload.drivers);
      })
      .addCase(fetchDrivers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetDrivers, setDrivers, driverAdded } = driversSlice.actions;

export default driversSlice.reducer;

export const selectAllDrivers = (state) => state.drivers.drivers;

export const driverSelectOptions = (state) =>
  state.drivers.drivers.map((obj) => ({
    label: obj.fullName,
    value: obj,
  }));
