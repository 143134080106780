import { useState, useEffect } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import Form from "./Form";
import { capitalizeFirstLetter } from "../../utils";

export const MultiFormCreate = ({
  form,
  setFormInit,
  clearForm,
  driverOptions,
  type,
  formKey,
  isValid,
  data,
  onFormChange,
  onAddForm,
  setNewForm,
  onRemoveForm,
  ...rest
}) => {
  const formData = data || {};
  const [formIds, setFormIds] = useState([1]);

  useEffect(() => {
    if (Object.keys(formData).length === 0 && !formData[formKey]) {
      onFormChange(
        { id: formKey, value: { [type + "_1"]: {} } },
        { formArray: true }
      );
    }
  }, [formData]);

  // useEffect(() => {
  //   (() => {
  //     const formKeys = Object.keys(formData || {});
  //     const newData = formKeys.map((item, i) => {
  //       return i + 1;
  //     });
  //     if (newData.length > 0) {
  //       setFormIds(newData);
  //     } else {
  //       setFormIds([1]);
  //     }
  //   })();
  // }, [form]);

  function addForm(init, options = {}) {
    const currentLength = formIds.length;
    const newId = currentLength + 1;
    const newTypeId = type + "_" + newId;

    if (setNewForm) {
      const data = {
        currentLength,
        newId,
        newTypeId,
        options,
      };

      if (options.init) {
        data.init = init;
      }

      onAddForm && onAddForm(data);
    } else {
      if (newId > 1) {
        const setData = {
          id: formKey,
          value: {
            ...data,
            [newTypeId]: {},
          },
        };
        onFormChange(setData);
      }
    }

    setFormIds([...formIds, newId]);
  }

  function reduceObject(data, type) {
    if (data.length === 0) return {};

    return data.reduce((obj, v, i) => {
      const itemId = `${type}_${i + 1}`;
      const formKeys = Object.keys(v);

      const childData = formKeys.reduce((child, o) => {
        const splitId = o.split("_");
        const label = splitId[splitId.length - 1];
        child[`${itemId}_${label}`] = data[i][o];
        return child;
      }, {});

      obj[itemId] = formKeys.length > 0 ? childData : v;
      return obj;
    }, {});
  }

  function removeForm(id) {
    const formKeys = Object.keys(formData);
    let newData = formKeys
      .filter((key) => key.startsWith(type) && key !== id)
      .map((item) => {
        return {
          ...formData[item],
        };
      });
    const reducedObject = reduceObject(newData, type);

    if (onRemoveForm) {
      onRemoveForm(
        {
          ...form,
          [formKey]: { ...reducedObject },
        },
        id
      );
    } else {
      setFormInit(
        {
          ...form,
          [formKey]: { ...reducedObject },
        },
        { reset: true, setErrors: true, valid: true }
      );
    }

    setFormIds(newData.map((item, i) => i + 1));
  }

  return (
    <div className="mt-5">
      <div>
        {Object.keys(formData).map((key, i) => {
          const itemForm = formData[key];
          return (
            <Form
              {...rest}
              key={i}
              id={i + 1}
              formId={key}
              type={type}
              data={formData}
              form={form}
              formKey={formKey}
              formIds={formIds}
              remove={removeForm}
              itemForm={itemForm}
              setFormInit={setFormInit}
              onFormChange={onFormChange}
              addForm={addForm}
            />
          );
        })}
        {isValid && (
          <div className="flex">
            <div
              className="flex items-center text-primary-600 cursor-pointer"
              onClick={addForm}
            >
              <PlusCircleIcon className="h-6 w-6" />
              Add {capitalizeFirstLetter(type)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiFormCreate;
