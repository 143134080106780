import {
  Bars3Icon,
  ChevronDoubleLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Drawer from "../../shared/Drawer";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import Spinner from "../../shared/Spinner";

export const SidebarContainer = ({
  footerHeight = 24,
  hideSidebar,
  ...props
}) => {
  const parentRef = useRef();
  const childRef = useRef();
  const { size } = useWindowSize();
  const [open, setOpen] = useState(false);
  const isHidden = size.width < 1024;
  const [parentHeight, setparentHeight] = useState();
  const [containerWidth, setContainerWidth] = useState();
  const [headerHeight, setHeaderHeight] = useState();

  useEffect(() => {
    setparentHeight(parentRef.current?.clientHeight);
  }, [parentRef.current?.clientHeight]);
  useEffect(() => {
    setContainerWidth(parentRef.current?.clientWidth);
  }, [parentRef.current?.clientWidth]);
  useEffect(() => {
    setHeaderHeight(childRef.current?.clientHeight);
  }, [childRef.current?.clientHeight]);

  const transition = "transition-all duration-200";

  const asideClasses = classNames(
    "flex flex-col border-l hidden lg:block m-0",
    transition,
    {
      "w-[55rem] xl:w-[50rem]": open,
      "!w-[3rem] bg-white hover:bg-gray-100 cursor-pointer": !open,
    }
  );
  const childClasses = classNames(
    transition,
    "h-full",
    // " flex-auto",
    {
      "opacity-1": open,
      "opacity-0 hidden": !open,
    }
  );

  const footerClasses = classNames({
    "opacity-1": open,
    "opacity-0": !open,
  });

  const Icon = !open ? Bars3Icon : XMarkIcon;
  const contentClasses = classNames(
    "w-full",
    "flex flex-col h-screen"
    // "overflow-y-auto"
  );
  const iconWrapClasses = classNames(
    transition,
    "p-1 cursor-pointer flex text-gray-700 hover:bg-gray-100 rounded-lg",
    { "justify-center hover:bg-gray-100": !open, "justify-end bg-white": open }
  );
  const topClasses = classNames(
    "flex px-2 items-center",
    // "flex-initial",
    transition,
    {
      "justify-center": !open,
      "justify-between bg-white border-b": open,
    }
  );
  const sideTitleClass = classNames("flex p-[0.24rem] bg-white", transition, {
    hidden: !open,
    block: open,
  });
  const childWrapper = classNames("flex flex-grow overflow-hidden");

  function handleOpen() {
    setOpen(!open);
  }
  const iconClass = classNames("w-4 w-5 text-gray-800");

  const wrapperClass = classNames("flex border-t", props.className);
  return (
    <>
      <div ref={parentRef} className={wrapperClass}>
        <div className={contentClasses}>
          {!props.hideHeader && (
            <div>
              {props.header ? (
                props.header({
                  setOpen,
                  hideSidebar,
                  parentRef,
                  childRef,
                  ...props,
                })
              ) : (
                <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
                  <div>{props.title}</div>
                  <div className="flex items-center space-x-3">
                    {props.actions}
                    <div
                      onClick={handleOpen}
                      className="hover:bg-gray-100 cursor-pointer p-2 rounded-lg block lg:hidden"
                    >
                      <ChevronDoubleLeftIcon className={iconClass} />
                    </div>
                  </div>
                </header>
              )}
            </div>
          )}

          {/* Children */}
          <div ref={childRef} className={childWrapper}>
            {props.loading ? (
              <div className="w-full flex justify-center items-center">
                <Spinner />
              </div>
            ) : (
              props.children({
                headerHeight,
                parentHeight,
                containerWidth,
                parentRef,
                childRef,
              })
            )}
          </div>
        </div>

        {/* Sidebar */}
        {!hideSidebar && (
          <aside className={asideClasses} onClick={() => !open && handleOpen()}>
            <div className="">
              <div className="contents">
                <div className={topClasses}>
                  <div className={sideTitleClass}>{props.sidebarTitle}</div>
                  <div className={iconWrapClasses} onClick={handleOpen}>
                    <Icon className="h-6 w-6" />
                  </div>
                </div>
                <div className={childClasses}>{props.sidebarContent}</div>
                {props.footer && (
                  <div
                    className={footerClasses}
                    style={{ flex: `0 1 ${footerHeight}rem` }}
                  >
                    {props.footer}
                  </div>
                )}
              </div>
            </div>
          </aside>
        )}
      </div>
      {isHidden && (
        <Drawer
          position="right"
          widthClasses="max-w-md"
          title={props.sidebarTitle}
          body={props.sidebarContent}
          visible={open}
          setVisible={setOpen}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};
