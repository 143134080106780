import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../../../shared/Modal";
import { useDeleteJobsMutation } from "../../../redux/services/jobs/jobsApi";

export const DeleteJobModal = ({ visible, setVisible, jobs, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deleteJobs, { isLoading: isDeleting }] = useDeleteJobsMutation();

  const title = t("jobs.delete.title");

  function cannotDeleteJob() {
    if (Array.isArray(jobs)) {
      const jobsArray = jobs;
      let error = false;
      jobsArray.forEach((job) => {
        if (job.invoiceId) {
          error = "jobs.delete.remove-invoice";
          return;
        }

        if (Array.isArray(job.legs)) {
          const foundManifest = job.legs.find((leg) => leg.manifestId);
          if (foundManifest) {
            error = "jobs.delete.remove-manifest";
            return;
          }
          const foundPayout = job.legs.find((leg) => leg.payoutId);
          if (foundPayout) {
            error = "jobs.delete.remove-payout";
            return;
          }
          // if (job.legs.length > 0) {
          //   error = "jobs.delete.remove-legs";
          //   return;
          // }
        }
      });
      return error;
    }
  }

  function renderContent() {
    const cannotDelete = cannotDeleteJob();
    if (cannotDelete) {
      return t(cannotDelete);
    }

    return t("jobs.delete.default");
  }

  async function handleDelete() {
    const res = await deleteJobs({ data: jobs });

    toast(res.data.message, {
      type: res.data.status,
      toastId: "delete-jobs",
    });

    if (res.data.status === "success") {
      navigate("/jobs");
      setVisible(false);
      props.deselectItems();
    }

    return res.data;
  }

  return (
    <Modal
      loading={isDeleting}
      confirm={handleDelete}
      disabled={cannotDeleteJob()}
      visible={visible}
      setVisible={setVisible}
      title={title}
      content={renderContent()}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};
